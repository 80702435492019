export default function cleanFormatFilters() {
  for (const key in localStorage) {
    //all filters
    if (key.includes('/')) {
      const { filters } = JSON.parse(localStorage[key])

      Object.keys(filters).forEach((o) => {
        const filter = filters[o],
          //new format have always whereclause AND description
          isgoodFormat = !!filter?.whereClause && !!filter?.description && !!filter?.label

        if (!isgoodFormat) {
          localStorage.removeItem(key)
        }
      })
    }
  }
}
