import { DateState } from '../../../../types/dateState.interface';
import { dateSelect } from './date.select';
import classes from './DateFilterResume.module.css';
import dateLabel from './dateLabel';

interface props {
  readonly filters: any
  readonly name: string
  readonly t: Function
  readonly resetDate: Function
  readonly handleSelect: Function
  readonly dateState: DateState
  readonly iHaveDateMin: boolean
}

export default function DateFilterResumeView({
  t,
  dateState,
  resetDate,
  name,
  filters,
  handleSelect,
  iHaveDateMin,
}: props) {
  return (
    <div className={classes.container}>
      {filters && filters[name] ? (
        <div className={classes.txt}>
          <div
            className={classes.txt}
            dangerouslySetInnerHTML={{
              __html: t(dateLabel(dateState[0], t)),
            }}
          />
          {iHaveDateMin && (
            <div className={classes.defaultDate}>{t('default date')}</div>
          )}
          <div className={classes.delete} onClick={() => resetDate()}>
            <div>{t('delete')}</div>
          </div>
        </div>
      ) : (
        <div className={classes.noDate}>{t('no date choiced')}</div>
      )}
      <div className={classes.choices}>
        {dateSelect.map((choice) => (
          <div
            className={classes.choice}
            data-current={
              filters ? dateLabel(dateState[0], t) === choice.period : ''
            }
            key={choice.period}
            onClick={() =>
              handleSelect({
                startDate: choice.start,
                endDate: choice.end,
                key: 'selection',
              })
            }
          >
            <span className={classes.choiceTxt}>{t(choice.period)}</span>
          </div>
        ))}
      </div>
      <div className={classes.choices}>
      </div>
    </div>
  );
}
