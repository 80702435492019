/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 5.38.4
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Brand from '../model/Brand';
import Category from '../model/Category';
import CompanyAccount from '../model/CompanyAccount';
import Localization from '../model/Localization';
import ObjectID from '../model/ObjectID';
import Sem from '../model/Sem';
import Term from '../model/Term';
import TermCategory from '../model/TermCategory';
import TermCompanyAccount from '../model/TermCompanyAccount';

/**
* Term service.
* @module api/TermApi
* @version 5.38.4
*/
export default class TermApi {

    /**
    * Constructs a new TermApi. 
    * @alias module:api/TermApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termCountWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termCount(opts) {
      return this.termCountWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object

      return this.apiClient.callApi(
        '/Terms', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termCreate(opts) {
      return this.termCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    termCreateChangeStreamGetTermsChangeStreamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'options': opts['options']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Terms/change-stream', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    termCreateChangeStreamGetTermsChangeStream(opts) {
      return this.termCreateChangeStreamGetTermsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    termCreateChangeStreamPostTermsChangeStreamWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Terms/change-stream', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    termCreateChangeStreamPostTermsChangeStream(opts) {
      return this.termCreateChangeStreamPostTermsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termDeleteByIdWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termDeleteById(id) {
      return this.termDeleteByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete all terms matching termId.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termDestroyAllWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'where': opts['where']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete all terms matching termId.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termDestroyAll(opts) {
      return this.termDestroyAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termExistsGetTermsidExistsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}/exists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termExistsGetTermsidExists(id) {
      return this.termExistsGetTermsidExistsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termExistsHeadTermsidWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termExistsHeadTermsid(id) {
      return this.termExistsHeadTermsidWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetch google search stats and frequency accordingly
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termFetchSearchStatsWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'where': opts['where'],
        'searchConvertRate': opts['searchConvertRate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/fetchSearchStats', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetch google search stats and frequency accordingly
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termFetchSearchStats(opts) {
      return this.termFetchSearchStatsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Term>} and HTTP response
     */
    termFindWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Term];

      return this.apiClient.callApi(
        '/Terms', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Term>}
     */
    termFind(opts) {
      return this.termFindWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termFindByIdWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termFindById(id, opts) {
      return this.termFindByIdWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termFindOneWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/findOne', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termFindOne(opts) {
      return this.termFindOneWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termPatchOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termPatchOrCreate(opts) {
      return this.termPatchOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts ads of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termPrototypeCountAdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}/ads/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts ads of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termPrototypeCountAds(id, opts) {
      return this.termPrototypeCountAdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts canBeAccessBy of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termPrototypeCountCanBeAccessByWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts canBeAccessBy of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termPrototypeCountCanBeAccessBy(id, opts) {
      return this.termPrototypeCountCanBeAccessByWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts categories of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termPrototypeCountCategoriesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts categories of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termPrototypeCountCategories(id, opts) {
      return this.termPrototypeCountCategoriesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in ads of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sem} and HTTP response
     */
    termPrototypeCreateAdsWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'termId': opts['termId'],
        'date': opts['_date'],
        'title': opts['title'],
        'description': opts['description'],
        'displayUrl': opts['displayUrl'],
        'targetUrl': opts['targetUrl'],
        'position': opts['position'],
        'realPosition': opts['realPosition'],
        'domain': opts['domain'],
        'country': opts['country'],
        'language': opts['language'],
        'region': opts['region'],
        'engine': opts['engine'],
        'type': opts['type'],
        'device': opts['device'],
        'id': opts['id'],
        'requestId': opts['requestId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Sem;

      return this.apiClient.callApi(
        '/Terms/{id}/ads', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in ads of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sem}
     */
    termPrototypeCreateAds(id2, opts) {
      return this.termPrototypeCreateAdsWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in canBeAccessBy of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    termPrototypeCreateCanBeAccessByWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in canBeAccessBy of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    termPrototypeCreateCanBeAccessBy(id2, opts) {
      return this.termPrototypeCreateCanBeAccessByWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in categories of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    termPrototypeCreateCategoriesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Terms/{id}/categories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in categories of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    termPrototypeCreateCategories(id2, opts) {
      return this.termPrototypeCreateCategoriesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all ads of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDeleteAdsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/ads', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all ads of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDeleteAds(id) {
      return this.termPrototypeDeleteAdsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all canBeAccessBy of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDeleteCanBeAccessByWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all canBeAccessBy of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDeleteCanBeAccessBy(id) {
      return this.termPrototypeDeleteCanBeAccessByWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all categories of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDeleteCategoriesWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/categories', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all categories of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDeleteCategories(id) {
      return this.termPrototypeDeleteCategoriesWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDestroyByIdAdsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/ads/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDestroyByIdAds(id, fk) {
      return this.termPrototypeDestroyByIdAdsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDestroyByIdCanBeAccessByWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDestroyByIdCanBeAccessBy(id, fk) {
      return this.termPrototypeDestroyByIdCanBeAccessByWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeDestroyByIdCategoriesWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeDestroyByIdCategories(id, fk) {
      return this.termPrototypeDestroyByIdCategoriesWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check the existence of canBeAccessBy relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    termPrototypeExistsCanBeAccessByWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/rel/{fk}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check the existence of canBeAccessBy relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    termPrototypeExistsCanBeAccessBy(id, fk) {
      return this.termPrototypeExistsCanBeAccessByWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check the existence of categories relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    termPrototypeExistsCategoriesWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/Terms/{id}/categories/rel/{fk}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check the existence of categories relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    termPrototypeExistsCategories(id, fk) {
      return this.termPrototypeExistsCategoriesWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sem} and HTTP response
     */
    termPrototypeFindByIdAdsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Sem;

      return this.apiClient.callApi(
        '/Terms/{id}/ads/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sem}
     */
    termPrototypeFindByIdAds(id, fk) {
      return this.termPrototypeFindByIdAdsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    termPrototypeFindByIdCanBeAccessByWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    termPrototypeFindByIdCanBeAccessBy(id, fk) {
      return this.termPrototypeFindByIdCanBeAccessByWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    termPrototypeFindByIdCategoriesWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    termPrototypeFindByIdCategories(id, fk) {
      return this.termPrototypeFindByIdCategoriesWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries ads of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Sem>} and HTTP response
     */
    termPrototypeGetAdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Sem];

      return this.apiClient.callApi(
        '/Terms/{id}/ads', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries ads of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Sem>}
     */
    termPrototypeGetAds(id, opts) {
      return this.termPrototypeGetAdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetches belongsTo relation brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    termPrototypeGetBrandWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'refresh': opts['refresh']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Terms/{id}/brand', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetches belongsTo relation brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    termPrototypeGetBrand(id, opts) {
      return this.termPrototypeGetBrandWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries canBeAccessBy of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CompanyAccount>} and HTTP response
     */
    termPrototypeGetCanBeAccessByWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [CompanyAccount];

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries canBeAccessBy of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CompanyAccount>}
     */
    termPrototypeGetCanBeAccessBy(id, opts) {
      return this.termPrototypeGetCanBeAccessByWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries categories of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Category>} and HTTP response
     */
    termPrototypeGetCategoriesWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Category];

      return this.apiClient.callApi(
        '/Terms/{id}/categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries categories of Term.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Category>}
     */
    termPrototypeGetCategories(id, opts) {
      return this.termPrototypeGetCategoriesWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetches belongsTo relation localization.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Localization} and HTTP response
     */
    termPrototypeGetLocalizationWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'refresh': opts['refresh']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Localization;

      return this.apiClient.callApi(
        '/Terms/{id}/localization', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetches belongsTo relation localization.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Localization}
     */
    termPrototypeGetLocalization(id, opts) {
      return this.termPrototypeGetLocalizationWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TermCompanyAccount} and HTTP response
     */
    termPrototypeLinkCanBeAccessByWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'id': opts['id'],
        'termId': opts['termId'],
        'companyAccountId': opts['companyAccountId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = TermCompanyAccount;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/rel/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TermCompanyAccount}
     */
    termPrototypeLinkCanBeAccessBy(id2, fk, opts) {
      return this.termPrototypeLinkCanBeAccessByWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TermCategory} and HTTP response
     */
    termPrototypeLinkCategoriesWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'id': opts['id'],
        'termId': opts['termId'],
        'categoryId': opts['categoryId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = TermCategory;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/rel/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TermCategory}
     */
    termPrototypeLinkCategories(id2, fk, opts) {
      return this.termPrototypeLinkCategoriesWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termPrototypePatchAttributesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termPrototypePatchAttributes(id2, opts) {
      return this.termPrototypePatchAttributesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the canBeAccessBy relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeUnlinkCanBeAccessByWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/rel/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the canBeAccessBy relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeUnlinkCanBeAccessBy(id, fk) {
      return this.termPrototypeUnlinkCanBeAccessByWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the categories relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    termPrototypeUnlinkCategoriesWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/rel/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the categories relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    termPrototypeUnlinkCategories(id, fk) {
      return this.termPrototypeUnlinkCategoriesWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Sem} and HTTP response
     */
    termPrototypeUpdateByIdAdsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'termId': opts['termId'],
        'date': opts['_date'],
        'title': opts['title'],
        'description': opts['description'],
        'displayUrl': opts['displayUrl'],
        'targetUrl': opts['targetUrl'],
        'position': opts['position'],
        'realPosition': opts['realPosition'],
        'domain': opts['domain'],
        'country': opts['country'],
        'language': opts['language'],
        'region': opts['region'],
        'engine': opts['engine'],
        'type': opts['type'],
        'device': opts['device'],
        'id': opts['id'],
        'requestId': opts['requestId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Sem;

      return this.apiClient.callApi(
        '/Terms/{id}/ads/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for ads.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Sem}
     */
    termPrototypeUpdateByIdAds(id2, fk, opts) {
      return this.termPrototypeUpdateByIdAdsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    termPrototypeUpdateByIdCanBeAccessByWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Terms/{id}/canBeAccessBy/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for canBeAccessBy.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    termPrototypeUpdateByIdCanBeAccessBy(id2, fk, opts) {
      return this.termPrototypeUpdateByIdCanBeAccessByWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    termPrototypeUpdateByIdCategoriesWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Terms/{id}/categories/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for categories.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    termPrototypeUpdateByIdCategories(id2, fk, opts) {
      return this.termPrototypeUpdateByIdCategoriesWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termReplaceByIdPostTermsidReplaceWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termReplaceByIdPostTermsidReplace(id2, opts) {
      return this.termReplaceByIdPostTermsidReplaceWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termReplaceByIdPutTermsidWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termReplaceByIdPutTermsid(id2, opts) {
      return this.termReplaceByIdPutTermsidWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termReplaceOrCreatePostTermsReplaceOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/replaceOrCreate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termReplaceOrCreatePostTermsReplaceOrCreate(opts) {
      return this.termReplaceOrCreatePostTermsReplaceOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termReplaceOrCreatePutTermsWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termReplaceOrCreatePutTerms(opts) {
      return this.termReplaceOrCreatePutTermsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    termUpdateAllWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Terms/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    termUpdateAll(opts) {
      return this.termUpdateAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    termUpsertWithWhereWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'state': opts['state'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Terms/upsertWithWhere', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    termUpsertWithWhere(opts) {
      return this.termUpsertWithWhereWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
