/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 5.38.4
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Brand from './Brand';
import Category from './Category';
import CompanyAccount from './CompanyAccount';
import Localization from './Localization';
import ObjectID from './ObjectID';

/**
 * The Term model module.
 * @module model/Term
 * @version 5.38.4
 */
export default class Term {
  /**
   * Constructs a new <code>Term</code>.
   * @alias module:model/Term
   * @class
   * @param term {String}
   * @param keywordType {String}
   * @param devices {Array.<String>}
   * @param every {Number}
   * @param engine {String}
   * @param localizationId {String}
   * @param region {String}
   * @param localizationId {module:model/ObjectID}
   */

  constructor(
    term,
    keywordType,
    devices,
    every,
    engine,
    localizationId,
    region
  ) {
    this['term'] = term;
    this['keywordType'] = keywordType;
    this['devices'] = devices;
    this['every'] = every;
    this['engine'] = engine;
    this['localizationId'] = localizationId;
    this['region'] = region;
  }

  /**
   * Constructs a <code>Term</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Term} obj Optional instance to populate.
   * @return {module:model/Term} The populated <code>Term</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Term();

      if (data.hasOwnProperty('term')) {
        obj['term'] = ApiClient.convertToType(data['term'], 'String');
      }
      if (data.hasOwnProperty('keywordType')) {
        obj['keywordType'] = ApiClient.convertToType(
          data['keywordType'],
          'String'
        );
      }
      if (data.hasOwnProperty('devices')) {
        obj['devices'] = ApiClient.convertToType(data['devices'], ['String']);
      }
      if (data.hasOwnProperty('every')) {
        obj['every'] = ApiClient.convertToType(data['every'], 'Number');
      }
      if (data.hasOwnProperty('engine')) {
        obj['engine'] = ApiClient.convertToType(data['engine'], 'String');
      }
      if (data.hasOwnProperty('state')) {
        obj['state'] = ApiClient.convertToType(data['state'], 'String');
      }
      if (data.hasOwnProperty('region')) {
        obj['region'] = ApiClient.convertToType(data['region'], 'String');
      }
      if (data.hasOwnProperty('localizationId')) {
        obj['localizationId'] = ObjectID.constructFromObject(
          data['localizationId']
        );
      }
      if (data.hasOwnProperty('options')) {
        obj['options'] = ApiClient.convertToType(data['options'], Object);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ObjectID.constructFromObject(data['id']);
      }
      if (data.hasOwnProperty('brandId')) {
        obj['brandId'] = ObjectID.constructFromObject(data['brandId']);
      }
      if (data.hasOwnProperty('canBeAccessBy')) {
        obj['canBeAccessBy'] = ApiClient.convertToType(data['canBeAccessBy'], [
          CompanyAccount,
        ]);
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], [
          Category,
        ]);
      }

      if (data.hasOwnProperty('localization')) {
        obj['localization'] = ApiClient.convertToType(
          data['localization'],
          Localization
        );
      }

      if (data.hasOwnProperty('createdDate')) {
        obj['createdDate'] = ApiClient.convertToType(
          data['createdDate'],
          'Date'
        );
      }
      if (data.hasOwnProperty('updatedDate')) {
        obj['updatedDate'] = ApiClient.convertToType(
          data['updatedDate'],
          'Date'
        );
      }
      if (data.hasOwnProperty('brand')) {
        obj['brand'] = ApiClient.convertToType(data['brand'], Brand);
      }
    }
    return obj;
  }

  /**
   * @member {String} term
   */
  term = undefined;
  /**
   * @member {String} keywordType
   * @default 'GENERIC'
   */
  keywordType = 'GENERIC';
  /**
   * @member {Array.<String>} devices
   */
  devices = undefined;
  /**
   * @member {Number} every
   */
  every = undefined;
  /**
   * @member {String} engine
   * @default 'GOOGLE'
   */
  engine = 'GOOGLE';
  /**
   * @member {String} state
   * @default 'disabled'
   */
  state = 'disabled';
  /**
   * @member {String} region
   */
  region = undefined;

  /**
   * @member {module:model/ObjectID} localizationId
   */
  localizationId = undefined;
  /**
   * @member {Object} options
   */
  options = undefined;
  /**
   * @member {module:model/ObjectID} id
   */
  id = undefined;
  /**
   * @member {module:model/ObjectID} brandId
   */
  brandId = undefined;
  /**
   * @member {Date} createdDate
   */
  createdDate = undefined;
  /**
   * @member {Date} updatedDate
   */
  updatedDate = undefined;
}
