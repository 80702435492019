import { useState } from 'react';

export default function usePost<T>(fetcher: Function) {
  const [loading, setLoading] = useState<boolean>(false),
    [error, setError] = useState<null | { message: string }>(null),
    [data, setData] = useState<T>();

  async function post(body: Object) {
    setLoading(true);
    try {
      setData(await fetcher(body));
    } catch (e: any) {
      setError(e);
    }
    setLoading(false);
  }

  return { loading, error, data, post };
}
