import DynamicScrollbar from '../../../components/DynamicScrollbar/DynamicScrollbar';
import UserForm from '../../../components/User/UserForm/UserForm';
import classes from './UserAdd.module.css';

export default function UserAdd(){

  return <DynamicScrollbar className={classes.container}>
    <div className={classes.box}>
      <UserForm/>
    </div>
  </DynamicScrollbar>;
}