import Flexbox from '../../_ui/Flexbox/Flexbox';
import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';
import useTermBubbleForTooltip from './useTermBubbleForTooltip';
import { useTranslation } from 'react-i18next';

TermBubbleForTooltip.propTypes = {
  term: PropTypes.shape(termDefinition),
};

export default function TermBubbleForTooltip({term}){
  const icons = useTermBubbleForTooltip(term),
    {t} = useTranslation();

  return <Flexbox>{
    icons.length === 0 ?
      <Flexbox column>
        <div>{t('The options do not allow')}</div>
        <div>{t('the detection of ads')}</div>
      </Flexbox>:
      icons.map(icon=><img key={icon} src={icon} alt={icon}/>)}
  </Flexbox>;
}
