import { pictos } from '../../../types/pictos.enum';
import UsersStore from '../../../pages/Users/users.store';
import { useHistory} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { StoreContext } from '../../../stores';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

interface Props {
  item: any
  store: UsersStore
}

function UserActionsCell({ item, store } : Props) {
  const 
    // call authstore ? 
    {authStore} = useContext(StoreContext),
    history = useHistory();

  return (
    <ShortMenuModale >
      <ShortMenuList>
        <ShortMenuAction 
          icon={pictos.switchAccount} 
          testid='switch-user'
          text="Switch user" 
          onClick={() =>
            store.setSelectedItem({
              user: item,
              action: async () => {
                await authStore.switchUser(item.id);
                store.setSelectedItem(null);
              },
              type: 'work as',
            })
          }
        />
        <ShortMenuAction 
          icon={pictos.pen} 
          text="Update user" 
          testid="update-user"
          onClick={() => history.push('/users/'+ item.id)}
        />
        <ShortMenuAction 
          icon={pictos.trash} 
          testid='delete-user'
          text="Delete user" 
          onClick={() =>
            store.setSelectedItem({
              user: item,
              action: () => store.deleteUser(),
              type: 'delete',
            })
          }
        />
      </ShortMenuList>
    </ShortMenuModale>
  );
}

export default  observer(UserActionsCell);
