export default function createPullUserMiddleware() {
  return async (to, from, next) => {

    if (!to.meta.uiStore.appLoaded) {
      await to.meta.authStore.pullUser();
      to.meta.uiStore.setAppLoaded(true);
    }

    if(to.meta.isAdmin && !to.meta.authStore.isAdmin){
      return next.redirect('/');
    }

    if (
      to.meta.guard &&
      !to.meta.authStore.currentUser &&
      to.location.pathname !== '/login'
    ) {
      const url = from.location.search
        ? `/login?redirect=${from.location.pathname}${from.location.search}`
        : `/login?redirect=${from.location.pathname}`;
      return next.redirect(url);
    }

    return next();
  };
}
