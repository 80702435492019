import { useReducer } from 'react';
import 'cropperjs/dist/cropper.css';
import defaultAvatar from '../../assets/img/default.png';
import classes from './AvatarEditor.module.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { AvatarEditorActionsType, AvatarEditorInitialState, AvatarEditorReducer } from './AvatarEditor.reducer';
import Button from '../Button/Button';

AvatarEditor.propTypes = {
  onSubmit: PropTypes.func,
  currentAvatar:PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

AvatarEditor.defaultProps = {
  currentAvatar: defaultAvatar
};

/**
 * 
 * Vous pouvez glisser-déposer une image dans le cercle.
 * onSubmit Permet de récuper l'image redimensionnée en base64.
 */
export default function AvatarEditor({onSubmit,currentAvatar}) {
  const { t } = useTranslation(),
    [{ preview, imgBase64, cropImg, cropBlob,isHover }, dispatch] = useReducer(AvatarEditorReducer,AvatarEditorInitialState),
    cropperRef = useRef();

  async function handleDrop(e) {
    e.preventDefault();

    if (e.dataTransfer.items) {
      for (const item of e.dataTransfer.files) {
        if(item.type.match(/image/)){
          getBase64(item);
        } else {
          toast.warning(t('This is not an image'));
        }
      }
    }

  }

  async function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    dispatch({type: AvatarEditorActionsType.setPreview,payload: await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })});
  }

  // just for UI (border when hover with img)
  async function handleDragOver(e) {
    e.preventDefault();
    if (!isHover) dispatch({type:AvatarEditorActionsType.setIsHover,payload:true});
  }

  function submitFile(e) {
    getBase64(_.first(e.target.files));
  }

  function onCrop() {
    const imageElement= cropperRef?.current,
      cropper = imageElement?.cropper;

    cropper.getCroppedCanvas({ width: 80, height: 80 }).toBlob((blob)=>{
      dispatch({type: AvatarEditorActionsType.setCropImg,payload: {
        cropImg : cropper
          .getCroppedCanvas({ width: 80, height: 80 })
          .toDataURL('image/jpeg'),
        cropBlob: blob
      }});
    },'image/jpeg');
 
  }

  async function handleSubmit(){
    onSubmit(cropBlob);
    dispatch({type: AvatarEditorActionsType.handleSubmit,payload:cropImg});
  }

  return (
    <div className={classes.container}>
      {preview && (
        <div className={classes.modale}>
          <div className={classes.cropper}>
            <Cropper
              src={preview}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
            />
          </div>
          <div className={classes.buttons}>
            <Button text="Cancel" type="reset" onClick={()=>dispatch({type:AvatarEditorActionsType.handleCancel})}/>
            <Button text="submit" onClick={handleSubmit}/>
          </div>
        </div>
      )}
      <label htmlFor="file" 
        id="drop_zone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={() => dispatch({type:AvatarEditorActionsType.setIsHover,payload:false})}
      >
        <div className={classes.dropZone} data-ishover={isHover}>
          <img
            className={classes.avatar}
            src={imgBase64 || (currentAvatar || defaultAvatar) }
            alt="preview"
          />
        </div>
      </label>
      <input
        type="file"
        name="file"
        id="file"
        className={classes.input}
        onChange={submitFile}
        accept="image/*"
      />
      <label htmlFor="file" className={classes.label}>
        {t('Choose a picture')}
      </label>
    </div>
  );
}
