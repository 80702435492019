import { CategoryApi, DealerApi } from '../../../swagger_client/src';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandInterface } from '../../../types/brand.interface';
import i18next from 'i18next';
import fr from './fr.json';
import en from './en.json';
import { TagInterface } from '../../../types/tag.interface';
import { getOrCreateExistingOfficialTag } from '../../../Utils/officialTags/getOrCreateExistingOfficialTag';
import Dealer from '../../../swagger_client/test/model/Dealer';
import { toast } from 'react-toastify';
import useFetch from '../../Hooks/useFetch';

interface props {
  readonly onClose: Function
  readonly brand: BrandInterface
}
export default function useBrandTagOfficial({ onClose, brand }: props) {
  i18next.addResourceBundle('fr', 'useBrandTagOfficial', fr);
  i18next.addResourceBundle('en', 'useBrandTagOfficial', en);

  const categoryApi = new CategoryApi(),
    dealerApi = new DealerApi(),
    {
      data: categories,
      loading
    } = useFetch<Array<TagInterface>>(
      (body: object) => categoryApi.categoryFind(body),
      {
        filter: JSON.stringify({
          where: {
            scope: 'tag',
            brandOwnerId: brand.id,
            label: { regexp: '/official/i' },
          },
          include: ['dealers'],
        }),
      }
    ),
    [loadingSubmit, setLoadingSubmit] = useState<boolean>(false),
    [domains, setDomains] = useState<Array<string>>([]),
    { t } = useTranslation('useBrandTagOfficial'),
    initialDomains = useMemo(() => {
      if (!categories) return [];

      return categories.reduce((acc: Array<string>, tag: TagInterface) => {
        if (tag?.dealers)
          acc.push(...tag.dealers.map((dealer) => dealer.domain));
        return acc;
      }, []);
    }, [categories]);

  useEffect(() => {
    if (initialDomains) {
      setDomains(initialDomains);
    }
  }, [initialDomains]);

  

  async function unLinkDomains(officialTag: TagInterface ) {
    const unTagDealers: Array<Dealer> = await dealerApi.dealerFind({
      filter: JSON.stringify({
        where: {
          domain: {
            inq: initialDomains.filter(
              (unTagDomain) => !domains.includes(unTagDomain)
            ),
          },
        },
      }),
    });
    const successDealersToRemove = [...unTagDealers];

    for (const unTagDealer of unTagDealers) {
      try {
        await dealerApi.dealerPrototypeUnlinkCategories(unTagDealer.id, officialTag.id);
      } catch (_e) {
        const index = successDealersToRemove.findIndex(
          (dealer) => dealer.id === unTagDealer.id
        );
        successDealersToRemove.splice(index, 1);
      }
    }

    if (successDealersToRemove.length > 0) {
      const msg = (
        <span
          dangerouslySetInnerHTML={{
            __html: t('removedDomains', {
              names: successDealersToRemove.map(domain=>domain.domain).join(', '),
            }),
          }}
        ></span>
      );
      toast.success(msg);
    }

    const errorDealersToRemove = unTagDealers.filter((domain)=>!successDealersToRemove.includes(domain));

    if (errorDealersToRemove.length > 0) {
      const msg = (
        <span
          dangerouslySetInnerHTML={{
            __html: t('removedDomainsError', {
              names: errorDealersToRemove.map(domain=>domain.domain).join(', '),
            }),
          }}
        ></span>
      );
      toast.error(msg);
    }
  }

  async function linkDomains(officialTag: TagInterface) {
    const tagDomains = domains.filter(
      (tagDomain) => !initialDomains.includes(tagDomain)
    );

    const existDomains:Array<any> = await dealerApi.dealerFind({
      filter: JSON.stringify({
        where: { domain: {inq:tagDomains} }
      })
    });

    const createDomains= [];

    const existDomainsStr:any = existDomains.map(domain=>domain.domain);
    const tagsDomainsToHandle = tagDomains.filter(domain=> !existDomainsStr.includes(domain));
    for (const createDomain of tagsDomainsToHandle) {
      const newDomain = await dealerApi.dealerCreate({
        body: {
          domain: createDomain
        }
      });
      if(newDomain)createDomains.push(newDomain);
    }

    const 
      dealersToTag = [...createDomains,...existDomains],
      succesDealersToTag = [...dealersToTag];

    for (const dealerToTag of dealersToTag) {
      try {
        await dealerApi.dealerPrototypeLinkCategories(dealerToTag.id, officialTag.id, {});
      } catch (_e) {
        const index = succesDealersToTag.findIndex(
          (dealer) => dealer.id === dealerToTag.id
        );
        succesDealersToTag.splice(index, 1);
      }
    }
  
    if (succesDealersToTag.length > 0) {
      const msg = (
        <span
          dangerouslySetInnerHTML={{
            __html: t('createdDomains', {
              names: succesDealersToTag.map(domain=>domain.domain).join(', '),
            }),
          }}
        ></span>
      );
      toast.success(msg);
    }
  
    const errorDealersToAdd = dealersToTag.filter((domain)=>!succesDealersToTag.includes(domain));
  
    if (errorDealersToAdd.length > 0) {
      const msg = (
        <span
          dangerouslySetInnerHTML={{
            __html: t('createdDomainsError', {
              names: errorDealersToAdd.map(domain=>domain.domain).join(', '),
            }),
          }}
        ></span>
      );
      toast.error(msg);
    }
  }

  async function handleLinkDomains() {
    setLoadingSubmit(true);

    const officialTag: TagInterface | undefined = await getOrCreateExistingOfficialTag(
      brand
    );
    if (officialTag) {
      await unLinkDomains(officialTag);
      await linkDomains(officialTag);
    }
    
    setLoadingSubmit(false);
    onClose();
  }

  function setDomainWithControl(domains: Array<string>) {
    const matchDomain = new RegExp(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    );
    //filter to prevent old value.
    setDomains(
      domains.filter((domain) => {
        const isDomain = domain.match(matchDomain);
        if (isDomain) return isDomain[0];
        return false;
      })
    );
  }

  return {
    loading: loading || loadingSubmit,
    disabledSubmit: initialDomains.toString() === domains.toString(),
    t,
    domains,

    handleLinkDomains,
    setDomainWithControl,
    newDomains: [],
    removedDomains: [],
  };
}
