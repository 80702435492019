import classes from './Menu.module.css';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuProvider } from './MenuContext';
import MenuContent from './MenuContent/MenuContent';
import MenuFooter from './MenuFooter/MenuFooter';
import useScreenSize from '../Hooks/useScreenSize';
import throttle from 'lodash.throttle';

Menu.propTypes = {
  setIsOpen: PropTypes.func,
  onMenuToggled: PropTypes.func,
  onMenuHovered: PropTypes.func,
  onMenuMinimized: PropTypes.func
};

function Menu({setIsOpen, onMenuToggled, onMenuHovered, onMenuMinimized}) {
  const [ expanded, setExpanded ] = useState(true),
    [ hovered, setHovered ] = useState(false),
    matchScreenSize = useScreenSize(1440);

  useEffect(() => {
    onMenuHovered && onMenuHovered(hovered);
  }, [ hovered, onMenuHovered ]);

  useEffect(() => {
    onMenuToggled && onMenuToggled(expanded);
  }, [ expanded, onMenuToggled ]);

  useEffect(() => {
    onMenuMinimized && onMenuMinimized(matchScreenSize);
  }, [ matchScreenSize, onMenuMinimized ]);

  useEffect(() => {
    setExpanded(!matchScreenSize);
  }, [ matchScreenSize ]);

  const toggleExpanded = () => {
    setExpanded(expanded => !expanded);
  };

  const onMouseOver = throttle(() => {
    matchScreenSize && !hovered && setHovered(true);
  }, 300);

  const onMouseOut = throttle(() => {
    matchScreenSize && hovered && setHovered(false);
  }, 300);

  return (
    <div
      className={`${classes.container} ${expanded ? classes.expanded : ''} ${hovered ? classes.hovered : ''} ${matchScreenSize ? classes.minized : ''}`}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOut}
    >
      <MenuProvider expanded={expanded || hovered}>
        <MenuContent setIsOpen={setIsOpen} />
        <MenuFooter
          toggleExpanded={toggleExpanded}
          menuMinimized={matchScreenSize}
        />
      </MenuProvider>
    </div>
  );
}
export default observer(Menu);
