// import { useMemo } from 'react';
import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
// import { Link } from 'react-router-dom';
import classes from './NameWithLinkAndSearchCell.module.css';
// import queryString from 'query-string';
import DomainsStore from '../../../pages/Domains/domains.store';
import AdvertisersStore from '../../../pages/Advertisers/advertisers.store';
import PropTypes from 'prop-types';
import domainDefinition from '../../../proptypes/domain.definition';

NameWithLinkAndSearchCell.propTypes = {
  item: PropTypes.shape(domainDefinition),
  store: PropTypes.oneOfType([
    PropTypes.instanceOf(DomainsStore),
    PropTypes.instanceOf(AdvertisersStore),
  ]),
  field: PropTypes.string.isRequired
};

export default function NameWithLinkAndSearchCell({ item, field, store }) {
  /*const domainUrl = useMemo(() => {
    const filter = { ...store.filters };

    // Only apply for advertiser
    if (filter.countries) {
      filter.country = filter.countries;
      delete filter.countries;
    }

    // Only apply for advertiser
    if (filter.lastView) {
      filter.date = filter.lastView;
      delete filter.lastView;
    }

    const search = queryString.stringify({
      ..._.mapValues(store.filters, JSON.stringify),
      page: 1,
      perPage: 25,
    });
    const searchString = search || '';

    return {
      pathname: encodeURI(`/domains/${item[field]}`),
      search: searchString
    };
  // eslint-disable-next-line
  }, [store.filters, item?.[field]]);

  */

  return (
    <div className={classes.container}>
      <div>
        {_.get(item, field)}
      </div>
      {item?.[field] && item[field].match(/\./) && <div
        className={classes.external}
      >
        <a
          href={`https://www.${item?.[field]}`}
          target="_blank"
          rel="noreferrer"
          className={classes.externalLink}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      </div>}
      <div
        className={classes.searchGlasses}
        onClick={() => store.setPortal(item)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
}
