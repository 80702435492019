import PropTypes from 'prop-types';
import tagDefinition from './tag.definition';

const domainDefinition = {
  id: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.date,
    undefined
  ]),
  domain: PropTypes.string,
  targetUrl: PropTypes.string,
  updated: PropTypes.oneOfType([
    PropTypes.date,
    undefined
  ]),
  evaluation: PropTypes.number,
  searchcount: PropTypes.number,
  categories: PropTypes.PropTypes.arrayOf(PropTypes.shape(tagDefinition)),
  tags: PropTypes.PropTypes.arrayOf(PropTypes.shape(tagDefinition)),
  history: PropTypes.object,
  datas: PropTypes.shape({
    similarweb: PropTypes.shape({
      updated: PropTypes.string,
      data: PropTypes.shape({
        also_visited_unique_count: PropTypes.number,
        category: PropTypes.string,
        large_screenshot: PropTypes.string,
        estimated_monthly_visits:PropTypes.object
      })
    })
  })
};

export default domainDefinition;
