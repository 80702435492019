/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Alert model module.
* @module model/Alert
* @version 4.0.0
*/
export default class Alert {
    /**
    * Constructs a new <code>Alert</code>.
    * @alias module:model/Alert
    * @class
    * @param mailjetTemplateId {Number} 
    * @param templateType {String} 
    * @param enable {Boolean} 
    * @param frequency {String} 
    * @param from {String} 
    * @param fromName {String} 
    * @param subject {String} 
    * @param type {String} 
    * @param context {Object} 
    */

    constructor(mailjetTemplateId, templateType, enable, frequency, from, fromName, subject, type, context) {
        
        
        this['mailjetTemplateId'] = mailjetTemplateId;
        this['templateType'] = templateType;
        this['enable'] = enable;
        this['frequency'] = frequency;
        this['from'] = from;
        this['fromName'] = fromName;
        this['subject'] = subject;
        this['type'] = type;
        this['context'] = context;
        
    }

    /**
    * Constructs a <code>Alert</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Alert} obj Optional instance to populate.
    * @return {module:model/Alert} The populated <code>Alert</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Alert();
                        
            
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
            }
            if (data.hasOwnProperty('mailjetTemplateId')) {
                obj['mailjetTemplateId'] = ApiClient.convertToType(data['mailjetTemplateId'], 'Number');
            }
            if (data.hasOwnProperty('templateType')) {
                obj['templateType'] = ApiClient.convertToType(data['templateType'], 'String');
            }
            if (data.hasOwnProperty('enable')) {
                obj['enable'] = ApiClient.convertToType(data['enable'], 'Boolean');
            }
            if (data.hasOwnProperty('cron')) {
                obj['cron'] = ApiClient.convertToType(data['cron'], 'String');
            }
            if (data.hasOwnProperty('frequency')) {
                obj['frequency'] = ApiClient.convertToType(data['frequency'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('nextSend')) {
                obj['nextSend'] = ApiClient.convertToType(data['nextSend'], 'Date');
            }
            if (data.hasOwnProperty('lastSend')) {
                obj['lastSend'] = ApiClient.convertToType(data['lastSend'], 'Date');
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'String');
            }
            if (data.hasOwnProperty('fromName')) {
                obj['fromName'] = ApiClient.convertToType(data['fromName'], 'String');
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('hookTrigger')) {
                obj['hookTrigger'] = ApiClient.convertToType(data['hookTrigger'], 'String');
            }
            if (data.hasOwnProperty('context')) {
                obj['context'] = ApiClient.convertToType(data['context'], Object);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('recipientIds')) {
                obj['recipientIds'] = ObjectID.constructFromObject(data['recipientIds']);
            }
        }
        return obj;
    }

    /**
    * @member {String} brand
    */
    brand = undefined;
    /**
    * @member {Number} mailjetTemplateId
    */
    mailjetTemplateId = undefined;
    /**
    * @member {String} templateType
    */
    templateType = undefined;
    /**
    * @member {Boolean} enable
    * @default false
    */
    enable = false;
    /**
    * @member {String} cron
    */
    cron = undefined;
    /**
    * @member {String} frequency
    * @default '7 days'
    */
    frequency = '7 days';
    /**
    * @member {Date} startDate
    */
    startDate = undefined;
    /**
    * @member {Date} nextSend
    */
    nextSend = undefined;
    /**
    * @member {Date} lastSend
    */
    lastSend = undefined;
    /**
    * @member {String} from
    * @default 'no-reply@monibrand.com'
    */
    from = 'no-reply@monibrand.com';
    /**
    * @member {String} fromName
    * @default 'Monibrand'
    */
    fromName = 'Monibrand';
    /**
    * @member {String} subject
    */
    subject = undefined;
    /**
    * @member {String} type
    * @default 'cron'
    */
    type = 'cron';
    /**
    * @member {String} hookTrigger
    */
    hookTrigger = undefined;
    /**
    * @member {Object} context
    */
    context = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {module:model/ObjectID} recipientIds
    */
    recipientIds = undefined;




}
