import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import classes from './Pagination.module.css';
import { useTranslation } from 'react-i18next';

const choices = [30, 100, 200, 500];

function Pagination({
  perPage,
  total,
  onChangePerPage,
  currentPage,
  next,
  previous,
}: {
  perPage: number;
  total: number;
  next?: () => void;
  previous?: () => void;
  onChangePerPage: (perPage: number) => void;
  currentPage: number;
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.perPageContainer}>
        <div className={classes.txtPerPage}>{t('Elements per page')} :</div>
        {choices.map((perPageCase) => (
          <div
            key={perPageCase}
            onClick={() => onChangePerPage(perPageCase)}
            className={classes.page}
            data-current={perPage === perPageCase}
          >
            {perPageCase}
          </div>
        ))}
      </div>
      <div className={classes.paginationContainer}>
        {previous && (
          <div className={classes.button} onClick={previous}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        )}
        <div className={classes.pages}>
          <div className={classes.currentPage}>
            <div className={classes.currenPageDetail}>
              {currentPage}/{Math.ceil(total / perPage)}
            </div>
          </div>
        </div>
        {next && (
          <div className={classes.button} onClick={next}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Pagination;
