import { Link } from 'react-router-dom';
import MonibrandLogo from '../../assets/img/logo-monibrand.svg';
import MonibrandLogoCollapsed from '../../assets/img/monibrand-logo-collapsed.svg';
import ProfileCard from '../ProfileCard/ProfileCard';
import WorkspaceSelector from '../WorkspaceSelector/WorkspaceSelector';
import classes from './Header.module.css';
import { useFlags } from 'flagsmith/react';

export default function Header({ menuExpanded }: {menuExpanded: boolean}) {
  const flags = useFlags(['header_workspaces_switch']);
  return (
    <div className={classes.container}>
      <div className={classes.item} data-testid="desktop">
        <Link to={{ pathname: '/' }}>
          <img
            className={`${classes.logo} ${!menuExpanded ? classes.collapsed : ''}`}
            src={menuExpanded ? MonibrandLogo : MonibrandLogoCollapsed}
            alt="Monibrand"
          />
        </Link>
      </div>
      <div className={classes.rightPanel}>
        { flags?.header_workspaces_switch?.enabled && <WorkspaceSelector /> }
        <ProfileCard/>
      </div>
    </div>
  );
}

