import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Pagination from '../../components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import MagicTable from '../../components/MagicTable/MagicTable';
import UsersStore from './users.store';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import UsersFilter from '../../components/Filters/UsersFilter/UsersFilter';
import UserActionsCell from '../../components/User/UserActionsCell/UserActionsCell';
import { Link } from 'react-router-dom';
import FullscreenModal from '../../components/FullscreenModal/FullscreenModal';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import UserAvatarCell from '../../components/User/UserAvatarCell/UserAvatarCell';
import { Redirect } from 'react-router-dom';
import Button from '../../components/Button/Button';

function Users() {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { currentUser } = authStore,
    [usersStore] = useState(() => new UsersStore(currentUser.id));

  useEffect(() => {
    uiStore.setTitle(t('Users'));

    return () => {
      usersStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return authStore.adminToken ? (
    <Redirect to={'/'} />
  ) : (
    <div className="tableContainer">
      {usersStore.selectedItem && (
        <FullscreenModal onClose={() => usersStore.setSelectedItem(null)}>
          <div className="tableTxtModale">
            {`${t('are you sure to')} ${t(usersStore.selectedItem.type)} ${
              usersStore.selectedItem.user.firstName
                ? usersStore.selectedItem.user.firstName +
                  ' ' +
                  usersStore.selectedItem.user.lastName
                : usersStore.selectedItem.user.email
            } ?`}
          </div>
          <div className="tableButtonsModale">
            <Button onClick={() => usersStore.setSelectedItem(null)} type="reset" text="cancel"/>
            <SubmitButton
              txt={t('submit')}
              testid="confirm-modale"
              isLoading={usersStore.isLoadingAction}
              onClick={() => usersStore.selectedItem.action()}
            />
          </div>
        </FullscreenModal>
      )}
      <div className="tableContainerFilter">
        <div className="tableContainerButtons">
          <Link to="/users/create" className='button'>
            {t('create user')}
          </Link>
        </div>
        {currentUser && (
          <NavFilter
            isLoading={usersStore.isLoadingCount}
            pageStore={usersStore}
            filters={usersStore.filters}
            counters={usersStore.counters}
          >
            <CompanyAccountsFilter
              label="company account"
              name="companyAccountId"
            />
            <UsersFilter label="email" name="email" />
            <UsersFilter label="first name" name="firstName" />
            <UsersFilter label="last name" name="lastName" />
          </NavFilter>
        )}
      </div>
      <div className="tableBox">
        <div className="table">
          <MagicTable store={usersStore}>
            <UserActionsCell field="actions" />
            <UserAvatarCell field="avatar" />
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={usersStore.count}
        perPage={usersStore.perPage}
        page={usersStore.page}
        handleSetPage={(pageNumber) => usersStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(Users);
