import { useEffect, useState } from 'react';
import { TermInterface, TermOptions } from '../../../types/term.interface';
import { useTranslation } from 'react-i18next';
import usePost from '../../Hooks/usePost';
import { TermApi } from '../../../swagger_client/src';
import { toast } from 'react-toastify';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
}

export default function useTermOptionsUpdate({term,onClose}:Props){
  const 
    [options,setOptions] = useState<TermOptions>(term.options),
    termApi = new TermApi(),
    {data,loading,error,post}  = usePost<TermInterface>((body:Object)=>termApi.termUpdateAll(body)),
    {t} = useTranslation();

  function handleCheck(stateKey:'disableSaveAds'|'saveOrganicResults'|'saveShoppingAds'){
    setOptions((prevState)=>({...prevState,[stateKey]: !prevState[stateKey]}));
  }

  function handleSubmit(){
    post({
      body: {
        options,
      },
      where: JSON.stringify({ id: term.id }),
    });
  }

  useEffect(()=>{
   
    if(error){ 
      const msg = t('Update failed');
      toast.error(msg);
    }
  // eslint-disable-next-line
  },[error]);


  useEffect(()=>{
    if(data){
      const msg = t('Term updated');
      toast.success(msg);
      onClose();
    } 
  // eslint-disable-next-line
  },[data]);

  return {t,options,setOptions,loading,handleSubmit,handleCheck};
}
