import roleDefinition from '../proptypes/role.definition';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classes from './RolesSelector.module.css';
import { RoleApi } from '../swagger_client/src';
import { useEffect } from 'react';
import { taglistStyle } from '../Utils/reactSelectStyles';
import { useTranslation } from 'react-i18next';

RolesSelector.propTypes = {
  onChange: PropTypes.func,
  defaultOptions: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.shape(roleDefinition))
};
  
RolesSelector.defaultProps = {
  defaultOptions: true
};
  
export default function RolesSelector({onChange,defaultOptions,defaultValue}){

  const roleApi = new RoleApi();
  const { t } = useTranslation();

  async function promiseOptions(inputValue) {
    const items = await roleApi.roleFind({
      filter: JSON.stringify({
        where: inputValue ? { name: { regexp: `/${inputValue}/i` } } : {},
        limit: 10,
      }),
    });
    return _.map(items, (o) => {
      return {
        label: o.name,
        value: o,
      };
    });
  }
    
  function handleChange(choices) {
    onChange(choices);
  }
    
  useEffect(()=>{
    if(defaultValue){
      onChange(defaultValue);
    }
    // eslint-disable-next-line
      },[]);
    
  return (
    <div className={classes.container}>
      <AsyncSelect
        isMulti
        cacheOptions
        menuPlacement="top"
        menuPosition="absolute"
        placeholder={t('Select...')}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        styles={taglistStyle}
        loadOptions={promiseOptions}
        onChange={handleChange}
      />
    </div>
  );
}