import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import NavFilter from '../../components/Filters/NavFilter/NewNavFilter';
import { StoreContext } from '../../stores';
import DateFilter from '../../components/Filters/DateFilter/NewDateFilter';
import SelectFilter from '../../components/Filters/SelectFilter/NewSelectFilter';
import TermsFilter from '../../components/Filters/TermsFilter/NewTermsFilter';
import DomainFilter from '../../components/Filters/DomainFilter/NewDomainFilter';
import TagsFilter from '../../components/Filters/TagsFilter/NewTagsFilter';
import Accordion from '../../components/Accordion/Accordion';
import TermsStore from './terms.store';
import useGetTags from '../../components/Hooks/data/getTags/getTags';
import { keywordtypes, engines } from '../../Utils/rawData/rawData';
import TagListMultiTerms from '../../components/Tag/TagListMultiTerms/TagListMultiTerms';
import GroupActions from '../../components/GroupActions/GroupActions';
import MagicTable from '../../components/MagicTable/MagicTable';
import CheckboxCell from '../../components/MagicTable/CheckboxCell/CheckboxCell';
import LocalizationFlagCell from '../../components/MagicTable/LocalizationFlagCell/LocalizationFlagCell';
import EngineCell from '../../components/MagicTable/EngineCell/EngineCell';
import TermsTagsCell from '../../components/Terms/TermsTagsCell/TermsTagsCell';
import TermsLinkCell from '../../components/Terms/TermsLinKCell/NewTermsLinkCell';
import BrandsFilter from '../../components/Filters/BrandsFilter/NewBrandsFilter';
import LocalizationFilter from '../../components/Filters/LocalizationFilter/NewLocalizationFilter';
import RegionFilter from '../../components/Filters/LocalizationFilter/RegionFilter';
//import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/NewCompanyAccountsFilter';
import CursorPagination from '../../components/Pagination/CursorPagination';

import classes from './Terms.module.css';

function Terms() {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { currentUser, isAdmin, token } = authStore,
    { tags, tagLoading, tagFetch } = useGetTags('term', currentUser, isAdmin),
    [termsStore] = useState(
      () => new TermsStore(currentUser.id, token, isAdmin)
    );

  useEffect(() => {
    uiStore.setTitle(t('Terms'));
    return () => {
      termsStore.stopStore();
    };
    // const items = await localizationApi.localizationFind({
    // filter: JSON.stringify(filter),
    // });
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    termsStore.handleNext();
  };
  const handlePrevious = () => {
    termsStore.handlePrevious();
  };

  const handlePerPage = (perPage) => {
    termsStore.setPage({ type: 'perPage', value: perPage });
  };

  const filterList = {
    date: [
      <DateFilter
        key='date'
        name='date'
        label='date'
        dateMin={termsStore.dateFilterMin}
      />,
    ],
    generic: [
      //<CompanyAccountsFilter
      //  key='companyID'
      //  label='company account'
      //  name='companyID'
      //  titleMode
      ///>,
      <BrandsFilter key='brandID' titleMode label='brand' name='brandID' />,
      <LocalizationFilter
        key='country'
        titleMode
        label='country'
        name='country'
      />,
      <RegionFilter
        key='region'
        titleMode
        basedOn='country'
        label='Targeted location'
        name='region'
      />,
      <SelectFilter
        key='engine'
        titleMode
        label='engine'
        name='engine'
        options={engines}
      />,
      <SelectFilter
        key='device'
        titleMode
        label='device'
        name='device'
        options={[
          { value: 'tablet', label: t('Tablet') },
          { value: 'mobile', label: t('Mobile') },
          { value: 'desktop', label: t('Desktop') },
        ]}
      />,
      <SelectFilter
        key='type'
        titleMode
        name='type'
        label='type of campaign'
        placeholder={t('Type of campaign')}
        options={[
          { label: t('Ads'), value: 'ads' },
          { label: 'Shopping', value: 'shopping' },
        ]}
      />,
    ],
    domainAndTerms: [
      <DomainFilter key='domain' titleMode label='domain' name='domain' />,
      <TagsFilter
        key='advertiserTag'
        titleMode
        label='tags (dealer)'
        name='advertiserTag'
        scope='tag'
        menuPlacement='top'
      />,
      <TermsFilter
        titleMode
        key='keywordContent'
        label='keywords'
        name='keywordContent'
        filterKey='term'
        options={{ menuPlacement: 'top' }}
        nameForSearch='term'
      />,
      <TagsFilter
        key='keywordTag'
        titleMode
        label='tags (keywords)'
        name='keywordTag'
        scope='term'
        menuPlacement='top'
      />,
      <SelectFilter
        key='keywordType'
        titleMode
        label='keyword type'
        name='keywordType'
        options={keywordtypes.map((o) => {
          return {
            value: o.value,
            label: t(o.label),
          };
        })}
        menuPlacement='top'
      />,
    ],
  };

  const standaloneFilters = [];
  Object.values(filterList).forEach((filters) => {
    standaloneFilters.push(...filters);
  });

  return (
    <div className='tableContainer'>
      <div className='tableContainerFilter'>
        {currentUser && (
          <NavFilter
            isLoading={termsStore.isLoadingCount}
            pageStore={termsStore}
            filters={termsStore.filters}
            counters={termsStore.counters}
            standaloneFilters={standaloneFilters}
          >
            <Accordion title={'Last detection date'} unmountOnExit={false}>
              <div className={classes.filterContainer}>
                {filterList['date']}
              </div>
            </Accordion>
            <Accordion title={'Generic'} unmountOnExit={false}>
              <div className={classes.filterContainer}>
                {filterList['generic']}
              </div>
            </Accordion>
            <Accordion title={'Domain & terms'} unmountOnExit={false}>
              <div className={classes.filterContainer}>
                {filterList['domainAndTerms']}
              </div>
            </Accordion>
          </NavFilter>
        )}
        <GroupActions selected={termsStore.selectedItems} actions={[]}>
          <TagListMultiTerms
            selectedItems={termsStore.selectedItems}
            scope='term'
            tags={tags}
            refetchTag={() => tagFetch()}
            refetch={() => termsStore.handleFetch()}
            commonTags={termsStore.commonTagsForSelectItems}
          />
        </GroupActions>
      </div>
      <div className='tableBox'>
        <div className='table'>
          <MagicTable
            store={termsStore}
            exportLink={termsStore.exportLink}
            exportName={'terms'}
            checkedField='id'
          >
            <CheckboxCell field='selectAll' checkedField='id' />
            <LocalizationFlagCell field='locale' showRegion={true} />
            <EngineCell field='engine' />
            <TermsLinkCell field='content' />
            <TermsTagsCell
              field='tags'
              tags={tags}
              isLoading={tagLoading || termsStore.isLoading}
              refetchTag={tagFetch}
            />
          </MagicTable>
        </div>
      </div>
      <CursorPagination
        total={termsStore.count}
        perPage={termsStore.perPage}
        currentPage={termsStore.page}
        next={termsStore.hasNextPage ? handleNext : undefined}
        previous={termsStore.hasPreviousPage ? handlePrevious : undefined}
        onChangePerPage={handlePerPage}
      />
    </div>
  );
}

export default observer(Terms);
