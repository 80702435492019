import classes from './LocalizationCell.module.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

LocalizationCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired
};

export default function LocalizationCell({ item, field }) {
  const { i18n,t } = useTranslation(),
    currentLang = i18n.language.slice(0, 2);

  const getLabel = (localization) => {
    return localization ? `${localization.country[currentLang]} (${t(localization.language)})` : '';
  };

  return (
    <div className={classes.container}>
      {_.get(item,field) && getLabel(item?.localization)}{' '}
      {item?.localization?.city}
    </div>
  );
}
