import { useState, useEffect } from 'react';

export default function useGet<T>(
  promise: Function,
  filters?: Object
): {
  readonly data?: T
  readonly loading: boolean
  readonly refetch: Function
  readonly error?: string
} {
  const [data, setData] = useState<T>(),
    [loading, setLoading] = useState<boolean>(false),
    [error, setError] = useState<string | undefined>(undefined);

  //you can surcharge filter on refetch
  async function handleFetch(newFilters?: Object) {
    setLoading(true);
    
    try {
      setData(await promise(newFilters || filters));
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  }

  useEffect(()=>{
    handleFetch();
  // eslint-disable-next-line
  },[]);

  return { data, error, loading, refetch: handleFetch };
}
