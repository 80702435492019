import PropTypes from 'prop-types';

const roleDefinition = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  created: PropTypes.string,
  modified: PropTypes.string
};

export default roleDefinition;