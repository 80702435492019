export function setCookie(name, value, daysToLive) {
  let cookie = name + '=' + encodeURIComponent(value);
  if (typeof daysToLive === 'number') {
    cookie += '; max-age=' + daysToLive * 24 * 60 * 60;
    cookie += 'Secure; Samesite=strict';
    document.cookie = cookie;
  }
}

export function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookiePair = cookies[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=0';
}
