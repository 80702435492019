import PropTypes from 'prop-types';
import brandDefinition from './brand.definition';
import companyAccountDefinition from './companyAccount.definition';
import localizationDefinition from './localization.definition';
import tagDefinition from './tag.definition';

const termDefinition = {
  id: PropTypes.string,
  term: PropTypes.string,
  keywordType: PropTypes.oneOf(['GENERIC','BRAND','GENERIC_BRAND','MULTIBRAND']),
  devices: PropTypes.arrayOf(PropTypes.oneOf(['MOBILE','DESKTOP'])),
  every: PropTypes.number,
  engine: PropTypes.oneOf(['GOOGLE','BING','google','bing']),
  enable: PropTypes.bool,
  localizationId: PropTypes.string,
  canBeAccessBy: PropTypes.arrayOf(PropTypes.shape(companyAccountDefinition)),
  categories: PropTypes.arrayOf(PropTypes.shape(tagDefinition)),
  brand: PropTypes.shape(brandDefinition),
  localization: PropTypes.shape(localizationDefinition)
};

export default termDefinition;