import _ from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicScrollbar from '../../../components/DynamicScrollbar/DynamicScrollbar';
import Loader from '../../../components/Loader/Loader';
import UserForm from '../../../components/User/UserForm/UserForm';
import { UserAccountApi } from '../../../swagger_client/src';
import classes from './UpdateUser.module.css';

/***
 * Un composant edit directement
 * 
 */

export default function UpdateUser() {
  const { id } = useParams(),
    [user, setUser] = useState(),
    { t } = useTranslation();

  async function handleFetch() {
    const userAccountApi = new UserAccountApi();
    try {
      setUser(await userAccountApi.userAccountFindById(id, {
        filter: JSON.stringify({
          include: ['roles', 'companyAccount', 'avatar']
        })
      }));
    } catch (e) {
      toast.error(t(e));
    }
  }

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line
  }, [id]);

  return <DynamicScrollbar className={classes.container}>
    <div className={classes.box}>
      {user ? <UserForm fields={
        {
          ...user,
          roles: _.get(user, 'roles', []).map(o => ({
            label: o.name,
            value: o
          })),
          clicdataUrl: _.get(user, 'dashboards.app-action-sem-summary'),
          clicdataLogin: _.get(user, 'dashboards.clicdata.login'),
          clicdataPassword: _.get(user, 'dashboards.clicdata.password'),
          companyAccount: user.companyAccount ? {
            label: user.companyAccount.name,
            value: user.companyAccount,
          } : {}
        }
      } /> : <Loader />}
    </div>
  </DynamicScrollbar>;
}