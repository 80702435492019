import { observer } from 'mobx-react-lite';
import StopGoKeywordStore from './Keyword.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../components/MagicTable/MagicTable';

function StopGoKeyword() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoKeywordStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go - keywords'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className='tableContainer'>
      <div className='tableContainerFilter'>
        <NavFilter
          isLoading={stopGoStore.isLoadingCount}
          pageStore={stopGoStore}
          counters={stopGoStore.counters}
        >
          <CompanyAccountsFilter label='company account' name='id' />
        </NavFilter>
      </div>
      <div className='tableBox'>
        <div className='table'>
          <MagicTable store={stopGoStore}></MagicTable>
        </div>
      </div>
      <Pagination
        total={stopGoStore.count}
        perPage={stopGoStore.perPage}
        page={stopGoStore.page}
        handleSetPage={(pageNumber) => stopGoStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(StopGoKeyword);
