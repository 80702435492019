import PropTypes from 'prop-types';

export default function AngleLeftSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M-2576.017-2244.333l-7.088-6.9a1.118,1.118,0,0,1,.034-1.577l7.058-6.854a1.207,1.207,0,0,1,1.667,0,1.114,1.114,0,0,1,0,1.614l-6.23,6.049,6.224,6.054a1.114,1.114,0,0,1,0,1.616,1.192,1.192,0,0,1-.833.333A1.2,1.2,0,0,1-2576.017-2244.333Zm-6.586,0-7.088-6.9a1.118,1.118,0,0,1,.034-1.577l7.059-6.854a1.207,1.207,0,0,1,1.667,0,1.118,1.118,0,0,1,0,1.614l-6.232,6.049,6.226,6.054a1.118,1.118,0,0,1,0,1.616,1.193,1.193,0,0,1-.833.333A1.193,1.193,0,0,1-2582.6-2244.333Z"
        transform="translate(2590 2260)"
        fill={color}
      />
    </svg>
  );
}

AngleLeftSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
