import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
/**
 * @description Styles and assets
 */
import classes from './UpdateProfile.module.css';
import { toast } from 'react-toastify';

/**
 * @description Components
 */
import AuthInput from '../AuthInput/AuthInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import { StoreContext } from '../../stores';
import { UserAccountApi } from '../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import CancelButton from '../CancelButton/CancelButton';
import CheckList from '../../components/CheckList/CheckList';
import { PASSWORD_VALID_REGEX } from '../../Utils/rules/passwordValidation';

function UpdateProfile() {
  toast.configure();
  const { t } = useTranslation(),
    { authStore } = useContext(StoreContext),
    [isConfirm, setIsConfirm] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [helpPassword, setHelpPassword] = useState(false),
    [passwordNotCompliant, setPasswordNotCompliant] = useState(true),
    [password, setPassword] = useState(''),
    [confirmPassword, setConfirmPassword] = useState(''),
    [payload, setPayload] = useState({
      email: authStore.currentUser.email,
      firstName: authStore.currentUser.firstName,
      lastName: authStore.currentUser.lastName,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

  function handleSetToken() {
    setIsConfirm(!isConfirm);
  }

  function handleCancel() {
    setIsConfirm(false);
    setPayload({
      email: authStore.currentUser.email,
      firstName: authStore.currentUser.firstName,
      lastName: authStore.currentUser.lastName,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }

  function handelChange(e) {
    const { name, value } = e.target;
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    if (name === 'newPassword') {
      setPassword(value);
      if (value && value.length > 0) {
        setHelpPassword(true);
        if (value.match(PASSWORD_VALID_REGEX)) {
          setPasswordNotCompliant(false);
        } else {
          setPasswordNotCompliant(true);
        }
      } else {
        setHelpPassword(false);
      }
    }
    setPayload({ ...payload, [name]: value });
  }

  function validatePassword(password) {
    // Password must contain at least one uppercase letter, one lowercase letter,
    // one number, one special character, and be at least 8 characters long.
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }

  async function handleUpdate(e) {
    e.preventDefault();
    const {
      email,
      newPassword,
      confirmPassword,
      lastName,
      firstName,
      oldPassword,
    } = payload;
    setIsLoading(true);
    const userAccountApi = new UserAccountApi();

    if (newPassword && newPassword === confirmPassword) {
      if (!validatePassword(newPassword)) {
        toast.error(t('Your password must have 8 chars minimum including 1 lowercase, 1 uppercase, 1 number, 1 special char !'), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      try {
        await userAccountApi.userAccountChangePassword({
          oldPassword: oldPassword,
          newPassword: newPassword,
        });

        toast(t('Your password has been changed successfuly !'), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        handleCancel();
      } catch (error) {
        toast.error(t(error.message), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      try {
        await userAccountApi.userAccountPrototypePatchAttributes(
          authStore.currentUser.id,
          { body: { email, firstName, lastName } }
        );
        toast(t('profile update'));
        handleCancel();
      } catch (error) {
        toast.error(t(error.message), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className={classes.link} onClick={handleSetToken}>
        {t('edit profile')}
      </div>
      {isConfirm && (
        <div className={classes.container}>
          <div className={classes.modal}>
            <div className={classes.title}>{t('profile')}</div>
            <form className={classes.form} onSubmit={handleUpdate}>
              <AuthInput
                name={'email'}
                value={payload.email}
                label={'Email Address'}
                onChange={(e) => handelChange(e)}
                disabled={true}
              />
              <AuthInput
                name={'firstName'}
                value={payload.firstName}
                label={'First name'}
                onChange={(e) => handelChange(e)}
              />
              <AuthInput
                name={'lastName'}
                value={payload.lastName}
                label={'Last name'}
                onChange={(e) => handelChange(e)}
              />

              <AuthInput
                name={'oldPassword'}
                value={payload.oldPassword}
                label={'Previous password'}
                type={'password'}
                onChange={(e) => handelChange(e)}
              />
              <AuthInput
                name={'newPassword'}
                value={payload.newPassword}
                label={'New password'}
                type={'password'}
                onChange={(e) => handelChange(e)}
              />
              <AuthInput
                name={'confirmPassword'}
                value={payload.confirmPassword}
                label={'Password confirmation'}
                type={'password'}
                onChange={(e) => handelChange(e)}
              />
              <CheckList
                value={password}
                className={helpPassword ? 'visible' : 'invisible' }
              />
              <div className={classes.buttons}>
                <CancelButton onClose={handleCancel} txt="Cancel" />
                <SubmitButton
                  onClick={handleUpdate}
                  isLoading={isLoading}
                  txt={t('confirm')}
                  disabled={password && password.length > 0 && (passwordNotCompliant || password !== confirmPassword)}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(UpdateProfile);
