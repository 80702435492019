import { observer } from 'mobx-react';
import classes from './CompanyAccountsFilter.module.css';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';
import useCompanyAccountsFilter from './useCompanyAccountsFilter';
import { FilterChild } from '../../../types/filter.interface';

function CompanyAccountsFilter(props:FilterChild) {
  const useProps = useCompanyAccountsFilter(props.name),
    passProps = { ...props, ...useProps };

  return <div className={classes.container}>
    <AsyncCreateFilter {...passProps} />
  </div>
  ;
}

export default observer(CompanyAccountsFilter);
