import PropTypes from 'prop-types';
import brandDefinition from './brand.definition';
import termDefinition from './term.definition';

const companyAccountDefinition = {
  id: PropTypes.string,
  name: PropTypes.string,
  brands: PropTypes.PropTypes.arrayOf(PropTypes.shape(brandDefinition)),
  canBeAccessTo: PropTypes.arrayOf(PropTypes.shape(termDefinition))
};

export default companyAccountDefinition;
