import { useState, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import classes from './Accordion.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function Accordion({
  children,
  title,
  initState,
  styleTxt,
  expanded = true,
  unmountOnExit,
}: {
  children: ReactNode;
  title: string;
  initState?: boolean;
  styleTxt?: string;
  expanded?: boolean;
  unmountOnExit?: boolean;
}) {
  const { t } = useTranslation(),
    [toggle, setToogle] = useState(initState === false ? false : true);

  return (
    <div>
      <div
        className={classes.title}
        data-style={styleTxt}
        onClick={() => setToogle(!toggle)}
        data-open={toggle}
        data-testid='toggle'
      >
        {expanded && (
          <>
            <span className={classes.titleTxt}>{t(title)}</span>
            <FontAwesomeIcon
              className={classes.icon}
              data-down={toggle}
              icon={faChevronUp}
            />
          </>
        )}
      </div>
      <div className={classes.panel}>
        <CSSTransition
          in={toggle}
          timeout={200}
          unmountOnExit={unmountOnExit === false ? false : true}
          mountOnEnter
          classNames='accordion'
        >
          {children}
        </CSSTransition>
      </div>
    </div>
  );
}
