import { useState, useRef, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import classes from './ActionPanel.module.css';
import EyeSVG from '../../components/Icon/svg/Eye.svg';
// import SmallEnvelopeSVG from '../../components/Icon/svg/SmallEnvelope.svg';
import MessagesIcon from './MessagesIcon';
import RemindSVG from '../../components/Icon/svg/Remind.svg';
import PaperPlaneSVG from '../../components/Icon/svg/PaperPlane.svg';
import {
  faChevronRight,
  faExternalLinkAlt,
  faCopy,
  faStarOfLife,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewTooltip from '../../components/_ui/Tooltip/NewTooltip';
import NewManagedTooltip from '../../components/_ui/Tooltip/NewManagedTooltip';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Google from './../../assets/icons/google.png';
import Bing from './../../assets/icons/bing.png';
import { Status } from '../../types/action.interface';
import {
  Action,
  computeHistory,
  isBeforeReappearance,
} from '../../types/action.newBackend';

function getWaiting(status: string, waiting: boolean) {
  if (status === Status.inprogress && waiting) {
    return classes.statusWaiting;
  }
  return classes.statusNotWaiting;
}

function getStatusClass(status: string) {
  switch (status) {
  case Status.new:
    return classes.statusNew;
  case Status.reappearance:
    return classes.statusReappearance;
  case Status.ignored:
    return classes.statusIgnored;
  case Status.accepted:
    return classes.statusAccepted;
  case Status.refused:
    return classes.statusRefused;
  case Status.disappeared:
    return classes.statusDisappeared;
  case Status.requested:
    return classes.statusRequested;
  case Status.inprogress:
    return classes.statusInprogress;
  case Status.reminded:
    return classes.statusReminded;
  case Status.blocked:
    return classes.statusBlocked;
  default:
    return null;
  }
}

function getStatusLabel(status: string, t: (sd: string) => string) {
  switch (status) {
  case Status.new:
    return t('Timeline_New');
  case Status.reappearance:
    return t('Timeline_Reappearance');
  case Status.ignored:
    return t('Timeline_Ignored');
  case Status.accepted:
    return t('Timeline_Accepted');
  case Status.refused:
    return t('Timeline_Refused');
  case Status.disappeared:
    return t('Timeline_No_more_detected');
  case Status.requested:
    return t('Timeline_Requested');
  case Status.inprogress:
    return t('Timeline_Notified');
  case Status.reminded:
    return t('Timeline_Reminded');
  case Status.blocked:
    return t('Timeline_Blocked');
  default:
    return null;
  }
}

interface ActionHistoryItemProps {
  status: string;
  date: Date;
  last: boolean;
  previousDate?: Date[];
  beforeReappearance: boolean;
  waiting: boolean;
}

function ActionHistoryItem({
  status,
  date,
  last,
  previousDate,
  beforeReappearance,
  waiting,
}: ActionHistoryItemProps) {
  const { t } = useTranslation();
  const statusClass = getStatusClass(status);
  const statusLabel = getStatusLabel(status, t);
  const statusNotWaiting = getWaiting(status, waiting);
  const formatDate = (date: Date) => {
    return dayjs(date).format('DD/MM/YYYY');
  };
  let dateFormatted = formatDate(date);
  if (previousDate && previousDate.length > 0) {
    const lastDate = previousDate[previousDate.length - 1];
    dateFormatted = formatDate(lastDate);
  }

  return (
    <>
      <div
        className={`${classes.status} ${last ? classes.last : ''} ${
          beforeReappearance ? classes.beforeReappearance : ''
        }`}
      >
        <div className={classes.statusLabel}>{statusLabel}
          <sup className={`${statusNotWaiting}`}>
            <FontAwesomeIcon
              icon={faStarOfLife}
              size='xs'
            />
          </sup>
        </div>
        <div className={classes.statusDate}>le {dateFormatted}</div>
        <div className={classes.timeline}>
          <div className={`${classes.roundedStatus} ${statusClass}`}></div>
        </div>
      </div>
      <div
        className={`${classes.status} ${last ? classes.last : ''} ${
          classes.responsive
        }`}
      >
        <div className={classes.timeline}>
          <NewTooltip
            content={t(`${statusLabel}\nle ${dateFormatted}`)}
            placement='bottom'
          >
            <div className={`${classes.roundedStatus} ${statusClass}`}></div>
          </NewTooltip>
        </div>
      </div>
    </>
  );
}

interface LabelWithIconProps {
  text: string;
  icon: any;
  textClassName?: string;
}

function LabelWithIcon({ text, icon, textClassName }: LabelWithIconProps) {
  return (
    <div className={classes.labelWithIcon}>
      <div className={classes.icon}>{icon}</div>
      <div className={textClassName}>{text}</div>
    </div>
  );
}

interface ActionPanelProps {
  action: Action;
  onSelect: (action: Action) => void;
  hasFocus: boolean;
}

export default function ActionPanel({
  action,
  onSelect,
  hasFocus,
}: ActionPanelProps) {
  const { t } = useTranslation(),
    [copied, setCopied] = useState(false),
    copyDomainRef = useRef();

  const actionRef = useRef<HTMLInputElement>(null);

  const onCopyText = () => {
    (copyDomainRef.current as any)?.hide();
    setTimeout(() => {
      setCopied(true);
    }, 500);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const actionHistory = useMemo(() => {
    return computeHistory(action.history, false);
  }, [action]);

  useEffect(() => {
    if (hasFocus === false || actionRef.current === null) {
      return;
    }

    actionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [hasFocus, actionRef]);

  return (
    <div
      className={classes.actionPanel}
      id={`action-${action.ID}`}
      onClick={() => onSelect(action)}
      ref={actionRef}
    >
      <div className={classes.actionHeader}>
        <div className={classes.actionHeaderName}>
          <div className={classes.actionHeaderTitle}>
            <div className={classes.actionHeaderTitleText}>{action.domain}</div>
            <div className={classes.actionHeaderTitleActions}>
              <div className={classes.actionHeaderTitleAction}>
                <NewTooltip content={t('See the website')} placement='bottom'>
                  <a
                    href={`https://www.${action.domain}`}
                    target='_blank'
                    rel='noreferrer'
                    className={classes.externalLink}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      size='lg'
                      color='var(--color-link-menu)'
                    />
                  </a>
                </NewTooltip>
              </div>
              <div
                className={classes.actionHeaderTitleAction}
                onClick={(e) => e.stopPropagation()}
              >
                <NewTooltip
                  content={t('Copy the domain')}
                  placement='bottom'
                  ref={copyDomainRef}
                >
                  <NewManagedTooltip
                    content={
                      <div className={classes.intentSuccess}>
                        {t('Domain name copied!')}
                      </div>
                    }
                    direction='bottom'
                    intent={copied ? 'success' : 'default'}
                    arrow={false}
                    active={copied}
                  >
                    <CopyToClipboard text={action.domain} onCopy={onCopyText}>
                      <FontAwesomeIcon
                        icon={faCopy}
                        size='lg'
                        color='var(--color-link-menu)'
                      />
                    </CopyToClipboard>
                  </NewManagedTooltip>
                </NewTooltip>
              </div>
            </div>
          </div>
          <NewTooltip content={action.country} placement='bottom'>
            <div
              className={`flag-icon flag-icon-${action.country?.toLocaleLowerCase()} ${
                classes.actionFlagIcon
              }`}
            ></div>
          </NewTooltip>
          <NewTooltip content={action.engine} placement='bottom'>
            <>
              {action.engine === 'google' && (
                <img alt='' src={Google} className={classes.actionEngineIcon} />
              )}
              {action.engine === 'bing' && (
                <img alt='' src={Bing} className={classes.actionEngineIcon} />
              )}
            </>
          </NewTooltip>
        </div>
        <div className={classes.actionHeaderDate}>
          <LabelWithIcon
            text={dayjs(action.lastView).format('DD/MM/YYYY')}
            textClassName={classes.dateText}
            icon={<EyeSVG height={20} color='var(--color-link-menu)' />}
          />
        </div>
        <div className={classes.actionHeaderHints}>
          <NewTooltip content={t('Received')} placement='bottom'>
            <LabelWithIcon
              text={action.receivedCount?.toString() || '0'}
              textClassName={classes.counterText}
              icon={<MessagesIcon count={action.unreadCount || 0} />}
            />
          </NewTooltip>
          <NewTooltip content={t('Sent')} placement='bottom'>
            <LabelWithIcon
              text={action.sendedCount?.toString() || '0'}
              textClassName={classes.counterText}
              icon={
                <PaperPlaneSVG height={20} color='var(--color-link-menu)' />
              }
            />
          </NewTooltip>
          <NewTooltip content={t('Reminders')} placement='bottom'>
            <LabelWithIcon
              text={action.remindedCount?.toString() || '0'}
              textClassName={classes.counterText}
              icon={<RemindSVG height={20} color='var(--color-link-menu)' />}
            />
          </NewTooltip>
        </div>
      </div>
      <div className={classes.actionBody}>
        {actionHistory.map((item, index) => (
          <ActionHistoryItem
            key={item.ID}
            {...item}
            waiting={action.waiting ? true: false}
            last={index === actionHistory.length - 1}
            beforeReappearance={isBeforeReappearance(actionHistory, index)}
          />
        ))}
        <button className={classes.showDetailsButton}>
          <FontAwesomeIcon
            className={classes.showDetailsIcon}
            icon={faChevronRight}
          />
        </button>
      </div>
    </div>
  );
}
