import _ from 'lodash';
import { useEffect, useReducer, useContext } from 'react';
import { CategoryApi } from '../../../../swagger_client/src';
import tagToOptions from '../../../../Utils/tagToOptions';
import Backend from '../../../../stores/newBackend';
import { StoreContext } from '../../../../stores';

const SET_LOADING = 'setLoading',
  SET_FETCH = 'setFetch',
  SET_ERROR = 'setError',
  SET_COUNT = 'setCount',
  useGetTagsInitialeState = {
    tagLoading: true,
    tagError: null,
    count: 0,
    tags: [],
  };

export default function useGetTags(
  scope: unknown,
  currentUser?: any,
  isAdmin?: boolean
) {
  const { authStore } = useContext(StoreContext),
    categoryApi = new CategoryApi(),
    [{ tagLoading, tagError, tags, count }, dispatch] = useReducer(
      useGetTagsReducer,
      useGetTagsInitialeState
    );

  function fetch() {
    const where: any = { scope };
    // for user, only your brand
    if (!isAdmin && currentUser) {
      const brands = _.get(currentUser, 'companyAccount.brands', []).map(
        (brand: any) => brand.id
      );
      if (brands && scope === 'tag') {
        where.brandOwnerId = brands;
      }
    }
    const filters = {
      where,
      order: 'label asc',
      include: [{ children: 'owner' }, 'owner'],
    };

    if (scope === 'tag') {
      // Use the new backend
      Backend.searchTag(authStore.token, where)
        .then((tags) => {
          dispatch({
            type: SET_FETCH,
            payload: tags,
          });
        })
        .catch((e) => dispatch({ type: SET_ERROR, payload: e }));
    } else {
      categoryApi
        .categoryCount({
          where: JSON.stringify(filters.where),
        })
        .then((data) => {
          dispatch({
            type: SET_COUNT,
            payload: data.count,
          });
        });

      categoryApi
        .categoryFind({
          filter: JSON.stringify(filters),
        })
        .then((tags) => {
          dispatch({
            type: SET_FETCH,
            payload: tags,
          });
        })
        .catch((e) => dispatch({ type: SET_ERROR, payload: e }));
    }
  }
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return { tagLoading, tagError, tags, count, tagFetch: fetch };
}

function useGetTagsReducer(
  state: { tagLoading: boolean; tagError: any; count: number; tags: any[] },
  action: { type: string; payload: any }
) {
  const newsState = { ...state };
  switch (action.type) {
    case SET_LOADING: {
      newsState.tagLoading = action.payload;
      break;
    }
    case SET_FETCH: {
      newsState.tagLoading = false;
      newsState.tags = tagToOptions(action.payload);
      break;
    }
    case SET_ERROR: {
      newsState.tagLoading = false;
      newsState.tagError = action.payload;
      break;
    }
    case SET_COUNT: {
      newsState.count = action.payload;
      break;
    }
    default:
      throw new Error();
  }
  return newsState;
}
