import LogoMobile from '../../assets/img/logo-mobile.svg';
import classes from './HeaderMobile.module.css';

export default function HeaderMobile() {
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={LogoMobile} alt="Monibrand" />
      </div>
    </div>
  );
}
