export const tagListFormMultiActionsType = {
  setIsLoading: 'setIsLoading',
  setIsCreated: 'setIsCreated',
  setDefaultOptions: 'setDefaultOptions',
  setTagsToRemove: 'setTagsToRemove',
  closeCreated: 'closeCreated',
  setTagsToAdd: 'setTagsToAdd',
  setDefaultOptionsWithCreated: 'setDefaultOptionsWithCreated',
  handleChange: 'handleChange',
  setLoading: 'setLoading',
};

export function tagListForMultiReducer(state, action) {
  return tagListFormMultiActions[action.type]({...state},action.payload);
}

const tagListFormMultiActions = {
  setLoading: (state, payload)=> {
    state.isLoading = payload;
    return state;
  },
  setDefaultOptions: (state, payload)=> {
    state.defaultOptions = payload;
    return state;
  },
  setDefaultOptionsWithCreated: (state,payload)=>{
    state.defaultOptions.push(payload);
    state.tagsToAdd.push({...payload,value: payload.id,originalTag:payload}) ;
    state.isCreated = undefined;
    return state;
  },
  setIsCreated: (state,payload)=>{
    state.isCreated = payload;
    return state;
  },
  setTagsToRemove: (state,payload)=>{
    state.tagsToRemove = payload;
    return state;
  },
  closeCreated: (state)=>{
    state.defaultOptions = state.defaultOptions.filter(
      (o) => !o.__isNew__
    );
    state.isCreated = undefined;
    state.setIsLoading = false;
    return state;
  },
  handleChange: (state,payload)=>{
    state.defaultOptions = payload.defaultOptions;
    state.tagsToAdd = payload.tagsToAdd;
    state.tagsToRemove = payload.tagsToRemove;
    return state;
  }
};
