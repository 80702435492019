import { useState, useRef } from 'react';
import { faBullseye, faCopy, faDesktop, faMobile, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import NewTooltip from '../../components/_ui/Tooltip/NewTooltip';
import NewManagedTooltip from '../../components/_ui/Tooltip/NewManagedTooltip';
import { useTranslation } from 'react-i18next';
import Engine from '../../components/Engine/Engine';
import classes from './ActionAdvertisementRow.module.css';
import PropTypes from 'prop-types';
import semDefinition from '../../proptypes/sem.definition';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function getFlagCode(code) {
  return code.replace('en', 'gb');
}

const DASHBOARD_URL = process.env
  .REACT_APP_MONIBRAND_DASHBOARD_URL;


AdvertisementCards.propTypes = {
  advertisement: PropTypes.shape(semDefinition).isRequired,
  activeFilters: PropTypes.object,
};

export default function AdvertisementCards({ advertisement, activeFilters }) {
  const [showAllKeywords, setShowAllKeywords] = useState(false),
    { t } = useTranslation(),
    [copied, setCopied] = useState(false),
    copyDomainRef = useRef();

  const onCopyText = () => {
    (copyDomainRef.current)?.hide();
    setTimeout(() => {
      setCopied(true);
    }, 500);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  let prettyDisplayUrl = advertisement.displayUrl;
  let prettyTargetUrl = advertisement.targetUrl;
  let engineUrl = 'https://www.google.com';
  if (advertisement && advertisement.engine && advertisement.engine.toLowerCase() === 'bing') {
    engineUrl = 'https://www.bing.com';
  }
  if (
    advertisement &&
    advertisement.displayUrl &&
    advertisement.displayUrl.startsWith('/aclk?')
  ) {
    prettyDisplayUrl = engineUrl + advertisement.displayUrl;
  }
  if (
    advertisement &&
    advertisement.targetUrl &&
    advertisement.targetUrl.startsWith('/aclk?')
  ) {
    prettyTargetUrl = engineUrl + advertisement.targetUrl;
  }

  return (
    <tr className={classes.container}>
      <td className={`${classes.td} ${classes.tdResume}`}>
        <div className={classes.resume}>
          <div className={classes.topLink}>
            {t('Advertisement')} · {advertisement.displayUrl} {advertisement.type === 'shopping' && 'Shopping'}
          </div>
          <div className={classes.containerAdvertisement}>
            {advertisement.image && (
              <div className={classes.img}>
                <img src={advertisement.image} alt={advertisement.domain} />
              </div>
            )}
            <div className={classes.advertisement}>
              <div className={classes.title}>
                {advertisement.title}
              </div>
              {advertisement.price && <div className={classes.price}>{advertisement.price}</div>}
              <div className={classes.desc}>{advertisement.description}</div>
              {advertisement.displayUrl && <div>
                {t('Display url')}:{' '}
                <a href={prettyDisplayUrl} className={classes.link} target="_blank" rel="noreferrer">
                  {prettyDisplayUrl}
                </a>
              </div> }
              {advertisement.targetUrl && (
                <div>
                  <FontAwesomeIcon icon={faBullseye} />{' '}
                  <a href={prettyTargetUrl} className={classes.link} target="_blank" rel="noreferrer">
                    {prettyTargetUrl}
                  </a>
                </div>
              )}
              {advertisement.comparatorName && (
                <a target="_blank" rel="noreferrer" href={advertisement.comparatorUrl}>
                  {advertisement.comparatorName}
                </a>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className={classes.td}>
        <div className={classes.cols}>
          <div className={classes.labelAction}>
            <NewTooltip
              content={t('See capture')}
              placement='bottom'
              ref={copyDomainRef}
            >
              <a
                href={`/terms/capture/${advertisement.resultId}?domain=${advertisement.domain}&adstype=${advertisement.type}`}
              >
                <FontAwesomeIcon icon={faSearch} size='md' />
              </a>
            </NewTooltip>
          </div>
          <div className={classes.labelActionCopy}>
            <NewTooltip
              content={t('Copy url')}
              placement='bottom'
              ref={copyDomainRef}
            >
              <NewManagedTooltip
                content={
                  <div className={classes.intentSuccess}>
                    {t('Url copied!')}
                  </div>
                }
                direction='bottom'
                intent={copied ? 'success' : 'default'}
                arrow={false}
                active={copied}
              ></NewManagedTooltip>
              <CopyToClipboard text={`${DASHBOARD_URL}/public-html-ad-content/${advertisement.resultId}?domain=${advertisement.domain}&adstype=${advertisement.type}`} onCopy={onCopyText}>
                <FontAwesomeIcon
                  icon={faCopy}
                  size='md'
                />
              </CopyToClipboard>
            </NewTooltip>
          </div>
        </div>
      </td>
      <td className={classes.td}>
        <div className={classes.cols}>
          <div className={`${classes.info} ${classes.infoLeft}`}>
            <div className={classes.engine}>
              <Engine name={advertisement.engine} />
            </div>
          </div>
          <div className={`${classes.info} ${classes.infoLeft}`}>
            <div className={classes.engine}>
              <FontAwesomeIcon icon={advertisement.device === 'desktop' ? faDesktop : faMobile}/>
            </div>
          </div>
        </div>
      </td>

      <td className={classes.td}>
        <div className={classes.cols}>
          <div className={`${classes.info} ${classes.infoLeft}`}>
            <div className={classes.label}>
              {t('Country')}
            </div>
            <div className={`flag-icon flag-icon-${getFlagCode(advertisement.country.toLowerCase())}`} />

          </div>
          <div className={`${classes.info} ${classes.infoLeft}`}>
            <div className={classes.label}>
              {t('Language')}
            </div>
            <div className={`flag-icon flag-icon-${getFlagCode(advertisement.language.toLowerCase())}`} />
          </div>
        </div>
      </td>

      <td className={classes.td}>
        <div className={classes.info}>
          {activeFilters && (
            <div className={classes.infoTitle}>{t('Keywords')} : </div>
          )}
          <ul className={classes.keywords}>
            {advertisement.keywords
              .slice(0, showAllKeywords ? advertisement.keywords.length : 4)
              .map((keyword) => (
                <li key={keyword} className={classes.keyword}>
                  {keyword}
                </li>
              ))}
          </ul>
          {!showAllKeywords && advertisement.keywords.length > 4 && (
            <div className={classes.plus} onClick={() => setShowAllKeywords(true)}>
              {advertisement.keywords.length - 4 > 1
                ? `${t('See the')} ${advertisement.keywords.length - 4} ${t('keywords')}`
                : t('See the last keyword')}
            </div>
          )}
          {showAllKeywords && (
            <div
              className={classes.plus}
              onClick={() => setShowAllKeywords(false)}
            >
              {t('Hide keywords')}
            </div>
          )}
        </div>
      </td>

      <td className={classes.td}>
        <div className={classes.views}>
          <div className={classes.view}>
            <div className={classes.num}>{advertisement.views}</div>
            <div className={classes.infoTitle}>
              {t('View')}
              {advertisement.views > 1 && 's'}
            </div>
          </div>
          {
            advertisement.type === 'ads' && typeof advertisement.realPositionAvg === 'number' && (
              <div className={classes.view}>
                <div className={classes.num}>
                  {advertisement.realPositionAvg?.toFixed(2)}
                </div>
                <div className={classes.infoTitle}>{t('Average position')}</div>
              </div>
            )
          }
          {
            advertisement.type === 'shopping' && typeof advertisement.positionAvg === 'number' && (
              <div className={classes.view}>
                <div className={classes.num}>
                  {advertisement.positionAvg?.toFixed(2)}
                </div>
                <div className={classes.infoTitle}>{t('Average position')}</div>
              </div>
            )
          }
        </div>
      </td>

      <td className={classes.td}>
        <div className={classes.info}>
          <div className={`${classes.infoTitle} ${classes.small}`}>{t('Seen between')}</div>
          <div className={classes.date}>
            {dayjs(advertisement.advertisementFirstSeen).format('D MMM YYYY, H:mm:ss')}
          </div>
          <div className={`${classes.infoTitle} ${classes.small}`}>{t('and')}</div>
          <div className={classes.date}>
            {dayjs(advertisement.advertisementLastSeen).format('D MMM YYYY, H:mm:ss')}
          </div>
        </div>
      </td>
    </tr>
  );
}
