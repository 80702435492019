import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';
import TagsStore from './tags.store';
import TagForm from '../../components/Tag/TagForm/TagForm';
import Pagination from '../../components/Pagination/Pagination';
import TagCreateButton from '../../components/Tag/TagCreateButton/TagCreateButton';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import SelectFilter from '../../components/Filters/SelectFilter/SelectFilter';
import TagDeleteModal from '../../components/Tag/TagDeleteModal/TagDeleteModal';
import MagicTable from '../../components/MagicTable/MagicTable';
import TagsTypeCell from '../../components/Tag/TagsTypeCell/TagsTypeCell';
import ClipboardCell from '../../components/MagicTable/ClipboardCell/ClipboardCell';
import TagsActionsCell from '../../components/Tag/TagsActionsCell/TagsActionsCell';
import BrandsFilter from '../../components/Filters/BrandsFilter/BrandsFilter';

function Tags() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser, currentBrands, isAdmin } = authStore,
    { t } = useTranslation(),
    [tagsStore] = useState(() => new TagsStore({}, isAdmin, currentUser));

  useEffect(() => {
    uiStore.setTitle(t('Tags'));

    return () => {
      tagsStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    tagsStore.setBrandsFilter(currentBrands, 'owner.id');
    // eslint-disable-next-line
  }, [currentBrands]);

  return (
    <div className="tableContainer">
      {tagsStore.tagToDelete && (
        <TagDeleteModal
          setIsLoading={(value) => tagsStore.setIsLoading(value)}
          update={(tag) => tagsStore.deleteTag(tag)}
          setIsOpen={() => tagsStore.setTagToDelete()}
          tagToDelete={tagsStore.tagToDelete}
        />
      )}
      {tagsStore?.tagToUpdate && (
        <TagForm
          fields={{
            name: tagsStore.tagToUpdate.name,
            label: tagsStore.tagToUpdate.label,
            scope: tagsStore.tagToUpdate.scope,
            visibility: tagsStore.tagToUpdate.visibility,
            id: tagsStore.tagToUpdate.id,
            brandOwnerId: tagsStore.tagToUpdate.owner
              ? {
                label: tagsStore.tagToUpdate.owner.label,
                value: tagsStore.tagToUpdate.owner.id,
              }
              : undefined,
            parent: tagsStore.tagToUpdate.parent || '',
          }}
          setClose={() => tagsStore.setTagToUpdate()}
          update={(tag) => tagsStore.updateTag(tag)}
        />
      )}
      <div className="tableContainerFilter">
        <div className="tableContainerButtons">
          <TagCreateButton
            update={(tag) => tagsStore.addTag(tag)}
            isLoading={tagsStore.isLoading}
          />
        </div>
        {currentUser && (
          <NavFilter
            isLoading={tagsStore.isLoadingCount}
            pageStore={tagsStore}
            counters={tagsStore.counters}
          >
            <BrandsFilter label="brand" name="owner.id"/>
            <SelectFilter
              label="scope"
              name="scope"
              isMulti
              placeholder={'scope'}
              options={[
                {
                  label: t('domain'),
                  value: 'tag',
                },
                {
                  label: t('term'),
                  value: 'term',
                },
              ]}
            />
          </NavFilter>
        )}
      </div>
      <div className="tableContainer">
        <div className="table">
          <MagicTable store={tagsStore}>
            <TagsTypeCell field="scope"/>
            <ClipboardCell field="name" fieldTxt="name"/>
            <TagsActionsCell field="actions"/>
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={tagsStore.count}
        perPage={tagsStore.perPage}
        page={tagsStore.page}
        handleSetPage={(pageNumber) => tagsStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(Tags);
