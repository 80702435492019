import _ from 'lodash';

export function computedFilters(filters){
  const numFilters = _.keys(filters).length;

  if (numFilters === 0) {
    return undefined;
  } else {
    return _.chain(filters)
      .mapValues((value, key) => {
        return { [key]: value?.whereClause || value };
      })
      .values()
      .map((value) => {
        return _.chain(value).values().find('or').value() || value;
      })
      .thru((thruFilters) => ({ and: thruFilters }))
      .value();
  }
}