import { useState, useEffect } from 'react';

export default function useFetch<T>(fetcher:Function,options?:Object):{
  data: T|undefined
  error:string|undefined
  loading:boolean
  refetch:Function

}{
  const [loading,setLoading] = useState<boolean>(false),
    [data,setData] = useState<T>() ,
    [error,setError] = useState<string|undefined>(); 

  async function handleFetch() {
    setLoading(true);
    try{
      setData(await fetcher(options ||{}));
    } catch(e:any) {
      setError(e?.message);
    }
    setLoading(false);
  }

  useEffect(()=>{
    handleFetch();
  // eslint-disable-next-line
  },[]);

  return {loading,data,error,refetch:handleFetch};
}