import classes from './Engine.module.css';
import google from './../../assets/icons/google.png';
import bing from './../../assets/icons/bing.png';
import PropTypes from 'prop-types';
const engine = { bing, google };

Engine.propTypes = {
  name: PropTypes.oneOf(['GOOGLE', 'BING', 'google', 'bing'])
};

export default function Engine({ name }) {
  return engine[name?.toLowerCase()] ? (
    <img
      src={engine[name.toLowerCase()]}
      alt={name}
      className={classes.icon}
      title={name}
    />
  ) : (
    <p>{name}</p>
  );
}
