import classes from './Radio.module.css';
import { ReactNode } from 'react';

interface props {
  readonly value: boolean
  readonly setValue: Function
  readonly children: string | ReactNode | Array<ReactNode>
}

export default function Radio({ value, setValue, children }: props) {
  return (
    <div className={classes.container} onClick={() => setValue(!value)}>
      <div className={classes.radio} data-check={value} />
      <label className={classes.label}>{children}</label>
    </div>
  );
}
