import Creatable from 'react-select/creatable';
import classes from './CreateSelectFilter.module.css';

interface props {
  readonly options:any;
}

export default function CreateSelectFilterView({options}:props) {

  return (
    <div className={classes.container}>
      <Creatable {...options} />
    </div>
  );
}
