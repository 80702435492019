import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface props {
  readonly isOpen: boolean;
  readonly setIsOpen: Function;
  readonly pageStore: any;
}

export default function usePanelFilter({
  pageStore,
  isOpen,
  setIsOpen,
}: props) {
  const [filters, setFilters] = useState<any>(),
    [loading, setIsLoading] = useState<boolean>(false),
    { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setFilters({ ...pageStore.filters });
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    setFilters({ ...pageStore.filters });
  }, [pageStore.filters]);

  async function reinitialization() {
    //Replace le reinit par un loader
    setIsLoading(true);
    setFilters(await pageStore.reinitFilters());
    setIsLoading(false);
  }

  function setFilter(
    name: string,
    value?: { whereClause: Object; description: string; value: any }
  ) {
    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function validate() {
    pageStore.setFiltersWithHydrate(filters);
    setIsOpen(false);
  }

  return { reinitialization, loading, filters, setFilter, validate, t };
}
