import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

DateCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  displayTime: PropTypes.bool
};

export default function DateCell({ item, field, displayTime }) {
  return (
    <span>{dayjs(_.get(item, field)).format(displayTime ? 'DD/MM/YYYY, hh:mm' : 'DD/MM/YYYY')}</span>
  );
}
