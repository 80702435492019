import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './InfoItem.module.css';

interface InfosProps {
  author?: { ID: string; firstname: string; lastname: string };
  date: string;
  text: string;
  updated: boolean;
}

const Infos = ({ author, date, text, updated }: InfosProps, ref: any) => {
  const { t } = useTranslation();
  let authName = t('Deleted user');
  if (author !== undefined) {
    authName = `${author.firstname} ${author.lastname}`;
  }
  
  if (!updated) { 
    return(
      <div ref={ref} className={classes.container}>
        <header className={classes.header}>
          {authName}, le {date}
        </header>
        <div className={classes.content}>{text}</div>
      </div>
    );
  } else {
    return(
      <div ref={ref} className={classes.container}>
        <header className={classes.header}>
          {authName}, modifié le {date}
        </header>
        <div className={classes.content}>{text}</div>
      </div>
    );
  }
};

export default forwardRef(Infos);
