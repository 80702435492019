import { observer } from 'mobx-react-lite';
import useCreateFormTerm from './useCreateFormTerm';
import CreateFormTermView from './CreateFormTermView';

interface props {
  readonly control: any;
  readonly register: any;
  readonly watch: Function;
  readonly getValues: Function;
}

function CreateFormTerm(props: props) {
  const useProps = useCreateFormTerm(props.watch);

  return <CreateFormTermView {...props} {...useProps} />;
}

export default observer(CreateFormTerm);
