import { createContext } from 'react';
import AuthStore from './auth.store';
import UiStore from './ui.store';
import CacheStore from './cache.store';

// @ts-expect-error
export const StoreContext = createContext<RootStore>();

class RootStore {
  authStore: AuthStore;
  uiStore: UiStore;
  cacheStore: CacheStore;

  constructor(flagsmith: any) {
    this.authStore = new AuthStore(this, flagsmith);
    this.uiStore = new UiStore();
    this.cacheStore = new CacheStore();
  }
}

export default RootStore;
