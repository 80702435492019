import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default function dateLabel({ startDate, endDate }, t, noHTML) {
  if (
    dayjs(startDate).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
  ) {
    return 'today';
  }

  if (
    dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs().add(-1, 'days').format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') ===
      dayjs().add(-1, 'days').format('YYYY-MM-DD')
  ) {
    return 'yesterday';
  }

  if (
    dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs().subtract(30, 'days').format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
  ) {
    return 'last 30 Days';
  }

  if (
    dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs().subtract(7, 'days').format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
  ) {
    return 'last 7 Days';
  }

  if (
    dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs().startOf('month').format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') ===
      dayjs().endOf('month').format('YYYY-MM-DD')
  ) {
    return 'this month';
  }

  if (
    dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs().startOf('month').add(-1, 'month').format('YYYY-MM-DD') &&
    dayjs(endDate).format('YYYY-MM-DD') ===
      dayjs().endOf('month').add(-1, 'month').format('YYYY-MM-DD')
  ) {
    return 'last month';
  }

  return noHTML ?  `${t('from')} ${dayjs(startDate).format('DD/MM/YYYY')} ${t('to')} ${dayjs(endDate).format('DD/MM/YYYY')}`: 
    `
    <div>${t('from')}</div>
    <div data-size="big">${dayjs(startDate).format('DD/MM/YYYY')}</div>
    <div>${t('to')}</div>
    <div data-size="big">${dayjs(endDate).format('DD/MM/YYYY')}</div>
    `;
}
