import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import classes from './PanelFilter.module.css';
import PanelFilterContent from './PanelFilterContent';

interface Props {
  readonly isOpen: boolean;
  readonly loading: boolean;
  readonly setIsOpen: Function;
  readonly pageStore: any;
  readonly filters: any;
  readonly filtersElements: Array<ReactElement>;
  readonly reinitialization: Function;
  readonly setFilter: Function;
  readonly validate: Function;
  readonly t: Function;
}

export default function PanelFilterView({
  isOpen,
  loading,
  setIsOpen,
  filters,
  filtersElements,
  setFilter,
  reinitialization,
  validate,
  t,
}: Props) {
  return (
    <div>
      <CSSTransition
        in={isOpen && !loading}
        timeout={300}
        mountOnEnter
        unmountOnExit
        classNames='fade'
      >
        <div className={classes.page} onClick={() => setIsOpen(false)} />
      </CSSTransition>
      <CSSTransition
        in={isOpen && !loading}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames='slide'
      >
        <div className={classes.panel}>
          <div onClick={() => setIsOpen(false)} className='close'>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <PanelFilterContent
            loading={loading}
            filters={filters}
            filtersElements={filtersElements}
            setFilter={setFilter}
            reinitialization={reinitialization}
            validate={validate}
            t={t}
          />
        </div>
      </CSSTransition>
    </div>
  );
}
