import { useContext } from 'react';
import useInputFilter from './useInputFilter';
import InputFilterView from './InputFilterView';
import { observer } from 'mobx-react-lite';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

interface Props {
  readonly currentValue: Function;
  readonly name: string;
  readonly options: any;
  readonly label: string;
  readonly placeholder: string;
  readonly titleMode?: boolean;
  readonly menuPlacement?: string;
  readonly optionsAutosort?: boolean;
}

function InputFilter(props: Props) {
  const { filters, setFilter } = useContext(PanelFilterContext);

  const useProps = useInputFilter({ ...props, filters, setFilter }),
    passProps = { ...useProps };

  return <InputFilterView {...passProps} />;
}
export default observer(InputFilter);
