import PropTypes from 'prop-types';
import classes from './Hamburger.module.css';

const bars = ['topLeft', 'topRight', 'middleRight', 'middleLeft', 'bottomLeft', 'bottomRight'];

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default function Hamburger({ isOpen, setIsOpen }) {
  return (
    <div
      data-testid="menu"
      className={`${classes.container}`}
      onClick={() => setIsOpen(isOpen ? false : true)}
    >
      <div
        data-testid="hamburger"
        data-isopen={isOpen}
        className={classes.navBarToggle}
      >
        {bars.map(bar =>
          <div className={classes.bar} data-bar={bar} key={bar} data-testid={bar} />)
        }
      </div>
    </div>
  );
}