import PropTypes from 'prop-types';

export default function EnvelopeSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27px" height="20px" viewBox="0 0 26 20">
      <path
        d="M 25.511719 6.605469 C 25.605469 6.535156 25.726562 6.523438 25.828125 6.574219 C 25.929688 6.625 25.996094 6.734375 26 6.851562 L 26 17.5 C 26 18.878906 24.910156 20 23.5625 20 L 2.4375 20 C 1.089844 20 0 18.878906 0 17.5 L 0 6.855469 C 0 6.738281 0.0664062 6.628906 0.171875 6.578125 C 0.273438 6.527344 0.394531 6.539062 0.488281 6.609375 C 1.625 7.515625 3.132812 8.667969 8.3125 12.527344 C 9.390625 13.332031 11.199219 15.015625 13 15 C 14.8125 15.015625 16.65625 13.292969 17.6875 12.519531 C 22.867188 8.667969 24.375 7.511719 25.511719 6.605469 Z M 13 13.332031 C 14.179688 13.355469 15.875 11.8125 16.726562 11.175781 C 23.464844 6.160156 23.976562 5.722656 25.535156 4.472656 C 25.828125 4.238281 26 3.875 26 3.488281 L 26 2.5 C 26 1.121094 24.910156 0 23.5625 0 L 2.4375 0 C 1.089844 0 0 1.121094 0 2.5 L 0 3.488281 C 0 3.875 0.171875 4.238281 0.46875 4.476562 C 2.023438 5.71875 2.535156 6.160156 9.273438 11.179688 C 10.125 11.8125 11.820312 13.351562 13 13.332031 Z M 13 13.332031"
        fill={color}
      />
    </svg>
  );
}

EnvelopeSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
