import {  useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as rdrLocales from 'react-date-range-ts/dist/locale';
import { useTranslation } from 'react-i18next';
import dateLabel from '../DateFilter/DateFilterResume/dateLabel';
import Calendar from 'dayjs/plugin/calendar';

dayjs.extend(Calendar);

interface DateSelection {
  startDate: Date
  endDate: Date
  key: string
}

interface props {
  readonly filters: any
  readonly setFilter: Function
  readonly name: string
  readonly label:string
}

export default function useDateFilter({ filters, setFilter, name,label }:props) {
  const { t, i18n } = useTranslation(),
    locale:any = i18n.language.toLocaleLowerCase() === 'fr' || i18n.language.toLocaleLowerCase().startsWith('fr-') ?
      rdrLocales.fr: rdrLocales.enUS,
    [toggle, setToggle] = useState(false),
    [dateState, setDateState] = useState<DateSelection[]>([]);

  async function handleConfirm() {
    const { startDate, endDate } = dateState[0];
    setFilter(name,{
      label,
      valueNew: {
        start: dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
        end: dayjs(endDate).format('YYYY-MM-DDT23:59:59')
      },
      whereClause:
        {
          between: [dayjs(startDate).format('YYYY-MM-DDT00:00:00'), 
            dayjs(endDate).format('YYYY-MM-DDT23:59:59')]
        }, 
      description: t(dateLabel({ startDate, endDate }, t,true))
    });
    setToggle(false);
  }

  function handSetDateState(date: DateSelection[], from: string) {
    setDateState(date);
  }

  useEffect(() => {
    if (filters && filters[name] && new Date(filters[name]?.whereClause?.between[0])) {
      setDateState([
        {
          startDate: new Date(filters[name]?.whereClause?.between[0]),
          endDate: new Date(filters[name]?.whereClause?.between[1]),
          key: 'selection',
        },
      ]);
    }
    // eslint-disable-next-line
  }, [filters]);

  return {handleConfirm,setDateState:handSetDateState,setToggle,dateState,t,i18n,toggle,locale};
}
