import dayjs from 'dayjs';
import classes from './HistoryItem.module.css';
import {
  faStarOfLife,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Status } from '../../types/action.interface';

function getWaiting(status: string, waiting: boolean) {
  if (status === Status.inprogress && waiting) {
    return classes.statusWaiting;
  }
  return classes.statusNotWaiting;
}

function getStatusClass(status: string) {
  switch (status) {
  case Status.new:
    return classes.statusNew;
  case Status.reappearance:
    return classes.statusReappearance;
  case Status.ignored:
    return classes.statusIgnored;
  case Status.accepted:
    return classes.statusAccepted;
  case Status.refused:
    return classes.statusRefused;
  case Status.disappeared:
    return classes.statusDisappeared;
  case Status.requested:
    return classes.statusRequested;
  case Status.inprogress:
    return classes.statusInprogress;
  case Status.reminded:
    return classes.statusReminded;
  case Status.blocked:
    return classes.statusBlocked;
  default:
    return null;
  }
}

function getStatusLabel(status: string, t: (sd: string) => string) {
  switch (status) {
  case Status.new:
    return t('Timeline_New');
  case Status.reappearance:
    return t('Timeline_Reappearance');
  case Status.ignored:
    return t('Timeline_Ignored');
  case Status.accepted:
    return t('Timeline_Accepted');
  case Status.refused:
    return t('Timeline_Refused');
  case Status.disappeared:
    return t('Timeline_No_more_detected');
  case Status.requested:
    return t('Timeline_Requested');
  case Status.inprogress:
    return t('Timeline_Notified');
  case Status.reminded:
    return t('Timeline_Reminded');
  case Status.blocked:
    return t('Timeline_Blocked');
  default:
    return null;
  }
}

interface ActionHistoryItemProps {
  status: string;
  date: Date;
  previousDate?: Date[];
  last: boolean;
  beforeReappearance?: boolean;
  waiting: boolean;
}

export default function ActionHistoryItem({
  status,
  date,
  previousDate,
  last,
  beforeReappearance,
  waiting,
}: ActionHistoryItemProps) {
  const { t } = useTranslation();
  const statusClass = getStatusClass(status);
  const statusLabel = getStatusLabel(status, t);
  const statusNotWaiting = getWaiting(status, waiting);
  const formatDate = (date: Date) => {
    return dayjs(date).format('DD/MM/YYYY');
  };
  let dateFormatted = formatDate(date);

  if (previousDate && previousDate.length > 0) {
    const previousDateFormatted = previousDate.map(formatDate);
    dateFormatted += `, ${previousDateFormatted.join(', ')}`;
  }

  return (
    <>
      <div
        className={`${classes.status} ${last ? classes.last : ''} ${
          beforeReappearance ? classes.beforeReappearance : ''
        }`}
      >
        <div className={classes.timeline}>
          <div className={`${classes.roundedStatus} ${statusClass}`}></div>
        </div>
        <div className={classes.labels}>
          <span className={classes.statusLabel}>{statusLabel}</span>
          <sup className={`${statusNotWaiting}`}>
            <FontAwesomeIcon
              icon={faStarOfLife}
              size='xs'
              color='var(--color-title)'
            />
          </sup>{' '}
          <span className={classes.statusDate}>le {dateFormatted}</span>
        </div>
      </div>
    </>
  );
}
