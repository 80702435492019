import PropTypes from 'prop-types';
import companyAccountDefinition from './companyAccount.definition';

const brandDefinition = {
  id: PropTypes.string,
  autoMatchRegExp: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  notificationAuto: PropTypes.bool,
  companyAccounts: PropTypes.arrayOf(companyAccountDefinition),
  validated: PropTypes.bool,
  reminder: PropTypes.shape({
    autoReminder: PropTypes.bool,
    quota: PropTypes.number,
    frequency: PropTypes.number
  })
};

export default brandDefinition;