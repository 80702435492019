import { action, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import PageStore from '../../stores/page.store';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/getSetCookie/getsetCookie';
import dayjs from 'dayjs';
import Backend from '../../stores/newBackend';
import { link } from 'fs';

export default class AdvertisersStore extends PageStore {
  statusCount = [];
  tableHeader = [
    { name: 'selectAll', value: 0, handleClick: () => this.toggleSelectAll() },
    { name: 'domain', title: 'advertiser', sortable: true, center: false },
    { name: 'countries', title: 'countries', sortable: false, center: true },
    { name: 'engine', title: 'engine', sortable: false, center: true },
    { name: 'categories', title: 'categories', center: false, sortable: false },
    { name: 'status', title: 'action status', sortable: false, center: false },
    {
      name: 'lastStatusDate',
      title: 'action status date',
      sortable: true,
      center: false,
    },
    { name: 'createdDate', title: 'firstView', sortable: true, center: false },
    { name: 'lastView', title: 'lastView', sortable: true, center: false },
    { name: 'priority', title: 'views', sortable: true, center: false },
    { name: 'actions', title: 'actions', center: true, sortable: false },
  ];
  endpoint = '/disabled';
  selectedItems = [];
  selected = [];
  order = 'priority';
  isLoading = false;
  direction = 'desc';
  showAds = null;
  errorOther = null;
  isAdmin = false;
  currentPage = 1;
  currentPerPage = 30;
  next = null;
  myFilters = {};
  previous = null;
  tags = [];
  defaultFilters = {
    lastView: {
      label: 'lastView',
      description: i18next.t('last 30 Days'),
      whereClause: {
        between: [
          dayjs(dayjs().subtract(30, 'day')).format('YYYY-MM-DDT00:00:00'),
          dayjs(dayjs()).format('YYYY-MM-DDT23:59:59'),
        ],
      },
    },
    status: {
      whereClause: { inq: ['new'] },
      description: i18next.t('new'),
      value: [{ label: i18next.t('new'), value: i18next.t('new') }],
      label: 'status',
    },
  };

  constructor(user, isAdmin, tags, token) {
    super(user);
    makeObservable(this, {
      selectedItems: observable,
      selected: observable,
      statusCount: observable,
      showAds: observable,
      isAdmin: observable,
      tags: observable,
      currentPage: observable,
      currentPerPage: observable,
      next: observable,
      myFilters: observable,
      previous: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      replaceAdvertiser: action,
      setAdvertiserGroup: action,
      setIsAdmin: action,
      setTags: action,
      setShowAds: action,
      updateItem: action,
      setStatusCount: action,
    });
    this.token = token;
    this.isAdmin = isAdmin;
    this.tags = tags;
    if (!this.filters) {
      this.filters = this.defaultFilters;
    }
  }

  setErrorOther(value) {
    this.errorOther = value;
  }

  setIsAdmin(value) {
    this.isAdmin = value;
  }

  setMyFilters(value) {
    this.myFilters = value;
  }

  setCurrentPage(value) {
    this.currentPage = value;
  }

  setCurrentPerPage(value) {
    this.currentPerPage = value;
  }

  setPrevious(value) {
    this.previous = value;
  }

  setNext(value) {
    this.next = value;
  }

  setTags(value) {
    this.tags = value;
  }

  setStatusCount(counts) {
    this.statusCount = counts;
  }

  handleChangePerPage(perPage) {
    this.setCurrentPerPage(perPage);
    this.handleFetch();
  }

  async handleCount() {}

  async changePage(direction) {
    if (this.isLoading) {
      return;
    }
    let link;
    if (direction === 'next') {
      const pageMax = Math.ceil(this.count / this.currentPerPage);
      const pageNumber =
        this.currentPage < pageMax ? this.currentPage + 1 : pageMax;
      this.setCurrentPage(pageNumber);
      link = this.next;
    }
    if (direction === 'previous') {
      const pageMin = 1;
      const pageNumber =
        this.currentPage > pageMin ? this.currentPage - 1 : pageMin;
      this.setCurrentPage(pageNumber);
      link = this.previous;
    }
    this.setIsLoading(true);
    this.selectedItems = [];
    this.selected = [];
    try {
      const result = await Backend.searchAdvertiserFromAction(
        this.token,
        {},
        link
      );
      if (result && result.data) {
        for (const item of result.data) {
          item.date = item.createdDate;
          item.statusDate = item.lastatusDate;
          if (!item.lastStatusDate && item.status === 'reappearance') {
            item.lastStatusDate = item.createdDate;
          }
        }

        this.setItems(result.data);
        this.setNext(result.links.next);
        this.setPrevious(result.links.prev);
      }
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }

    this.setIsLoading(false);
  }

  transformFilter(filters) {
    let myFilters = {};
    // eslint-disable-next-line
    const jsonFilters = JSON.stringify(filters);
    const decodeFilters =
      jsonFilters === undefined ? {} : JSON.parse(jsonFilters);
    const testFilter = decodeFilters?.and;
    if (testFilter) {
      testFilter.forEach((filter) => {
        while (filter[0] || filter.and || filter.or) {
          if (filter[0]) {
            filter = filter[0];
          } else if (filter.and) {
            filter = filter.and;
          } else if (filter.or) {
            filter = filter.or;
          }
        }
        let filterKey = Object.keys(filter)[0];
        if (filterKey === 'master') {
          filterKey = 'domain';
        }
        //if (filterKey === 'presence') {
        //  filterKey = 'type';
        //}
        if (filterKey === 'dealer.categories.id') {
          filterKey = 'advertiserTagId';
        }
        const filterTemp = Object.values(filter);
        filterTemp.forEach((insideFilter) => {
          const insideFilterKeysArray = Object.keys(insideFilter);
          const insideFilterValuesArray = Object.values(insideFilter);
          for (let i=0; i<insideFilterKeysArray.length; i++) {
            // loop
            const filterTempKey = insideFilterKeysArray[i];
            const filterTempValues = insideFilterValuesArray[i];
            if (filterTempKey === 'inq') {
              myFilters[filterKey] = [];
              filterTempValues.forEach((value) => {
                if (value && filterKey !== 'metas.originalStatus') {
                  myFilters[filterKey].push(value);
                } else {
                  if (value && filterKey === 'metas.originalStatus') {
                    myFilters['metas'] = {};
                    myFilters['metas']['originalStatus'] =
                      'reappearance';
                  }
                  if (!value && filterKey === 'metas.originalStatus') {
                    myFilters['metas'] = {};
                    myFilters['metas']['originalStatus'] = '-reappearance';
                  }
                }
              });
            }
            if (filterTempKey === 'nin') {
              if (!myFilters[filterKey]) {
                myFilters[filterKey] = [];
              }
              filterTempValues.forEach((value) => {
                myFilters[filterKey].push('-' + value);
              });
            }
            if (filterTempKey === 'between') {
              myFilters[filterKey] = {};
              myFilters[filterKey]['start'] = filterTempValues[0];
              myFilters[filterKey]['end'] = filterTempValues[1];
            }
            if (filterTempKey === 'regexp') {
              if (!myFilters[filterKey]) {
                myFilters[filterKey] = [];
              }
            
              myFilters[filterKey].push(filterTempValues.split('/')[1]);
           
            // myFilters[filterKey] = filterTempValues.split('/')[1];
            }
          }
        });
      });
    }

    myFilters.sortBy = `${this.direction === 'desc' ? '-' : ''}${this.order}`;
    myFilters.page = {
      size: this.currentPerPage,
    };
    return myFilters;
  }

  async handleFetch() {
    if (this.isLoading) {
      return;
    }
    this.setErrorOther(null);
    this.setCurrentPage(1);
    this.setIsLoading(true);
    this.setIsLoadingCount(true);
    this.selectedItems = [];
    this.selected = [];
    const isAdminFields = [
      { txt: 'new', color: 'azure' },
      { txt: 'requested', color: 'dark-blue' },
      { txt: 'inprogress', color: 'normal' },
      { txt: 'accepted', color: 'green' },
      { txt: 'refused', color: 'red' },
      { txt: 'disappeared', color: 'dark-green' },
      { txt: 'blocked', color: 'grey' },
      { txt: 'reminded', color: 'orange' },
    ];

    const nonAdminFields = [
      { txt: 'new', color: 'azure' },
      { txt: 'inprogress', color: 'normal' },
      { txt: 'accepted', color: 'green' },
      { txt: 'refused', color: 'red' },
    ];

    try {
      const myFilters = this.transformFilter(this.computedFilters);
      this.setMyFilters(myFilters);
      const result = await Backend.searchAdvertiserFromAction(
        this.token,
        this.myFilters,
        link
      );
      if (result && result.data) {
        for (const item of result.data) {
          item.date = item.createdDate;
          item.statusDate = item.lastatusDate;
          if (!item.lastStatusDate && item.status === 'reappearance') {
            item.lastStatusDate = item.createdDate;
          }
        }

        let countFields = nonAdminFields;

        if (this.isAdmin) {
          countFields = isAdminFields;
        }

        this.setStatusCount(
          countFields.map(({ txt, color }) => {
            const count = result.meta?.page?.subTotal[txt];
            return { txt, color, count };
          })
        );

        this.setItems(result.data);
        this.setCount(result.meta.page.total);
        this.setNext(result.links.next);
        this.setPrevious(result.links.prev);
      }
    } catch (e) {
      this.setErrorOther(true);
      console.error(e.message.toString());
      toast.error(i18next.t('An error occured'));
    }

    this.initPage = true;
    this.setIsLoading(false);
    this.setIsLoadingCount(false);
  }

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.selected = [];
      this.tableHeader[0].value = 0;
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.ID);
      this.selected = this.filteredItems.map((item) => item);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item) {
    this.selectedItems = _.xor([item.ID], this.selectedItems);
    this.selected = _.xorWith([item], this.selected, (a, b) => a.ID === b.ID);
  }

  get counters() {
    return [
      { txt: 'advertiser', count: this.count, color: 'secondary' },
      ...this.statusCount,
    ];
  }

  setAdvertiserGroup(status) {
    this.selectedItems.forEach((ID) => {
      const newItem = _.find(this.items, { ID });
      newItem.status = status;
      this.replaceAdvertiser(newItem);
    });
  }

  replaceAdvertiser(advertiser, deselect) {
    const oldAdvertiser = _.find(this.items, { ID: advertiser.ID }),
      newAdvertiser = { ...advertiser };

    if (_.get(oldAdvertiser, 'dealer')) {
      newAdvertiser.dealer = oldAdvertiser.dealer;
    }

    this.items.splice(
      _.findIndex(this.items, { ID: advertiser.ID }),
      1,
      newAdvertiser
    );
    const newSelectedList = [...this.selected];
    newSelectedList.splice(
      _.findIndex(this.selected, { ID: advertiser.ID }),
      1,
      newAdvertiser
    );
    if (!deselect) {
      this.selected = newSelectedList;
      this.selectedItems = this.selected.map((item) => item.ID);
    }
  }

  updateItem(item, tags) {
    const index = _.findIndex(this.items, { termId: item.termId });
    if (index >= 0) {
      this.items.splice(index, 1, {
        ...item,
        categories: tags.map((o) => o.originalTag),
      });
    }
  }

  filterSearch(item) {
    const { engine, categories, brand, localization, canBeAccessBy, domain } =
      item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (engine) {
      engine.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (domain) {
      domain.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (localization) {
      localization?.locale.toLowerCase().includes(this.inputFilterValue) &&
        count++;
    }

    if (brand) {
      if (brand.label) {
        brand?.label.toLowerCase().includes(this.inputFilterValue) && count++;
      }

      if (brand.name) {
        brand?.name.toLowerCase().includes(this.inputFilterValue) && count++;
      }
    }

    if (categories) {
      _.map(
        categories,
        (category) =>
          category.label.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    if (canBeAccessBy) {
      _.map(
        canBeAccessBy,
        (companyAccount) =>
          companyAccount.name.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    return count > 0 ? true : false;
  }

  setShowAds(value) {
    this.showAds = value;
  }

  get exportLink() {
    const myFilters = JSON.parse(JSON.stringify(this.myFilters));
    delete myFilters.page;
    let query = {
      noPaging: true,
      csv: true,
      access_token: getCookie('access_token'),
    };
    let queryString = new URLSearchParams(query);
    for (const [key, value] of Object.entries(myFilters)) {
      if (typeof value !== 'object') {
        // console.log(typeof value);
        queryString.append(key, myFilters[key]);
      } else if (value['start']) {
        console.log(value['start']);
        queryString.append(key + '[start]', value['start']);
        queryString.append(key + '[end]', value['end']);
      } else {
        // console.log('value is', value);
        const arrayValue = Object.values(value);
        // console.log(arrayValue);
        for (let i = 0; i < arrayValue.length; i++) {
          queryString.append(`${key}[${i}]`, arrayValue[i]);
        }
      }
    }
    const link =
      process.env.REACT_APP_MONIBRAND_BACKEND_V2_API_URL +
      '/actions/searchAll' +
      '?' +
      queryString.toString();
    return link;
    // 'https://api-v2.monibrand.local/actions/searchAll?sortBy=-priority&page[size]=30&noPaging=true&csv=true&access_token=' + access_token
  }
}
