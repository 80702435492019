import _ from 'lodash';
import { DealerApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import containsValueMapper from '../../../Utils/filter/containsValueMapper';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

export default function DomainFilter() {
  const dealerApi = new DealerApi(),
    { data } = useGet<{ count: number } | undefined>((f: Object) =>
      dealerApi.dealerCount(f)
    ),
    { t } = useTranslation();

  async function promiseOptions(inputValue?: string) {
    const filter: { limit: number; order: string; where?: Object,fields:Array<string> } = {
      limit: 50,
      order: 'domain asc',
      fields: ['domain'],
    };

    if (inputValue) {
      filter.where = { domain: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } };
    }

    const items = await dealerApi.dealerFind({
      filter: JSON.stringify(filter),
    });

    const options = _.map(items, (o) => [
      {
        label: o.domain,
        value: o.domain,
      },{
        label: `${t('exclude')} : ${o.domain}`,
        value: `exclude:${o.domain}`,
      }
    ]);
    options
      .sort((a, b) => a[0].label.localeCompare(b[0].label));

    return options
      .reduce((acc, value) => ([
        ...acc,
        ...value
      ]), []);
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('select domain'),
      isMulti: true,
      creatable:true,
    },
    count: data?.count,
    valueMapper: containsValueMapper
  };
}
