import PropTypes from 'prop-types';

const semDefinition = {
  termId: PropTypes.string,
  term: PropTypes.string,
  title: PropTypes.string,
  domain: PropTypes.string,
  date: PropTypes.string,
  engine: PropTypes.oneOf(['GOOGLE','BING', 'google','bing']),
  region: PropTypes.string,
  language: PropTypes.string,
  country: PropTypes.string,
  targetUrl: PropTypes.string,
  displayUrl: PropTypes.string,
  description: PropTypes.string,
  realPosition: PropTypes.number,
  position: PropTypes.number,
  type: PropTypes.string,
  device: PropTypes.oneOf(['desktop','mobile']),

};

export default semDefinition;