import _ from 'lodash';
import Checkbox from '../../Checkbox/Checkbox';
import classes from './CheckboxCell.module.css';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

CheckboxCell.propTypes = {
  item: PropTypes.object,
  store: PropTypes.object,
  checkedField: PropTypes.string
};

export default function CheckboxCell({ item, store, checkedField }) {
  const isChecked = useMemo(() => !!store.selectedItems?.find(
    (it) => it === _.get(item, checkedField || 'id')), 
  // eslint-disable-next-line
    [item, checkedField,store.selectedItems]);

  return (
    <div
      className={classes.container}
      data-testid='checkbox_cell'
      onClick={() => store.toggleSelectOne(item)}
    >
      <Checkbox
        value={item.value}
        checked={isChecked}
      />
    </div>
  );
}
