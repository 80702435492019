import PropTypes from 'prop-types';
import { getScale, getDimensions } from './IconSvgUtils';

export default function EyeSVG({ color, height }) {
  const scale = getScale(12, height);
  const dimensions = getDimensions(18, 12, null, height);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${height ? dimensions.height + 5 : dimensions.height}`}
    >
      <path
        d="M17.891,10.044A10.023,10.023,0,0,0,9,4.5,10.025,10.025,0,0,0,.109,10.044a1.011,1.011,0,0,0,0,.912A10.023,10.023,0,0,0,9,16.5a10.025,10.025,0,0,0,8.891-5.544A1.011,1.011,0,0,0,17.891,10.044ZM9,15a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,9,15ZM9,7.5a2.978,2.978,0,0,0-.791.118A1.5,1.5,0,0,1,6.118,9.709,2.993,2.993,0,1,0,9,7.5Z"
        transform={`translate(0 -4.5) ${scale ? `scale(${scale})` : ''}`}
        fill={color}
      />
    </svg>
  );
}

EyeSVG.propTypes = {
  color: PropTypes.string.isRequired,
  height:PropTypes.number
};
