import { CategoryApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import tagToOptions from '../../../Utils/tagToOptionsWithChildren';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

export default function useTagsFilter(scope:string) {
  const categoryApi = new CategoryApi(),
    { data } = useGet<{ count: number } | undefined>((f: Object) =>
      categoryApi.categoryCount(f)
    ,{
      where: JSON.stringify({ scope }),
    }),
    { t } = useTranslation();

  async function promiseOptions(inputValue?: string) {
    const filter: { limit: number; order: string; where?: Object,include:Array<string | Object> } = {
      limit: 50,
      order: 'name asc',
      include: [{'children': 'owner'}, 'owner'],
      where: { scope }
    };

    if (inputValue) {
      filter.where = { and: [
        { scope },
        { label: { regexp: `/${createInputValueRegexContent(inputValue, { noSpaceConversion: true })}/i` } }
      ] };
    }

    const items = await categoryApi.categoryFind({
      filter: JSON.stringify(filter),
    });

    const options = tagToOptions(items || []);
    return options;
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('Select tag'),
      isMulti: true,
    },
    count: data?.count
  };
}
