import { useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ErrorsMsg from '../../components/ErrorsMsg/ErrorsMsg';
import { ParseUseFormErrors } from '../../Utils/utils';
import AuthInput from '../../components/AuthInput/AuthInput';
import PublicSubmitButton from '../../components/PublicSubmitButton/PublicSubmitButton';
import { StoreContext } from '../../stores';
import CheckList from '../../components/CheckList/CheckList';
import { PASSWORD_VALID_REGEX } from '../../Utils/rules/passwordValidation';

function Reset() {
  const { authStore } = useContext(StoreContext),
    { token } = useParams(),
    { resetInProgress, resetErrors } = authStore,
    { register, handleSubmit, errors, watch } = useForm(),
    [passwordJustReset, setPasswordJustReset] = useState(false);
  const onHandleSubmit = (event) => {
    authStore.resetPassword({ ...event, token }).then(() => {
      if (!resetErrors) {
        setPasswordJustReset(true);
      }
    });
  };
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordNotCompliant, setPasswordNotCompliant] = useState(true);

  function handleChange(e) {
    const { value } = e.target;
    if (value.match(PASSWORD_VALID_REGEX)) {
      setPasswordNotCompliant(false);
    } else {
      setPasswordNotCompliant(true);
    }
    console.log('password', value);
    setPassword(value);
  }

  function handleChangeConfirm(e) {
    const { value } = e.target;
    console.log('password', value);
    setConfirmPassword(value);
  }


  return (
    <>
      {passwordJustReset ? (
        <div>
          <div>{t('Votre mot de passe a bien été réinitialisé!')}</div>
          <Link to="/login">
            <div>{t('Connectez-vous')}</div>
          </Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <AuthInput
            label={t('Mot de passe')}
            name="password"
            type="password"
            onChange={(e) => handleChange(e)}
            register={register({
              required: {
                value: true,
                message: t('The new password is required'),
              },
            })}
            errors={errors}
          />
          <AuthInput
            label={t('Confirmer Mot de passe')}
            name="confirmPassword"
            type="password"
            onChange={(e) => handleChangeConfirm(e)}
            register={register({
              required: {
                value: true,
                message: t('The new password confirmation is required'),
              },
              validate: (value) =>
                value === watch('password') || t('The passwords do not match'),
            })}
            errors={errors}
          />
          <CheckList
            value={password}
            className={'visible'}
          />
          {(resetErrors ||
            (Object.keys(errors).length > 0 &&
            errors.constructor === Object)) && (
            <ErrorsMsg errors={resetErrors ? resetErrors : ParseUseFormErrors(errors)} UnauthorizedMsg={t('Votre token a expiré.')}/>
          )}
          <PublicSubmitButton disabled={resetInProgress || passwordNotCompliant || password !== confirmPassword}>
            Réinitialiser
          </PublicSubmitButton>
        </form>
      )}
    </>
  );
}

export default observer(Reset);
