import { useReducer, Reducer, useState } from 'react';
import { Action } from '../../../types/action.newBackend';
import Backend from '../../../stores/newBackend';

export const parseAction = (httpAction: any): Action => {
  const action = {
    ...httpAction,
  };

  if (action.lastView) {
    action.lastView = new Date(action.lastView);
  }

  action.history = action.history
    ? action.history.map((h: any) => {
        const newHistory = {
          ...h,
          date: new Date(h.date),
        };

        return newHistory;
      })
    : [];

  return action;
};

type ActionResult = {
  type: 'results';
  action: any;
};

type ReducerAction =
  | { type: 'load' }
  | ActionResult
  | { type: 'error'; error: any };

interface State {
  loading: boolean;
  error: any;
  action: any;
}

const useGetActionsInitialeState = {
  loading: false,
  error: null,
  action: null,
};

export default function useGetAction(initState?: Partial<State>) {
  const [{ loading, error, action }, dispatch] = useReducer<
    Reducer<State, ReducerAction>
  >(
    useGetActionReducer,
    initState
      ? {
          ...useGetActionsInitialeState,
          ...initState,
        }
      : useGetActionsInitialeState
  );

  const [fetchBackend] = useState(() => {
    return async (token: string, actionID: string) => {
      dispatch({
        type: 'load',
      });

      try {
        const result = await Backend.loadAction(token, actionID);
        const dispatchData: ActionResult = {
          type: 'results',
          action: parseAction(result),
        };

        dispatch(dispatchData);
      } catch (e) {
        dispatch({ type: 'error', error: e });
      }
    };
  });

  return {
    loading,
    error,
    action,
    fetch: fetchBackend,
  };
}

function useGetActionReducer(state: State, action: ReducerAction): State {
  const newState = { ...state };
  switch (action.type) {
    case 'load':
      newState.loading = true;
      newState.action = undefined;
      newState.error = undefined;
      break;
    case 'results':
      newState.loading = false;
      newState.action = action.action;
      break;
    case 'error':
      newState.loading = false;
      newState.error = action.error;
      break;
    default:
      throw new Error('Missing action reducer');
  }

  return newState;
}
