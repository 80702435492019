import classes from './TagListFormMulti.module.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DealerApi, TermApi } from '../../../swagger_client/src';
import _ from 'lodash';
import tagToOptions from '../../../Utils/tagToOptions';
import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import TagForm from '../TagForm/TagForm';
import SubmitButton from '../../SubmitButton/SubmitButton';
import Creatable from 'react-select/creatable';
import { observer } from 'mobx-react';
import CancelButton from '../../CancelButton/CancelButton';
import {
  tagListFormMultiActionsType,
  tagListForMultiReducer,
} from './TagListForMulti.reducer';
import tagDefinition from '../../../proptypes/tag.definition';
import selectOptions from '../../../proptypes/selectOptions.definition';
import termDefinition from '../../../proptypes/term.definition';

TagListForMulti.defaultProps = {
  defaultTags: []
};

TagListForMulti.propTypes = {
  defaultTags: PropTypes.arrayOf(tagDefinition).isRequired,
  selectedItems: PropTypes.arrayOf(termDefinition).isRequired,
  options: PropTypes.arrayOf(selectOptions).isRequired,
  scope: PropTypes.oneOf(['tag','term']).isRequired,
  onClose: PropTypes.func.isRequired,
  refetchTag: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

function TagListForMulti({
  options,
  scope,
  defaultTags,
  onClose,
  selectedItems,
  refetchTag,
  refetch,
}) {
  const [{ defaultOptions, isCreated, tagsToRemove, tagsToAdd,isLoading }, dispatch] =
      useReducer(tagListForMultiReducer, {
        isLoading: false,
        isCreated: undefined,
        defaultOptions: tagToOptions(defaultTags),
        tagsToRemove: [],
        tagsToAdd: [],
      }),
    termApi = new TermApi(),
    dealerApi = new DealerApi(),
    { t } = useTranslation();

  function handleChange(selectedTags) {
    /**
     * On ne peut supprimer que les tags communs.
     */
    const tagsRemove = [
        ...tagsToRemove.filter(
          (o) => !_.find(selectedTags, { originalTag: { id: o.id } })
        ),
        ...defaultTags.filter(
          (o) => !_.find(selectedTags, { originalTag: { id: o.id } })
        ),
      ],
      isNew = _.find(selectedTags, { __isNew__: true });

    if (isNew) {
      dispatch({
        type: tagListFormMultiActionsType.setIsCreated,
        payload: {
          label: isNew.label,
          scope,
          visibility: 'owner',
        },
      });
      return;
    }

    const tagsAdd = _.filter(selectedTags, (o) => {
      const isCommon = _.find(defaultTags, { id: o.originalTag.id }),
        isDelete = _.find(tagsRemove, { id: o.originalTag.id });

      if (isCommon || isDelete) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: tagListFormMultiActionsType.handleChange,
      payload: {
        defaultOptions: selectedTags,
        tagsToAdd: _.uniqBy(tagsAdd, 'label'),
        tagsToRemove: _.uniqBy(tagsRemove, 'label'),
      },
    });
  }

  async function addAfterCreate(tag) {
    dispatch({
      type: tagListFormMultiActionsType.setDefaultOptionsWithCreated,
      payload: { ...tag, originalTag: tag, value: tag.id },
    });
    await refetchTag();
  }

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch({
      type: tagListFormMultiActionsType.setLoading,
      payload:true
    });
    const successAdds = [],
      failAdds = [],
      successRemove = [],
      failRemove = [];

    for (const selectedItem of selectedItems) {
      for (const { originalTag } of tagsToAdd) {
        const { id } = originalTag;
        try {
          if (scope === 'term') {
            await termApi.termPrototypeLinkCategories(selectedItem, id, {});
          }

          if (scope === 'tag') {
            await dealerApi.dealerPrototypeLinkCategories(selectedItem,id,{});
          }

          successAdds.push(id);
        } catch (e) {
          toast.error(t(e.message));
          failAdds.push(id);
        }
      }

      for (const { id } of tagsToRemove) {
        try {
          if (scope === 'term') {
            await termApi.termPrototypeUnlinkCategories(selectedItem, id, {});
          }

          if (scope === 'tag') {
            await dealerApi.dealerPrototypeUnlinkCategories(
              selectedItem,
              id,{}
            );
          }
          successRemove.push(id);
        } catch (e) {
          toast.error(t(e.message));
          failRemove.push(id);
        }
      }
    }

    if (_.uniq(successAdds).length > 0) {
      toast.success(
        _.uniq(successAdds).length +
          ' ' +
          t(`tag${_.uniq(successAdds).length > 1 ? 's' : ''} added`)
      );
    }

    if (_.uniq(failAdds).length > 0) {
      toast.error(
        _.uniq(failAdds).length +
          ' ' +
          t(`tag${_.uniq(failAdds).length > 1 ? 's' : ''} not added`)
      );
    }

    if (_.uniq(successRemove).length > 0) {
      toast.info(
        _.uniq(successRemove).length +
          ' ' +
          t(`tag${_.uniq(successRemove).length > 1 ? 's' : ''} removed`)
      );
    }

    if (_.uniq(failRemove).length > 0) {
      toast.error(
        _.uniq(failRemove).length +
          ' ' +
          t(`tag${_.uniq(failRemove).length > 1 ? 's' : ''} not removed`)
      );
    }

    dispatch({
      type: tagListFormMultiActionsType.setLoading,
      payload:false
    });
    refetch();
  }
  return (
    <>
      {isCreated && (
        <TagForm
          setClose={() =>
            dispatch({
              type: tagListFormMultiActionsType.closeCreated,
            })
          }
          fields={isCreated}
          update={(tag) => addAfterCreate(tag)}
        />
      )}
      <form className={classes.container} onSubmit={(e) => handleSubmit(e)}>
        <Creatable
          options={options}
          isMulti
          value={defaultOptions}
          formatCreateLabel={(input) => `${t('create')} "${input}"`}
          styles={taglistStyle}
          closeMenuOnScroll={(e)=>{
            const targetClass = e.target.getAttribute('class');
            if(targetClass && !targetClass.includes('taglist')){
              return true;
            }
          }}
          menuPosition="fixed"
          placeholder={t('select tags')}
          classNamePrefix="taglist"
          onChange={(choices) => handleChange(choices, defaultOptions)}
        />

        <div className={classes.resume}>
          <div className={classes.titleResume}>{t('actions resume')}</div>
          <div className={classes.list}>
            <div className={classes.listItem}>
              <div className={classes.titleList}>{t('deleted tags')} :</div>
              <div className={classes.listItems}>
                {tagsToRemove.map((o) => (
                  <div className={classes.delete} key={o.label}>
                    {o.label}
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.listItem}>
              <div className={classes.titleList}>{t('added tags')} :</div>
              <div className={classes.listItems}>
                {tagsToAdd.map((o) => (
                  <div className={classes.add} key={o.label}>
                    {o.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <CancelButton txt={t('cancel')} onClose={() => onClose()} />
          <SubmitButton
            onClick={(e) => handleSubmit(e)}
            isLoading={isLoading}
            txt={t('update tags')}
          />
        </div>
      </form>
    </>
  );
}

export default observer(TagListForMulti);