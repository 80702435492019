import classes from './CollapsableSearchInput.module.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function CollapsableSearchInput({
  searchTerm,
  onChangeSearch,
}: {
  searchTerm: string;
  onChangeSearch: (searchTerm: string) => void;
}) {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChangeSearch(e.target.value);
  };

  return (
    <div
      className={`${classes.searchContainer} ${
        !searchTerm ? '' : classes.withSearchTerm
      }`}
    >
      <input
        type='text'
        className={classes.searchInput}
        placeholder={t('Search')}
        value={searchTerm}
        onChange={handleChange}
      />
      <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
    </div>
  );
}
