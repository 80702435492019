import { useEffect, useReducer } from 'react';
import { BrandApi } from '../../../swagger_client/src';

const SET_LOADING = 'setLoading',
  SET_FETCH = 'setFetch',
  SET_ERROR = 'setError',
  SET_COUNT = 'setCount',
  useGetBrandsInitialeState = {
    brandsLoading: true,
    brandError: null,
    brandsCount: 0,
    brands: [],
  };

export default function useGetBrands() {
  const brandApi = new BrandApi(),
    [{ brandsLoading, brandError, brands, brandsCount }, dispatch] = useReducer(
      useGetBrandsReducer,
      useGetBrandsInitialeState
    );

  function fetch() {
    brandApi.brandCount().then((data) => {
      dispatch({
        type: SET_COUNT,
        payload: data.count,
      });
    });

    brandApi
      .brandFind()
      .then((brands) => {
        dispatch({
          type: SET_FETCH,
          payload: brands.map(o=>({
            ...o,
            value: o
          })),
        });
      })
      .catch((e) => dispatch({ type: SET_ERROR, payload: e }));
  }
  useEffect(() => {
    fetch();
    // eslint-disable-next-line
    }, []);

  return { brandsLoading, brandError, brands, brandsCount, brandsFetch: fetch };
}

function useGetBrandsReducer(state, action) {
  const newsState = { ...state };
  switch (action.type) {
  case SET_LOADING:
    newsState.brandsLoading = action.payload;
    break;
  case SET_FETCH:
    newsState.brandsLoading = false;
    newsState.brands = action.payload;
    break;
  case SET_ERROR:
    newsState.brandsLoading = false;
    newsState.brandError = action.payload;
    break;
  case SET_COUNT:
    newsState.brandsCount = action.payload;
    break;
  default:
    throw new Error();
  }
  return newsState;
}
