import { observer } from 'mobx-react';
import useDomainFilter from './useDomainFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/NewAsyncCreateFilter';

function DomainFilter(props: FilterChild) {
  const useProps = useDomainFilter(),
    passProps = { ...props, ...useProps };

  return <AsyncCreateFilter {...passProps} />;
}

export default observer(DomainFilter);
