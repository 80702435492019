import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionForMultiSelectNoTrigger from '../../ActionForMultiSelect/ActionForMultiSelectNoTrigger/ActionForMultiSelectNoTrigger';
import PropTypes from 'prop-types';
import CreateFormTerm from '../CreateFormTerm/CreateFormTerm';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

AddTermsModale.propTypes = {
  settingTermsStore: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
};

export default function AddTermsModale({ settingTermsStore, onClose }) {
  const { t } = useTranslation(),
    { register, handleSubmit, control, watch, getValues } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: {
        engine: { label: 'Google', value: 'GOOGLE' },
        percent: 5,
        every: {
          label: t('auto'),
          value: 0,
        },
        activeSaveAds: true,
        state:true,
      },

      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    }),
    [ creating, setCreating ] = useState(false);


  async function handleSubmitForm(formValues) {
    //control required fields.
    if (!formValues.terms || formValues.terms?.length === 0) {
      return toast.warning(t('You need at least one keyword.'));
    }

    if (!formValues.localization) {
      return toast.warning(t('You need to choice a localization.'));
    }

    if (!formValues.engine) {
      return toast.warning(t('You need to choice an engine.'));
    }

    if (!formValues.every) {
      return toast.warning(t('You need to choice a daily requests.'));
    }

    const { canBeAccessBy, engine, categories, every, state, localization, region } =
      formValues;
    setCreating(true);

    const body = {
      terms: formValues.terms,
      every: every.value === 0 ? undefined: every.value,
      searchConvertRate: (formValues.percent && every.value) ? formValues.percent / 100: undefined,
      engine: engine.value,
      state: state ? 'enabled' : 'disabled',
      localizationId: localization.value.id,
      region: !region?.value ? undefined : region.value,
      options: {
        //WARNING - to guarantee the homogeneity of the ui, we activate the ads rather than deactivate them
        disableSaveAds: !formValues.activeSaveAds,
        saveOrganicResults: formValues.saveOrganicResults,
        saveShoppingAds: formValues.saveShoppingAds,
        delayForOrganicResults: formValues.delayForOrganicResults?.value,
      },
    };

    if(canBeAccessBy){
      body.canBeAccessBy = canBeAccessBy.map(
        (companyAccount) => companyAccount.value
      );
    }

    body.categories = _.map(categories, (tag) => {
      return tag.originalTag;
    });

    await settingTermsStore.createKeywords(body);

    settingTermsStore.setShowCreateModale(false);
    setCreating(false);
  }

  function handleOnClose() {
    settingTermsStore.setShowCreateModale(false);
    onClose();
  }

  return (
    <ActionForMultiSelectNoTrigger
      title={t('Add new keywords')}
      isLoading={creating}
      onSubmit={handleSubmit(handleSubmitForm)}
      onClose={onClose}
      submitbuttonText={t('add the keywords')}
      submitbuttonDisabled={(!watch('terms') || !watch('localization')) || settingTermsStore.isCreatedLoading}
      fullHeight
    >
      <CreateFormTerm
        updateMode={settingTermsStore.updateMode}
        register={register}
        control={control}
        watch={watch}
        getValues={getValues}
        onClose={handleOnClose}
      />
    </ActionForMultiSelectNoTrigger>
  );
}
