import classes from './TermOptionsUpdate.module.css';
import { TermInterface, TermOptions } from '../../../types/term.interface';
import Checkbox from '../../Checkbox/Checkbox';
import PopinTitle from '../../_ui/Popin/PopinTitle/PopinTitle';
import PopinSubtitle from '../../_ui/Popin/PopinSubtitle/PopinSubtitle';
import CancelButton from '../../CancelButton/CancelButton';
import SubmitButton from '../../SubmitButton/SubmitButton';

interface Props {
  readonly term: TermInterface
  readonly options: TermOptions
  readonly t: Function
  readonly onClose: Function
  readonly loading:boolean
  readonly handleSubmit: Function
  readonly handleCheck:Function
}

export default function TermOptionsUpdateView({options,t,onClose,loading,handleSubmit,handleCheck}:Props){

  return <div className={classes.container}>
    <PopinTitle text={t('Modify term options')} />
    <PopinSubtitle text={t('optionsTermsAssing')} />
    <section className={classes.section} >
      <div className={classes.checkOptions}>
        <div className={classes.checkTitle}>{t('In the engine results page, you want to collect')}:</div>
        <div className={classes.checkbox}>
          <Checkbox
            label={t('The organic results')}
            checked={options.saveOrganicResults}
            handleClick={() =>handleCheck('saveOrganicResults')}
          />
        </div>
        <div className={classes.checkbox}>
          <Checkbox
            label={t('Paid results of the "Classic advertising" type')}
            checked={!options.disableSaveAds}
            handleClick={() =>handleCheck('disableSaveAds')}
          />
        </div>
        <div className={classes.checkbox}>
          <Checkbox
            label={t('Paid results of the "Shopping" type')}
            checked={options.saveShoppingAds}
            handleClick={() =>handleCheck('saveShoppingAds')}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <CancelButton txt="cancel" onClose={()=>onClose()} />
        <SubmitButton
          onClick={()=>handleSubmit()}
          txt='Modify options'
          isLoading={loading}
          disabled={loading}
        />
      </div>
    </section>
  </div>;
}