
import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';
import Cart from '../../../assets/icons/Icon awesome-shopping-cart.svg';
import Ad from '../../../assets/icons/Icon awesome-ad.svg';
import Search from '../../../assets/icons/Icon awesome-search.svg';

useTermBubbleForTooltip.propTypes = {
  item: PropTypes.shape(termDefinition),
};

export default function useTermBubbleForTooltip(item){
  const icons = [];

  if(item.options?.saveShoppingAds) icons.push(Cart);
  if(!item.options?.disableSaveAds) icons.push(Ad);
  if(item.options?.saveOrganicResults) icons.push(Search);

  return icons;
}
