import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../Checkbox/Checkbox';
import classes from './Thead.module.css';
import PropTypes from 'prop-types';
import OpenNewTabSVG from '../../Icon/svg/OpenNewTab';

Thead.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    sortable: PropTypes.bool
  })).isRequired,
  sortSetter: PropTypes.func.isRequired,
  order: PropTypes.string,
  direction: PropTypes.string,
  noFixedHeader: PropTypes.bool,
  simpleHeader: PropTypes.bool
};

export default function Thead({
  header,
  sortSetter,
  order,
  direction,
  noFixedHeader,
  simpleHeader
}) {
  const { t } = useTranslation();

  return (
    <thead className={`${classes.container} ${noFixedHeader ? classes.noFixedHeader : ''}`}>
      <tr className={classes.tr}>
        {header?.map((element) =>
          element.name === 'selectAll' ? (
            <th
              key={element.name}
              className={`${classes.th} ${simpleHeader ? classes.simple : ''} ${element.center ? classes.center : ''}`}
            >
              <div className={classes.checkbox} data-testid="checkall">
                <Checkbox 
                  value={element.value}
                  checked={!!element.value}
                  handleClick={element.handleClick}
                />
              </div>
            </th>
          ) : element.name === 'openNewTab' ? (
            <th
              key={element.name}
              className={`${classes.th} ${simpleHeader ? classes.simple : ''} ${element.center ? classes.center : ''}`}
            >
              <OpenNewTabSVG />
            </th>
          ) : (
            <th
              key={element.name}
              className={`${classes.th} ${simpleHeader ? classes.simple : ''} ${element.center ? classes.center : ''}`}
              onClick={() => element.sortable && sortSetter(element.name)}
            >
              <div className={classes.thTxt} data-sortable={element.sortable}>
                <span className={classes.title}>{t(element.title)}</span>
                {element.sortable && (
                  <div className={classes.sort}>
                    <div
                      className={classes.icon}
                      data-enabled={
                        order === element.name && direction === 'asc'
                      }
                    >
                      <FontAwesomeIcon
                        className={classes.icon}
                        icon={faSortUp}
                      />
                    </div>
                    <div
                      className={classes.icon}
                      data-enabled={
                        order === element.name && direction === 'desc'
                      }
                    >
                      <FontAwesomeIcon
                        className={classes.icon}
                        icon={faSortDown}
                      />
                    </div>
                  </div>
                )}
              </div>
            </th>
          )
        )}
      </tr>
    </thead>
  );
}
