import { useEffect, useContext } from 'react';
import AdsRow from '../../AdsRow/AdsRow';
import useGetAdvertisements from '../../Hooks/data/getAdvertisements';
import { ActionAdvertisementTable } from '../../../pages/Action/ActionAdvertisementList';
import { StoreContext } from '../../../stores';
import Loader from '../../Loader/Loader';

/**
 * Items et stores sont passé par la MagiTable
 * field est passé lorsque l'on appelle le composant en children de la magic Table
 * @param {string} field - Sert à insérer le composant dans la colonne correspondante de la magic Table
 */
function AdvertiserPortalCell({
  advertisements,
  store,
  loading,
}: {
  advertisements: any[];
  loading: boolean;
  store: any;
}) {
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <AdsRow
      AdsInTable={
        <>
          {/**
          <AdsInTable filters={filters} item={item} />
          */}
          <ActionAdvertisementTable
            advertisements={advertisements}
            shoppingType={false}
          />
        </>
      }
      onClose={() => store.setPortal()}
    />
  );
}

const AdvertiserPortalCellContainer = ({
  item,
  store,
}: {
  item: any;
  store: any;
}) => {
  const action = item;
  const { loading, advertisements, fetch } = useGetAdvertisements();
  const { authStore } = useContext(StoreContext);

  useEffect(() => {
    const criteria = {
      // type: 'ads',
      brandId: action.brandId,
      domain: action.domain,
      fromDate: action.createdDate,
      engine: action.engine,
      country: action.country,
      title: undefined,
      description: undefined,
    };

    const filters = store.transformFilter(store.computedFilters);
    if (filters.advertisementTitle) {
      criteria.title = filters.advertisementTitle;
    }

    if (filters.advertisementDescription) {
      criteria.description = filters.advertisementDescription;
    }

    fetch(authStore.token, criteria);
  }, [fetch, authStore.token, action, store]);

  return (
    <AdvertiserPortalCell
      store={store}
      advertisements={advertisements}
      loading={loading}
    />
  );
};

export default AdvertiserPortalCellContainer;
