// import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FullscreenModal from '../../components/FullscreenModal/FullscreenModal';
import ActionHistoryItem from './HistoryItem';
import classes from './FullHistoryModal.module.css';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import { GroupedHistory } from '../../types/action.newBackend';

// import { isBeforeReappearance, computeHistory } from './History';

interface FullHistoryModalProps {
  history: GroupedHistory[];
  waiting: boolean;
  onClose: () => void;
}

export default function FullHistoryModal({
  history,
  waiting,
  onClose,
}: FullHistoryModalProps) {
  const { t } = useTranslation();

  console.log('newHistory:', history);

  return (
    <FullscreenModal onClose={onClose} fullHeight>
      <div className={classes.header}>{t('Activities')}</div>
      <div className={classes.container}>
        <DynamicScrollbar>
          <div className={classes.history}>
            <div>
              {history.map((item, index: number) => (
                <ActionHistoryItem
                  key={item.ID}
                  beforeReappearance={false}
                  {...item}
                  last={index === history.length - 1}
                  waiting={waiting}
                />
              ))}
            </div>
          </div>
        </DynamicScrollbar>
      </div>
    </FullscreenModal>
  );
}
