import ProgressBar from '../../ProgressBar/ProgressBar';
import PropTypes from 'prop-types';
import domainDefinition from '../../../proptypes/domain.definition';
import DomainsStore from '../../../pages/Domains/newDomains.store';

DomainsVisibilityCell.propTypes = {
  item: PropTypes.shape(domainDefinition),
  store: PropTypes.instanceOf(DomainsStore),
  field: PropTypes.string,
};
export default function DomainsVisibilityCell({ item, store, field }) {
  return <ProgressBar value={item[field]} total={store.totalViews} />;
}
