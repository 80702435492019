import _ from 'lodash';
/**
 *
 * @param {string} name
 * @description see all code for flag here : https://github.com/lipis/flag-icon-css/tree/master/flags/4x3
 * @returns
 */
export default function nameForFlag(name) {
  if (name) {
    const nameArray = name.split(/[-_]/),
      newName = _.last(nameArray).toLowerCase();
    switch (newName) {
    case 'eng':
      return 'gb';
    case 'en':
      return 'gb';
    case 'gb':
      return 'gb';
    case 'uk':
      return 'gb';
    case 'be':
      return 'be';
    case 'it':
      return 'it';
    case 'fr':
      return 'fr';
    default:
      return newName;
    }
  } else return 'fr';
}
