import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

GroupActionsSubTitle.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  }),
  selected: PropTypes.array.isRequired
};

export default function GroupActionsSubTitle({action,selected}){
  return <Trans i18nKey={`actionType${action.type}`} count={selected.length}>
    Are you sure you want to {action.type} the <strong>{{ count: selected.length }}</strong> selected keywords?
  </Trans>;
}
