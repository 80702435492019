/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

 import { Brand } from '..';
 import ApiClient from '../ApiClient';
 import ObjectID from './ObjectID';
 import XAny from './XAny';
 
 /**
 * The CompanyAccount model module.
 * @module model/CompanyAccount
 * @version 4.0.0
 */
 export default class CompanyAccount {
     /**
     * Constructs a new <code>CompanyAccount</code>.
     * @alias module:model/CompanyAccount
     * @class
     * @param name {String} 
     */
 
     constructor(name) {
         
         
         this['name'] = name;
         
     }
 
     /**
     * Constructs a <code>CompanyAccount</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyAccount} obj Optional instance to populate.
     * @return {module:model/CompanyAccount} The populated <code>CompanyAccount</code> instance.
     */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new CompanyAccount();              
             
             if (data.hasOwnProperty('name')) {
                 obj['name'] = ApiClient.convertToType(data['name'], 'String');
             }
             if (data.hasOwnProperty('id')) {
                 obj['id'] = ObjectID.constructFromObject(data['id']);
             }
             if (data.hasOwnProperty('brands')) {
                 obj['brands'] = ObjectID.constructFromObject(data['brands'], [Brand]);
             }
         }
         return obj;
     }
 
     /**
     * @member {String} name
     */
     name = undefined;
     /**
     * @member {Boolean} notificationAuto
     * @default false
     */
     notificationAuto = false;
     /**
     * @member {module:model/XAny} reminder
     */
     reminder = undefined;
     /**
     * @member {module:model/ObjectID} id
     */
     id = undefined;
 
 
 
 
 }
 