import i18next from 'i18next'
import {
   isContainOption, formatContainOptionValue, formatContainOptionLabel
} from './option';

export default function containsValueMapper(value: any[]) {
  return (value || []).map(valueItem => isContainOption(valueItem) ? {
    value: formatContainOptionValue(valueItem.value),
    label: formatContainOptionLabel(valueItem.label)
  } : valueItem);
}
