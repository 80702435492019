export const UPPERCASE_REGEX = new RegExp(/.*[A-ZÀ-ÖØ-Þ]/u);
export const LOWERCASE_REGEX = new RegExp(/.*[a-zà-öø-ÿ]/u);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{10,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[-’/`~!#*$€£@_%+=.,^&(){}[\]|;:”<>?\\]/
);

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    LENGTH_REGEX.source,
    UPPERCASE_REGEX.source,
    LOWERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source
  ].join(')(?=')}).*$`
);
