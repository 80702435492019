import { LocalizationApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import { LocalizationInterface } from '../../../types/localization.interface';

export default function useLocalizationFilter(
  {name,type='full',placeholder}:{name:string,type:string,placeholder:string|undefined}
) {
  const localizationApi = new LocalizationApi(),
    { data } = useGet<{ count: number } | undefined>((f: Object) =>
      localizationApi.localizationCount(f)
    ),
    { t, i18n} = useTranslation(),
    currentLang = i18n.language.slice(0, 2);

  function transformLabel(localization:LocalizationInterface) {
    const lang:'fr'|'en' = (currentLang === 'fr' || currentLang === 'en') ? currentLang :'en';

    if (name  === 'country' || type === 'country')
      return localization.country[lang];

    if (name.match(/language/)) return t(localization.language);

    return `${localization.country[lang]} (${t(localization.language)})`;
  }


  function transformValue(locale:LocalizationInterface) {
    if (name === 'country' || type === 'country') return locale.locale.slice(3);
    if (name.match(/language/)) return locale.locale.slice(0, 2);
    if (name === 'localizationId') return locale.id;
    return locale.locale;
  }

  async function promiseOptions(inputValue: string) {

    const filter: { limit: number; order: string; where?: Object } = {
      limit: 50,
      order: 'locale asc',
    };

    if (inputValue) {
      filter.where = {
        or: [
          { locale: { regexp: `/${inputValue}/i` } },
          { language: { regexp: `/${inputValue}/i` } },
          { [`country.${currentLang}`]: { like: '.*' + inputValue + '.*',options:'gmi' } }
        ]
      };
    }

    const items = await localizationApi.localizationFind({
      filter: JSON.stringify(filter),
    });

    const optionsNoDuplicate = new Map<string, { label: string; value: string }>();
    items.forEach((item: any) => {
      const option = {
        label: transformLabel(item),
        value: transformValue(item)
      };
      optionsNoDuplicate.set(option.value, option);
    });
    const options = Array.from(optionsNoDuplicate.values());

    return options
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: placeholder || t('Select localization'),
      isMulti: true,
    },
    count: data?.count
  };
}
