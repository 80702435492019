import classes from './Loader.module.css';

/**
 *
 * Loader **Logo Monibrand**.
 */
export default function Loader() {
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <div className={classes.circleExt}>
          <div className={classes.subCircleExt} />
          <div className={classes.triangle} />
          <div className={classes.circleExtwhite} />
        </div>
        <div className={classes.circleMid}>
          <div className={classes.subCircleExt} />
          <div className={classes.triangle} />
          <div className={classes.circleExtwhite} />
        </div>
        <div className={classes.circleInt}>
          <div className={classes.subCircleExt} />
          <div className={classes.triangle} />
          <div className={classes.circleExtwhite} />
        </div>
      </div>
    </div>
  );
}
