import { useMemo } from 'react';
import classes from './ProgressBar.module.css';
import PropTypes from 'prop-types';

function percent(value, total) {
  if (!total || !value) {
    return '0%';
  }

  const per = (value / total) * 100;
  return per < 1 || per === 'Infinity'
    ? '0.01%'
    : parseFloat(per).toFixed(2) + '%';
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default function ProgressBar({ value, total }) {
  const memoizedPercent = useMemo(() => percent(value, total), [value, total]);
  return (
    <div className={classes.container}>
      <div className={classes.value}>{memoizedPercent}</div>
      <div className={classes.bar}>
        <div className={classes.progress} style={{ width: memoizedPercent }} />
      </div>
    </div>
  );
}
