import PropTypes from 'prop-types';
import brandDefinition from './brand.definition';

const tagDefinition = {
  id: PropTypes.string,
  brandOwnerId: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
  label: PropTypes.string,
  name: PropTypes.string,
  scope: PropTypes.oneOf(['tag','term']),
  visibility: PropTypes.oneOf(['all','owner']),
  owner: PropTypes.shape(brandDefinition)
};

export default tagDefinition;