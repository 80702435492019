import { observer } from 'mobx-react-lite';
import classes from './LocalizationFilter.module.css';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/NewAsyncCreateFilter';
import useLocalizationFilter from './useLocalizationFilter';

interface Props extends FilterChild {
  readonly type: string;
  readonly placeholder: string;
  readonly titleMode: boolean;
}

function LocalizationFilter(props: Props) {
  const useProps = useLocalizationFilter({
      name: props.name,
      type: props.type,
      placeholder: props.placeholder,
    }),
    passProps = { ...props, ...useProps };
  return (
    <div className={classes.container}>
      <AsyncCreateFilter {...passProps} />
    </div>
  );
}

export default observer(LocalizationFilter);
