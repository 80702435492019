import useSelectFilter from './useSelectFilter';
import SelectFilterView from './SelectFilterView';
import { Filter } from '../../../types/filter.interface';
import { observer } from 'mobx-react-lite';

interface Props {
  readonly filters: Filter;
  readonly setFilter: Function;
  readonly currentValue: Function;
  readonly name: string;
  readonly options: any;
  readonly label: string;
  readonly placeholder: string;
  readonly reactSelectOptions: any;
  readonly titleMode?: boolean;
  readonly menuPlacement?: string;
  readonly optionsAutosort?: boolean;
}

function SelectFilter(props: Props) {
  const useProps = useSelectFilter(props),
    passProps = { ...useProps };

  return <SelectFilterView {...passProps} />;
}
export default observer(SelectFilter);
