import { useContext, useEffect } from 'react';

/**
 * @description Components
 */
import { withRouter } from 'react-router-dom';

/**
 * @description Styles and assets
 */
import classes from './Dashboard.module.css';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function Dashboard() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    { t } = useTranslation(),
    login = _.get(currentUser, 'dashboards.clicdata.login'),
    password = _.get(currentUser, 'dashboards.clicdata.password'),
    url = process.env.REACT_APP_STOPGO_DASHBOARD_URL,
    clicDataUrl =
      url + '?Authorization=Basic%20' + btoa(login + ':' + password);

  console.log(process.env.REACT_APP_STOPGO_DASHBOARD_URL);
  console.log({ url, login, password });

  useEffect(() => {
    uiStore.setTitle(t('Dashboard stop and go'));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <iframe title='StopAndGo' className={classes.iframe} src={clicDataUrl} />
    </div>
  );
}

export default withRouter(Dashboard);
