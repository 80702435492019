export default function DollarSVG({ color }: { color: string }) {
  return (
    <svg
      fill={color}
      version='1.1'
      viewBox='144 144 512 512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m293.73 525.95c-5.625 0-10.824 3.0039-13.637 7.875-2.8125 4.8711-2.8125 10.871 0 15.742s8.0117 7.8711 13.637 7.8711h90.527v47.234c0 5.625 3 10.82 7.8711 13.633s10.875 2.8125 15.746 0 7.8711-8.0078 7.8711-13.633v-47.234h19.68c30.938 0 59.523-16.504 74.992-43.293 15.469-26.793 15.469-59.801 0-86.594s-44.055-43.297-74.992-43.297h-19.68v-110.21h90.527c5.625 0 10.824-3 13.637-7.8711 2.8125-4.8711 2.8125-10.875 0-15.746-2.8125-4.8711-8.0117-7.8711-13.637-7.8711h-90.527v-47.234c0-5.625-3-10.82-7.8711-13.633s-10.875-2.8125-15.746 0-7.8711 8.0078-7.8711 13.633v47.234h-19.68c-30.938 0-59.523 16.504-74.992 43.297-15.469 26.789-15.469 59.801 0 86.59 15.469 26.793 44.055 43.297 74.992 43.297h19.68v110.21zm122.02-110.21h19.68c19.688 0 37.879 10.504 47.723 27.551 9.8438 17.051 9.8438 38.055 0 55.105-9.8438 17.051-28.035 27.551-47.723 27.551h-19.68zm-51.168-31.488c-19.688 0-37.879-10.504-47.723-27.551-9.8438-17.051-9.8438-38.055 0-55.105 9.8438-17.047 28.035-27.551 47.723-27.551h19.68v110.21z' />
    </svg>
  );
}
