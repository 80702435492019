import { observer } from 'mobx-react';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import classes from './Actions.module.css';
import Header from '../../components/Header/Header';
import HeaderMobile from '../../components/HeaderMobile/HeaderMobile';
import { ToastContainer } from 'react-toastify';
// import MenuMobile from '../../components/MenuMobile/MenuMobile';
import { StoreContext } from '../../stores';

function ActionsLayout({ children }) {
  const { uiStore } = useContext(StoreContext);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        {uiStore.device.match(/desktop|laptop/) ? <Header menuExpanded /> : <HeaderMobile />}
      </header>
      { /* <nav className={classes.main}>
        {uiStore.device.match(/desktop|laptop/) ? <Menu /> : <MenuMobile />}
      </nav> */ }
      { /* <Breadcrumb /> */ }
      <div className={classes.body}>
        {children}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default observer(ActionsLayout);

ActionsLayout.propTypes = {
  children: PropTypes.node.isRequired
};
