import dayjs from 'dayjs';
export const dateSelect =  [
  {
    period: 'Last 30 days',
    start: dayjs().subtract(30,'days').toDate(),
    end:  dayjs().toDate()
  },
  {
    period: 'Last 60 days',
    start: dayjs().subtract(60,'days').toDate(),
    end:  dayjs().toDate()
  },
  {
    period: 'Last 7 Days',
    start: dayjs().subtract(7,'days').toDate(),
    end:  dayjs().toDate()
  },
  {
    period: 'Last month',
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate()
  },
  {
    period: 'This month',
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate()
  },
  {
    period: 'Today',
    start: dayjs().toDate(),
    end: dayjs().toDate()
  },
  {
    period: 'Yesterday',
    start: dayjs().subtract(1,'days').toDate(),
    end: dayjs().subtract(1,'days').toDate()
  }
];