import NotFoundContainer from '../layouts/404/NotFound.container';
import Auth from '../layouts/Auth/Auth.container';
import DashboardLayout from '../layouts/Dashboard/Dashboard.container';
import PublicLayout from '../layouts/PublicLayout/PublicLayout';
import ActionsLayout from '../layouts/Actions/Actions.container';
import PropTypes from 'prop-types';

RouterSwitch.propTypes = {
  children: PropTypes.node.isRequired
};

export default function RouterSwitch({ children }) {
  const
    {pathname} = window.location,
    Layout =
      (pathname.match(/public/) && PublicLayout) ||
      (pathname.match(/\/404\//) && NotFoundContainer) ||
      (pathname.match(/^\/+(login|logout|forgot|reset)+$/) && Auth) ||
      (pathname.match(/^\/+(login|logout|forgot|reset)+[/?]/) && Auth) ||
      (pathname.match(/^\/+(actions|todo|action\/[0-9a-zA-Z]+)/) && ActionsLayout) ||
      DashboardLayout;
      
  return <Layout>{children}</Layout>;
}
