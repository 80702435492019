import { useContext, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import InfoItem from './InfoItem';
import classes from './Infos.module.css';
import { Action } from '../../types/action.newBackend';
import useGetActionNotes from '../../components/Hooks/data/getActionNote';
import { StoreContext } from '../../stores';
import Backend from '../../stores/newBackend';
import { useActionNote } from '../../stores/actionNoteContext';
import { Note } from '../../types/note';

const InfoForm = ({
  onAddNote,
  onSizeChange,
}: {
  onSizeChange: () => void;
  onAddNote: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const handleAddNote = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onAddNote(message);
    setMessage('');
  };

  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const handleFocus = () => {
    onSizeChange();
  };

  const handleBlur = () => {
    onSizeChange();
  };

  const canSubmit = message.trim() !== '';

  return (
    <form className={classes.footer} onSubmit={handleAddNote}>
      <textarea
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={message}
        onChange={handleChangeMessage}
        placeholder={t('Add a note...')}
        className={`${classes.input}`}
      />
      <button
        disabled={!canSubmit}
        type='submit'
        className={classes.submitButton}
      >
        <FontAwesomeIcon
          icon={faArrowCircleUp}
          className={classes.submitIcon}
        />
      </button>
    </form>
  );
};

const InfoItemContainer = ({ note }: { note: Note }) => {
  let updated = false;
  let dateToShow: Date = note.createdDate;
  if (note.updatedDate.getTime() - note.createdDate.getTime() > 5000) {
    updated = true;
    dateToShow = note.updatedDate;
  }

  return (
    <InfoItem
      key={note.ID}
      author={note.user}
      date={dayjs(dateToShow).format('DD/MM/YYYY')}
      text={note.message}
      updated={updated}
    />
  );
};

function Infos({
  loading,
  notes,
  onAddNote,
}: {
  action: Action;
  token: string;
  notes: Note[];
  loading: boolean;
  error: any;
  onAddNote: (message: string) => void;
}) {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const scrollToBotton = () => {
    if (!scrollRef.current) {
      return;
    }
    const { current }: { current: any } = scrollRef;
    current.scrollTop = current.scrollHeight;
  };
  useEffect(() => {
    scrollToBotton();
  }, [notes]);

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <DynamicScrollbar ref={scrollRef} contentClassName={classes.content}>
          {notes.length === 0 && loading !== true && (
            <div className={classes.empty}>
              {t('There is no note at this time.')}
            </div>
          )}
          {notes.map((note) => (
            <InfoItemContainer key={note.ID} note={note} />
          ))}
        </DynamicScrollbar>
      </div>
      <div>
        <InfoForm onSizeChange={scrollToBotton} onAddNote={onAddNote} />
      </div>
    </div>
  );
}

const getAPILinks = (
  action: Action,
  type: 'action' | 'advertiser' | 'multiCountry'
): { get: string; add: string } => {
  switch (type) {
  case 'action':
    return {
      get: action.links.getNotes,
      add: action.links.addNote,
    };
  case 'advertiser':
    return {
      get: action.links.advertiserGetNotes,
      add: action.links.advertiserAddNote,
    };
  case 'multiCountry':
    return {
      get: action.links.brandAdvertiserGetNotes as string,
      add: action.links.brandAdvertiserAddNote as string,
    };
  default:
    throw new Error('bad type');
  }
};

const InfosContainer = ({
  action,
  type,
}: {
  action: Action;
  type: 'action' | 'advertiser' | 'multiCountry';
}) => {
  const { t } = useTranslation();
  const { authStore } = useContext(StoreContext);
  const [apiLinks, setApiLinks] = useState(getAPILinks(action, type));

  const { cacheKey } = useActionNote();

  useEffect(() => {
    setApiLinks(getAPILinks(action, type));
  }, [action, type]);

  const { loading, notes, error, fetch } = useGetActionNotes();
  useEffect(() => {
    fetch(authStore.token, apiLinks.get);
  }, [fetch, authStore.token, apiLinks.get, cacheKey]);

  const handleAddNote = async (message: string) => {
    try {
      await Backend.executeLink(authStore.token, apiLinks.add, {
        message,
      });
      fetch(authStore.token, apiLinks.add);
    } catch (e) {
      console.error(e);
      toast.error(t('An error occured.'));
    }
  };

  return (
    <Infos
      loading={loading}
      notes={notes}
      error={error}
      action={action}
      token={authStore.token}
      onAddNote={handleAddNote}
    />
  );
};

export default InfosContainer;
