import SettingTermsStore from '../../../pages/Settings/Terms/settingTerms.store';
import { TagInterface } from '../../../types/tag.interface';
import { TermInterface } from '../../../types/term.interface';
import SettingTermsActionsCellView from './SettingTermsActionsCellView';
import useSettingTermsActionsCell from './useSettingTermsActionsCell';

interface Props {
  readonly item: TermInterface
  readonly store: SettingTermsStore
  readonly tags: Array<TagInterface>
  readonly refetchTag: Function
}

export default function SettingTermsActionsCell(props:Props) {
  const useProps = useSettingTermsActionsCell(props);

  return <SettingTermsActionsCellView {...props} {...useProps}/>;
}