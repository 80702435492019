import PropTypes from 'prop-types';

export default function TagSVG({ color }) {
  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.120445, 0.000000)">
        <g>
          <rect id="body" x="0" y="0" width="25" height="25" />
          <g
            id="icon"
            transform="translate(3.333333, 0.000000)"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          >
            <path
              d="M0.654654412,7.73819296 L7.14864828,1.83456217 C8.29291196,
                            0.794322459 10.0404214,0.794322459 11.184685,1.83456217 L17.6786789,
                            7.73819296 C18.0956277,8.11723732 18.3333333,
                            8.65458316 18.3333333,9.21807311 L18.3333333,21.1666667 C18.3333333,
                            22.8235209 16.9901876,24.1666667 15.3333333,24.1666667 L3,
                            24.1666667 C1.34314575,24.1666667 2.02906125e-16,22.8235209 0,
                            21.1666667 L0,9.21807311 C-4.56706425e-16,8.65458316 0.237705619,
                            8.11723732 0.654654412,7.73819296 Z"
              id="Rectangle"
            />
            <circle id="Oval" cx="9.16666667" cy="8.33333333" r="2.5" />
            <path
              d="M9.16666667,5.83333333 C12.2222222,0.833333333 16.1111111,
                        -0.416666667 20.8333333,2.08333333"
              id="Path-606"
            />
          </g>
        </g>
      </g>
    </g>
  );
}

TagSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
