/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Action model module.
* @module model/Action
* @version 4.0.0
*/
export default class Action {
    /**
    * Constructs a new <code>Action</code>.
    * @alias module:model/Action
    * @class
    * @param brand {String} 
    * @param category {String} 
    * @param type {String} 
    * @param master {String} 
    * @param provider {String} 
    * @param priority {Number} 
    * @param selected {Boolean} 
    * @param status {String} 
    * @param createdDate {Date} 
    * @param inprogressDate {Date} 
    * @param createdDate {Date} 
    * @param refusedDate {Date} 
    * @param ignoredDate {Date} 
    * @param lastView {Date} 
    */

    constructor(brand, category, type, master, provider, priority, selected, status, createdDate, updatedDate, lastView) {
        
        
        this['brand'] = brand;
        this['category'] = category;
        this['type'] = type;
        this['master'] = master;
        this['provider'] = provider;
        this['priority'] = priority;
        this['selected'] = selected;
        this['status'] = status;
        this['createdDate'] = createdDate;
        this['updatedDate'] = updatedDate;
        this['lastView'] = lastView;
        
    }

    /**
    * Constructs a <code>Action</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Action} obj Optional instance to populate.
    * @return {module:model/Action} The populated <code>Action</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Action();
                        
            
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
            }
            if (data.hasOwnProperty('category')) {
                obj['category'] = ApiClient.convertToType(data['category'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('master')) {
                obj['master'] = ApiClient.convertToType(data['master'], 'String');
            }
            if (data.hasOwnProperty('provider')) {
                obj['provider'] = ApiClient.convertToType(data['provider'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('unreadEmail')) {
                obj['unreadEmail'] = ApiClient.convertToType(data['unreadEmail'], 'Number');
            }
            if (data.hasOwnProperty('readEmail')) {
                obj['readEmail'] = ApiClient.convertToType(data['readEmail'], 'Number');
            }
            if (data.hasOwnProperty('selected')) {
                obj['selected'] = ApiClient.convertToType(data['selected'], 'Boolean');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('countries')) {
                obj['countries'] = ApiClient.convertToType(data['countries'], ['String']);
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('languages')) {
                obj['languages'] = ApiClient.convertToType(data['languages'], ['String']);
            }
            if (data.hasOwnProperty('metas')) {
                obj['metas'] = ApiClient.convertToType(data['metas'], Object);
            }
            if (data.hasOwnProperty('createdDate')) {
                obj['createdDate'] = ApiClient.convertToType(data['createdDate'], 'Date');
            }
            if (data.hasOwnProperty('updatedDate')) {
                obj['updatedDate'] = ApiClient.convertToType(data['updatedDate'], 'Date');
            }
            if (data.hasOwnProperty('requestedDate')) {
                obj['requestedDate'] = ApiClient.convertToType(data['requestedDate'], 'Date');
            }
            if (data.hasOwnProperty('refusedDate')) {
                obj['refusedDate'] = ApiClient.convertToType(data['refusedDate'], 'Date');
            }
            if (data.hasOwnProperty('acceptedDate')) {
                obj['acceptedDate'] = ApiClient.convertToType(data['acceptedDate'], 'Date');
            }
            if (data.hasOwnProperty('inprogressDate')) {
                obj['inprogressDate'] = ApiClient.convertToType(data['inprogressDate'], 'Date');
            }
            if (data.hasOwnProperty('ignoredDate')) {
                obj['ignoredDate'] = ApiClient.convertToType(data['ignoredDate'], 'Date');
            }
            if (data.hasOwnProperty('lastView')) {
                obj['lastView'] = ApiClient.convertToType(data['lastView'], 'Date');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }

            if (data.hasOwnProperty('dealer')) {
                obj['dealer'] = ApiClient.convertToType(data['dealer'], 'dealer');
            }

            if (data.hasOwnProperty('emails')) {
                obj['emails'] = ApiClient.convertToType(data['emails'], 'actionEmail');
            }

            if (data.hasOwnProperty('brandId')) {
                obj['brandId'] = ApiClient.convertToType(data['brandId'], 'String');
            }

            if (data.hasOwnProperty('remindedCount')) {
                obj['remindedCount'] = ApiClient.convertToType(data['remindedCount'], 'Number');
            }

            if (data.hasOwnProperty('presence')) {
                obj['presence'] = ApiClient.convertToType(data['presence'], ['presence']);
            }
        }
        return obj;
    }

    /**
    * @member {String} brand
    */
    brand = undefined;
    /**
    * @member {String} category
    */
    category = undefined;
    /**
    * @member {String} type
    */
    type = undefined;
    /**
    * @member {String} master
    */
    master = undefined;
    /**
    * @member {String} provider
    */
    provider = undefined;
    /**
    * @member {String} url
    */
    url = undefined;
    /**
    * @member {Number} priority
    * @default 50.0
    */
    priority = 50.0;
    /**
    * @member {Number} unreadEmail
    * @default 0.0
    */
    unreadEmail = 0.0;
    /**
    * @member {Number} readEmail
    * @default 0.0
    */
    readEmail = 0.0;
    /**
    * @member {Boolean} selected
    * @default false
    */
    selected = false;
    /**
    * @member {String} status
    * @default 'new'
    */
    status = 'new';
    /**
    * @member {Array.<String>} countries
    */
    countries = undefined;
    /**
    * @member {Array.<String>} languages
    */
    languages = undefined;
    /**
    * @member {Object} metas
    */
    metas = undefined;
    /**
    * @member {Date} createdDate
    */
    createdDate = undefined;
    /**
    * @member {Date} updatedDate
    */
    updatedDate = undefined;
    /**
    * @member {Date} requestedDate
    */
    requestedDate = undefined;
    /**
    * @member {Date} refusedDate
    */
    refusedDate = undefined;
    /**
    * @member {Date} acceptedDate
    */
    acceptedDate = undefined;
    /**
    * @member {Date} inprogressDate
    */
    inprogressDate = undefined;
    /**
    * @member {Date} lastView
    */
    lastView = undefined;
    /**
     * @member {Number} remindedCount
     * @default 0.0
     */
    remindedCount = 0.0; 
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;




}
