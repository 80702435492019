import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { LocalizationApi } from '../../../swagger_client/src';
import useGetTags from '../../Hooks/data/getTags/getTags';
import { useFrequencyOptions } from '../../Hooks/useFrequencyOptions';
import { useRegionOptions } from '../../Hooks/useRegionOptions';
import i18next from 'i18next';

export default function useCreateFormTerm(watch: Function) {
  const { t } = useTranslation(),
    currentLang = i18next.language.slice(0, 2),
    everyOptions = useFrequencyOptions(),
    regionOptionsByCountry = useRegionOptions(),
    delayOptions = [
      {
        label: `7 ${t('days')}`,
        value: '7 days',
      },
      {
        label: `15 ${t('days')}`,
        value: '15 days',
      },
      {
        label: `1 ${t('month')}`,
        value: '1 month',
      },
    ],
    localizationApi = new LocalizationApi(),
    { tags, tagLoading } = useGetTags('term');

  async function localizations(
    inputValue: string,
    callback: Function
  ): Promise<Array<{ label: string; value: any }>> {
    const items = await localizationApi.localizationFind({
      filter: JSON.stringify({
        where: inputValue
          ? {
            or: [
              { locale: { regexp: `/${inputValue}/i` } },
              { language: { regexp: `/${inputValue}/i` } },
              {
                [`country.${currentLang}`]: {
                  like: '.*' + inputValue + '.*',
                  options: 'gmi',
                },
              },
            ],
          }
          : {},
        limit: 10,
        order: 'locale asc',
      }),
    });

    const locales = _.map(items, (o) => ({
      label: `${o.country[currentLang]} (${t(o.language)})`,
      value: o,
    }));
    callback(locales);
    return locales;
  }

  const saveOrganicResults = watch('saveOrganicResults'),
    terms = watch('terms'),
    engine = watch('engine'),
    localization = watch('localization');

  let regionOptions: { label: string; value: string }[] = [];
  if (engine?.value === 'GOOGLE' && !!localization?.value?.locale) {
    const country = localization.value.locale.split('_')[1];
    if (country && !!regionOptionsByCountry[country]) {
      regionOptions = regionOptionsByCountry[country];
    }
  }

  return {
    terms,
    saveOrganicResults,
    tags,
    tagLoading,
    localizations,
    localization,
    everyOptions,
    delayOptions,
    regionOptions,
    t,
  };
}
