import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import dateLabel from './dateLabel';

interface props {
  readonly filters:any
  readonly name:string
  readonly label:string
  readonly setDateState:Function
  readonly setToggle: Function
  readonly dateMin:Object
  readonly setFilter:Function
}

export default function useDateFilterResume({label,filters,name,setFilter,setDateState,dateMin,setToggle}:props){
  const {t} = useTranslation(),
    iHaveDateMin= dateMin && dateMin === filters?.[name]?.whereClause;

  function resetDate(){
    setFilter(name,dateMin);
  }

  function handleSelect(dates:{startDate:Date,endDate:Date,key:string}) {
    setDateState([dates]);
    setFilter(name,{
      label,
      whereClause:
      {
        between: [dayjs(dates?.startDate).format('YYYY-MM-DDT00:00:00'), 
          dayjs(dates?.endDate).format('YYYY-MM-DDT23:59:59')],
      },description: t(dateLabel(dates, t,true))
    });
  }
  
  return {resetDate,handleSelect,iHaveDateMin,t};
}