import Select from 'react-select';
import { filterSelectStyle } from '../../../Utils/reactSelectStyles';
import createFilterDescription from '../../../Utils/filter/createFilterDescription';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

interface props {
  readonly filters:any
  readonly setFilter:Function
  readonly name: string
  readonly options:any
  readonly label:string
  readonly reactSelectOptions:any
  readonly useRegexpMode:boolean
  readonly valueMapper?: (value: any) => any
}

export default function useCreateSelectFilter({
  filters,
  setFilter,
  name,
  label,
  options,
  reactSelectOptions,
  useRegexpMode,
  valueMapper = (value: any) => value
}:props) {

  function handleChange(select:Select) {

    const choices = Array.isArray(select)? select:[select];

    setFilter(name,choices.length === 0 ? undefined : {
      whereClause:useRegexpMode ?
        { regexp: `/${choices.map((o) => createInputValueRegexContent(o.value, { noSpaceConversion: true })).join('|')}/i` } :
        { inq: choices.map((o) => o.value) },
      description: createFilterDescription(choices),
      value:select,
      label
    });
  }

  return {
    options: {
      menuPosition: 'absolute',
      className:'basic-multi-select',
      styles:filterSelectStyle,
      isMulti:true,
      ...reactSelectOptions,
      options:options.sort((a:any, b:any) => a.label.localeCompare(b.label)),
      value: valueMapper(filters?.[name]?.value) || undefined,
      onChange: handleChange,
    },
  };
}
