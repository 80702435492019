import { observer } from 'mobx-react-lite';
import classes from './GroupActions.module.css';
import { CSSTransition } from 'react-transition-group';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ActionForMultiSelect from '../ActionForMultiSelect/ActionForMultiSelect';
import GroupActionsSubTitle from './GroupActionsSubTitle';

GroupActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  })),
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired
};

function GroupActions({ actions, selected, isLoading, children }) {
  const [confirm, setConfirm] = useState(),
    { t } = useTranslation();

  async function handleSubmit() {
    await confirm.action();
    setConfirm(null);
  }

  return (
    <CSSTransition
      in={selected.length >= 1}
      timeout={200}
      unmountOnExit
      mountOnEnter
      classNames="fade"
    >
      <div className={classes.container}>
        <span className={classes.select}>
          <span className={classes.bold}>{selected.length} </span>
          <Trans key="selected" count={selected.length}>
            selected
          </Trans>
        </span>

        <div className={classes.actions}>
          {actions.map((action) => (
            <ActionForMultiSelect
              actionName={t(action.type)}
              title={action.title}
              isLoading={isLoading}
              isOpen={confirm?.type === action.type}
              key={action.type}
              setIsOpen={() => setConfirm(confirm => confirm ? null : action)}
              onSubmit={handleSubmit}
              submitbuttonText={action.submitText || 'confirm'}
              subTitle={<GroupActionsSubTitle action={action} selected={selected}/>}
            />
          ))}
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

export default observer(GroupActions);
