import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateState } from '../../../../types/dateState.interface';

interface Props {
  readonly setDateState: Function
  readonly dateState: DateState
}

export default function useDateFilterPopin({dateState,setDateState}:Props){
  const {t} = useTranslation();  

  function makeEditableInputs(){
    const inputs = ['Early', 'End date'];

    inputs.forEach(inputLabel => {
      const input:HTMLInputElement|null = document.querySelector(`[placeholder="${t(inputLabel)}"]`);

      if(!input) return;

      input.addEventListener('focus', () => {
        input.type = 'date';
      });

      input.addEventListener('keypress', (e) => {
        if(!e.code.match(/NumpadEnter|Enter/)) return;
        input.blur();
        if (input.placeholder === t('Early')) {
          const inputEnd:HTMLInputElement|null = document.querySelector(`[placeholder="${t('End date')}"]`);
          if(inputEnd) inputEnd.focus();
    
        } else {
          const submitButton:HTMLInputElement|null = document.querySelector(`button[type="submit"]`);
          if(submitButton) submitButton.focus();
        }

      });

      input.addEventListener('blur', (e:any) => {
        const
          selection = dateState[0],
          typeDate = inputLabel === 'Early' ? 'startDate' : 'endDate';

        if (!typeDate && !e?.target) return; 
              
        selection[typeDate] = e.target.value ?
          dayjs(dayjs(e.target.value).format('MM/DD/YYYY')).toDate() :
          selection[typeDate];

        if ((selection.startDate && selection.endDate) &&
                dayjs(selection.endDate).diff(dayjs(selection.startDate), 'day') < 0) {
          setDateState([{
            ...selection,
            startDate: selection.endDate,
            endDate: selection.startDate
          }]);
          input.value = dayjs(selection[typeDate === 'endDate' ? 'startDate' : 'endDate'])
            .format('MMM D, YYYY');

        } else {
          setDateState([selection]);
          input.value = dayjs(selection[typeDate]).format('MMM D, YYYY');
        }

        input.type = 'text';
      });
    
    });
  }
  
  useEffect(() => {
    makeEditableInputs();
    // eslint-disable-next-line
  }, []);

  return {t};
}