import { useContext } from 'react';
import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import { observer } from 'mobx-react-lite';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateFilterView from '../DateFilterNew/DateFilter';
import useDateFilter from './useDateFilter';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

dayjs.extend(Calendar);

interface Props {
  readonly name: string;
  readonly label: string;
  readonly dateMin: Date;
}

function DateFilter(props: Props) {
  const { filters, setFilter } = useContext(PanelFilterContext);

  const useProps = useDateFilter({ ...props, setFilter, filters }),
    passProps = { ...props, ...useProps, setFilter, filters };
  return <DateFilterView {...passProps} />;
}

export default observer(DateFilter);
