export const AvatarEditorInitialState = {
  preview: undefined,
  imgBase64: undefined,
  cropImg: undefined,
  cropBlob: undefined,
  isHover: false,
};

export function AvatarEditorReducer(state, action) {
  return AvatarEditorActions[action.type]({...state},action.payload);
}

export const AvatarEditorActionsType = {
  setPreview: 'setPreview',
  setIsHover: 'setIsHover',
  setCropImg:'setCropImg',
  handleSubmit:'handleSubmit',
  handleCancel: 'handleCancel'
};

const AvatarEditorActions = {
  setPreview: (state, payload)=> {
    state.preview = payload;
    return state;
  },
  setIsHover: (state,payload)=>{
    state.isHover = payload;
    return state;
  },
  setCropImg: (state,payload)=>{
    state.cropImg = payload.cropImg;
    state.cropBlob = payload.cropBlob;
    return state;
  },
  handleSubmit: (state,payload)=>{
    state.imgBase64 = payload;
    state.cropImg = undefined;
    state.cropBlob = undefined;
    state.preview = undefined;
    state.isHover = undefined;
    return state;
  },
  handleCancel: ()=> AvatarEditorInitialState
};