import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import { observer } from 'mobx-react-lite';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Filter } from '../../../types/filter.interface';
import DateFilterView from './DateFilterView';
import useDateFilter from './useDateFilter';

dayjs.extend(Calendar);

interface Props {
  readonly name:string
  readonly filters:Filter
  readonly setFilter:Function
  readonly label:string
  readonly dateMin: Date
}

function DateFilter(props:Props) {
  const useProps = useDateFilter(props),
    passProps = {...props,...useProps};
  return  <DateFilterView {...passProps}/>;
}

export default observer(DateFilter);
