import PropTypes from 'prop-types';
import { getScale, getDimensions } from './IconSvgUtils';

export default function RemindSVG({ color, height }) {
  const scale = getScale(16, height);
  const dimensions = getDimensions(16, 16, null, height);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${height ? dimensions.height : dimensions.height}`}
    >
      <path
        d="M13.656,2.344l1.063-1.062A.751.751,0,0,1,16,1.809V5.25a.748.748,0,0,1-.75.75H11.809a.751.751,0,0,1-.531-1.281l.963-.963a6,6,0,1,0-.812,9.169,1,1,0,1,1,1.147,1.641A8,8,0,1,1,13.656,2.344ZM8,4a.748.748,0,0,1,.75.75V8a.749.749,0,0,1-.219.531l-2.25,2.25A.749.749,0,0,1,5.222,9.722L7.253,7.691V4.75A.748.748,0,0,1,8,4Z"
        transform={scale ? `scale(${scale})` : ''}
        fill={color}
      />
    </svg>
  );
}

RemindSVG.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.number
};
