import { useTranslation } from 'react-i18next';

interface props {
  readonly filters:any
  readonly name:string
  readonly setDateState:Function
  readonly setToggle: Function
}

export default function useDateFilterSelectDates({filters,name,setDateState,setToggle}:props){
  const {t} = useTranslation();

  function handleSubmit(){
    // if (!filters[name]) return;
    setDateState([
      {
        endDate: new Date(),
        startDate: new Date(),
        key: 'selection',
      },
    ]);
    setToggle(true);
  }

  return {handleSubmit, t};
}