import nameForFlag from '../../../Utils/nameForFlag';
import classes from './LocalizationFlagCell.module.css';
import _ from 'lodash';

export default function LocalizationFlagCell({
  item,
  field,
  showRegion,
}: {
  item?: {
    localization?: { city: string };
    region?: string;
  };
  field: string;
  showRegion?: boolean;
}) {
  return (
    <div className={classes.container}>
      {_.get(item, field) && (
        <div
          data-highlightborder={_.get(item, field)}
          className={`flag-icon flag-icon-${nameForFlag(_.get(item, field))}`}
        />
      )}{' '}
      {item?.localization?.city}
      {showRegion === true && item?.region}
    </div>
  );
}
