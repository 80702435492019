import PropTypes from 'prop-types';

export default function NotificationsSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
      <g transform="translate(-6.775 -3.93)">
        <path
          d="M18.232,28.336a.558.558,0,0,0-.556.408.966.966,0,0,1-.219.436.865.865,0,0,1-.705.236.88.88,0,0,1-.705-.236.966.966,0,0,1-.219-.436.558.558,0,0,0-.556-.408h0a.535.535,0,0,0-.556.636,1.923,1.923,0,0,0,2.037,1.484,1.92,1.92,0,0,0,2.037-1.484.537.537,0,0,0-.556-.636Z" transform="translate(-2.989 -10.525)"
          fill={color}
        />
        <path
          d="M20.6,15.78c-.674-.812-2-1.288-2-4.923,0-3.731-1.8-5.231-3.486-5.591-.158-.036-.272-.084-.272-.236V4.914a1.028,1.028,0,0,0-1.051-.984h-.026a1.028,1.028,0,0,0-1.051.984V5.03c0,.148-.114.2-.272.236-1.686.364-3.486,1.86-3.486,5.591,0,3.635-1.327,4.107-2,4.923a.791.791,0,0,0,.7,1.272H19.916A.791.791,0,0,0,20.6,15.78Zm-1.708.232H8.678a.174.174,0,0,1-.145-.292,4.813,4.813,0,0,0,.92-1.336,8.359,8.359,0,0,0,.626-3.527,5.721,5.721,0,0,1,.915-3.471,2.829,2.829,0,0,1,1.695-1.1,1.574,1.574,0,0,0,.815-.42.369.369,0,0,1,.521-.008,1.63,1.63,0,0,0,.823.428,2.829,2.829,0,0,1,1.695,1.1,5.721,5.721,0,0,1,.915,3.471,8.359,8.359,0,0,0,.626,3.527,4.873,4.873,0,0,0,.942,1.356A.164.164,0,0,1,18.891,16.011Z" transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

NotificationsSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
