import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import classes from './FormInputSelect.module.css';
import PropTypes from 'prop-types';
import selectOptions from '../../../proptypes/selectOptions.definition';

FormInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape(selectOptions)),
  register: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  menuPosition: PropTypes.oneOf(['absolute','fixed']),
  hidden: PropTypes.bool,
  direction: PropTypes.oneOf(['bottom','auto','top']),
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool
};

FormInputSelect.defaultProps = {
  isMulti: false,
  isClearable: false
};

export default function FormInputSelect({
  name,
  label,
  defaultValue,
  direction,
  values,
  register,
  control,
  errors,
  placeholder,
  isClearable,
  menuPosition,
  hidden,
  isMulti,
}) {
  const 
    { t } = useTranslation();

  function handleChange(val, func){
    func(val ? val : '');
  }
  
  return (
    <div className={classes.container}>
      <label className={classes.label} htmlFor={name}>{t(label)}</label>
      <Controller
        render={({ onChange, value, name, ref }) => (
          <ReactSelect
            menuPlacement={direction}
            options={values}
            value={value}
            placeholder={t(placeholder)}
            isClearable={isClearable}
            inputId={name}
            isMulti={isMulti}
            menuPosition={menuPosition || 'absolute'}
            onChange={(e)=>handleChange(e,onChange)}
            styles={taglistStyle}
            defaultValue={defaultValue}
          />)
        }
        control={control}
        rules={register}
        name={name}
        hidden={hidden}
      />
      {errors && errors[name] && (
        <div className={classes.errorMsg}>{t(errors[name].message)}</div>
      )}
    </div>
  );
}
