import PropTypes from 'prop-types';
import classes from './ConfirmModal.module.css';
import CancelButton from '../CancelButton/CancelButton';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FullscreenModal from '../FullscreenModal/FullscreenModal';

ConfirmModal.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string,
  setReset: PropTypes.func.isRequired,
  setSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  commentArea: PropTypes.bool,
  setComments: PropTypes.func
};

export default function ConfirmModal({
  title,
  message,
  confirmMessage,
  setReset,
  setSubmit,
  isLoading,
  commentArea,
}) {
  const { t } = useTranslation();
  const [ commentsText, setCommentsText ] = useState(null);
  const handleChangeMessage = (e) => {
    setCommentsText(e.target.value);
  };

  const handleSetSubmit = () => {
    if (commentArea && commentsText) {
      setSubmit(commentsText);
    } else {
      setSubmit();
    }
  };

  return (
    <FullscreenModal onClose={setReset}>
      <div className={classes.modale}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subTitle}>{message}</div>
        {
          commentArea && (
            <textarea
              value={commentsText}
              onChange={handleChangeMessage}
              placeholder={t('Add a comment(optional)...')}
              className={`${classes.input}`}
            />
          )
        }
        <div className={classes.containerButtons}>
          <CancelButton onClose={setReset} txt="Cancel" />
          <SubmitButton
            isLoading={isLoading}
            onClick={handleSetSubmit}
            txt={confirmMessage || t('confirm')}
            disabled={isLoading}
          />
        </div>
      </div>
    </FullscreenModal>
  );
}

ConfirmModal.propTypes = {
  message: PropTypes.string.isRequired,
  setReset: PropTypes.func.isRequired,
  setSubmit: PropTypes.func.isRequired,
};
