import _ from 'lodash';
import { TermApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import containsValueMapper from '../../../Utils/filter/containsValueMapper';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

export default function useTermsFilter(filterKey:string='term',options:any[]) {
  const termApi = new TermApi(),
    { data } = useGet<{ count: number } | undefined>((f: Object) =>
      termApi.termCount(f)
    ,{
    }),
    { t } = useTranslation();
   

  async function promiseOptions(inputValue?: string) {
    const filter: { limit: number; order: string; where?: Object,include:Array<string> } = {
      include: ['brand'],
      limit: 100,
      order: 'term asc',
    };

    if (inputValue) {
      filter.where = { term: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } };
    }

    const items = await termApi.termFind({
      filter: JSON.stringify(filter),
    });

    const options = _.map(items, (o) => {
      return {
        label: o.brand ? `${o.term} (${o.brand.name})` : o.term,
        value: filterKey === 'term' ? o.term : o.id,
      };
    });
    return options
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('Select term'),
      isMulti: true,
      creatable:true,
      ...options
    },
    count: data?.count,
    valueMapper: containsValueMapper
  };
}
