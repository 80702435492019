import { useEffect, useState } from 'react';
import { TermInterface } from '../../../types/term.interface';
import { useTranslation } from 'react-i18next';
import usePost from '../../Hooks/usePost';
import { TermApi } from '../../../swagger_client/src';
import { toast } from 'react-toastify';
import { useFrequencyOptions } from '../../Hooks/useFrequencyOptions';

interface Props {
  readonly term: TermInterface;
  readonly onClose: Function;
}

export default function useTermOptionsUpdate({ term, onClose }: Props) {
  const [percent, setPercent] = useState<number>(5),
    frequencyOptions: Array<{ label: string; value: number }> =
      useFrequencyOptions(),
    [frequency, setFrequency] = useState<any>(() => {
      const index = frequencyOptions.findIndex((o) => o.value === term.every);
      return frequencyOptions.at(index >= 0 ? index : 0);
    }),
    termApi = new TermApi(),
    { data, loading, error, post } = usePost<TermInterface>((data: any) => {
      if (data.body.searchConvertRate) {
        return termApi.termFetchSearchStats(data.body);
      }

      return termApi.termUpdateAll(data);
    }),
    { t } = useTranslation();

  function handleSubmit() {
    if (frequency.value === 0) {
      post({
        body: {
          where: { id: term.id },
          searchConvertRate: percent / 100,
        },
      });
      return;
    }

    post({
      where: JSON.stringify({ id: term.id }),
      body: { every: frequency.value },
    });
  }

  useEffect(() => {
    if (error) {
      const msg = t('Update failed');
      toast.error(msg);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (data) {
      const msg = t('Term updated');
      toast.success(msg);
      onClose();
    }
    // eslint-disable-next-line
  }, [data]);

  return {
    t,
    frequency,
    setFrequency,
    loading,
    handleSubmit,
    percent,
    setPercent,
    frequencyOptions,
  };
}
