import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './NavFilter.module.css';
import PropTypes from 'prop-types';
import FiltersDescriptions from '../FiltersDescriptions/FiltersDescriptions';
import PanelFilter from '../PanelFilter/PanelFilter';
import Counters from './Counters';
import Filters from './Filters';

// This component displays the block for updatable filters


// This component displays the block for counters

// This component displays the whole block for filters
// Notice that the updatable filtes can be disabled with the
// displayFilters property

NavFilter.propTypes = {
  pageStore: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  counters: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayFilters: PropTypes.bool,
};

function NavFilter({ counters, children, isLoading, pageStore, displayFilters = true }) {
  // const [currentHoverFilter,setCurrentHoverFilter] = useState(),
  const  [standAloneFilter, setStandAlone] = useState(),
    [isOpen, setIsOpen] = useState(false);

  function handleOpenPanel() {
    setIsOpen(true);
  }

  function childrenFilter(o) {
    //for advertiser dates group filter
    if (
      o.props.label === 'dates' &&
      standAloneFilter.match(
        /lastView|inprogressDate|updatedDate|acceptedDate|date|refusedDate/
      )
    ) {
      return true;
    } else {
      return o.props.label === standAloneFilter;
    }
  }

  return (
    <div className={classes.container}>
      { displayFilters && (
        <>
          <Filters
            pageStore={pageStore}
            standAloneFilter={standAloneFilter}
            setStandAlone={setStandAlone}
            childrenFilter={childrenFilter}
            handleOpenPanel={handleOpenPanel}
          >{children}</Filters>
          <div className={classes.counters}>
            <Counters isLoading={isLoading} counters={counters} />
          </div>
        </>
      )}

      {pageStore?.filtersAsArray?.length > 0 && <>
        <div className={classes.description}>
          <FiltersDescriptions
            filters={pageStore.computedFiltersDescription}
            deleteFilter={(name)=>pageStore.deleteFilter(name)}
            revertFilter={(filter)=>pageStore.revertFilter(filter)}
            setCurrentHoverFilter={() => {}}
            clearAll={()=> pageStore.reinitFilters()}
          />
        </div>
        {!displayFilters && (
          <div className={classes.counters}>
            <Counters isLoading={isLoading} counters={counters} />
          </div>
        )}
      </>}
      {displayFilters && (
        <div className={classes.panel}>
          <PanelFilter
            setIsOpen={setIsOpen}
            isLoading={isLoading}
            isOpen={isOpen}
            pageStore={pageStore}
            filtersElements={children}
          />
        </div>
      )}
    </div>
  );
}

export default observer(NavFilter);
