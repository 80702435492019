import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import classes from './ClipBoard.module.css';

ClipBoard.propTypes = {
  txt: PropTypes.string.isRequired,
  typeTxt: PropTypes.oneOf(['input', 'text']),
};

export default function ClipBoard({ txt, typeTxt }) {
  const refInput = useRef(null),
    [msg, setMsg] = useState(null),
    handleClick = () => {
      refInput.current.type = 'text';
      refInput.current.select();

      try {
        navigator.clipboard.writeText(txt);
        setMsg('Copy in clipboard');
      } catch (err) {
        setMsg('Oops, impossible to copy');
      }

      refInput.current.type = 'hidden';
      window.getSelection().removeAllRanges();
      setTimeout(() => setMsg(null), 2000);
    };

  return (
    <div className={classes.container}>
      {typeTxt === 'input' && (
        <input className={classes.input} value={txt} readOnly />
      )}
      {(typeTxt === 'text' || !typeTxt) && (
        <span>{txt}</span>
      )}
      <div
        onClick={() => handleClick()}
        title="Copy in clipboard"
        className={classes.icon}
      >
        <FontAwesomeIcon icon={faCopy} />
      </div>
      <input type="hidden" ref={refInput} value={txt} readOnly />
      {msg && <div className={classes.msg}>{msg}</div>}
    </div>
  );
}