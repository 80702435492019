import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../stores/page.store';
import { UserAccountApi } from '../../swagger_client/src';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export default class UsersStore extends PageStore {
  userApi = new UserAccountApi();
  isLoadingAction = false;
  endpoint = '/users';
  selectedItem = null;
  tableHeader = [
    { name: 'avatar', center: true },
    { name: 'email', title: 'email', sortable: true, center: true },
    { name: 'firstName', title: 'first name', sortable: true, center: true },
    { name: 'lastName', title: 'last name', sortable: true, center: true },
    { name: 'companyAccount.name', title: 'company', center: true },
    { name: 'actions',title: '', center: true }
  ];
  order = 'firstName';
  direction = 'desc';

  constructor(user) {
    super(user);
    makeObservable(this, {
      isLoadingAction: observable,
      setSelectedItem: action,
      selectedItem: observable
    });
    if(!this.fitlers) this.filters = {};
  }

  get actions() {
    return [
      {
        type: 'delete',
        action: () => this.deleteUser()
      },
    ];
  }

  async handleCount() {
    this.setIsLoadingCount(true);
    try {
      //le count n'est pas dispo directement, actionCount renvoi un objet
      const { count } = await this.userApi.userAccountCount({
        //passer les filtres pour avoir le bon count
        filter: JSON.stringify({ where: this.computedFilters }),
      });

      this.setCount(count);
    } catch (e) {
      console.error(e);
    }
    this.setIsLoadingCount(false);
  }

  async handleFetch() {
    this.setIsLoading(true);
    try {
      this.setItems(await this.userApi.userAccountFind({
        filter: JSON.stringify({
          where: this.computedFilters,
          order: `${this.order} ${this.direction.toUpperCase()}`,
          skip: (this.page - 1) * this.perPage,
          limit: this.perPage,
          include: ['companyAccount'],
        }),
      }));
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }
    this.initPage = true;
    this.setIsLoading(false);
    this.selectedItems = [];
  }

  get counters() {
    return [{ txt: 'user', count: this.count }];
  }

  filterSearch(item) {
    const { email, firstName, lastName, companyAccount } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (companyAccount) {
      companyAccount.name.toLowerCase().includes(this.inputFilterValue) &&
        count++;
    }

    if (email) {
      email.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (firstName) {
      firstName.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (lastName) {
      lastName.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

  setSelectedItem(value) {
    this.selectedItem = value;
  }

  async deleteUser(){
    this.isLoadingAction = true;
    try {
      await this.userApi.userAccountDeleteById(this.selectedItem.user.id);
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }
    this.isLoadingAction = false;
    this.selectedItem = null;
    this.handleCount();
    this.handleFetch();
  }
}
