import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import classes from './AdsRow.module.css';
import PropTypes from 'prop-types';

AdsRow.propTypes = {
  onClose: PropTypes.func.isRequired,
  AdsInTable: PropTypes.any.isRequired
};

function AdsRow({ onClose, AdsInTable }) {
  const ref = useRef(null);
  useEffect(() => {
    const listener = window.addEventListener('mousedown', (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClose();
      }
      return ref;
    });

    return () => window.removeEventListener('mousedown', listener);
  });

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.close}>
        <div className={classes.cross} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div>
        {AdsInTable}
      </div>
    </div>
  );
}
export default observer(AdsRow);
