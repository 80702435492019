import { useContext } from 'react';
import RangeFilterView from './NewRangeFilterView';
import useRangeFilter from './useNewRangeFilter';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

interface Props {
  readonly name: string;
  readonly min: number;
  readonly max: number;
  readonly label: string;
  readonly defaultValue: any;
  readonly setFilter: Function;
  readonly filters: any;
  readonly percent: number;
  readonly step: number;
  readonly marks: string;
}

export default function RangeFilter(props: Props) {
  const { filters, setFilter } = useContext(PanelFilterContext);
  const useProps = useRangeFilter({ ...props, filters, setFilter });
  return <RangeFilterView {...props} {...useProps} />;
}
