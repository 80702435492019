import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import Async from 'react-select/async';
import { CompanyAccountApi } from '../../../swagger_client/src';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

SelectCompanyAccount.propTypes = {
  defaultValue: PropTypes.array,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired
};

function SelectCompanyAccount({
  defaultValue,
  register,
  control,
}) {
  const 
    companyAccountApi = new CompanyAccountApi(),
    { t } = useTranslation();

  async function promiseOptions(inputValue) {
    const items = await companyAccountApi.companyAccountFind({
      filter: JSON.stringify({
        where: { name: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } },
        limit: 50,
        order: 'name asc',
      }),
    });
    const newOptions = _.map(items, (o) => {
      return {
        label: o.name,
        value: o,
      };
    });
    return newOptions.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <Controller
      render={(field) => (
        <Async
          cacheOptions
          defaultValue={defaultValue}
          styles={taglistStyle}
          menuPosition="fixed"
          defaultOptions
          isClearable
          isMulti
          inputId="companyAccounts"
          placeholder={t('select company accounts')}
          onChange={(selected) => field.onChange(selected.map(select=>select.value))}
          loadOptions={promiseOptions}
        />
      )}
      control={control}
      rules={register}
      isMulti
      name="companyAccounts"
      defaultValue={defaultValue}
    />
  );
}

export default observer(SelectCompanyAccount);
