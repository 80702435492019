import { pictos } from '../../../types/pictos.enum';
import BrandsStore from '../../../pages/Brands/brands.store';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

interface Props {
  readonly store: BrandsStore
  readonly item: any
}

export default function BrandsActionsCell({ item, store } : Props) {

  return (<ShortMenuModale>
    <ShortMenuList>
      <ShortMenuAction 
        icon={pictos.officiel} 
        text="Link active domains" 
        onClick={() => store.setOpenModaleLinkActiveDomains(item, '')} 
      />
      <ShortMenuAction 
        icon={pictos.dotCircle} 
        text="Launch auto match" 
        onClick={() => {
          if (item.autoMatchRegExp) {
            store.setOpenModaleAutoMatch(item, '');
          }
        }}
      />
      <ShortMenuAction
        isActive={!!item.autoMatchRegExp} 
        icon={pictos.bullseye} 
        text="Launch auto match all" 
        onClick={() => {
          if (item.autoMatchRegExp) {
            store.setOpenModaleAutoMatch(item, 'all');
          }
        }}
      />
    </ShortMenuList>
    <ShortMenuList separator>
      <ShortMenuAction
        icon={pictos.pen} 
        text="Update brand" 
        onClick={() => store.updateOneBrand(item)}
      />
      {!item.enable && (
        <ShortMenuAction
          icon={pictos.trash} 
          text="Delete brand" 
          onClick={() =>
            store.setSelectedItem({
              term: item,
              action: () => store.deleteBrand(item.id),
              type: 'delete',
            })
          }
        />
      )}
    </ShortMenuList>
  </ ShortMenuModale>);
}
