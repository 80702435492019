import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import { LocalizationApi, TermApi } from '../../../swagger_client/src';
import i18next from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default class SettingTermsStore extends PageStore {
  termApi = new TermApi();
  selectedItems = [];
  selectedItem = null;
  terms = [];
  updateMode = true;
  showCurrentTable = true;
  returnToCurrentResult = false;
  items = [];
  createdTerms = [];
  showCreateModale = false;
  inputFilterValue = '';
  isLoadingAction = false;
  isCreatedLoading = false;
  termToUpdateBrand = null;
  endpoint = '/terms';
  //for show form or resume
  isSubmit = false;
  tableHeader = [
    { name: 'term', title: 'term', sortable: true, center: true },
    {
      name: 'localization.locale',
      title: 'location',
      admin: true,
      center: true,
    },
    {
      name: 'region',
      title: 'region',
      sortable: true,
      admin: true,
      center: true,
    },
    { name: 'engine', title: 'engine', sortable: true, center: true },
    { name: 'brand', title: 'brand', sortable: true, center: true },
    { name: 'keywordType', title: 'type', sortable: true, center: true },
    { name: 'categories', title: 'tag', field: 'label', center: true },
    { name: 'every', title: 'daily request', sortable: true, center: true },
    { name: 'state', title: 'state', sortable: true, center: true },
  ];
  constructor(defaultFilters = {}, isAdmin, user) {
    super(user);
    makeObservable(this, {
      selectedItems: observable,
      selectedItem: observable,
      returnToCurrentResult: observable,
      createdTerms: observable,
      updateMode: observable,
      showCreateModale: observable,
      isLoadingAction: observable,
      showCurrentTable: observable,
      terms: observable,
      //for show form or resume
      isSubmit: observable,
      isCreatedLoading: observable,
      termToUpdateBrand: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      setIsSubmit: action,
      createKeywords: action,
      reset: action,
      resetFilter: action,
      setSelectedItem: action,
      setShowCreateModale: action,
      updateEnabled: action,
      setModale: action,
      setShowUpdateForm: action,
      setShowCurrentTable: action,
      setModaleUpdateBrand: action,
      setUpdateBrandAndType: action,
    });

    if (isAdmin) {
      this.tableHeader = [
        {
          name: 'selectAll',
          value: 0,
          handleClick: () => this.toggleSelectAll(),
          center: true,
        },
        ...this.tableHeader,
        {
          name: 'canBeAccessBy',
          title: 'Customer access',
          admin: true,
          field: 'name',
          center: true,
        },
        {
          name: 'actions',
          title: 'Actions',
          admin: true,
          center: true,
        },
      ];
    }

    this.order = 'term';
    this.isAdmin = isAdmin;
    this.defaultFilters = defaultFilters;
  }

  setReturnToCurrentResult() {
    this.returnToCurrentResult = false;
  }

  setShowCurrentTable() {
    this.showCurrentTable = !this.showCurrentTable;
    if (this.showCurrentTable) {
      this.perPage = 25;
      this.selectedItems = [];
    }
    this.handleCount();
    this.handleFetch();
  }

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.tableHeader[0].value = 0;
      this.setSelectedItem(null);
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.id);
      this.setSelectedItem(this.items[0]);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item) {
    this.selectedItems = _.xor([item.id], this.selectedItems);
    this.setSelectedItem(this.selectedItems.length === 1 ? item : null);
  }

  setModaleUpdateBrand(term) {
    this.termToUpdateBrand = term;
  }

  setUpdateBrandAndType(term) {
    const index = _.findIndex(this.items, { id: term.id });
    this.items.splice(index, 1, term);
  }

  async handleCount(filterSelected) {
    this.setIsLoadingCount(true);
    this.filters = this.setDefaultFilters(this.filters);
    let newComputedFilters = JSON.parse(JSON.stringify(this.computedFilters));

    newComputedFilters.and = newComputedFilters.and.map((filter) => {
      if (
        filter['options.saveShoppingAds'] &&
        filter['options.saveShoppingAds']['inq']
      ) {
        if (filter['options.saveShoppingAds']['inq'].length === 1) {
          if (filter['options.saveShoppingAds']['inq'][0] === 'false') {
            filter['options.saveShoppingAds']['inq'] = null;
            filter['options.saveShoppingAds'] = false;
          } else if (filter['options.saveShoppingAds']['inq'][0] === 'true') {
            filter['options.saveShoppingAds']['inq'] = null;
            filter['options.saveShoppingAds'] = true;
          }
        }
      }
      return filter;
    });

    const defaultFilters = {
      id: { inq: _.map(this.createdTerms, (o) => o.id) },
    };

    try {
      //le count n'est pas dispo directement, actionCount renvoi un objet
      const { count } = await this.termApi.termCount({
        //passer les filtres pour avoir le bon count
        where: JSON.stringify(
          filterSelected ? defaultFilters : newComputedFilters
        ),
      });
      this.setCount(count);
    } catch (e) {
      console.error(e);
    }

    this.setIsLoadingCount(false);
  }

  get counters() {
    return [{ txt: 'terms', count: this.count }];
  }

  //surcharge
  get filteredItems() {
    return this.items.filter((item) => this.filterSearch(item));
  }

  get commonTagsForSelectItems() {
    /**recupère les tags des items selectionnés */
    if (this.selectedItems) {
      const tags = _.chain(this.items)
        .filter((o) => !!this.selectedItems.find((it) => it === o.id))
        .reduce((acc, { categories }) => {
          return acc.concat(categories || []);
        }, [])
        .value();
      /** garde uniquement les tags en commun à tous les items selectionnés */
      const commonTags = _.chain(tags)
        .reduce((acc, categorie) => {
          const isCommon = tags.filter((o) => o.id === categorie.id);

          if (isCommon.length === this.selectedItems.length) {
            acc.push(categorie);
          }

          return acc;
        }, [])
        .uniqBy('id')
        .value();

      return commonTags;
    } else return [];
  }

  async handleFetch() {
    this.selectedItems = [];
    this.setSelectedItem(null);
    this.createdTerms = [];
    this.terms = [];
    this.tableHeader[0].value = 0;
    this.setIsLoading(true);
    this.filters = this.setDefaultFilters(this.filters);
    let newComputedFilters = JSON.parse(JSON.stringify(this.computedFilters));

    newComputedFilters.and = newComputedFilters.and.map((filter) => {
      if (
        filter['options.saveShoppingAds'] &&
        filter['options.saveShoppingAds']['inq']
      ) {
        if (filter['options.saveShoppingAds']['inq'].length === 1) {
          if (filter['options.saveShoppingAds']['inq'][0] === 'false') {
            filter['options.saveShoppingAds']['inq'] = null;
            filter['options.saveShoppingAds'] = false;
          } else if (filter['options.saveShoppingAds']['inq'][0] === 'true') {
            filter['options.saveShoppingAds']['inq'] = null;
            filter['options.saveShoppingAds'] = true;
          }
        }
      }
      return filter;
    });

    try {
      this.setItems(
        await this.termApi.termFind({
          filter: JSON.stringify({
            where: newComputedFilters,
            include: ['brand', 'localization', 'categories', 'canBeAccessBy'],
            order: `${this.order} ${this.direction.toUpperCase()}`,
            skip: this.perPage > 0 && (this.page - 1) * this.perPage,
            limit: this.perPage > 0 && this.perPage,
          }),
        })
      );
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }

    this.setIsLoading(false);
    this.initPage = true;
  }

  filterSearch(item) {
    const { term, engine, categories, canBeAccessBy } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (term) {
      term.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (engine) {
      engine.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (canBeAccessBy) {
      _.map(
        canBeAccessBy,
        (companyAccount) =>
          companyAccount.name.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    if (categories) {
      _.map(
        categories,
        (category) =>
          category.label.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    return count > 0 ? true : false;
  }

  // Edit this to archive instead of remove
  async deleteTerm(idToDelete) {
    this.isLoadingAction = true;
    try {
      await this.termApi.termDeleteById(idToDelete);
      this.items = _.filter(this.items, (o) => o.id !== idToDelete);
      this.selectedItem = null;
      toast.success(i18next.t('term deleted'));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.isLoadingAction = false;
  }

  get actions() {
    return [
      {
        type: 'activate',
        title: this.selectedItem ? 'activate term' : 'activate terms',
        submitText: this.selectedItem ? 'activate term' : 'activate terms',
        action: async () =>
          await this.updateEnabled(
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            'enabled'
          ),
      },
      {
        type: 'desactivate',
        title: this.selectedItem ? 'desactivate term' : 'desactivate terms',
        submitText: this.selectedItem
          ? 'desactivate term'
          : 'desactivate terms',
        action: async () =>
          await this.updateEnabled(
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            'disabled'
          ),
      },
      {
        type: 'archive',
        title: this.selectedItem ? 'archive term' : 'archive terms',
        submitText: this.selectedItem ? 'archive term' : 'archive terms',
        action: async () =>
          await this.updateEnabled(
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            'archived'
          ),
      },
    ];
  }

  // Edit this to archive instead of remove
  async deleteSeleted() {
    this.isLoadingAction = true;
    try {
      await this.termApi.termDestroyAll({
        body: {
          where: { id: { inq: this.selectedItems } },
        },
      });
      toast.success(i18next.t('terms deleted'));
    } catch (e) {
      toast.error(i18next.t(e));
      return;
    }

    this.items = _.filter(
      this.items,
      (o) => o.id !== this.selectedItems.find((it) => it === o.id)
    );
    this.selectedItems = [];
    this.handleFetch();
    this.handleCount();
    this.isLoadingAction = false;
  }

  /**
   *
   * @param {boolean} value
   */
  setIsSubmit(value) {
    this.isSubmit = value;
    if (!value) {
      this.handleCount();
      this.handleFetch(true);
    }
  }

  async createKeywords(options) {
    this.setNoFetch(true);
    this.isLoading = true;
    this.isCreatedLoading = true;
    this.isSubmit = true;
    this.showTable = true;

    this.terms = options.terms;
    delete options.terms;

    try {
      const createdTerms = _.map(this.terms, (o) => {
        return {
          term: o,
          ...options,
        };
      });

      const termsToCreate = await this.termApi.termCreate({
          body: createdTerms,
          include: ['brand', 'localization', 'categories', 'canBeAccessBy'],
        }),
        localizationAPi = new LocalizationApi(),
        localization = await localizationAPi.localizationFindById(
          options.localizationId,
          {}
        );

      this.count = termsToCreate.length;
      const newItems = _.map(
        _.isArray(termsToCreate) ? termsToCreate : [termsToCreate],
        (o) => ({
          ...o,
          canBeAccessBy: _.get(options, 'canBeAccessBy', []),
          localization,
          state: options.state,
          categories: options.categories,
        })
      );

      this.setItems(newItems);

      this.perPage = newItems.length;
      this.count = newItems.length;
      this.setSelectedItem(null);

      this.isCreatedLoading = false;
      //this.setReturnToCurrentResult(true);
    } catch (e) {
      toast.error(i18next.t(e));
    } finally {
      this.isLoading = false;
    }
  }

  reset() {
    this.terms = [];
    this.tags = [];
    this.engine = null;
    this.localization = null;
    this.every = null;
    this.companyAccounts = [];
    this.handleCount();
    this.handleFetch();
  }

  setInputFilterValue(value) {
    this.inputFilterValue = value;
  }

  setModale(value) {
    this.showCreateModale = value;
    this.terms = [];
    this.engine = null;
    this.localization = null;
    this.companyAccount = null;
    this.every = null;
    this.tags = [];
  }

  setShowCreateModale(value) {
    this.setModale(value);
  }

  resetFilter() {
    this.createdTerms = [];
    this.selectedItems = [];
    this.selectedItem = null;
    this.handleCount();
    this.handleFetch();
  }

  setSelectedItem(value) {
    this.selectedItem = value;
  }

  async updateEnabled(terms, enableValue) {
    this.isLoadingAction = true;
    try {
      await this.termApi.termUpdateAll({
        body: { state: enableValue },
        where: JSON.stringify({ id: { inq: _.map(terms, (o) => o.id) } }),
      });

      if (terms.length === 1) {
        terms[0].state = enableValue;
        const index = _.findIndex(this.items, { id: terms[0].id });
        this.items.splice(index, 1, terms[0]);
      } else {
        this.isLoadingAction = false;
        await this.handleFetch();
        await this.handleCount();
      }

      toast.success(i18next.t(`term${enableValue}`, { count: terms.length }));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.selectedItem = null;
    this.isLoadingAction = false;
  }

  setShowUpdateForm(value) {
    this.isLoadingAction = false;
    if (this.selectedItems.length > 0) {
      const item = _.find(this.items, { id: this.selectedItems[0] });
      this.tags = item.categories;

      if (item.engine) {
        this.engine = item.engine;
      }
      if (item.localization) {
        this.localization = {
          label: item?.localization?.city
            ? `${item.localization.city} (${item.localization.locale})`
            : item.localization.locale,
          value: item.localization,
        };
      }
      this.companyAccounts = _.map(item.canBeAccessBy, (o) => {
        return {
          label: o.name,
          value: o,
        };
      });
    }

    this.updateMode = value;

    this.setModale(value);
  }

  termBrand(term) {
    const index = _.findIndex(this.items, { id: term.id });
    this.items[index] = {
      ...this.items[index],
      brand: term.brand,
      brandId: term.brandId,
    };
  }

  setDefaultFilters(filters) {
    let defaultFilters = { ...filters };
    const options = ['enabled', 'disabled'].map((o) => ({
      label: i18next.t(o),
      value: o,
    }));
    if (!defaultFilters.state) {
      defaultFilters.state = {
        whereClause: { inq: ['enabled', 'disabled'] },
        description: `${i18next.t('enabled')}, ${i18next.t('disabled')}`,
        value: options,
        label: 'state',
      };
    }
    return defaultFilters;
  }
}
