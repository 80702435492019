import { action, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import PageStore from '../../stores/page.store';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getCookie } from '../../Utils/getSetCookie/getsetCookie';
import dayjs from 'dayjs';
import Backend from '../../stores/newBackend';

export default class ConversationStore extends PageStore {
  statusCount: any[] = [];
  tableHeader: {
    name: string;
    title?: string;
    sortable: boolean;
    value?: any;
    customSort?: (a: any, b: any) => number;
  }[] = [
    {
      name: 'brand.label',
      title: 'brand',
      sortable: true,
      customSort: (a: any, b: any) => {
        return a.brand.label.localeCompare(b.brand.label);
      },
    },
    { name: 'country', title: 'country', sortable: true },
    { name: 'engine', title: 'engine', sortable: true },
    { name: 'status', title: 'status', sortable: true },
    { name: 'lastStatusDate', title: 'status update', sortable: true },
    { name: 'exchanges', title: 'exchanges', sortable: false },
    { name: 'openNewTab', sortable: false },
  ];
  endpoint = '/none';
  selectedItems: any[] = [];
  order = 'priority';
  direction = 'desc';
  showAdvertisement = null;
  isAdmin = false;
  searchTerm: string | undefined = '';
  rawItems: any[] = [];
  defaultFilters = {
    lastView: {
      label: 'lastView',
      description: i18next.t('last 30 Days'),
      whereClause: {
        between: [
          dayjs(dayjs().subtract(100, 'day')).format('YYYY-MM-DDT00:00:00'),
          dayjs(dayjs()).format('YYYY-MM-DDT23:59:59'),
        ],
      },
    },
    status: {
      whereClause: { inq: ['new'] },
      description: i18next.t('new'),
      value: [{ label: i18next.t('new'), value: i18next.t('new') }],
      label: 'status',
    },
  };
  advertiserID: string = '';
  token: string = '';

  constructor(
    user: any,
    isAdmin: boolean,
    advertiserID: string,
    token: string
  ) {
    super(user);
    makeObservable(this, {
      selectedItems: observable,
      statusCount: observable,
      showAdvertisement: observable,
      isAdmin: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      replaceAdvertiser: action,
      setAdvertiserGroup: action,
      setIsAdmin: action,
      setShowAdvertisement: action,
      updateItem: action,
      setStatusCount: action,
    });
    this.isAdmin = isAdmin;
    this.advertiserID = advertiserID;
    this.token = token;
    this.allowUrlChange = false;
    if (!this.filters) {
      this.filters = this.defaultFilters;
    }
  }

  setIsAdmin(value: boolean) {
    this.isAdmin = value;
  }

  setStatusCount(counts: any[]) {
    this.statusCount = counts;
  }

  async handleCount() {
    this.setIsLoadingCount(true);
    //le count n'est pas dispo directement, actionCount renvoi un objet
    try {
      const result = await Backend.searchAction(this.token, {
        advertiser: { id: this.advertiserID },
      });
      const count = result.meta.page.total;
      this.setCount(count);
    } catch (e) {
      console.error(e);
    }

    this.setIsLoadingCount(false);
  }

  async handleFetch() {
    this.setIsLoading(true);

    try {
      const result = await Backend.searchAction(this.token, {
        advertiser: { id: this.advertiserID },
        sortBy: `${this.direction === 'desc' ? '-' : ''}${this.order}`,
        page: {
          size: 500,
        },
        noPaging: true,
      });
      this.setItems(result.data);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.setIsLoading(false);
  }

  setSearchTerm(searchTerm: string | undefined) {
    this.searchTerm = searchTerm;
    super.setItems(this.searchItems());
  }

  searchItems() {
    if (this.searchTerm === '' || this.searchTerm === undefined) {
      return this.rawItems;
    }

    const normalize = (str: string) => {
      return str
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();
    };

    const searchNormalize = normalize(this.searchTerm);

    return this.rawItems.filter((item: any) => {
      const { engine, countries, country, brand, lastStatusDate, status } =
        item;
      if (engine) {
        const providerNormalize = normalize(engine);
        if (providerNormalize.includes(searchNormalize)) {
          return true;
        }
      }

      if (country) {
        console.log('ttttrtr', countries, 'sfsfsfsfs', country);
        const countryNormalize = normalize(country);
        if (countryNormalize.includes(searchNormalize)) {
          return true;
        }
      }

      if (brand && brand.label) {
        const brandLabelNormalize = normalize(brand.label);
        if (brandLabelNormalize.includes(searchNormalize)) {
          return true;
        }
      }

      if (lastStatusDate) {
        const dateStr = dayjs(lastStatusDate).format('DD/MM/YYYY, hh:mm');
        if (dateStr.includes(searchNormalize)) {
          return true;
        }
      }

      if (status) {
        const statusNormalize = normalize(i18next.t(status));
        if (statusNormalize.includes(searchNormalize)) {
          return true;
        }
      }

      return false;
    });
  }

  setItems(items: any[]) {
    this.rawItems = items;
    super.setItems(this.searchItems());
  }

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.tableHeader[0].value = 0;
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.id);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item: any) {
    this.selectedItems = _.xor([item.id], this.selectedItems);
  }

  get counters() {
    return [{ txt: 'advertiser', count: this.count }, ...this.statusCount];
  }

  setAdvertiserGroup(status: any) {
    this.selectedItems.forEach((id) => {
      const newItem = _.find(this.items, { id });
      newItem.status = status;
      this.replaceAdvertiser(newItem);
    });
  }

  replaceAdvertiser(advertiser: any) {
    const oldAdvertiser = _.find(this.items, { id: advertiser.id }),
      newAdvertiser = { ...advertiser };

    if (_.get(oldAdvertiser, 'dealer')) {
      newAdvertiser.dealer = oldAdvertiser.dealer;
    }

    this.items.splice(
      _.findIndex(this.items, { id: advertiser.id }),
      1,
      newAdvertiser
    );
  }

  updateItem(item: any, tags: any[]) {
    const index = _.findIndex(this.items, { termId: item.termId });
    if (index >= 0) {
      this.items.splice(index, 1, {
        ...item,
        categories: tags.map((o) => o.originalTag),
      });
    }
  }

  filterSearch(item: any) {
    const { engine, categories, brand, localization, canBeAccessBy, master } =
      item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (engine) {
      engine.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (master) {
      master.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (localization) {
      localization?.locale.toLowerCase().includes(this.inputFilterValue) &&
        count++;
    }

    if (brand) {
      if (brand.label) {
        brand?.label.toLowerCase().includes(this.inputFilterValue) && count++;
      }

      if (brand.name) {
        brand?.name.toLowerCase().includes(this.inputFilterValue) && count++;
      }
    }

    if (categories) {
      _.map(
        categories,
        (category) =>
          category.label.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    if (canBeAccessBy) {
      _.map(
        canBeAccessBy,
        (companyAccount) =>
          companyAccount.name.toLowerCase().includes(this.inputFilterValue) &&
          count++
      );
    }

    return count > 0 ? true : false;
  }

  setShowAdvertisement(value: any) {
    this.showAdvertisement = value;
  }

  get exportLink() {
    const queryString = new URLSearchParams({
      filter: JSON.stringify({
        where: this.computedFilters,
        include: [{ relation: 'dealer', scope: { include: 'categories' } }],
      }),
      csv: 'flatten',
      access_token: getCookie('access_token') as string,
    });
    const link =
      process.env.REACT_APP_MONIBRAND_BACKEND_API_URL +
      this.endpoint +
      '?' +
      queryString.toString();
    return link;
  }
}
