import dayjs from 'dayjs';

export default function AdvertiserUpdateDateCell({ item }) {
  switch (item.status) {
  case 'ignored':
    return dayjs(item.ignoredDate).format('DD/MM/YYYY');
  case 'accepted':
    return dayjs(item.acceptedDate).format('DD/MM/YYYY');
  case 'refused':
    return dayjs(item.refusedDate).format('DD/MM/YYYY');
  default:
    return '';
  }
}
