import classes from './ActionForMultiSelect.module.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ActionForMultiSelectNoTrigger from './ActionForMultiSelectNoTrigger/ActionForMultiSelectNoTrigger';
import ShortMenuAction from '../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

ActionForMultiSelect.propTypes = {
  actionName: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  submitbuttonText: PropTypes.string,
  subTitle: PropTypes.any
    .isRequired,
  forShortMenuIcon:PropTypes.any
};

export default function ActionForMultiSelect({
  title,
  actionName,
  submitbuttonText,
  onSubmit,
  children,
  isLoading,
  subTitle,
  isOpen,
  setIsOpen,
  forShortMenuIcon
}) {
  const { t } = useTranslation();

  return (
    <>
      {forShortMenuIcon ?  
        <ShortMenuAction
          icon={forShortMenuIcon}
          text={actionName}
          onClick={() => setIsOpen(true)}
        />: 
        <div className={classes.button} onClick={() => setIsOpen(true)}>
          {t(actionName)}
        </div>
      }
      {isOpen && (
        <ActionForMultiSelectNoTrigger
          fullHeight={false}
          title={title}
          submitbuttonText={submitbuttonText}
          onSubmit={onSubmit}
          isLoading={isLoading}
          subTitle={subTitle}
          onClose={()=>setIsOpen(false)}
        >
          {children}
        </ActionForMultiSelectNoTrigger>
      )}
    </>
  );
}
