import classes from './LoaderSmall.module.css';
import PropTypes from 'prop-types';

LoaderSmall.propTypes = {
  color: PropTypes.oneOf(['black','white','monibrand'])
};

LoaderSmall.defaultProps = {
  color:'black',
};

/**
 * 
 * Ce loader peut prendre une **props** `color` de valeur `white`,`black` ou `monibrand`.
 * Sa valeur par défaut est `black`.
 *  
 */
export default function LoaderSmall({ color = 'black' }) {
  const points = [1, 2, 3, 4];

  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {points.map((point) => (
          <div
            data-testid="point"
            key={point}
            data-color={color}
            className={classes.point}
            style={{
              transform: `translateX(-${point}00%)`,
              animationDelay: `${points.length - point}00ms`,
            }}
          />
        ))}
      </div>
    </div>
  );
}