import BrandTagOfficialView from './BrandTagOfficialView';
import useBrandTagOfficial from './useBrandTagOfficial';
import { BrandInterface } from '../../../types/brand.interface';

interface Props {
  readonly onClose: Function
  readonly brand: BrandInterface
}

export default function BrandTagOfficial(props:Props){
  const useProps = useBrandTagOfficial(props);

  return <BrandTagOfficialView  {...props} {...useProps}/>;
}