import { TagInterface } from '../../types/tag.interface'
import { BrandInterface } from '../../types/brand.interface'
import { CategoryApi } from '../../swagger_client/src'

export async function getOrCreateExistingOfficialTag(
  brand: BrandInterface
): Promise<TagInterface | undefined> {
  const categoryApi = new CategoryApi();
  const officialParent = await categoryApi.categoryFindOne({
    filter: JSON.stringify({
      where: { name: 'website-classification', scope: 'tag' }
    }),
  });
  const existingTag = await categoryApi.categoryFindOne({
    filter: JSON.stringify({
      where: { name: 'official', brandOwnerId: brand.id },
    }),
  })

  // If the "official" category doesn't exist for the brand,
  // an empty object is received (the call strangely returns
  // a "method not found" message)
  if (existingTag && existingTag.id) return existingTag

  if (officialParent && officialParent.id) {
    const createdTag = await categoryApi.categoryCreate({
      body: {
        label: 'Official',
        brandOwnerId: brand.id,
        scope: 'tag',
        visibility: 'owner',
        parentId: officialParent.id
      }
    });
    return createdTag;
  }
  return;
}
