import { observer } from 'mobx-react';
import useTagsFilter from './useTagsFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';

interface Props extends FilterChild {
  readonly scope: string
}

function TagsFilter(props:Props) {
  const useProps = useTagsFilter(props.scope),
    passProps = {...props,...useProps};

  return <AsyncCreateFilter {...passProps}/>;
}

export default observer(TagsFilter);