import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * @description Component and func
 */
import handleOutside from '../../../Utils/handleOutside/handleOutside';

/**
 * @description Styles and assets
 */
import classes from './ProfileCardModale.module.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import UpdateProfile from '../../UpdateProfile/UpdateProfile';
import { useFlagsmith } from 'flagsmith/react';

export default function ProfileCardModale({ parentRef, onClose }) {
  const ref = useRef(null),
    { t } = useTranslation(),
    flagsmith = useFlagsmith();

  useEffect(() => handleOutside([ref, parentRef], onClose));

  const disconnectFlagSmith = () => {
    flagsmith.logout();
  };

  return (
    <div className={classes.container}>
      <div className={classes.cross}>
        <div className="cross" />
      </div>
      <div className={classes.grid} ref={ref}>
        <UpdateProfile/>
        <NavLink className="button" to="/logout" onClick={disconnectFlagSmith}>{t('logout')}</NavLink>
      </div>

    </div>
  );
}

ProfileCardModale.propTypes = {
  parentRef: PropTypes.object,
  onClose: PropTypes.func,
};
