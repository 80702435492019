import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import MonibrandLogo from '../../assets/img/logo-monibrand.png';
import classes from './PublicLayout.module.css';

function PublicLayout({ children }) {
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <img
          className={classes.logo}
          src={MonibrandLogo}
          alt="Monibrand"
          width="200px"
        />
      </header>
      <main>{children}</main>
    </div>
  );
}

export default observer(PublicLayout);

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired
};

