/**
 *
 * @param {Object[]|Object} errors - errors is in array
 *
 */
export function ParseUseFormErrors(errors) {
  const msgs = [];
  if (typeof errors === 'object' && Object.prototype.hasOwnProperty.call(errors, 'error')) {
    msgs.push(errors.error.type);
  }
  for (const error in errors) {
    if (errors[error].message) {
      msgs.push(errors[error].message);
    }
  }
  return msgs;
}

export const dbTable = {
  filter_domains: '++id,name',
  filter_tags: '++id,name',
  filter_terms: '++id,name',
  filter_advertisers: '++id,name',
};