import i18next from 'i18next';
import googleImg from '../../assets/icons/google.png';
import bingImg from '../../assets/icons/bing.png';

export const devices = [
  { value: 'MOBILE', label: i18next.t('Tablet') },
  { value: 'TABLET', label: 'Mobile' },
  { value: 'DESKTOP', label: i18next.t('Desktop') },
];

export const keywordtypes = [
  { value: 'GENERIC', label: 'Generic' },
  { value: 'BRAND', label: 'Brand' },
  { value: 'GENERIC_BRAND', label: 'Generic brand' },
  { value: 'MULTIBRAND', label: 'Multi brand' },
];

export const engines = [
  { value: 'google', label: 'Google' },
  { value: 'bing', label: 'Bing' },
];

export const enginesOptions = [
  { value: 'GOOGLE', label: 'Google', image: googleImg },
  { value: 'BING', label: 'Bing', image: bingImg }
];