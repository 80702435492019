import { cloneElement, ReactElement } from 'react';
import classes from './StandAloneFilter.module.css';
import Button from '../../Button/Button';

interface props {
  readonly name: string
  readonly child: ReactElement
  readonly onClose: Function
  readonly validate: Function
  readonly filters: any
  readonly setFilter: Function
}

export default function StandAloneFilterView({
  child,
  name,
  validate,
  onClose,
  filters,
  setFilter,
}: props) {

  return (
    <div className={classes.container}>
      {filters && cloneElement(child, { name, filters, setFilter })}
      <div className={classes.containerButtons}>
        <Button onClick={() => onClose()} type="reset" text="cancel" />
        <Button onClick={() => validate()} text="submit" />
      </div>
    </div>
  );
}
