/**
 * @param {Array} refs
 * @param {*} setter
 */
export default function handleOutside(refs, setter) {
  window.addEventListener('mousedown', (e) => {
    refs.map((ref) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setter();
      }
      return ref;
    });
  });
}
