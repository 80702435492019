import { useState } from 'react';
import classes from './EngineSelector.module.css';
import PropTypes from 'prop-types';
import Radio from '../_ui/Radio/Radio';

EngineSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  engines: PropTypes.array.isRequired
};

export default function EngineSelector({
  value, onChange, engines
}) {
  const [selected, setSelected] = useState(value); 

  const onSelectedRadio = (value) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div className={classes.container}>
      {engines.map((engine) => (
        <div key={engine.value} className={classes.inputGroup}>
          <Radio value={selected?.value === engine.value} setValue={(value) => {
            if (value) onSelectedRadio({ value: engine.value });
          }}>
            <img src={engine.image} width={30} height={30} alt={engine.value} />
          </Radio>
        </div>
      ))}
    </div>
  );
}
