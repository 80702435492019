import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import tagDefinition from '../../../proptypes/tag.definition';

TagsTypeCell.propTypes = {
  item: PropTypes.shape(tagDefinition),
};

export default function TagsTypeCell({ item }) {
  const { t } = useTranslation();

  return t(item.scope === 'tag' ? 'domain' : item.scope);
}
