import PropTypes from 'prop-types';
import classes from './FormSummaryErrors.module.css';
import { useTranslation } from 'react-i18next';

FormSummaryErrors.propTypes = {
  errors: PropTypes.object.isRequired,
};

export default function FormSummaryErrors({ errors }) {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.summary}>
        {errors.length} {errors.length > 1 ? t('errors') : t('error')} found
      </div>
      <ul className={classes.list}>
        {errors.map((error) => (
          <li key={error}>
            {t(error)}
          </li>
        ))}
      </ul>
    </div>
  );
}