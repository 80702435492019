import { useTranslation } from 'react-i18next';
/**
 *
 * @description hook for use region.
 */
export function useRegionOptions(): {
  [key: string]: { label: string; value: string }[];
  } {
  const { t } = useTranslation();
  return {
    PT: [
      { label: t('Aveiro District'), value: 'Aveiro District,Portugal' },
      { label: t('Braga'), value: 'Braga,Portugal' },
      { label: t('Braganca District'), value: 'Braganca District,Portugal' },
      {
        label: t('Castelo Branco'),
        value: 'District Castelo Branco District,Portugal',
      },
      { label: t('Coimbra District'), value: 'Coimbra District,Portugal' },
      { label: t('Evora District'), value: 'Evora District,Portugal' },
      { label: t('Faro District'), value: 'Faro District,Portugal' },
      { label: t('Guarda District'), value: 'Guarda District,Portugal' },
      { label: t('Leiria District'), value: 'Leiria District,Portugal' },
      { label: t('Lisbon'), value: 'Lisbon,Portugal' },
      {
        label: t('Portalegre District'),
        value: 'Portalegre District,Portugal',
      },
      { label: t('Porto District'), value: 'Porto District,Portugal' },
      { label: t('Santarem District'), value: 'Santarem District,Portugal' },
      { label: t('Setubal'), value: 'Setubal,Portugal' },
      {
        label: t('Viana do'),
        value: 'Castelo District Viana do Castelo District,Portugal',
      },
      { label: t('Viseu District'), value: 'Viseu District,Portugal' },
      { label: t('Azores'), value: 'Azores,Portugal' },
      { label: t('Madeira'), value: 'Madeira,Portugal' },
      { label: t('Beja District'), value: 'Beja District,Portugal' },
      { label: t('Vila Real'), value: 'District Vila Real District,Portugal' },
    ],
    BE: [
      {
        label: t('Toute la belgique'),
        value: '',
      },
      { label: t('Brussels'), value: 'Brussels,Belgium' },
      { label: t('Antwerp'), value: 'Antwerp,Flanders,Belgium' },
      {
        label: t('Flemish Brabant'),
        value: 'Flemish Brabant,Flanders,Belgium',
      },
      { label: t('Limburg'), value: 'Limburg,Flanders,Belgium' },
      { label: t('East Flanders'), value: 'East Flanders,Flanders,Belgium' },
      { label: t('West Flanders'), value: 'West Flanders,Flanders,Belgium' },
      {
        label: t('Walloon Brabant'),
        value: 'Walloon Brabant,Wallonia,Belgium',
      },
      { label: t('Hainaut'), value: 'Hainaut,Wallonia,Belgium' },
      { label: t('Liege'), value: 'Liege,Wallonia,Belgium' },
      { label: t('Luxembourg'), value: 'Luxembourg,Wallonia,Belgium' },
      {
        label: t('Province of Namur'),
        value: 'Province of Namur,Wallonia,Belgium',
      },
    ],
    FR: [
      {
        label: t('Toute la france'),
        value: '',
      },
      { label: t('Ain'), value: 'Ain,Auvergne-Rhone-Alpes,France' },
      { label: t('Aisne'), value: 'Aisne,Hauts-de-France,France' },
      { label: t('Allier'), value: 'Allier,Auvergne-Rhone-Alpes,France' },
      {
        label: t('Alpes-de-Haute-Provence'),
        value: 'Alpes-de-Haute-Provence,Provence-Alpes-Cote d\'Azur,France',
      },
      {
        label: ('Alpes-Maritimes'),
        value: 'Alpes-Maritimes,Provence-Alpes-Cote d\'Azur,France',
      },
      { label: t('Ardeche'), value: 'Ardeche,Auvergne-Rhone-Alpes,France' },
      { label: t('Ardennes'), value: 'Ardennes,Grand Est,France' },
      { label: t('Ariege'), value: 'Ariege,Occitanie,France' },
      { label: t('Aube'), value: 'Aube,Grand Est,France' },
      { label: t('Aude'), value: 'Aude,Occitanie,France' },
      { label: t('Aveyron'), value: 'Aveyron,Occitanie,France' },
      { label: t('Bas-Rhin'), value: 'Bas-Rhin,Grand Est,France' },
      {
        label: t('Bouches-du-Rhone'),
        value: 'Bouches-du-Rhone,Provence-Alpes-Cote d\'Azur,France',
      },
      { label: t('Calvados'), value: 'Calvados,Normandy,France' },
      { label: t('Cantal'), value: 'Cantal,Auvergne-Rhone-Alpes,France' },
      { label: t('Charente'), value: 'Charente,Nouvelle-Aquitaine,France' },
      {
        label: t('Charente-Maritime'),
        value: 'Charente-Maritime,Nouvelle-Aquitaine,France',
      },
      { label: t('Cher'), value: 'Cher,Centre-Val de Loire,France' },
      { label: t('Correze'), value: 'Correze,Nouvelle-Aquitaine,France' },
      { label: t('Corse-du-Sud'), value: 'Corse-du-Sud,Corsica,France' },
      {
        label: t('Cote-d\'Or'),
        value: 'Cote-d\'Or,Bourgogne-Franche-Comte,France',
      },
      { label: t('Cotes-d\'Armor'), value: 'Cotes-d\'Armor,Brittany,France' },
      { label: t('Creuse'), value: 'Creuse,Nouvelle-Aquitaine,France' },
      {
        label: t('Deux-Sevres'),
        value: 'Deux-Sevres,Nouvelle-Aquitaine,France',
      },
      { label: t('Dordogne'), value: 'Dordogne,Nouvelle-Aquitaine,France' },
      { label: t('Doubs'), value: 'Doubs,Bourgogne-Franche-Comte,France' },
      { label: t('Drome'), value: 'Drome,Auvergne-Rhone-Alpes,France' },
      { label: t('Gard'), value: 'Gard,Occitanie,France' },
      { label: t('Gers'), value: 'Gers,Occitanie,France' },
      { label: t('Eure'), value: 'Eure,Normandy,France' },
      {
        label: t('Eure-et-Loir'),
        value: 'Eure-et-Loir,Centre-Val de Loire,France',
      },
      { label: t('Essonne'), value: 'Essonne,Ile-de-France,France' },
      { label: t('Finistere'), value: 'Finistere,Brittany,France' },
      { label: t('Gironde'), value: 'Gironde,Nouvelle-Aquitaine,France' },
      { label: t('Guadeloupe'), value: 'Gaudeloupe' },
      { label: t('Haut-Rhin'), value: 'Haut-Rhin,Grand Est,France' },
      {
        label: t('Hautes-Alpes'),
        value: 'Hautes-Alpes,Provence-Alpes-Cote d\'Azur,France',
      },
      { label: t('Haute-Corse'), value: 'Haute-Corse,Corsica,France' },
      { label: t('Haute-Garonne'), value: 'Haute-Garonne,Occitanie,France' },
      {
        label: t('Haute-Loire'),
        value: 'Haute-Loire,Auvergne-Rhone-Alpes,France',
      },
      { label: t('Haute-Marne'), value: 'Haute-Marne,Grand Est,France' },
      {
        label: t('Hautes-Pyrenees'),
        value: 'Hautes-Pyrenees,Occitanie,France',
      },
      {
        label: t('Haute-Saone'),
        value: 'Haute-Saone,Bourgogne-Franche-Comte,France',
      },
      {
        label: t('Haute-Savoie'),
        value: 'Haute-Savoie,Auvergne-Rhone-Alpes,France',
      },
      {
        label: t('Haute-Vienne'),
        value: 'Haute-Vienne,Nouvelle-Aquitaine,France',
      },
      {
        label: t('Hauts-de-Seine'),
        value: 'Hauts-de-Seine,Ile-de-France,France',
      },
      { label: t('Herault'), value: 'Herault,Occitanie,France' },
      { label: t('Ille-et-Vilaine'), value: 'Ille-et-Vilaine,Brittany,France' },
      { label: t('Indre'), value: 'Indre,Centre-Val de Loire,France' },
      {
        label: t('Indre-et-Loire'),
        value: 'Indre-et-Loire,Centre-Val de Loire,France',
      },
      { label: t('Isere'), value: 'Isere,Auvergne-Rhone-Alpes,France' },
      { label: t('Jura'), value: 'Jura,Bourgogne-Franche-Comte,France' },
      { label: t('Landes'), value: 'Landes,Nouvelle-Aquitaine,France' },
      {
        label: t('Loir-et-Cher'),
        value: 'Loir-et-Cher,Centre-Val de Loire,France',
      },
      { label: t('Loire'), value: 'Loire,Auvergne-Rhone-Alpes,France' },
      {
        label: t('Loire-Atlantique'),
        value: 'Loire-Atlantique,Pays de la Loire,France',
      },
      { label: t('Loiret'), value: 'Loiret,Centre-Val de Loire,France' },
      { label: t('Lot'), value: 'Lot,Occitanie,France' },
      {
        label: t('Lot-et-Garonne'),
        value: 'Lot-et-Garonne,Nouvelle-Aquitaine,France',
      },
      { label: t('Lozere'), value: 'Lozere,Occitanie,France' },
      { label: t('Manche'), value: 'Manche,Normandy,France' },
      {
        label: t('Maine-et-Loire'),
        value: 'Maine-et-Loire,Pays de la Loire,France',
      },
      { label: t('Marne'), value: 'Marne,Grand Est,France' },
      { label: t('Martinique'), value: 'Martinique' },
      { label: t('Mayenne'), value: 'Mayenne,Pays de la Loire,France' },
      {
        label: t('Meurthe-et-Moselle'),
        value: 'Meurthe-et-Moselle,Grand Est,France',
      },
      { label: t('Meuse'), value: 'Meuse,Grand Est,France' },
      { label: t('Morbihan'), value: 'Morbihan,Brittany,France' },
      { label: t('Moselle'), value: 'Moselle,Grand Est,France' },
      { label: t('Nievre'), value: 'Nievre,Bourgogne-Franche-Comte,France' },
      { label: t('Nord'), value: 'Nord,Hauts-de-France,France' },
      { label: t('Oise'), value: 'Oise,Hauts-de-France,France' },
      { label: t('Orne'), value: 'Orne,Normandy,France' },
      { label: t('Paris'), value: 'Paris,Ile-de-France,France' },
      {
        label: t('Pas-de-Calais'),
        value: 'Pas-de-Calais,Hauts-de-France,France',
      },
      {
        label: t('Puy-de-Dome'),
        value: 'Puy-de-Dome,Auvergne-Rhone-Alpes,France',
      },
      {
        label: t('Pyrenees-Atlantiques'),
        value: 'Pyrenees-Atlantiques,Nouvelle-Aquitaine,France',
      },
      {
        label: t('Pyrenees-Orientales'),
        value: 'Pyrenees-Orientales,Occitanie,France',
      },

      { label: t('Reunion'), value: 'Reunion' },
      { label: t('Rhone'), value: 'Rhone,Auvergne-Rhone-Alpes,France' },
      {
        label: t('Saone-et-Loire'),
        value: 'Saone-et-Loire,Bourgogne-Franche-Comte,France',
      },
      { label: t('Sarthe'), value: 'Sarthe,Pays de la Loire,France' },
      { label: t('Savoie'), value: 'Savoie,Auvergne-Rhone-Alpes,France' },
      {
        label: t('Seine-et-Marne'),
        value: 'Seine-et-Marne,Ile-de-France,France',
      },
      { label: t('Seine-Maritime'), value: 'Seine-Maritime,Normandy,France' },
      {
        label: t('Seine-Saint-Denis'),
        value: 'Seine-Saint-Denis,Ile-de-France,France',
      },
      { label: t('Somme'), value: 'Somme,Hauts-de-France,France' },
      {
        label: t('Tarn-et-Garonne'),
        value: 'Tarn-et-Garonne,Occitanie,France',
      },
      { label: t('Tarn'), value: 'Tarn,Occitanie,France' },
      {
        label: t('Territoire de Belfort'),
        value: 'Territoire de Belfort,Bourgogne-Franche-Comte,France',
      },
      { label: t('Val-d\'Oise'), value: 'Val-d\'Oise,Ile-de-France,France' },
      { label: t('Val-de-Marne'), value: 'Val-de-Marne,Ile-de-France,France' },
      { label: t('Var'), value: 'Var,Provence-Alpes-Cote d\'Azur,France' },
      {
        label: t('Vaucluse'),
        value: 'Vaucluse,Provence-Alpes-Cote d\'Azur,France',
      },
      { label: t('Vendee'), value: 'Vendee,Pays de la Loire,France' },
      { label: t('Vienne'), value: 'Vienne,Nouvelle-Aquitaine,France' },
      { label: t('Vosges'), value: 'Vosges,Grand Est,France' },
      { label: t('Yonne'), value: 'Yonne,Bourgogne-Franche-Comte,France' },
      { label: t('Yvelines'), value: 'Yvelines,Ile-de-France,France' },
    ],
  };
}
