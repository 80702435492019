import SettingTermsStore from '../../../pages/Settings/Terms/settingTerms.store';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import { TagInterface } from '../../../types/tag.interface';
import { TermInterface } from '../../../types/term.interface';
import FullscreenModal from '../../FullscreenModal/FullscreenModal';
import TermOptionsUpdate from '../../Terms/TermOptionsUpdate/TermOptionsUpdate';
import TermCompanyAccountEdit from '../../Terms/TermCompanyAccountEdit/TermCompanyAccountEdit';
import TermFrequencyUpdate from '../../Terms/TermFrequencyUpdate/TermFrequencyUpdate';
import TermTagsUpdate from '../../Terms/TermTagsUpdate/TermTagsUpdate';
import { pictos } from '../../../types/pictos.enum';

interface Props {
  readonly item: TermInterface
  readonly store: SettingTermsStore
  readonly tags: Array<TagInterface>
  readonly refetchTag: Function
  readonly Popin: JSX.Element | null
  readonly setPopin: Function
}

export default function SettingTermsActionsCellView({item, store,tags ,refetchTag, Popin, setPopin}:Props) {

  // pass <></> because ShortMenuLlist clone element
  return <>
    {Popin && <FullscreenModal onClose={()=>setPopin(null)}>{Popin}</FullscreenModal>}
    <ShortMenuModale>
      <ShortMenuList>
        {item.state !== 'enabled' ? <ShortMenuAction
          icon={pictos.checkCircle}
          text={'activate'}
          onClick={() => store.updateEnabled([item], 'enabled')}
        />:<></>}
        {item.state !== 'disabled' ? <ShortMenuAction
          icon={pictos.timesCircle}
          text={'desactivate'}
          onClick={() => store.updateEnabled([item], 'disabled')}
        />:<></>}
        {item.state !== 'archived' ? <ShortMenuAction
          icon={pictos.archive}
          text={'archive'}
          onClick={() => store.updateEnabled([item], 'archived')}
        />:<></>}
      </ShortMenuList>
      <ShortMenuList separator>
        <ShortMenuAction
          icon={pictos.copyright}
          text={'update brand and type'}
          onClick={() => store.setModaleUpdateBrand(item)}
        />
        <ShortMenuAction
          icon={pictos.tag}
          text={'Manage tags'}
          onClick={() => setPopin(<TermTagsUpdate term={item} scope="term" tags={tags} onClose={()=>store.handleFetch()} refetchTag={() => refetchTag()}/>)}
        />
        <ShortMenuAction
          icon={pictos.calendarClock}
          text={'Modify frequency'}
          onClick={() => setPopin(<TermFrequencyUpdate term={item} onClose={()=>store.handleFetch()}/>)}
        />
        <ShortMenuAction
          icon={pictos.building}
          text={'Modify customer access'}
          onClick={() => setPopin(<TermCompanyAccountEdit term={item} onClose={()=>store.handleFetch()}/>)}
        />
        <ShortMenuAction
          icon={pictos.collecte}
          text={'Modify options'}
          onClick={() => setPopin(<TermOptionsUpdate term={item} onClose={()=>store.handleFetch()}/>)}
        />
      </ShortMenuList>
    </ShortMenuModale>
  </>
  ;
}