import classes from './UserActionsCell.module.css';
import PropTypes from 'prop-types';
import userDefinition from '../../../proptypes/user.definition';
import UsersStore from '../../../pages/Users/users.store';
import { Link } from 'react-router-dom';
import defaultAvatar from '../../../assets/img/default.png';
import getAvatar from '../../../Utils/getAvatar';

UserAvatarCell.propTypes = {
  item: PropTypes.shape(userDefinition),
  store: PropTypes.instanceOf(UsersStore),
};

export default function UserAvatarCell({ item, store }) {

  return (
    <div className={classes.container}>
      <Link to={'/users/'+ item.id}>
        <img src={item.avatarId ? getAvatar(item.avatarId): defaultAvatar}  alt={item.firstName} width='30' height='30'/>
      </Link>
    </div>
  );
}
