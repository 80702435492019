export const UserFormInitialState = {
  companyAccount: [],
  roles: [],
  isLoading: false,
  avatar: undefined,
};

export function UserFormReducer(state, action) {
  return UserFormActions[action.type]({...state},action.payload);
}

export const UserFormActionsType = {
  setIsLoading: 'setIsLoading',
  setCompanyAccount: 'setCompanyAccount',
  setRoles: 'setRoles',
  setAvatar: 'setAvatar'
};

const UserFormActions = {
  setIsLoading: (state, payload)=> {
    state.isLoading = payload;
    return state;
  },
  setCompanyAccount: (state, payload)=> {
    state.companyAccount = payload.value;
    return state;
  },
  setRoles: (state, payload)=> {
    state.roles = payload.map(o=>o.value);
    return state;
  },
  setAvatar: (state, payload)=> {
    state.avatar = payload;
    return state;
  }
};