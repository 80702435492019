import { useState, ReactNode, forwardRef } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

interface DynamicScrollbarProps {
  className?: string;
  contentClassName?: string;
  children: ReactNode;
  as?: string;
  asContent?: string;
  handleHover?: boolean;
  noScrollY?: boolean;
}


export default forwardRef(function DynamicScrollbar(
  {
    className = '',
    contentClassName = '',
    children,
    as,
    asContent,
    handleHover = true,
    noScrollY,
    ...props
  }: DynamicScrollbarProps,
  ref: any
) {
  const [scrollbarsDisplayed, setScrollbarsDisplayed] = useState(!handleHover);
  const WrappedTag = as ? as : 'div';
  const WrappedContentTag = asContent ? asContent : 'div';

  return (
    <Scrollbar
      ref={ref}
      className={`${className} ${!scrollbarsDisplayed ? 'hidden' : ''}`}
      noScrollY={noScrollY}
      renderer={(props: any) => {
        const { elementRef, className, ...restProps } = props;
        return (
          <WrappedTag
            {...restProps}
            ref={elementRef}
            className={className}
            onMouseLeave={() => {
              handleHover && setScrollbarsDisplayed(false);
            }}
            onMouseEnter={() => {
              handleHover && setScrollbarsDisplayed(true);
            }}
          />
        );
      }}
      scrollerProps={{
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef} />;
        },
      }}
      contentProps={{
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return (
            <WrappedContentTag
              {...restProps}
              className={`ScrollbarsCustom-Content ${contentClassName}`}
              ref={elementRef}
            />
          );
        },
      }}
      {...props}
    >
      {children}
    </Scrollbar>
  );
});
