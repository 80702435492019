import PropTypes from 'prop-types';
import { CategoryApi } from './../../../swagger_client';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEffect,useState } from 'react';

TagDeleteModal.propTypes = {
  update: PropTypes.func.isRequired,
  tagToDelete: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default function TagDeleteModal({ update, setIsOpen, tagToDelete }) {
  const { t } = useTranslation(),
    [isLoading,setIsLoading] = useState(false),
    [done,setDone]= useState(false),
    categoryApi = new CategoryApi();

  async function handleDelete() {
    setIsLoading(true);
    try {
      update(await categoryApi.categoryDeleteById(tagToDelete.id));
      toast(t('tag deleted'));
      
    } catch (e) {
      toast.error(t(e));
    }
    setDone(true);
    setIsLoading(false);
  }

  useEffect(()=>{
    if(done){
      setIsOpen();
    }
  // eslint-disable-next-line
  },[done])

  return (
    <ConfirmModal
      message={`${t('Are you sure to delete the tag')} ${tagToDelete.label} ?`}
      setReset={() => setIsOpen()}
      setSubmit={handleDelete}
      isLoading={isLoading}
    />
  );
}
