import { useContext } from 'react';
import AsyncCreateFilterView from './AsyncCreateFilterView';
import useAsyncCreateFilter from './newUseAsyncCreateFilter';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

interface Props {
  readonly fetcher: Function;
  readonly name: string;
  readonly options: any;
  readonly count?: number;
  readonly label: string;
  readonly titleMode?: boolean;
  readonly valueMapper?: (value: any) => any;
}

export default function AsyncCreateFilter(props: Props) {
  const { filters, setFilter } = useContext(PanelFilterContext);

  const useProps = useAsyncCreateFilter({ ...props, filters, setFilter }),
    passProps = {
      ...useProps,
      count: props?.count,
      name: props.name,
      label: props.label,
      titleMode: props.titleMode,
    };

  return <AsyncCreateFilterView {...passProps} />;
}
