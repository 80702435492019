import PropTypes from 'prop-types';

const fileDefinition = {
  id: PropTypes.string,
  name: PropTypes.string,
  mimeType: PropTypes.string,
  path: PropTypes.string,
  modified: PropTypes.string
};

export default fileDefinition;