import _ from 'lodash';
import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';

SettingTermsBrandCell.propTypes = {
  item: PropTypes.shape(termDefinition)
};

export default function SettingTermsBrandCell({item}){
  return <p>{_.get(item,'brand.label','')}</p>;
}
