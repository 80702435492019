import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import FullscreenModal from '../../components/FullscreenModal/FullscreenModal';
import classes from './FullConversationsModal.module.css';
import MagicTable from '../../components/MagicTable/MagicTable';
import EngineCell from '../../components/MagicTable/EngineCell/EngineCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StatusCell from '../../components/MagicTable/StatusCell/StatusCell';
import LocalizationFlagCell from '../../components/MagicTable/LocalizationFlagCell/LocalizationFlagCell';
import ExchangesCell from './ConversationExchangeCell';
import BrandCell from './ConversationBrandCell';
import CollapsableSearchInput from '../../components/_ui/CollapsableSearchInput/CollapsableSearchInput';
import ConversationsStore from './conversations.store';
import { Action } from '../../types/action.newBackend';

interface FullConversationsModalProps {
  action: Action;
  onClose: () => void;
  searchTerm: string;
}

export default function FullConversationsModal({
  action,
  searchTerm,
  onClose,
}: FullConversationsModalProps) {
  const { t } = useTranslation();

  const { authStore } = useContext(StoreContext);
  const [conversationSearchTerm, setConversationSearchTerm] = useState(
    searchTerm || ''
  );

  const { currentUser } = authStore;
  const [conversationsStore] = useState(() => {
    const store = new ConversationsStore(
      currentUser?.id,
      authStore.isAdmin,
      action.advertiserID,
      authStore.token
    );

    store.handleFetch();
    return store;
  });

  useEffect(() => {
    conversationsStore.setSearchTerm(conversationSearchTerm);
  }, [conversationSearchTerm, conversationsStore]);

  const onOpenConversation = (row: any) => {
    const { origin } = window.location;
    const url = `${origin}/action/${row.ID}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <FullscreenModal onClose={onClose} fullHeight>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>{t('Other conversations with this advertiser')}</div>
          <div className={classes.search}>
            <CollapsableSearchInput
              searchTerm={conversationSearchTerm}
              onChangeSearch={setConversationSearchTerm}
            />
          </div>
        </div>
        <div className={classes.conversations}>
          <MagicTable
            store={conversationsStore}
            checkedField='ID'
            showInputSearch={false}
            simpleHeader
            clickOnRow
            onOpenRow={onOpenConversation}
          >
            <EngineCell field='engine' />
            <BrandCell field='domain' />
            <LocalizationFlagCell field='country' />
            <DateCell field='lastStatusDate' displayTime />
            <StatusCell field='status' />
            <ExchangesCell
              field='exchanges'
              messagesField='receivedCount'
              sentMessagesField='sendedCount'
            />
          </MagicTable>
        </div>
      </div>
    </FullscreenModal>
  );
}
