import { useTranslation } from 'react-i18next';
import { hourInMs } from '../../Utils/hourInMs';

/**
 * 
 * @description hook for use translate in label.
 */
export function useFrequencyOptions(){
  const { t } = useTranslation();
  return [
    {
      label:'auto',
      value: 0,
    },
    {
      label: `${t('everyes')} ${t('hour')}s`,
      value: hourInMs(1),
    },
    {
      label: `${t('everyes')} 2 ${t('hour')}s`,
      value: hourInMs(2),
    },
    {
      label: `${t('everyes')} 6 ${t('hour')}s`,
      value: hourInMs(6),
    },
    {
      label: `${t('everyes')} 12 ${t('hour')}s`,
      value: hourInMs(12),
    },
    {
      label: `${t('everys')} ${t('day')}s`,
      value: hourInMs(24),
    },
    {
      label: `${t('everys')} 3 ${t('day')}s`,
      value: hourInMs(24 * 3),
    },
    {
      label: `${t('everyes')} ${t('week')}s`,
      value: hourInMs(24 * 7),
    },
    {
      label: `${t('everys')} ${t('month')}`,
      value: hourInMs(24 * 30.5),
    },
  ]; 
}