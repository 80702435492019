/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The TermCompanyAccount model module.
* @module model/TermCompanyAccount
* @version 4.0.0
*/
export default class TermCompanyAccount {
    /**
    * Constructs a new <code>TermCompanyAccount</code>.
    * @alias module:model/TermCompanyAccount
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>TermCompanyAccount</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/TermCompanyAccount} obj Optional instance to populate.
    * @return {module:model/TermCompanyAccount} The populated <code>TermCompanyAccount</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TermCompanyAccount();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('termId')) {
                obj['termId'] = ObjectID.constructFromObject(data['termId']);
            }
            if (data.hasOwnProperty('companyAccountId')) {
                obj['companyAccountId'] = ObjectID.constructFromObject(data['companyAccountId']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {module:model/ObjectID} termId
    */
    termId = undefined;
    /**
    * @member {module:model/ObjectID} companyAccountId
    */
    companyAccountId = undefined;




}
