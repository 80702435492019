import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import classes from './NotFound.module.css';

export default function NotFound() {
  const {t} = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.cell}>4</div>
      <div className={classes.cell}>
        <Loader />
      </div>
      <div className={classes.cell}>4</div>
      <div className={classes.txt}>{`This page doesn't exist.`}</div>
      <div className={classes.txt}>
        <NavLink to="/" className={`button ${classes.link}`}>
          {t('Return to home page')}
        </NavLink>
      </div>
    </div>
  );
}
