import { SyntheticEvent, useMemo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useDateFilterSelectDates from './useDateFilterSelectDates';
import classes from './DateFilterSelectDates.module.css';
import { DateState } from '../../../../types/dateState.interface';
import dateLabel from './dateLabel';
import { dateSelect } from '../DateFilterResume/date.select';

interface Props {
  readonly dateState:DateState
  readonly filters:any
  readonly name:string
  readonly setFilter:Function
  readonly setDateState:Function
  readonly setToggle: Function
}

export default function DateFilterSelectDates(props:Props) {
  const { handleSubmit, t } = useDateFilterSelectDates(props);
  const [needClear, setNeedClear] = useState(false);

  const options = useMemo(() => {
    return dateSelect.map(choice => ({
      label: t(choice.period),
      value: {
        startDate: choice.start,
        endDate: choice.end,
        key: 'selection',
      }
    }));
  }, [ t ]);

  const selectedOption = useMemo(() => {
    if (props.filters && props.name && !props.filters[props.name]) {
      console.log('needClear');
      setNeedClear(true);
      return null;
    }
    // setNeedClear(false);
    return props.dateState && props.dateState.length > 0 && props.dateState[0] && props.dateState[0].startDate && props.dateState[0].endDate ?
      options.find(ds =>
        dayjs(ds.value.startDate).isSame(props.dateState[0].startDate, 'day') &&
        dayjs(ds.value.endDate).isSame(props.dateState[0].endDate, 'day')
      ) || null :
      null;
  }, [ options, props.dateState, props.filters, props.name ]);

  const clearDate = (evt: SyntheticEvent) => {
    evt.stopPropagation();
    props.setDateState([]);
    props.setFilter(props.name, null);
  };

  // useEffect(() => {
  // 
  // }, [props.filters, props.name]);

  useEffect(() => {
    if (needClear) {
      props.setDateState([]);
    }
    // setNeedClear(false);
  }, [needClear, props.filters]); // eslint-disable-line

  return (
    <>
      <div className={classes.selectorContainer}>
        <div className={classes.selectorLabel}>{t('Calendar')}</div>
        <div
          className={`${classes.selector} ${props.dateState && props.dateState[0] && props.dateState.length > 0 && !selectedOption ? classes.selectOptionSelected : ''}`}
          onClick={() => handleSubmit()}
        >
          <div className={classes.valueContainer}>
            { props.dateState && props.dateState[0] && props.dateState.length > 0 && !selectedOption ? (
              dateLabel(props.dateState[0], t)
            ) : (
              <div className={classes.placeholder}>{t('Select dates')}</div>
            )}
          </div>
          <div className={classes.indicatorContainer} aria-hidden="true" onClick={clearDate}>
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={classes.indicatorSvg}>
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
            </svg>
          </div>
          <div className={classes.indicatorsContainer}>
            <span className={classes.indicatorSeparator}></span>
            <div className={classes.indicatorContainer} aria-hidden="true">
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className={classes.indicatorSvg}>
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      { /* <SubmitButton
        txt={t('Select dates')}
        onClick={() => handleSubmit()}
        isLoading={false}
      /> */ }
    </>
  );
}
