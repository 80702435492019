import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../stores/page.store';
import { CompanyAccountApi } from '../../swagger_client/src';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

export default class CompanyAccountStore extends PageStore {
  companyAccountApi = new CompanyAccountApi();
  totalViews = 0;
  selectedItem = null;
  isLoadingAction = false;
  companyAccountToUpdate = null;
  adsCount = 0;
  tableHeader = [
    { name: 'name', title: 'company account', sortable: true, center: true },
    { name: 'actions', title: '', center: true },
  ];
  direction = 'desc';
  order = 'totalViews';
  constructor(defaultFilters = {}, user) {
    super(user);
    makeObservable(this, {
      totalViews: observable,
      adsCount: observable,
      selectedItem: observable,
      isLoadingAction: observable,
      companyAccountToUpdate: observable,
      addCompanyAccount: action,
      deleteCompanyAccount: action,
      updateOneCompanyAccount: action,
    });

    this.defaultFilters = defaultFilters;
    if (!this.filters) this.filters = {};
  }

  setSelectedItem(value) {
    this.selectedItem = value;
  }
  async handleCount() {
    this.isLoadingCount = true;
    try {
      //le count n'est pas dispo directement, actionCount renvoi un objet
      const { count } = await this.companyAccountApi.companyAccountCount({
        //passer les filtres pour avoir le bon count
        filter: JSON.stringify({ where: this.computedFilters }),
      });
      this.count = count;
    } catch (e) {
      console.error(e);
    }

    this.isLoadingCount = false;
  }

  async handleFetch() {
    this.isLoading = true;
    try {
      this.items = await this.companyAccountApi.companyAccountFind({
        filter: JSON.stringify({
          where: this.computedFilters,
          order: `${this.order} ${this.direction.toUpperCase()}`,
          include: ['brands'],
          skip: (this.page - 1) * this.perPage,
          limit: this.perPage,
        }),
      });
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }
    this.initPage = true;
    this.isLoading = false;
  }

  get counters() {
    return [{ txt: 'companies accounts', count: this.count }];
  }

  async updateField(fieldName, fieldValue, id) {
    if (id) {
      this.selectedItems.push(id);
    }
    const body =
      fieldName.substring(0, 9) === 'reminder.'
        ? { reminder: { [fieldName.substring(9)]: fieldValue } }
        : { [fieldName]: fieldValue };

    await this.companyAccountApi.companyAccountUpdateAll({
      where: JSON.stringify({
        id: { inq: this.selectedItems },
      }),
      body,
    });

    if (this.selectedItems.length === 1) {
      const search = (it) => it.id === this.selectedItems[0],
        index = this.items.findIndex(search),
        item = this.items.find(search);

      if (fieldName.substring(0, 9) === 'reminder.') {
        item.reminder[fieldName.substring(9)] = fieldValue;
      } else {
        item[fieldName] = fieldValue;
      }
      this.items.splice(index, 1, { ...item });
    } else {
      this.handleFetch();
    }

    this.selectedItems = [];
  }

  filterSearch(item) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

  updateOneCompanyAccount(companyAccount) {
    this.companyAccountToUpdate = companyAccount;
  }

  addCompanyAccount(companyAccount) {
    this.items.push(companyAccount);
  }

  async deleteCompanyAccount(idToDelete) {
    this.isLoadingAction = true;
    try {
      await this.companyAccountApi.companyAccountDeleteById(idToDelete);
      this.items = _.filter(this.items, (o) => o.id !== idToDelete);
      this.selectedItem = null;
      toast.success(i18next.t('company account deleted'));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.isLoadingAction = false;
  }
}
