import { DateState } from '../../../../types/dateState.interface';
import DateFilterPopinView from './DateFilterPopinView';
import useDateFilterPopin from './useDateFilterPopin';

interface Props {
  readonly name: string
  readonly filters: any
  readonly dateMin: Date
  readonly label: string
  readonly setFilter: Function
  readonly handleConfirm: Function
  readonly setDateState: Function
  readonly setToggle: Function
  readonly locale: any
  readonly toggle: boolean
  readonly dateState: DateState
}


export default function DateFilterPopin(props:Props){
  const 
    useProps = useDateFilterPopin(props),
    passProps = {...useProps,...props};

  return <DateFilterPopinView {...passProps} />;
}