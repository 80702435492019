import PropTypes from 'prop-types';
import ArrowRight from './../../assets/icons/arrow-right.svg';
import classes from './PublicSubmitButton.module.css';

PublicSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.string,
};

export default function PublicSubmitButton({ children, disabled }) {
  return (
    <button className={classes.button} disabled={disabled}>
      <div className={classes.border}>
        <img className={classes.arrow} src={ArrowRight} alt="fleche" />
      </div>
      {children}
    </button>
  );
}