import { BrandInterface } from './brand.interface';
import { DealerInterface } from './dealer.interface';
import { LocalizationInterface } from './localization.interface';

export enum Status {
  new= 'new',
  reappearance= 'reappearance',
  ignored= 'ignored',
  accepted= 'accepted',
  refused= 'refused',
  disappeared= 'disappeared',
  requested= 'requested',
  inprogress= 'inprogress',
  reminded= 'reminded',
  blocked= 'blocked',
};

export interface ActionInterface {
  readonly id: string
  readonly category: string
  readonly type: string
  readonly merchantId:string
  readonly note: string
  readonly master: string
  readonly provider: string
  readonly url: string
  readonly unreadEmail: number
  readonly readEmail: number
  readonly selected: boolean
  readonly status: Status
  readonly countries: Array<LocalizationInterface>
  readonly country: LocalizationInterface
  readonly dealer: DealerInterface
  readonly brandRel: BrandInterface
  readonly createdDate: Date
  readonly updatedDate: Date
  readonly requestedDate: Date
  readonly refusedDate: Date
  readonly acceptedDate: Date
  readonly inprogressDate: Date
  readonly ignoredDate: Date
  readonly remindedDate: Date
  readonly blockedDate: Date
  readonly reappearanceDate: Date
  readonly waiting: boolean
}
