import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as rdrLocales from 'react-date-range-ts/dist/locale';
import { useTranslation } from 'react-i18next';
import dateLabel from './DateFilterResume/dateLabel';
import Calendar from 'dayjs/plugin/calendar';

dayjs.extend(Calendar);

interface props {
  readonly filters: any;
  readonly setFilter: Function;
  readonly name: string;
  readonly label: string;
}

export default function useDateFilter({
  filters,
  setFilter,
  name,
  label,
}: props) {
  const { t, i18n } = useTranslation(),
    locale: any =
      i18n.language.slice(0, 2) === 'fr' ? rdrLocales.fr : rdrLocales.enUS,
    [toggle, setToggle] = useState(false),
    [dateState, setDateState] = useState([
      {
        startDate: dayjs(
          filters?.[name]?.whereClause?.between[0] || new Date()
        ).toDate(),
        endDate: dayjs(
          filters?.[name]?.whereClause?.between[1] || new Date()
        ).toDate(),
        key: 'selection',
      },
    ]);

  async function handleConfirm() {
    const { startDate, endDate } = dateState[0];
    setFilter(name, {
      label,
      whereClause: {
        between: [
          dayjs(startDate).format('YYYY-MM-DDT00:00:00'),
          dayjs(endDate).format('YYYY-MM-DDT23:59:59'),
        ],
      },
      description: t(dateLabel({ startDate, endDate }, t, true)),
    });
    setToggle(false);
  }

  useEffect(() => {
    if (
      filters &&
      filters[name] &&
      new Date(filters[name]?.whereClause?.between[0])
    ) {
      setDateState([
        {
          startDate: new Date(filters[name]?.whereClause?.between[0]),
          endDate: new Date(filters[name]?.whereClause?.between[1]),
          key: 'selection',
        },
      ]);
    }
    // eslint-disable-next-line
  }, [filters]);

  return {
    handleConfirm,
    setDateState,
    setToggle,
    dateState,
    t,
    i18n,
    toggle,
    locale,
  };
}
