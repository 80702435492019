import { makeObservable, observable } from 'mobx';
import PageStore from '../../stores/page.store';
import Backend from '../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

export default class StopGoStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem = null;
  tableHeader = [
    {
      name: 'brandLabel',
      title: 'brand',
      sortable: true,
      center: true,
    },
    {
      name: 'customerID',
      title: 'Google ads customer',
      sortable: true,
      center: true,
    },

    {
      name: 'loginCustomerID',
      title: 'login Customer ID',
      sortable: true,
      center: true,
    },
    {
      name: 'negativeSharedSetID',
      title: 'negative Liste',
      sortable: true,
      center: true,
    },
    {
      name: 'campaignIDs',
      title: 'CPC campaign ids',
      sortable: true,
      center: true,
    },
    {
      name: 'createdDate',
      title: 'creation date',
      sortable: true,
      center: true,
    },
    { name: 'actions', title: '', center: true },
  ];
  direction = 'desc';
  order = 'name';
  token = '';

  constructor(userId: string, token: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() {}

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;

    try {
      const result = await Backend.loadGoogleConnect(this.token);
      this.count = result.length;
      this.setItems(result);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'google connect', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }
}
