import classes from './Tooltip.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { useRef, ReactNode } from 'react';

export function getPlacementClassName(placement: string) {
  switch (placement) {
  case 'bottom-right':
    return classes.bottomRight;
  default:
    return '';
  }
}

export function getIntentClassName(intent: string) {
  switch (intent) {
  case 'success':
    return classes.intentSuccess;
  default:
    return '';
  }
}

interface ManagedTooltipProps {
  children: ReactNode
  content: ReactNode | string;
  direction?: 'top' | 'right' | 'left' |'bottom';
  placement?: 'bottom' | 'bottom-right';
  intent?: 'default' | 'success';
  arrow?: boolean;
  active: boolean;
  displayMode?: 'default' | 'flex';
}

export default function ManagedTooltip({
  children,
  content,
  direction = 'top',
  placement = 'bottom',
  intent = 'default',
  arrow,
  active,
  displayMode = 'default'
}: ManagedTooltipProps){
  const ref = useRef(null),
    placementClassName = getPlacementClassName(placement),
    intentClassName = getIntentClassName(intent);

  return (
    <div
      className={`${classes.container} ${displayMode === 'flex' ? classes.flex : ''}`}
      data-testid='tooltip'
    >
      {children || <FontAwesomeIcon icon={faInfoCircle} className={classes.info} />}
      <CSSTransition
        nodeRef={ref}
        in={active}
        timeout={200}
        unmountOnExit
        mountOnEnter
        classNames="tooltip"
      >
        <div
          className={`${classes.tip} ${placementClassName}`}
          data-direction={direction}
          data-arrow={arrow}
        >
          <div className={`${classes.box} ${intentClassName}`} ref={ref}>
            {content}
          </div>
        </div>

      </CSSTransition>
    </div>
  );
}
