import { SVGProps } from 'react';
import classes from './CloseIcon.module.css';

export default function CloseIcon({...props}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Close_popin"
      data-name="Close popin"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={classes.root}
      {...props}
    >
      <path
        id="Icon_ionic-md-close"
        data-name="Icon ionic-md-close"
        d="M25.523,9.323l-1.8-1.8-7.2,7.2-7.2-7.2-1.8,1.8,7.2,7.2-7.2,7.2,1.8,1.8,7.2-7.2,7.2,7.2,1.8-1.8-7.2-7.2Z"
        transform="translate(-7.523 -7.523)"
      />
    </svg>
  );
}
