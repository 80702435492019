import { makeAutoObservable, observable } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { ApiClient, BrandApi, UserAccountApi } from '../swagger_client/src';
import {
  eraseCookie,
  getCookie,
  setCookie,
} from '../Utils/getSetCookie/getsetCookie';
const ACCES_TOKEN = 'access_token',
  ADMIN_ACCES_TOKEN = 'admin_access_token',
  userAccountApi = new UserAccountApi(),
  brandApi = new BrandApi();

export type User = {
  id: string;
  roles: { name: string }[];
};

export default class AuthStore {
  currentUser: User | null = null;
  isLoading = true;
  signinInProgress = false;
  token = '';
  adminToken = '';
  resetInProgress = false;
  forgotError: any;
  isAdmin = false;
  currentBrands = null;
  brands = [];
  brandsIsReady = false;
  flagsmith: any = null;
  loadingUser: boolean = false;
  recoverErrors: any[] | undefined = undefined;
  recoverInProgress = false;

  constructor(_other: any, flagsmith: any) {
    makeAutoObservable(this, { currentUser: observable.struct });
    this.adminToken = getCookie(ADMIN_ACCES_TOKEN) || '';
    this.flagsmith = flagsmith;
  }

  async login(credentials: any) {
    this.setSigninInProgress(true);

    try {
      const response = await userAccountApi.userAccountLogin(credentials, {});
      setCookie(ACCES_TOKEN, response.id, 15);
      await this.pullUser(response.id);
    } catch (error) {
      //@ts-expect-error
      toast.error(i18next.t(error.message));
    }

    this.setSigninInProgress(false);
  }

  async pullUser(token: string) {
    const cookieConnection = token || getCookie(ACCES_TOKEN);

    if (cookieConnection) {
      this.setLoadingUser(true);
      this.setToken(cookieConnection);
      ApiClient.instance.defaultHeaders.Authorization =
        token || cookieConnection;
    }

    try {
      const user = await userAccountApi.userAccountFindById('me', {
        filter: JSON.stringify({
          include: [
            'roles',
            {
              companyAccount: 'brands',
            },
          ],
        }),
      });

      this.setCurrentUser(user);
      if (this.flagsmith) {
        await this.flagsmith.identify(user.id);
      }
      this.setIsAdmin(
        !!this.currentUser?.roles.find((role) => role.name === 'admin')
      );
    } catch (error) {
      if (cookieConnection) {
        return toast.warn(
          // @ts-expect-error
          error.status === 401 ? 'session expired ' : error.status
        );
      }
    }

    this.setLoadingUser(false);
    return this.currentUser;
  }

  async logout() {
    await userAccountApi.userAccountLogout();
    eraseCookie(ACCES_TOKEN);
    eraseCookie(ADMIN_ACCES_TOKEN);
    this.adminToken = '';
    this.token = '';
    this.currentUser = null;
  }

  setProfile(value: any) {
    this.currentUser = value;
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
  }

  async fetchBrands() {
    await brandApi.brandFind({});
  }

  setIsAdmin(value: boolean) {
    this.isAdmin = value;
  }

  setCurrentBrands(brands: any) {
    this.currentBrands = brands;
  }

  setToken(token: string) {
    this.token = token;
  }

  setAdminToken(token: string) {
    this.adminToken = token;
  }

  setLoadingUser(value: boolean) {
    this.loadingUser = value;
  }

  setSigninInProgress(value: boolean) {
    this.signinInProgress = value;
  }

  async recoverPassword({ email }: { email: string }) {
    this.recoverErrors = undefined;

    try {
      await userAccountApi.userAccountResetPassword({ email });
      this.recoverInProgress = false;
      toast(
        // @ts-expect-error
        i18next.t(
          'An email allowing you to update your password has been sent to you!'
        )
      );
      return true;
    } catch (error) {
      this.recoverErrors = [error];
      this.recoverInProgress = false;
      // @ts-expect-error
      const parsedError = JSON.parse(error.message);
      // @ts-expect-error
      toast.error(i18next.t(parsedError.error.message));
      return false;
    }
  }

  async resetPassword({
    token,
    password,
  }: {
    token: string;
    password: string;
  }) {
    this.resetInProgress = true;

    try {
      userAccountApi.apiClient.defaultHeaders.Authorization = token;
      await userAccountApi.userAccountSetPassword({
        newPassword: password,
      });
      const cookieConnection = getCookie(ACCES_TOKEN);
      if (cookieConnection) {
        this.loadingUser = true;
        ApiClient.instance.defaultHeaders.Authorization = cookieConnection;
      }
      // @ts-expect-error
      toast(i18next.t('Your password has been reset!'));
      return true;
    } catch (error) {
      // @ts-expect-error
      toast.error(i18next.t(error.message));
      this.resetInProgress = false;
      return false;
    }
  }

  async switchUser(id: string | null) {
    this.setAdminToken(this.token);
    setCookie(ADMIN_ACCES_TOKEN, this.token, 15);
    const res = await userAccountApi.userAccountPrototypeCreateAccessTokens(
      id,
      {
        body: {
          ttl: 120000,
        },
      }
    );
    this.setToken(res.id);
    ApiClient.instance.defaultHeaders.Authorization = this.token;
    await this.pullUser(this.token);
    setCookie(ACCES_TOKEN, res.id, 15);
  }

  async recoverMyProfile() {
    ApiClient.instance.defaultHeaders.Authorization = this.adminToken;
    setCookie(ACCES_TOKEN, this.adminToken, 15);
    this.setAdminToken('');
    this.setToken(this.adminToken);
    eraseCookie(ADMIN_ACCES_TOKEN);
    await this.pullUser(this.token);
    localStorage.clear();
    window.location.reload();
  }
}
