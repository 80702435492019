import { useContext, useEffect } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { routes } from './routes';
import { StoreContext } from '../stores';
import RouterSwitch from './RouterSwitch';
import NotFoundRedirect from '../components/NotFoundRedirect/NotFoundRedirect';
import createAuthMiddleware from './Middlewares/auth.middleware';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'flagsmith/react';
import dayjs from 'dayjs';

const createFlagMiddleware = (flags, routeFlag) => {
  return async (_to, _from, next) => {
    if (flags?.[routeFlag]?.enabled === true) {
      return next();
    }

    return next.redirect('/');
  };
};

/* eslint-disable react/prop-types */
const Route = ({ uiStore, authStore, Page, path, guard, guards, isAdmin, flag}) => {
  const flags = useFlags([flag || '']);

  if (flag && uiStore.appLoaded) {
    const flagMiddleWare = createFlagMiddleware(flags, flag);
    guards.push(flagMiddleWare);
  }

  return (
    <GuardedRoute
      exact
      path={path}
      guards={guards}
      meta={{ authStore, guard, uiStore, isAdmin }}
    >
      <Page />
    </GuardedRoute>
  );
};

function Router() {
  const { authStore, uiStore } = useContext(StoreContext),
    { i18n } = useTranslation(),
    authMiddleware = createAuthMiddleware();
  useEffect(() => {
    dayjs.locale(i18n.language.slice(0, 2));

    dayjs.updateLocale(i18n.language.slice(0, 2), {
      name: i18n.language.slice(0, 2),
      weekStart: 1,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <RouterSwitch>
      <GuardProvider>
        <Switch>
          {routes.map(({Page, path, guard, isAdmin, flag}) => {
            const guards = [authMiddleware];
            return (
              <Route
                key={path}
                Page={Page}
                path={path}
                guard={guard}
                isAdmin={isAdmin}
                guards={guards}
                flag={flag}
                authStore={authStore}
                uiStore={uiStore}
              />
            );
          })}
          <GuardedRoute component={NotFoundRedirect} />
        </Switch>
      </GuardProvider>
    </RouterSwitch>
  );
}

export default withRouter(observer(Router));
