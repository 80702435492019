import { useState, useMemo } from 'react';
import classes from './History.module.css';
import { useTranslation } from 'react-i18next';
import FullHistoryModal from './FullHistoryModal';
import ActionHistoryItem from './HistoryItem';
import {
  History as HistoryInterface,
  GroupedHistory,
  computeHistory,
  isBeforeReappearance,
} from '../../types/action.newBackend';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';

interface Props {
  history: HistoryInterface[];
  waiting: boolean;
}

export default function History({ history, waiting }: Props) {
  const { t } = useTranslation(),
    [modalOpened, setModalOpened] = useState(false);

  const computedHistory = useMemo(() => {
    return computeHistory(history, false);
    // return action.history;
  }, [history]);

  const computeFullHistory = useMemo(() => {
    return computeHistory(history, true);
    // return action.history;
  }, [history]);


  const onShowFullHistoryModal = () => {
    setModalOpened(true);
  };

  const onCloseFullHistoryModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      {modalOpened && (
        <FullHistoryModal
          history={computeFullHistory}
          waiting={waiting}
          onClose={onCloseFullHistoryModal}
        />
      )}
      <div className={classes.container}>
        <div className={classes.body}>
          <DynamicScrollbar>
            <div className={classes.history}>
              {computedHistory.map((item: GroupedHistory, index: number) => (
                <ActionHistoryItem
                  key={item.ID}
                  {...item}
                  last={index === computedHistory.length - 1}
                  beforeReappearance={isBeforeReappearance(computedHistory, index)}
                  waiting={waiting}
                />
              ))}
            </div>
          </DynamicScrollbar>
        </div>
        <div className={classes.footer}>
          <button
            className={classes.historyButton}
            onClick={onShowFullHistoryModal}
          >
            {t('See all history')}
          </button>
        </div>
      </div>
    </>
  );
}
