import { makeAutoObservable } from 'mobx';

export default class CacheStore {
  cachedItems: { [key: string]: any } = {};

  constructor() {
    makeAutoObservable(this);
  }

  save(key: string, data: any) {
    this.cachedItems[key] = JSON.stringify(data);
  }

  get(key: string) {
    const data = this.cachedItems[key];
    if (!data) {
      return data;
    }
    return JSON.parse(data);
  }

  clear(key: string) {
    delete this.cachedItems[key];
  }
}
