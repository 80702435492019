import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Backend from '../../../stores/newBackend';
import { Action } from '../../../types/action.newBackend';
import { useActionNote } from '../../../stores/actionNoteContext';

export default function ActionInprogressModal({
  actionID,
  setIsOpen,
  onUpdate,
  token,
}: {
  actionID: string;
  setIsOpen: () => void;
  onUpdate: (action: Action) => void;
  token: string;
}) {
  const { t } = useTranslation(),
    [isLoading, setIsLoading] = useState(false),
    [done, setDone] = useState(false);

  const { updateCacheKey } = useActionNote();

  async function handleMarkAsInprogress(comments: any) {
    setIsLoading(true);
    try {
      const action = await Backend.markAsInprogress(
        token,
        actionID,
        comments,
        true
      );
      onUpdate(action);
      updateCacheKey();
      toast.success(t('Le statut de l’annonceur est passé en notifié.'));
    } catch (e: any) {
      toast.error(t(e.toString()));
    }
    setDone(true);
    setIsLoading(false);
  }

  useEffect(() => {
    if (done) {
      setIsOpen();
    }
  }, [done, setIsOpen]);

  return (
    <ConfirmModal
      title={`${t('Mark this advertiser as Notified')}`}
      message={`${t('Add a comment and mark this advertiser as Notified.')}`}
      confirmMessage={`${t('Mark as notified')}`}
      setReset={() => setIsOpen()}
      setSubmit={handleMarkAsInprogress}
      isLoading={isLoading}
      commentArea={true}
    />
  );
}
