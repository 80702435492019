/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Role model module.
* @module model/Role
* @version 4.0.0
*/
export default class Role {
    /**
    * Constructs a new <code>Role</code>.
    * @alias module:model/Role
    * @class
    * @param name {String} 
    */

    constructor(name) {
        
        
        this['name'] = name;
        
    }

    /**
    * Constructs a <code>Role</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Role} obj Optional instance to populate.
    * @return {module:model/Role} The populated <code>Role</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Role();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('modified')) {
                obj['modified'] = ApiClient.convertToType(data['modified'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {Date} created
    */
    created = undefined;
    /**
    * @member {Date} modified
    */
    modified = undefined;




}
