import dayjs from 'dayjs';
export const dateSelect =  [
  {
    period: 'today',
    start: dayjs().toDate(),
    end: dayjs().toDate()
  },
  {
    period: 'yesterday',
    start: dayjs().subtract(1,'days').toDate(),
    end: dayjs().subtract(1,'days').toDate()
  },
  {
    period: 'last 30 Days',
    start: dayjs().subtract(30,'days').toDate(),
    end:  dayjs().toDate()
  },
  {
    period: 'last 7 Days',
    start: dayjs().subtract(7,'days').toDate(),
    end:  dayjs().toDate()
  },
  {
    period: 'this month',
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate()
  },
  {
    period: 'last month',
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate()
  }
];