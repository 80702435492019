import { useReducer, Reducer, useState } from 'react';
import Backend from '../../../stores/newBackend';
import { Note } from '../../../types/note';

type ActionResult = {
  type: 'results';
  notes: any[];
};

type ReducerAction =
  | { type: 'load' }
  | ActionResult
  | { type: 'error'; error: any };

interface State {
  loading: boolean;
  error: any;
  notes: any[];
}

const useGetActionNotesInitialeState = {
  loading: false,
  error: null,
  notes: [],
};

export default function useGetActionNotes() {
  const [{ loading, error, notes }, dispatch] = useReducer<
    Reducer<State, ReducerAction>
  >(useGetActionNotesReducer, useGetActionNotesInitialeState);

  const [fetch] = useState(() => {
    return async (token: string, link: string) => {
      dispatch({
        type: 'load',
      });

      try {
        const results = await Backend.fetchLink(token, link);
        const dispatchData: ActionResult = {
          type: 'results',
          notes: results.map(parseNote),
        };

        dispatch(dispatchData);
      } catch (e) {
        dispatch({ type: 'error', error: e });
      }
    };
  });

  return { loading, error, notes, fetch };
}

const parseNote = (httpNote: any): Note => {
  const note = {
    ...httpNote,
  };

  if (note.createdDate) {
    note.createdDate = new Date(note.createdDate);
  }
  if (note.updatedDate) {
    note.updatedDate = new Date(note.updatedDate);
  }

  return note;
};

function useGetActionNotesReducer(state: State, action: ReducerAction): State {
  const newState = { ...state };
  switch (action.type) {
    case 'load':
      newState.loading = true;
      break;
    case 'results':
      newState.loading = false;
      newState.notes = action.notes;
      break;
    case 'error':
      newState.loading = false;
      newState.error = action.error;
      break;
    default:
      throw new Error();
  }

  return newState;
}
