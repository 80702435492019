import classes from './CompanyAccountSelector.module.css';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { CompanyAccountApi } from '../../swagger_client/src';
import { taglistStyle } from '../../Utils/reactSelectStyles';
import { useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createInputValueRegexContent } from '../../Utils/filter/createFilterInputValue';

CompanyAccountSelector.propTypes = {
  onChange: PropTypes.func,
  defaultOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(['top', 'bottom']),
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(object), object]),
};

CompanyAccountSelector.defaultProps = {
  defaultOptions: true,
  isMulti: true,
  menuPlacement: 'top',
  menuPosition: 'absolute',
};

export default function CompanyAccountSelector({
  onChange,
  defaultOptions,
  defaultValue,
  isMulti,
  menuPosition,
  menuPlacement,
}) {
  const companyAccountApi = new CompanyAccountApi(),
    { t } = useTranslation();

  async function promiseOptions(inputValue) {
    const items = await companyAccountApi.companyAccountFind({
      filter: JSON.stringify({
        where: inputValue ? { name: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } } : {},
        limit: 10,
      }),
    });
    return _.map(items, (o) => {
      return {
        label: o.name,
        value: o,
      };
    });
  }

  function handleChange(choices) {
    onChange(choices);
  }

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <AsyncSelect
        isMulti={isMulti}
        cacheOptions
        menuPlacement={menuPlacement}
        placeholder={t('Select...')}
        menuPosition={menuPosition}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        styles={taglistStyle}
        loadOptions={promiseOptions}
        onChange={handleChange}
      />
    </div>
  );
}
