import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';
import NewTooltip from '../../_ui/Tooltip/NewTooltip';
import TermBubbleForTooltip from '../../Terms/TermBubbleForTooltip/TermBubbleForTooltip';
import classes from './SettingTermsTermCell.module.css';

SettingTermsTermCell.propTypes = {
  item: PropTypes.shape(termDefinition)
};

export default function SettingTermsTermCell({ item }) {

  return <NewTooltip
    text={item.term}
    className={classes.cell}
    content={<TermBubbleForTooltip term={item}/>}
    placement='top'
    arrow
  >
    {item.term}
  </NewTooltip>;
}
