import classes from './ShortMenuList.module.css';
import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

ShortMenuList.propTypes = {
  children: PropTypes.node,
  separator: PropTypes.bool,
  setIsOpen: PropTypes.func
};

ShortMenuList.defaultProps = {
  separator: false
};

export default function ShortMenuList({ children,setIsOpen, separator }) {
  return <div className={classes.container} data-separator={separator}>
    {Children.map(children,child=> {
      if (child === null) {
        return null;
      }
      return cloneElement(child, { setIsOpen, ...child?.props });
    })}
  </div>;
}
