import classes from './TermCompanyAccountEdit.module.css';
import PopinTitle from '../../_ui/Popin/PopinTitle/PopinTitle';
import CancelButton from '../../CancelButton/CancelButton';
import SubmitButton from '../../SubmitButton/SubmitButton';
import CompanyAccountSelector from '../../CompanyAccountSelector/CompanyAccountSelector';
import { CompanyAccountInterface } from '../../../types/companyAccount.interface';

interface Props {
  readonly t: Function
  readonly onClose: Function
  readonly loading: boolean
  readonly handleSubmit: Function
  readonly companiesAccount: Array<{
    label: string
    value: CompanyAccountInterface
  }>
  readonly setCompaniesAccount: Function
}

export default function TermCompanyAccountEditView({
  t,
  onClose,
  loading,
  handleSubmit,
  companiesAccount,
  setCompaniesAccount,
}: Props) {
  return (
    <div className={classes.container}>
      <PopinTitle text={t('Modify customer access for keyword')} />

      <div className={classes.formContainer}>
        <CompanyAccountSelector
          onChange={(choices) => setCompaniesAccount(choices)}
          menuPosition="fixed"
          menuPlacement="bottom"
          defaultValue={companiesAccount}
        />
      </div>
      <div className={classes.buttons}>
        <CancelButton txt="cancel" onClose={() => onClose()} />
        <SubmitButton
          onClick={() => handleSubmit()}
          txt="Modify customer access"
          isLoading={loading}
          disabled={loading}
        />
      </div>
    </div>
  );
}
