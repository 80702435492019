
import { TagInterface } from '../../../types/tag.interface';
import { TermInterface } from '../../../types/term.interface';
import TermTagsUpdateView from './TermTagsUpdateView';
import useTermTagsUpdate from './useTermTagsUpdate';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
  readonly scope : 'term'|'tag'
  readonly tags: Array<TagInterface>
  readonly refetchTag: Function
}

export default function TermTagsUpdate(props:Props){
  const useProps = useTermTagsUpdate();

  return <TermTagsUpdateView {...props} {...useProps}/>;
}