import {
  faDownload,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './InputSearch.module.css';
import PropTypes from 'prop-types';

Inputsearch.propTypes = {
  className: PropTypes.string,
  exportLink: PropTypes.string,
  exportName: PropTypes.string,
  setter: PropTypes.func.isRequired
};


export default function Inputsearch({ setter, exportLink, exportName }) {
  const { t } = useTranslation(),
    [value, setValue] = useState('');

  function handleChange(e) {
    setValue(e.target.value);
    setter(e.target.value.toLowerCase());
  }

  return (
    <div className={classes.container}>
      <div className={classes.export}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className={classes.input}
        data-testid="inputsearch"
        placeholder={t('filter on table')}
      />

      <div>
        {exportLink && (
          <a
            className={classes.export}
            href={exportLink}
            download={`${exportName}-${dayjs().format('YYYY-MM-DD')}.csv`}
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}
      </div>
    </div>
  );
}
