import { observer } from 'mobx-react-lite';
import FilterPanelView from './NewPanelFilterView';
import usePanelFilter from './usePanelFilter';
import { ReactElement, createContext } from 'react';

interface Props {
  readonly isOpen: boolean;
  readonly setIsOpen: Function;
  readonly pageStore: any;
  readonly filtersElements: Array<ReactElement>;
}

type PanelFilterContextType = {
  filters: any;
  setFilter: (
    name: string,
    value?: { whereClause: Object; description: string; value: any }
  ) => void;
};

const PanelFilterContext = createContext<PanelFilterContextType>({
  filters: {},
  setFilter: () => {},
});

function PanelFilter(props: Props) {
  const useProps = usePanelFilter(props),
    passProps = { ...props, ...useProps };

  const { filters, setFilter } = useProps;

  return (
    <PanelFilterContext.Provider value={{ filters, setFilter }}>
      <FilterPanelView {...passProps} />
    </PanelFilterContext.Provider>
  );
}

export { PanelFilterContext };
export default observer(PanelFilter);
