import classes from './MessagesIcon.module.css';
import SmallEnvelopeSVG from '../../components/Icon/svg/SmallEnvelope.svg';

function getNumberDigits(n: number) {
  if (n < 10) {
    return 1;
  } else if (n >= 10 && n < 100) {
    return 2;
  } else if (n >= 100 && n < 1000) {
    return 3;
  } else if (n >= 1000 && n < 10000) {
    return 4;
  }
  return -1;
}

interface MessagesIconProps {
  count: number;
}

export default function MessagesIcon(props: MessagesIconProps) {
  const numberDigits = getNumberDigits(props.count);
  return (
    <div className={`${classes.container} ${!props.count ? classes.noCount : ''}`}>
      <SmallEnvelopeSVG height={20} color="var(--color-link-menu)" />
      { props.count ? <div className={`${classes.countHint} ${numberDigits === 3 ? classes.countHintThree : numberDigits === 4 ? classes.countHintFour : ''}`}>{props.count}</div> : null }
    </div>
  );
}
  