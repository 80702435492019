import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import classes from './SettingTermsTypeCell.module.css';
import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';
import SettingTermsStore from '../../../pages/Settings/Terms/settingTerms.store';

SettingTermsTypeCell.propTypes = {
  item: PropTypes.shape(termDefinition),
  store: PropTypes.instanceOf(SettingTermsStore),
  field: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool
};

export default function SettingTermsTypeCell({ item, store, isAdmin }) {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.txt}>
        <Highlighter
          searchWords={[store.inputFilterValue]}
          autoEscape={true}
          textToHighlight={t(item.keywordType.toLowerCase())}
        />
      </div>
    </div>
  );
}
