import _ from 'lodash';

interface ExchangesCellProps {
  item?: any;
  field: string
}

export default function BrandCell({ item, field }: ExchangesCellProps) {
  return (
    <div>{_.get(item, field)}</div>
  );
}
