import _ from 'lodash';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import TagList from '../../Tag/TagList/TagList';
import DomainsStore from '../../../pages/Domains/newDomains.store';

export default function DealerTagCell({
  item,
  store,
  tags,
  tagFetch,
  isLoading,
}: {
  item: any;
  store: DomainsStore;
  tags: any[];
  tagFetch: () => void;
  isLoading: boolean;
}) {
  //wait "categorie" because item is proxy, and never undefined.
  if (isLoading || !_.get(item, 'categories', undefined)) {
    return <LoaderSmall />;
  }
  return (
    <TagList
      scope='tag'
      entity={item}
      options={tags}
      refetchTag={() => tagFetch()}
      updateTab={(tags) => store.updateItem(item, tags)}
    />
  );
}
