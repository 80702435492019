import { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
// import ActionsStore from './actions.store';
import classes from './Action.module.css';
import ActionHeader from './ActionHeader';
import ActionTags from './ActionTags';
import ActionPanels from './ActionPanels';
import ActionvertisementPanels from './ActionAdvertisementPanels';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import { StoreContext } from '../../stores';
import useGetAction from '../../components/Hooks/data/getAction';
import Loader from '../../components/Loader/Loader';
import { Action } from '../../types/action.newBackend';
import ActionInprogressModal from '../../components/Action/ActionInprogressModal/ActionInprogressModal';
import { ActionNoteProvider } from '../../stores/actionNoteContext';

const LEGACY_URL = process.env
  .REACT_APP_MONIBRAND_DASHBOARD_LEGACY_URL as string;

const ActionContainer = () => {
  const { authStore, cacheStore } = useContext(StoreContext);
  const { state, pathname } = useLocation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { action: stateAction, from: stateFrom } = state || {};
  const [from] = useState(stateFrom);
  const [currentAction, setCurrentAction] = useState(stateAction);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeReady, setIframeReady] = useState(false);

  const { loading, action, fetch } = useGetAction();

  useEffect(() => {
    if (stateAction || !authStore.token) {
      return;
    }
    const { id } = params;
    fetch(authStore.token, id);
  }, [authStore.token, fetch, stateAction, params]);

  useEffect(() => {
    if (
      !authStore.currentUser?.id ||
      !authStore.token ||
      !iframeRef ||
      !iframeLoaded
    ) {
      return;
    }
    const authInfos = {
      id: authStore.currentUser?.id,
      token: authStore.token,
    };
    iframeRef.current?.contentWindow?.postMessage(authInfos, LEGACY_URL);
    setTimeout(() => {
      setIframeReady(true);
    }, 100);
  }, [authStore.token, authStore.currentUser?.id, iframeLoaded, currentAction]);

  useEffect(() => {
    const handler = (event: { data: any; origin: string }) => {
      if (event.origin === LEGACY_URL) {
        const message: any = event.data;
        if (message && message.ready) {
          setIframeLoaded(true);
        }
        if (message && message.actionID && !message.data) {
          cacheStore.clear('actions');
          cacheStore.clear('todo_actions');
        }
        if (message && message.actionID && message.data) {
          // will refresh action
          let newAction = currentAction;
          newAction.updatedDate = message.data.updatedDate;
          newAction.inprogressDate = message.data.inprogressDate;
          newAction.status = message.data.status;
          newAction.history.push(message.data.history);
          handleUpdateAction(newAction);
        }
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, [action]); // eslint-disable-line

  useEffect(() => {
    if (action) {
      setCurrentAction(action);
    }
  }, [action, history, pathname]);

  useEffect(() => {
    history.replace(pathname, null);
  }, [history, pathname]);

  const handleActionRefresh = () => {
    fetch(authStore.token, currentAction.ID);
    cacheStore.clear('actions');
    cacheStore.clear('todo_actions');
  };

  const handleUpdateAction = (newAction: Action) => {
    cacheStore.clear('actions');
    cacheStore.clear('todo_actions');
    setCurrentAction(newAction);
  };

  return (
    <ActionNoteProvider>
      <ActionScreen
        action={currentAction}
        loading={loading}
        onUpdateAction={handleUpdateAction}
        from={from}
        iframeRef={iframeRef}
        iframeReady={iframeReady}
        onRefresh={handleActionRefresh}
        token={authStore.token}
      />
    </ActionNoteProvider>
  );
};

const ActionScreen = ({
  action,
  onUpdateAction,
  loading,
  from,
  iframeRef,
  iframeReady,
  onRefresh,
  token,
}: {
  loading: boolean;
  action: Action;
  onUpdateAction: (action: Action) => void;
  onRefresh: () => void;
  from: string;
  iframeRef: any;
  iframeReady: boolean;
  token: string;
}) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [isOpenedActionInprogressModal, setIsOpenedActionInprogressModal] =
    useState(false);

  const onModalOpened = (opened: boolean) => {
    setScrollEnabled(!opened);
  };

  const setIsOpenedInprogressModal = () => {
    if (isOpenedActionInprogressModal) {
      setIsOpenedActionInprogressModal(false);
    } else {
      setIsOpenedActionInprogressModal(true);
    }
  };

  return (
    <div className={`${classes.container} ${loading ? classes.center : ''}`}>
      {isOpenedActionInprogressModal && (
        <ActionInprogressModal
          actionID={action.ID}
          setIsOpen={setIsOpenedInprogressModal}
          token={token}
          onUpdate={onUpdateAction}
        />
      )}
      {loading && <Loader />}
      {loading === false && action && (
        <>
          <ActionHeader
            onRefresh={onRefresh}
            action={action}
            from={from}
            onUpdate={onUpdateAction}
            setIsOpenedInprogressModal={setIsOpenedInprogressModal}
          />
          <DynamicScrollbar
            contentClassName={classes.body}
            noScrollY={!scrollEnabled}
          >
            <ActionTags action={action} />
            <ActionPanels action={action} onModalOpened={onModalOpened} />
            <ActionvertisementPanels action={action} />
            {iframeReady === false && (
              <iframe
                title='emailsForAction'
                ref={iframeRef}
                src={LEGACY_URL}
              ></iframe>
            )}
            {iframeReady === true && (
              <div className={classes.iframeContainer}>
                <iframe
                  className={classes.iframe}
                  title='emailsForAction'
                  ref={iframeRef}
                  src={
                    LEGACY_URL +
                    '/action/operator/list/' +
                    action.ID +
                    '/embedded/true'
                  }
                ></iframe>
              </div>
            )}
          </DynamicScrollbar>
        </>
      )}
    </div>
  );
};

export default ActionContainer;
