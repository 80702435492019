import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './SettingTermsStateCell.module.css';
import termDefinition from '../../../proptypes/term.definition';

SettingTermsStateCell.propTypes = {
  item: PropTypes.shape(termDefinition)
};

export default function SettingTermsStateCell({item}) {
  const {t} = useTranslation();
  const state = item.state || null;
  return (
    <div className={classes.container}>
      <div className={classes.round} data-color={state} />
      <div>{t(state)}</div>
    </div>
  );
}
