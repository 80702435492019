import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './LoaderTable.module.css';


LoaderTable.propTypes = {
  cols: PropTypes.number.isRequired,
};

export default function LoaderTable({ cols }) {
  const [loadingTable, setLoadingTable] = useState({
    tableLines: [1],
    tableCols: [1],
  });

  useEffect(() => {
    const tableLines = [],
      tableCols = [];

    for (let i = 0; i < 15; i++) {
      tableLines.push(i);
    }

    for (let i = 0; i < cols; i++) {
      tableCols.push(i);
    }
    setLoadingTable({ tableLines, tableCols });
    // eslint-disable-next-line
  }, []);

  return loadingTable.tableLines.map((line) => (
    <tr key={line}>
      {loadingTable.tableCols.map((col) => (
        <td key={col}>
          <div className={classes.bar} />
        </td>
      ))}
    </tr>
  ));
}
