import classes from './Flexbox.module.css';
import PropTypes from 'prop-types';

Flexbox.propTypes = {
  children: PropTypes.node,
  column: PropTypes.bool,
  className: PropTypes.string
};

export default function Flexbox({children,column, className}){

  return (
    <div
      className={`${classes.container} ${className ? className : ''}`}
      data-col={column}
    >
      {children}
    </div>
  );
}
