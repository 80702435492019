import PropTypes from 'prop-types';

export default function TermSVG({ color }) {
  return (
    <g>
      <rect id="body" width="25" height="25" fill="none" />
      <g id="icon" transform="translate(0.833 3.333)">
        <rect
          id="Rectangle"
          width="23.333"
          height="18.333"
          rx="3"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_229"
          data-name="Path 229"
          d="M0,6.667,2.5,3.333,0,0"
          transform="translate(8.333 5.833)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_230"
          data-name="Path 230"
          d="M0,.417H2.5"
          transform="translate(12.5 12.083)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </g>
  );
}

TermSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
