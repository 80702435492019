import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import SettingTermsStore from './settingTerms.store';
import GroupActions from '../../../components/GroupActions/GroupActions';
import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import NavFilter from '../../../components/Filters/NavFilter/NavFilter';
import SelectFilter from '../../../components/Filters/SelectFilter/SelectFilter';
import TagsFilter from '../../../components/Filters/TagsFilter/TagsFilter';
import Pagination from '../../../components/Pagination/Pagination';
import TermsFilter from '../../../components/Filters/TermsFilter/TermsFilter';
import CompanyAccountsFilter from '../../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import { keywordtypes } from '../../../Utils/rawData/rawData';
import TagListMultiTerms from '../../../components/Tag/TagListMultiTerms/TagListMultiTerms';
import useGetTags from '../../../components/Hooks/data/getTags/getTags';
import MagicTable from '../../../components/MagicTable/MagicTable';
import CheckboxCell from '../../../components/MagicTable/CheckboxCell/CheckboxCell';
import LocalizationCell from '../../../components/MagicTable/LocalizationCell/LocalizationCell';
import EngineCell from '../../../components/MagicTable/EngineCell/EngineCell';
import SettingTermsTypeCell from '../../../components/SettingTerms/SettingTermsTypeCell/SettingTermsTypeCell';
import SettingTermsFrequencyCell from '../../../components/SettingTerms/SettingTermsFrequencyCell/SettingTermsFrequencyCell';
import SettingTermsActionsCell from '../../../components/SettingTerms/SettingTermsActionsCell/SettingTermsActionsCell';
import SettingTermsBrandCell from '../../../components/SettingTerms/SettingTermsBrandCell/SettingTermsBrandCell';
import BrandsFilter from '../../../components/Filters/BrandsFilter/BrandsFilter';
import BrandAndTypeModale from '../../../components/SettingTerms/BrandAndTypeModale/BrandAndTypeModale';
import SettingTermsStateCell from '../../../components/SettingTerms/SettingTermsStateCell/SettingTermsStateCell';
import TermEditCompanyAccounts from '../../../components/Terms/TermEditCompanyAccounts/TermEditCompanyAccounts';
import UpdateTermOptions from '../../../components/Terms/UpdateTermOptions/UpdateTermOptions';
import UpdateFrequencyTerms from '../../../components/Terms/UpdateFrequencyTerms/UpdateFrequencyTerms';
import Button from '../../../components/Button/Button';
import LocalizationFilter from '../../../components/Filters/LocalizationFilter/LocalizationFilter';
import SettingTermsTermCell from '../../../components/SettingTerms/SettingTermsTermCell/SettingTermsTermCell';
import AddTermsModale from '../../../components/Terms/AddTermsModale/AddTermsModale';

function SettingTerms() {
  const { authStore, uiStore } = useContext(StoreContext),
    { tags, tagFetch } = useGetTags('term'),
    { t } = useTranslation(),
    { isAdmin, currentBrands, currentUser } = authStore,
    [settingTermsStore] = useState(() => new SettingTermsStore({}, isAdmin, currentUser.id));

  useEffect(() => {
    uiStore.setTitle(t('Setting terms'));

    return () => {
      settingTermsStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    settingTermsStore.setBrandsFilter(currentBrands, 'brandId');
    // eslint-disable-next-line
  }, [currentBrands]);

  return (
    <div className="tableContainer">
      {settingTermsStore.termToUpdateBrand && (
        <FullscreenModal
          onClose={() => settingTermsStore.setModaleUpdateBrand(null)}
        >
          <BrandAndTypeModale
            onClose={() => settingTermsStore.setModaleUpdateBrand(null)}
            term={settingTermsStore.termToUpdateBrand}
            updateTerm={(term) => settingTermsStore.setUpdateBrandAndType(term)}
          />
        </FullscreenModal>
      )}
      {settingTermsStore.showCreateModale && (
        <AddTermsModale
          settingTermsStore={settingTermsStore}
          onClose={() => settingTermsStore.setShowCreateModale(false)}
        />
      )}

      <div className="tableContainerFilter">
        <div className="tableContainerButtons">
          {isAdmin && (
            <Button 
              onClick={() => settingTermsStore.setShowCreateModale(!settingTermsStore.showCreateModale)} 
              text={'add terms'} 
            />
          )}
        </div>
        {!settingTermsStore.showCreateModale && (
          <>
            <NavFilter
              isLoading={settingTermsStore.isLoadingCount}
              pageStore={settingTermsStore}
              counters={settingTermsStore.counters}
            >
              <BrandsFilter label="brand" name="brandId" />
              <TermsFilter
                label="keywords"
                name="term"
                filterKey="term"
                nameForSearch="term"
              />
              <CompanyAccountsFilter
                label="companies accounts"
                name="canBeAccessBy.id"
              />
              <SelectFilter
                label="engine"
                name="engine"
                options={[
                  { label: 'Google', value: 'GOOGLE' },
                  { label: 'Bing', value: 'BING' },
                ]}
              />
              <LocalizationFilter
                label="localization"
                name="localizationId"/>
              <SelectFilter
                label="state"
                name="state"
                placeholder="state"
                options={[
                  {
                    label: t('enabled'),
                    value: 'enabled',
                  },
                  {
                    label: t('disabled'),
                    value: 'disabled',
                  },
                  {
                    label: t('archived'),
                    value: 'archived',
                  },
                ]}
              />
              <TagsFilter
                label="tag"
                scope="term"
                name="categories.id"
                nameForReg="categories.name"
              />
              <SelectFilter
                label="keyword type"
                name="keywordType"
                options={keywordtypes.map((o) => {
                  return {
                    value: o.value,
                    label: t(o.label),
                  };
                })}
                reactSelectOptions={{menuPlacement:'top'}}
              />
              <SelectFilter
                label="type of campaign"
                name="options.saveShoppingAds"
                placeholder={t('Type of campaign')}
                options={[
                  { label: t('Only Ads'), value: 'false' },
                  { label: t('Ads & Shopping'), value: 'true' },
                ]}
              />
            </NavFilter>
          </>
        )}
        <GroupActions
          selected={settingTermsStore.selectedItems}
          actions={settingTermsStore.actions}
          isLoading={settingTermsStore.isLoadingAction}
        >
          <TagListMultiTerms
            selectedItems={settingTermsStore.selectedItems}
            scope="term"
            tags={tags}
            refetchTag={() => tagFetch()}
            refetch={() => settingTermsStore.handleFetch()}
            commonTags={settingTermsStore.commonTagsForSelectItems}
          />
          <TermEditCompanyAccounts
            selectedItem={settingTermsStore.selectedItem}
            selectedItems={settingTermsStore.selectedItems}
            refetch={() => settingTermsStore.handleFetch()}
          />
          <UpdateFrequencyTerms
            selectedItems={settingTermsStore.selectedItems}
            refetch={() => settingTermsStore.handleFetch()}
          />
          <UpdateTermOptions
            selectedItem={settingTermsStore.selectedItem}
            selectedItems={settingTermsStore.selectedItems}
            refetch={() => settingTermsStore.handleFetch()}
          />
        </GroupActions>
      </div>

      <div className="tableContainer">
        <div className="table">
          <MagicTable
            store={settingTermsStore}
            exportLink={settingTermsStore.exportLink}
            exportName="terms"
          >
            <CheckboxCell field="selectAll" />
            <SettingTermsTermCell field="term" />
            <LocalizationCell field="localization.locale" />
            <EngineCell field="engine" />
            <SettingTermsBrandCell field="brand" />
            <SettingTermsTypeCell field="keywordType" isAdmin={isAdmin} />
            <SettingTermsFrequencyCell field="every" />
            <SettingTermsStateCell field="state" />
            <SettingTermsActionsCell field="actions"  tags={tags} refetchTag={() => tagFetch()}/>
          </MagicTable>
        </div>
      </div>
      <Pagination
        handleSetNoLimit={() => settingTermsStore.handleSetNoLimit()}
        total={settingTermsStore.count}
        perPage={settingTermsStore.perPage}
        page={settingTermsStore.page}
        handleSetPage={(pageNumber) => settingTermsStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(SettingTerms);
