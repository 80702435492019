import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classes from './MenuSection.module.css';
import { useTranslation } from 'react-i18next';
import { useMemo, useContext } from 'react';
import { StoreContext } from '../../../stores';
import { observer } from 'mobx-react';
import { useMenuExpanded } from '../MenuContext';

function MenuSectionItem({ expanded, standalone, item, setIsOpen }) {
  const { t } = useTranslation(),
    location = useLocation();

  const isActive = useMemo(() => {
    return location.pathname === item.path;
  }, [ location, item ]);

  return (
    <NavLink
      onClick={() => {
        setIsOpen && setIsOpen(false);
      }}
      className={`${classes.link} ${standalone ? classes.standalone : ''} ${!expanded ? classes.collapsed : ''}`}
      isActive={(match, location) => {
        if (
          location.pathname !== '/' &&
              match &&
              match.path.substring(1) !== '/'
        ) {
          if (match && match.path.substring(1) === item.path) {
            return true;
          }
        } else {
          if (location.pathname === '/' && item.path === '/') {
            return true;
          }
        }
      }}
      to={{
        pathname: item.path,
        search: item.search
      }}
      data-testid={item.testid}
    >
      <div className={classes.icon}>
        { standalone ? (
          <item.icon
            color={isActive ? 'var(--color-princ-monibrand)' : 'var(--color-link-menu)'}
          />
        ) : (
          <div className={`${classes.bullet} ${isActive ? classes.bulletActive : ''}`} />
        ) }
      </div>
      { expanded && (<div className={classes.label}>{t(item.title)}</div>) }
    </NavLink>
  );
}

MenuSectionItem.propTypes = {
  setIsOpen: PropTypes.func,
  standalone: PropTypes.bool,
  expanded: PropTypes.bool,
  item: PropTypes.object
};

function MenuSection({ routes, section, setIsOpen, standalone }) {
  const { authStore } = useContext(StoreContext),
    expanded = useMenuExpanded();

  return (
    <div className={`${classes.container} ${!expanded ? classes.collapsed : ''}`}>
      {routes
        .filter((route) => route.section === section)
        .filter((route) => !route.isAdmin || authStore.isAdmin)
        .map((item) => (
          <MenuSectionItem
            key={item.title}
            item={item}
            expanded={expanded}
            standalone={standalone}
            setIsOpen={setIsOpen}
          />
        ))
      }
    </div>
  );
}

export default observer(MenuSection);

MenuSection.propTypes = {
  routes: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func,
  standalone: PropTypes.bool
};
