import PropTypes from 'prop-types';

export default function ResumeSVG({ color }) {
  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.489903, 0.000000)">
        <g>
          <rect id="body" x="0" y="0" width="25" height="25" />
          <g
            id="icon"
            transform="translate(0.833333, 0.833333)"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.5"
          >
            <circle id="Oval" cx="11.6666667" cy="11.6666667" r="11.6666667" />
            <line
              x1="14.1666667"
              y1="15"
              x2="5.83333333"
              y2="15"
              id="Path-121"
            />
            <circle id="Oval-Copy-6" cx="15.8333333" cy="15" r="1.66666667" />
            <line
              x1="17.5"
              y1="8.33333333"
              x2="9.16666667"
              y2="8.33333333"
              id="Path-121-Copy"
            />
            <circle id="Oval" cx="7.5" cy="8.33333333" r="1.66666667" />
          </g>
        </g>
      </g>
    </g>
  );
}

ResumeSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
