import Engine from '../../Engine/Engine';
import _ from 'lodash';
import PropTypes from 'prop-types';

EngineCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired
};

export default function EngineCell({item, field}){
  return <Engine name={_.get(item, field)}/>;
}
