import classes from './PercentInput.module.css';

interface props {
  readonly percent: number
  readonly onChange:Function
  readonly disabled?:boolean
}

export default function PercentInputView({percent, onChange, disabled}:props) {
  return (
    <div className={classes.container} data-disabled={disabled}>
      <input
        disabled={disabled}
        value={percent}
        className={classes.input}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className={classes.percent}>{'%'}</div>
    </div>
  );
}
