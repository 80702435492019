import { observer } from 'mobx-react';
import useBrandsFilter from './useBrandFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';

function BrandsFilter(props:FilterChild) {
  const useProps = useBrandsFilter(),
    passProps = {...props,...useProps};

  return <AsyncCreateFilter {...passProps}/>;
}

export default observer(BrandsFilter);