/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Contact model module.
* @module model/Contact
* @version 4.0.0
*/
export default class Contact {
    /**
    * Constructs a new <code>Contact</code>.
    * @alias module:model/Contact
    * @class
    * @param email {String} 
    */

    constructor(email) {
        
        
        this['email'] = email;
        
    }

    /**
    * Constructs a <code>Contact</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Contact} obj Optional instance to populate.
    * @return {module:model/Contact} The populated <code>Contact</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Contact();
                        
            
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('region')) {
                obj['region'] = ApiClient.convertToType(data['region'], 'String');
            }
            if (data.hasOwnProperty('postcode')) {
                obj['postcode'] = ApiClient.convertToType(data['postcode'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], ['String']);
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
        }
        return obj;
    }

    /**
    * @member {String} email
    */
    email = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} address
    */
    address = undefined;
    /**
    * @member {String} city
    */
    city = undefined;
    /**
    * @member {String} region
    */
    region = undefined;
    /**
    * @member {String} postcode
    */
    postcode = undefined;
    /**
    * @member {String} country
    */
    country = undefined;
    /**
    * @member {String} notes
    */
    notes = undefined;
    /**
    * @member {Array.<String>} tags
    */
    tags = undefined;
    /**
    * @member {String} state
    * @default 'active'
    */
    state = 'active';
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;




}
