import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './UpdateFrequencyTerms.module.css';
import { useFrequencyOptions } from '../../Hooks/useFrequencyOptions';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { TermApi } from '../../../swagger_client/src';
import ActionForMultiSelect from '../../ActionForMultiSelect/ActionForMultiSelect';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import PercentInput from '../../_ui/PercentInput/PercentInput';

UpdateFrequencyTerms.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  refetch: PropTypes.func.isRequired,
  forShortMenuIcon: PropTypes.any
};

function UpdateFrequencyTerms({ selectedItems, refetch, forShortMenuIcon }) {
  const
    { t } = useTranslation(),
    frequencyOptions = useFrequencyOptions(),
    [isOpen, setIsOpen] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [percent, setPercent] = useState(5),
    [frequency, setFrequency] = useState(frequencyOptions[0]),
    termApi = new TermApi();

  async function handleSubmit() {
    setIsLoading(true);
    //frequency 0 = auto. refresh only auto with percent.
    if (frequency.value === 0) {
      try {
        await termApi.termFetchSearchStats({
          body: {
            where: { id: { inq: selectedItems } },
            searchConvertRate: percent / 100
          }
        });
        toast.success(t('Synchronization done.'));
      } catch (e) {
        toast.error(t('Synchronization failed.'));
      }
    } else {
      try {
        await termApi.termUpdateAll({
          where: JSON.stringify({ id: { inq: selectedItems } }),
          body: { every: frequency.value },
        });

        toast.success(t('Update done.'));
      } catch (e) {
        toast.error(t('Update done.'));
      }
    }
    setIsLoading(false);
    refetch();
    //close popin
    setIsOpen();

  }

  return (
    <ActionForMultiSelect
      forShortMenuIcon={forShortMenuIcon}
      actionName="Modify frequency"
      title="Modify keywords monitoring frequency"
      isLoading={isLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSubmit={handleSubmit}
      submitbuttonText="Modify frequency"
      subTitle={
        <Trans i18nKey="chooseFrequency" count={selectedItems.length}>
          Choose the frequency you want to use to monitor the{' '}
          <strong>{{ count: selectedItems.length }}</strong> selected keyword
        </Trans>
      }
    >
      <div className={classes.container}>
        <div className={classes.frequencyContainer}>
          <ReactSelect
            options={frequencyOptions}
            onChange={setFrequency}
            value={frequency}
            menuPosition="fixed"
            styles={taglistStyle}
            placeholder={t('Select Frequency...')}
          />
        </div>
        <div
          className={classes.searchConvertRateContainer}
          data-disabled={frequency?.value !== 0}
        >
          <PercentInput percent={percent} setPercent={setPercent}/>
          <div className={classes.infoText}>
            <FontAwesomeIcon
              className={classes.icon}
              icon={faExclamationTriangle}
            />
            <p>
              <Trans i18nKey="impactFrequency">
                This value impact the percentage of views on google Ads for your
                brand. If you type in <strong>{{ percent }}%</strong>, you will
                have <strong>{{ percent }}%</strong> more views on your google
                ads.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </ActionForMultiSelect>
  );
}

export default observer(UpdateFrequencyTerms);
