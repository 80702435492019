import { Children, cloneElement, ReactElement, useMemo } from 'react';
import { Trans } from 'react-i18next';
import Button from '../../Button/Button';
import DynamicScrollbar from '../../DynamicScrollbar/DynamicScrollbar';
import SubmitButton from '../../SubmitButton/SubmitButton';
import classes from './PanelFilterContent.module.css';

interface Props {
  readonly loading: boolean;
  readonly filters: any;
  readonly filtersElements: Array<ReactElement>;
  readonly reinitialization: Function;
  readonly setFilter: Function;
  readonly validate: Function;
  readonly t: Function;
}

export default function PanelFilterContent({
  loading,
  filters,
  filtersElements,
  setFilter,
  reinitialization,
  validate,
}: Props) {
  const filtersCount = useMemo(() => {
    return filters
      ? Object.keys(filters).filter((key) => !!filters[key]).length
      : 0;
  }, [filters]);

  return (
    <>
      <div className={classes.main}>
        <DynamicScrollbar className={classes.containerFilters}>
          <div className={classes.filters}>
            {filters &&
              Children.map(filtersElements, (child: ReactElement) =>
                cloneElement(child, {
                  filters,
                  setFilter,
                  name: child?.props.name,
                  label: child?.props.label,
                })
              )}
          </div>
        </DynamicScrollbar>
      </div>
      <div className={classes.buttons}>
        <Button onClick={() => reinitialization()} type='reset' text='reset' />
        <SubmitButton
          txtBlock={
            <Trans i18nKey={`filtersCount`} count={filtersCount}>
              <>Filters ({{ count: filtersCount }})</>
            </Trans>
          }
          onClick={() => validate()}
          isLoading={loading}
        />
      </div>
    </>
  );
}
