import { pictos } from '../../../types/pictos.enum';
import CompanyAccountStore from '../../../pages/CompanyAccount/CompanyAccount.store';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

interface Props {
  readonly store: CompanyAccountStore
  readonly item: any
}

export default function CompanyAccountActionsCell({ item, store } : Props) {
  return (
    <ShortMenuModale >
      <ShortMenuList>
        <ShortMenuAction 
          icon={pictos.pen} 
          text="update company account" 
          testid='caac_update' 
          onClick={() => store.updateOneCompanyAccount(item)}
        />
        <ShortMenuAction 
          icon={pictos.trash} 
          text="delete company account" 
          testid='caac_delete' 
          onClick={() =>
            store.setSelectedItem({
              term: item,
              action: () => store.deleteCompanyAccount(item.id),
              type: 'delete',
            })
          }
        />
      </ShortMenuList>
    </ShortMenuModale>
  );
}
