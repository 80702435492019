/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Action from '../model/Action';
import ActionEmail from '../model/ActionEmail';
import Contact from '../model/Contact';
import Dealer from '../model/Dealer';
import ObjectID from '../model/ObjectID';

/**
* Action service.
* @module api/ActionApi
* @version 4.0.0
*/
export default class ActionApi {

    /**
    * Constructs a new ActionApi. 
    * @alias module:api/ActionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionCountWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionCount(opts) {
      return this.actionCountWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionCreate(opts) {
      return this.actionCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    actionCreateChangeStreamGetActionsChangeStreamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'options': opts['options']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Actions/change-stream', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    actionCreateChangeStreamGetActionsChangeStream(opts) {
      return this.actionCreateChangeStreamGetActionsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    actionCreateChangeStreamPostActionsChangeStreamWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Actions/change-stream', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    actionCreateChangeStreamPostActionsChangeStream(opts) {
      return this.actionCreateChangeStreamPostActionsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionDeleteByIdWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionDeleteById(id) {
      return this.actionDeleteByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionExistsGetActionsidExistsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/{id}/exists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionExistsGetActionsidExists(id) {
      return this.actionExistsGetActionsidExistsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionExistsHeadActionsidWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/{id}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionExistsHeadActionsid(id) {
      return this.actionExistsHeadActionsidWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Action>} and HTTP response
     */
    actionFindWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Action];

      return this.apiClient.callApi(
        '/Actions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Action>}
     */
    actionFind(opts) {
      return this.actionFindWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionFindByIdWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionFindById(id, opts) {
      return this.actionFindByIdWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionFindOneWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/findOne', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionFindOne(opts) {
      return this.actionFindOneWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Request actions list group by master for Actions Request dashboard
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionFindRequestWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/findRequest', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Request actions list group by master for Actions Request dashboard
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionFindRequest(opts) {
      return this.actionFindRequestWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionPatchOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionPatchOrCreate(opts) {
      return this.actionPatchOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts emails of Action.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionPrototypeCountEmailsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/{id}/emails/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts emails of Action.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionPrototypeCountEmails(id, opts) {
      return this.actionPrototypeCountEmailsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in emails of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ActionEmail} and HTTP response
     */
    actionPrototypeCreateEmailsWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'email': opts['email'],
        'body': opts['body'],
        'subject': opts['subject'],
        'status': opts['status'],
        'createdDate': opts['createdDate'],
        'receivedDate': opts['receivedDate'],
        'sendedDate': opts['sendedDate'],
        'open': opts['open'],
        'events': this.apiClient.buildCollectionParam(opts['events'], 'multi'),
        'templateType': opts['templateType'],
        'id': opts['id'],
        'recipients': this.apiClient.buildCollectionParam(opts['recipients'], 'multi'),
        'actionId': opts['actionId'],
        'attachments': this.apiClient.buildCollectionParam(opts['attachments'], 'multi')
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = ActionEmail;

      return this.apiClient.callApi(
        '/Actions/{id}/emails', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in emails of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ActionEmail}
     */
    actionPrototypeCreateEmails(id2, opts) {
      return this.actionPrototypeCreateEmailsWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all emails of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    actionPrototypeDeleteEmailsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Actions/{id}/emails', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all emails of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    actionPrototypeDeleteEmails(id) {
      return this.actionPrototypeDeleteEmailsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    actionPrototypeDestroyByIdEmailsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Actions/{id}/emails/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    actionPrototypeDestroyByIdEmails(id, fk) {
      return this.actionPrototypeDestroyByIdEmailsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ActionEmail} and HTTP response
     */
    actionPrototypeFindByIdEmailsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = ActionEmail;

      return this.apiClient.callApi(
        '/Actions/{id}/emails/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ActionEmail}
     */
    actionPrototypeFindByIdEmails(id, fk) {
      return this.actionPrototypeFindByIdEmailsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetches belongsTo relation dealer.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Dealer} and HTTP response
     */
    actionPrototypeGetDealerWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'refresh': opts['refresh']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Dealer;

      return this.apiClient.callApi(
        '/Actions/{id}/dealer', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetches belongsTo relation dealer.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Dealer}
     */
    actionPrototypeGetDealer(id, opts) {
      return this.actionPrototypeGetDealerWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries emails of Action.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ActionEmail>} and HTTP response
     */
    actionPrototypeGetEmailsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [ActionEmail];

      return this.apiClient.callApi(
        '/Actions/{id}/emails', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries emails of Action.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ActionEmail>}
     */
    actionPrototypeGetEmails(id, opts) {
      return this.actionPrototypeGetEmailsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionPrototypePatchAttributesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionPrototypePatchAttributes(id2, opts) {
      return this.actionPrototypePatchAttributesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ActionEmail} and HTTP response
     */
    actionPrototypeUpdateByIdEmailsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'email': opts['email'],
        'body': opts['body'],
        'subject': opts['subject'],
        'status': opts['status'],
        'createdDate': opts['createdDate'],
        'receivedDate': opts['receivedDate'],
        'sendedDate': opts['sendedDate'],
        'open': opts['open'],
        'events': this.apiClient.buildCollectionParam(opts['events'], 'multi'),
        'templateType': opts['templateType'],
        'id': opts['id'],
        'recipients': this.apiClient.buildCollectionParam(opts['recipients'], 'multi'),
        'actionId': opts['actionId'],
        'attachments': this.apiClient.buildCollectionParam(opts['attachments'], 'multi')
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = ActionEmail;

      return this.apiClient.callApi(
        '/Actions/{id}/emails/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for emails.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ActionEmail}
     */
    actionPrototypeUpdateByIdEmails(id2, fk, opts) {
      return this.actionPrototypeUpdateByIdEmailsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionReplaceByIdPostActionsidReplaceWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionReplaceByIdPostActionsidReplace(id2, opts) {
      return this.actionReplaceByIdPostActionsidReplaceWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionReplaceByIdPutActionsidWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionReplaceByIdPutActionsid(id2, opts) {
      return this.actionReplaceByIdPutActionsidWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionReplaceOrCreatePostActionsReplaceOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/replaceOrCreate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionReplaceOrCreatePostActionsReplaceOrCreate(opts) {
      return this.actionReplaceOrCreatePostActionsReplaceOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionReplaceOrCreatePutActionsWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionReplaceOrCreatePutActions(opts) {
      return this.actionReplaceOrCreatePutActionsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    actionUpdateAllWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Actions/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    actionUpdateAll(opts) {
      return this.actionUpdateAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Action} and HTTP response
     */
    actionUpsertWithWhereWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'brand': opts['brand'],
        'category': opts['category'],
        'type': opts['type'],
        'master': opts['master'],
        'provider': opts['provider'],
        'url': opts['url'],
        'priority': opts['priority'],
        'unreadEmail': opts['unreadEmail'],
        'readEmail': opts['readEmail'],
        'selected': opts['selected'],
        'status': opts['status'],
        'countries': this.apiClient.buildCollectionParam(opts['countries'], 'multi'),
        'languages': this.apiClient.buildCollectionParam(opts['languages'], 'multi'),
        'metas': opts['metas'],
        'createdDate': opts['createdDate'],
        'updatedDate': opts['updatedDate'],
        'requestedDate': opts['requestedDate'],
        'refusedDate': opts['refusedDate'],
        'acceptedDate': opts['acceptedDate'],
        'inprogressDate': opts['inprogressDate'],
        'lastView': opts['lastView'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Action;

      return this.apiClient.callApi(
        '/Actions/upsertWithWhere', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Action}
     */
    actionUpsertWithWhere(opts) {
      return this.actionUpsertWithWhereWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
