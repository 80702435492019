import PropTypes from 'prop-types';

export default function ParametersSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.866" height="16.866" viewBox="0 0 16.866 16.866">
      <path
        d="M692.629-1109.567a8.442,8.442,0,0,1,8.433-8.433,8.442,8.442,0,0,1,8.433,8.433,8.442,8.442,0,0,1-8.433,8.433A8.442,8.442,0,0,1,692.629-1109.567Zm1,0a7.442,7.442,0,0,0,7.433,7.433,7.442,7.442,0,0,0,7.433-7.433,7.442,7.442,0,0,0-7.433-7.433A7.442,7.442,0,0,0,693.629-1109.567Zm8.711,2.767H697.1a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5.244a1.637,1.637,0,0,1,1.555-1.133,1.635,1.635,0,0,1,1.633,1.634,1.636,1.636,0,0,1-1.633,1.633A1.637,1.637,0,0,1,702.34-1106.8Zm1.555.134a.635.635,0,0,0,.634-.634.635.635,0,0,0-.634-.634.635.635,0,0,0-.633.612c0,.007,0,.014,0,.022s0,.014,0,.021A.635.635,0,0,0,703.9-1106.666Zm-7.3-5.167a1.635,1.635,0,0,1,1.633-1.634,1.636,1.636,0,0,1,1.555,1.133h5.244a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-5.244a1.637,1.637,0,0,1-1.555,1.134A1.636,1.636,0,0,1,696.6-1111.833Zm1,0a.635.635,0,0,0,.634.634.635.635,0,0,0,.634-.634.635.635,0,0,0-.634-.634A.635.635,0,0,0,697.6-1111.833Z" transform="translate(-692.629 1118)"
        fill={color}
      />
    </svg>
  );
}

ParametersSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
