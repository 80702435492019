import { Children, cloneElement, ReactElement, useMemo } from 'react';
import Accordion from '../../Accordion/Accordion';
import Button from '../../Button/Button';
import SubmitButton from '../../SubmitButton/SubmitButton';
import classes from './PanelFilterContentWithBlocks.module.css';
import { Trans } from 'react-i18next';
import DynamicScrollbar from '../../DynamicScrollbar/DynamicScrollbar';

interface PanelFilterContentBlockProps {
  readonly label: string;
  readonly filters?: any;
  readonly children:
    | Array<ReactElement<{ label?: string; name: string }>>
    | ReactElement;
  readonly setFilter?: Function;
  initState: boolean;
}

export function PanelFilterContentBlock({
  label,
  filters,
  children,
  setFilter,
  initState,
}: PanelFilterContentBlockProps) {
  return (
    <Accordion title={label} initState={initState}>
      <>
        {Children.map(children, (child) =>
          cloneElement(child, {
            filters,
            setFilter,
            name: child?.props.name,
            label: child?.props.label,
            titleMode: child?.props.titleMode,
          })
        )}
      </>
    </Accordion>
  );
}

interface PanelFilterContentWithBlocksProps {
  readonly loading: boolean;
  readonly filters: any;
  readonly reinitialization: Function;
  readonly setFilter: Function;
  readonly validate: Function;
  readonly t: Function;
  readonly blocks: ReactElement[];
}

export function PanelFilterContentWithBlocks({
  loading,
  filters,
  reinitialization,
  setFilter,
  validate,
  t,
  blocks,
}: PanelFilterContentWithBlocksProps) {
  const filtersCount = useMemo(() => {
    return filters
      ? Object.keys(filters).filter((key) => !!filters[key]).length
      : 0;
  }, [filters]);

  return (
    <>
      <DynamicScrollbar className={classes.main}>
        <div className={classes.containerFilters}>
          {Children.map(blocks, (block: ReactElement) =>
            cloneElement(block, {
              filters,
              setFilter,
              label: block?.props.label,
              children: block?.props.children,
            })
          )}
        </div>
      </DynamicScrollbar>
      <div className={classes.buttons}>
        <Button onClick={() => reinitialization()} type='reset' text='reset' />
        <SubmitButton
          txtBlock={
            <Trans i18nKey={`filtersCount`} count={filtersCount}>
              <>Filters ({{ count: filtersCount }})</>
            </Trans>
          }
          onClick={() => validate()}
          isLoading={loading}
        />
      </div>
    </>
  );
}
