import _ from 'lodash';
import nameForFlag from '../../../Utils/nameForFlag';
import classes from './LocalizationFlagCell.module.css';
import PropTypes from 'prop-types';

LocalizationArrayCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired
};

export default function LocalizationArrayCell({ item, field }) {
  
  return (
    <div className={classes.container}>
      {_.get(item, field, []).map((flag) => (
        <div
          key={flag}
          title={flag}
          data-highlightborder={flag}
          className={`flag-icon flag-icon-${nameForFlag(flag)}`}
        />
      ))}
    </div>
  );
}
