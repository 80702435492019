import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BrandApi, TermApi } from '../../swagger_client/src';
import FullscreenModal from '../FullscreenModal/FullscreenModal';
import LoaderSmall from '../_ui/LoaderSmall/LoaderSmall';
import SubmitButton from '../SubmitButton/SubmitButton';
import classes from './AutoMatchModale.module.css';
import AutoMatchModaleList from '../AutoMatchModaleList/AutoMatchModaleList';
import PropTypes from 'prop-types';
import brandDefinition from '../../proptypes/brand.definition';

AutoMatchModale.propTypes = {
  brand: PropTypes.shape(brandDefinition).isRequired,
  option: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default function AutoMatchModale({ brand, option, onClose }) {
  const brandApi = new BrandApi(),
    termApi = new TermApi(),
    [isLoading, setIsLoading] = useState(false),
    [where, setWhere] = useState(false),
    [showKeywords, setShowKeywords] = useState(false),
    [count, setCount] = useState(0),
    { t } = useTranslation();
   
  async function handleFetch() {
    setIsLoading(true);
    const { count } = await termApi.termCount({
      where: JSON.stringify(where),
    });
    setCount(count);
    setIsLoading(false);
  }

  async function handleSubmit() {
    setIsLoading(true);
    try {
      await brandApi.brandPrototypeAutoMatch(brand.id, {
        body: { options: { rematchAll: option === 'all' ? true : false } },
      });
      toast.success(
        t(option ? 'Auto match all done for' : 'Auto match done for') +
          ' ' +
          brand.label
      );
    } catch (e) {
      toast.error(t('Auto match fail for') + ' ' + brand.label);
    }
    onClose();
  }

  function createWhere() {
    //Complet automatch
    if(option === 'all'){
      const and = [{term: {regexp: `/${brand.autoMatchRegExp || ''}/i` }}];
      if(brand.autoMatchExcludeRegExp) and.push({term: {nlike: brand.autoMatchExcludeRegExp }});
      setWhere({and});
    } 
    //Partial automatch
    else {
      const or = {
        or: [
          {
            term: { regexp: `/${brand.autoMatchRegExp || ''}/i` },
            brandId: { inq: [null] },
          },
          {
            term: { regexp: `/${brand.autoMatchRegExp || ''}/i` },
            brandId: { inq: [brand.id] },
          },
        ],
      };

      if(brand.autoMatchExcludeRegExp ){
        setWhere({
          and: [
            {
              term: {nlike: brand.autoMatchExcludeRegExp },
              brandId: { inq: [brand.id] },
            },
            or
          ]
        });

      } else {
        setWhere(or);
      }
    }
  }

  useEffect(() => {
    createWhere();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(where) handleFetch();
    // eslint-disable-next-line
  }, [where]);

  return (
    <FullscreenModal onClose={onClose}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          {' '}
          {t(option ? 'auto match all' : 'auto match')}
        </h2>
        <div className={classes.desc}>
          {isLoading ? (
            <LoaderSmall />
          ) : (
            <span>
              {count}{' '}
              {t(
                count > 1
                  ? 'keywords will be impacted'
                  : 'keywords will be impacted'
              )}
            </span>
          )}
        </div>
        {count > 0 && <div
          className={classes.plus}
          onClick={() => setShowKeywords(!showKeywords)}
        >
          {t(
            showKeywords
              ? 'close'
              : count > 1
                ? 'see the keywords'
                : 'see the keyword'
          )}
        </div>
        }
        {showKeywords && <AutoMatchModaleList  where={where} />}
        <div className={classes.buttons}>
          <SubmitButton
            txt={t('launch auto match')}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </FullscreenModal>
  );
}
