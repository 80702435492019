import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { faExternalLinkAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import NewTooltip from '../../components/_ui/Tooltip/NewTooltip';
import classes from './ActionHeader.module.css';
import Google from './../../assets/icons/google.png';
import Bing from './../../assets/icons/bing.png';
import EyeSVG from '../../components/Icon/svg/Eye.svg';
import RemindSVG from '../../components/Icon/svg/Remind.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faCheckCircle,
  faTimesCircle,
  faBell,
  faEyeSlash,
  faBackspace,
  faStarOfLife,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import ShortMenuModale from '../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import { Status } from '../../types/action.interface';
import { Action, getStatusLabel } from '../../types/action.newBackend';
import { StoreContext } from '../../stores';
import Backend from '../../stores/newBackend';
import NewManagedTooltip from '../../components/_ui/Tooltip/NewManagedTooltip';

interface LabelWithIconProps {
  text: string;
  icon?: any;
  className?: string;
  textClassName?: string;
}

function LabelWithIcon({
  text,
  icon,
  className = '',
  textClassName = '',
}: LabelWithIconProps) {
  return (
    <div className={`${classes.labelWithIcon} ${className}`}>
      <div className={classes.icon}>{icon}</div>
      <div className={textClassName}>{text}</div>
    </div>
  );
}

interface ActionHeaderLabelProps {
  action: Action;
  from: string;
}

function ActionHeaderLabel({ action, from }: ActionHeaderLabelProps) {
  const { t } = useTranslation(),
    [copied, setCopied] = useState(false),
    copyDomainRef = useRef();

  const onCopyText = () => {
    (copyDomainRef.current as any)?.hide();
    setTimeout(() => {
      setCopied(true);
    }, 500);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className={classes.labelWrapper}>
      <div className={classes.label}>
        {from === '/actions' ? t('Actions') : t('To do')} -{' '}
        {action.brand?.label} /{' '}
        <span className={classes.labelName}>{action.domain}</span>
      </div>
      <div className={classes.labelActions}>
        <div className={classes.labelAction}>
          <NewTooltip content={t('See the website')} placement='bottom'>
            <a
              href={`https://www.${action.domain}`}
              target='_blank'
              rel='noreferrer'
              className={classes.externalLink}
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                size='lg'
                color='var(--color-link-menu)'
              />
            </a>
          </NewTooltip>
        </div>
        <div
          className={classes.labelAction}
          onClick={(e) => e.stopPropagation()}
        >
          <NewTooltip
            content={t('Copy the domain')}
            placement='bottom'
            ref={copyDomainRef}
          >
            <NewManagedTooltip
              content={
                <div className={classes.intentSuccess}>
                  {t('Domain name copied!')}
                </div>
              }
              direction='bottom'
              intent={copied ? 'success' : 'default'}
              arrow={false}
              active={copied}
            >
              <CopyToClipboard text={action.domain} onCopy={onCopyText}>
                <FontAwesomeIcon
                  icon={faCopy}
                  size='lg'
                  color='var(--color-link-menu)'
                />
              </CopyToClipboard>
            </NewManagedTooltip>
          </NewTooltip>
        </div>
      </div>
    </div>
  );
}

interface ActionHeaderProps {
  action: Action;
  from: string;
  onUpdate: (action: Action) => void;
  onRefresh: () => void;
  setIsOpenedInprogressModal: () => void;
}

function getWaiting(status: string, waiting: boolean) {
  if (status === Status.inprogress && waiting) {
    return classes.statusWaiting;
  }
  return classes.statusNotWaiting;
}

function getStatusTooltip(status: string, waiting: boolean, blockedReason: string) {
  if (status === 'blocked' && blockedReason) {
    return blockedReason;
  }
  if (status === 'blocked' && !blockedReason) {
    return 'Blocked unknown reason';
  }
  if (status === 'inprogress' && waiting) {
    return 'en attente de réponse';
  }
  return 'Statut de l’action';

}

function getStatusClass(status: string) {
  switch (status) {
  case Status.new:
    return classes.statusNew;
  case Status.reappearance:
    return classes.statusReappearance;
  case Status.ignored:
    return classes.statusIgnored;
  case Status.accepted:
    return classes.statusAccepted;
  case Status.refused:
    return classes.statusRefused;
  case Status.disappeared:
    return classes.statusDisappeared;
  case Status.requested:
    return classes.statusRequested;
  case Status.inprogress:
    return classes.statusInprogress;
  case Status.reminded:
    return classes.statusReminded;
  case Status.blocked:
    return classes.statusBlocked;
  default:
    return null;
  }
}

export default function ActionHeader({
  action,
  from = '/actions',
  onUpdate,
  onRefresh,
  setIsOpenedInprogressModal,
}: ActionHeaderProps) {
  const { t } = useTranslation();
  const { authStore } = useContext(StoreContext);
  let blockedReason = null;
  if (action.metas?.blockedReason && action.status === 'blocked') {
    if (typeof action.metas.blockedReason === 'string') {
      blockedReason = action.metas.blockedReason;
    } else {
      for (let i = 0; i < action.metas.blockedReason.length; i++) {
        if (action.blockedDate === action.metas.blockedReason[i].date) {
          blockedReason = action.metas.blockedReason[i].reason;
        }
      }
    }
  }

  const handleDoAction = async (link: string) => {
    try {
      const action = await Backend.executeLink(authStore.token, link);
      onUpdate(action);
      toast.success(t('Le statut de l\'annonceur est à jour.'));
    } catch (e) {
      toast.error('Une erreur est suvenue.');
    }
  };

  const handleInprogressAction = () => {
    setIsOpenedInprogressModal();
  };

  const handleNotificationAction = async (link: string) => {
    try {
      const result = await Backend.executeLink(authStore.token, link);
      if (result) {
        toast.success(t('Le statut de l\'annonceur est à jour.'));
      } else {
        toast.error('Une erreur est suvenue.');
      }
      onRefresh();
    } catch (e) {
      toast.error('Une erreur est suvenue.');
    }
  };

  const hasFirstAction =
    !!action &&
    !!action.links &&
    (!!action.links.markAsAccepted ||
      !!action.links.markAsBlocked ||
      !!action.links.markAsRefused ||
      !!action.links.markAsInprogress);

  const lastViewFormatted = dayjs(action.lastView).format('DD/MM/YYYY');
  const lastStatusDate = dayjs(action.history.slice(-1)[0].date).format(
    'DD/MM/YYYY'
  );

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        {/* <AngleLeftSVG /> */}
        <Link to={from} className={classes.back}>
          <FontAwesomeIcon icon={faChevronLeft} className={classes.back} />
        </Link>
        <ActionHeaderLabel action={action} from={from} />
        <NewTooltip content={action.country} placement='bottom'>
          <div
            className={`flag-icon flag-icon-${action.country?.toLocaleLowerCase()} ${
              classes.actionFlagIcon
            }`}
          ></div>
        </NewTooltip>
        <NewTooltip
          content={action.engine}
          placement='bottom'
          displayMode='flex'
        >
          {action.engine === 'google' && (
            <img alt='' src={Google} className={classes.actionEngineIcon} />
          )}
          {action.engine === 'bing' && (
            <img alt='' src={Bing} className={classes.actionEngineIcon} />
          )}
        </NewTooltip>
      </div>
      <div className={classes.right}>
        <NewTooltip
          content={t(getStatusTooltip(action.status, action.waiting ? true : false, blockedReason))}
          placement='bottom'
          displayMode='flex'
        >
          <div className={`${classes.status} ${getStatusClass(action.status)}`}>
            {getStatusLabel(action.status, t)}
            <sup className={`${getWaiting(action.status, action.waiting ? true : false)}`}>
              <FontAwesomeIcon
                icon={faStarOfLife}
                size='xs'
              />
            </sup>
          </div>
        </NewTooltip>
        <LabelWithIcon
          text={lastStatusDate}
          textClassName={classes.dateText}
          className={classes.firstDate}
        />
        <LabelWithIcon
          text={lastViewFormatted}
          textClassName={classes.dateText}
          icon={<EyeSVG height={20} color='var(--color-link-menu)' />}
        />
        <ShortMenuModale>
          <ShortMenuList>
            {action.links.markAsAccepted !== undefined ? (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text='accepter'
                testid='caac_update'
                onClick={() => {
                  handleDoAction(action.links.markAsAccepted as string);
                }}
              />
            ) : null}
            {!!action.links.markAsRefused && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text='refuser'
                testid='caac_update'
                onClick={() => {
                  handleDoAction(action.links.markAsRefused as string);
                }}
              />
            )}
            {!!action.links.markAsInprogress && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faBell}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text='Passer en Notifié'
                testid='caac_update'
                onClick={() => {
                  handleInprogressAction();
                }}
              />
            )}
            {!!action.links.markAsBlocked && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faBan}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text='Block'
                testid='caac_block'
                onClick={() => {
                  handleDoAction(action.links.markAsBlocked as string);
                }}
              />
            )}
            {!!action.links.forceReminderCycle && (
              <ShortMenuAction
                icon={<RemindSVG color='var(--color-link-menu)' />}
                text='relancer automatiquement'
                iconMode
                onClick={() => {
                  handleNotificationAction(
                    action.links.forceReminderCycle as string
                  );
                }}
              />
            )}
          </ShortMenuList>
          <ShortMenuList separator={hasFirstAction}>
            {!!action.links.markAsIgnored && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text='ignorer'
                testid='caac_update'
                onClick={() => {
                  handleDoAction(action.links.markAsIgnored as string);
                }}
              />
            )}
            {!!action.links.cancelIgnore && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    color='var(--color-link-menu)'
                  />
                }
                iconMode
                text="don't ignore"
                testid='caac_update'
                onClick={() => {
                  handleDoAction(action.links.cancelIgnore as string);
                }}
              />
            )}
            {!!action.links.cancel && (
              <ShortMenuAction
                icon={
                  <FontAwesomeIcon
                    icon={faBackspace}
                    color='var(--color-link-menu)'
                  />
                }
                text="annuler l'action"
                iconMode
                onClick={() => {
                  handleDoAction(action.links.cancel as string);
                }}
              />
            )}
          </ShortMenuList>
        </ShortMenuModale>
      </div>
    </div>
  );
}
