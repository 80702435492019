import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import classes from './DateFilter.module.css';
import DateFilterResume from './DateFilterResume/DateFilterResume';
import DateFilterSelectDates from './DateFilterSelectDates/DateFilterSelectDates';
import { DateState } from '../../../types/dateState.interface';
import DateFilterPopin from './DateFilterPopin/DateFilterPopin';

dayjs.extend(Calendar);

interface Props {
  readonly name: string
  readonly filters: any
  readonly dateMin: Date
  readonly label: string
  readonly setFilter: Function
  readonly handleConfirm: Function
  readonly setDateState: Function
  readonly setToggle: Function
  readonly t: Function
  readonly locale: any
  readonly toggle: boolean
  readonly dateState: DateState
}

export default function DateFilterView({
  filters,
  dateState,
  t,
  name,
  dateMin,
  handleConfirm,
  setDateState,
  label,
  setToggle,
  setFilter,
  locale,
  toggle,
}: Props) {
  return (
    <div className={classes.container}>
      <DateFilterResume
        filters={filters}
        name={name}
        label={label}
        handleConfirm={handleConfirm}
        setFilter={setFilter}
        setDateState={setDateState}
        dateMin={dateMin}
        setToggle={setToggle}
        locale={locale}
        dateState={dateState}
        toggle
      />
      <DateFilterSelectDates
        filters={filters}
        name={name}
        dateState={dateState}
        setFilter={setFilter}
        setDateState={setDateState}
        setToggle={setToggle}
      />
      {toggle && (
        <DateFilterPopin
          filters={filters}
          name={name}
          label={label}
          handleConfirm={handleConfirm}
          setFilter={setFilter}
          setDateState={setDateState}
          dateMin={dateMin}
          setToggle={setToggle}
          locale={locale}
          dateState={dateState}
          toggle
        />
      )}
    </div>
  );
}
