/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.41.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Brand from '../model/Brand';
import CompanyAccount from '../model/CompanyAccount';
import CompanyAccountBrand from '../model/CompanyAccountBrand';
import Term from '../model/Term';

/**
* Brand service.
* @module api/BrandApi
* @version 4.41.0
*/
export default class BrandApi {

    /**
    * Constructs a new BrandApi. 
    * @alias module:api/BrandApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandCountWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandCount(opts) {
      return this.brandCountWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandCreate(opts) {
      return this.brandCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    brandCreateChangeStreamGetBrandsChangeStreamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'options': opts['options']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Brands/change-stream', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    brandCreateChangeStreamGetBrandsChangeStream(opts) {
      return this.brandCreateChangeStreamGetBrandsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    brandCreateChangeStreamPostBrandsChangeStreamWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Brands/change-stream', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    brandCreateChangeStreamPostBrandsChangeStream(opts) {
      return this.brandCreateChangeStreamPostBrandsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandDeleteByIdWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandDeleteById(id) {
      return this.brandDeleteByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandExistsGetBrandsidExistsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}/exists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandExistsGetBrandsidExists(id) {
      return this.brandExistsGetBrandsidExistsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandExistsHeadBrandsidWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandExistsHeadBrandsid(id) {
      return this.brandExistsHeadBrandsidWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Brand>} and HTTP response
     */
    brandFindWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Brand];

      return this.apiClient.callApi(
        '/Brands', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Brand>}
     */
    brandFind(opts) {
      return this.brandFindWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandFindByIdWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandFindById(id, opts) {
      return this.brandFindByIdWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandFindOneWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/findOne', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandFindOne(opts) {
      return this.brandFindOneWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandPatchOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandPatchOrCreate(opts) {
      return this.brandPatchOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Match Terms with autoMatch param in Brand
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandPrototypeAutoMatchWithHttpInfo(id, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}/autoMatch', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Match Terms with autoMatch param in Brand
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandPrototypeAutoMatch(id, opts) {
      return this.brandPrototypeAutoMatchWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts companyAccounts of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandPrototypeCountCompanyAccountsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts companyAccounts of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandPrototypeCountCompanyAccounts(id, opts) {
      return this.brandPrototypeCountCompanyAccountsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts terms of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandPrototypeCountTermsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/{id}/terms/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts terms of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandPrototypeCountTerms(id, opts) {
      return this.brandPrototypeCountTermsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in companyAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    brandPrototypeCreateCompanyAccountsWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in companyAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    brandPrototypeCreateCompanyAccounts(id2, opts) {
      return this.brandPrototypeCreateCompanyAccountsWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in terms of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    brandPrototypeCreateTermsWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'enable': opts['enable'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Brands/{id}/terms', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in terms of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    brandPrototypeCreateTerms(id2, opts) {
      return this.brandPrototypeCreateTermsWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all companyAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    brandPrototypeDeleteCompanyAccountsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all companyAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    brandPrototypeDeleteCompanyAccounts(id) {
      return this.brandPrototypeDeleteCompanyAccountsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all terms of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    brandPrototypeDeleteTermsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Brands/{id}/terms', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all terms of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    brandPrototypeDeleteTerms(id) {
      return this.brandPrototypeDeleteTermsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    brandPrototypeDestroyByIdCompanyAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    brandPrototypeDestroyByIdCompanyAccounts(id, fk) {
      return this.brandPrototypeDestroyByIdCompanyAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    brandPrototypeDestroyByIdTermsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Brands/{id}/terms/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    brandPrototypeDestroyByIdTerms(id, fk) {
      return this.brandPrototypeDestroyByIdTermsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check the existence of companyAccounts relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    brandPrototypeExistsCompanyAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/rel/{fk}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check the existence of companyAccounts relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    brandPrototypeExistsCompanyAccounts(id, fk) {
      return this.brandPrototypeExistsCompanyAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    brandPrototypeFindByIdCompanyAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    brandPrototypeFindByIdCompanyAccounts(id, fk) {
      return this.brandPrototypeFindByIdCompanyAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    brandPrototypeFindByIdTermsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Brands/{id}/terms/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    brandPrototypeFindByIdTerms(id, fk) {
      return this.brandPrototypeFindByIdTermsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries companyAccounts of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CompanyAccount>} and HTTP response
     */
    brandPrototypeGetCompanyAccountsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [CompanyAccount];

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries companyAccounts of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CompanyAccount>}
     */
    brandPrototypeGetCompanyAccounts(id, opts) {
      return this.brandPrototypeGetCompanyAccountsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries terms of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Term>} and HTTP response
     */
    brandPrototypeGetTermsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Term];

      return this.apiClient.callApi(
        '/Brands/{id}/terms', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries terms of Brand.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Term>}
     */
    brandPrototypeGetTerms(id, opts) {
      return this.brandPrototypeGetTermsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccountBrand} and HTTP response
     */
    brandPrototypeLinkCompanyAccountsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'id': opts['id'],
        'companyAccountId': opts['companyAccountId'],
        'brandId': opts['brandId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccountBrand;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/rel/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccountBrand}
     */
    brandPrototypeLinkCompanyAccounts(id2, fk, opts) {
      return this.brandPrototypeLinkCompanyAccountsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandPrototypePatchAttributesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandPrototypePatchAttributes(id2, opts) {
      return this.brandPrototypePatchAttributesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the companyAccounts relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    brandPrototypeUnlinkCompanyAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/rel/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the companyAccounts relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    brandPrototypeUnlinkCompanyAccounts(id, fk) {
      return this.brandPrototypeUnlinkCompanyAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    brandPrototypeUpdateByIdCompanyAccountsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/Brands/{id}/companyAccounts/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for companyAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    brandPrototypeUpdateByIdCompanyAccounts(id2, fk, opts) {
      return this.brandPrototypeUpdateByIdCompanyAccountsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    brandPrototypeUpdateByIdTermsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'enable': opts['enable'],
        'localizationId': opts['localizationId'],
        'options': opts['options'],
        'id': opts['id'],
        'brandId': opts['brandId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/Brands/{id}/terms/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for terms.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    brandPrototypeUpdateByIdTerms(id2, fk, opts) {
      return this.brandPrototypeUpdateByIdTermsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandReplaceByIdPostBrandsidReplaceWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandReplaceByIdPostBrandsidReplace(id2, opts) {
      return this.brandReplaceByIdPostBrandsidReplaceWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandReplaceByIdPutBrandsidWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandReplaceByIdPutBrandsid(id2, opts) {
      return this.brandReplaceByIdPutBrandsidWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandReplaceOrCreatePostBrandsReplaceOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/replaceOrCreate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandReplaceOrCreatePostBrandsReplaceOrCreate(opts) {
      return this.brandReplaceOrCreatePostBrandsReplaceOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandReplaceOrCreatePutBrandsWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandReplaceOrCreatePutBrands(opts) {
      return this.brandReplaceOrCreatePutBrandsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    brandUpdateAllWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Brands/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    brandUpdateAll(opts) {
      return this.brandUpdateAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    brandUpsertWithWhereWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'label': opts['label'],
        'name': opts['name'],
        'autoMatchRegExp': opts['autoMatchRegExp'],
        'validated': opts['validated'],
        'createNotification': opts['createNotification'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Brands/upsertWithWhere', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    brandUpsertWithWhere(opts) {
      return this.brandUpsertWithWhereWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
