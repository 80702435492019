import PropTypes from 'prop-types';
import classes from './Counter.module.css';
import { Trans } from 'react-i18next';

Counter.propTypes = {
  color: PropTypes.oneOf([
    'normal',
    'secondary',
    'alert',
    'valid',
    'green',
    'grey',
    'azure',
    'grey',
    'orange',
    'red',
    'dark-blue',
    'dark-green',
  ]),
  txt: PropTypes.string,
  txtPlural: PropTypes.string,
  count: PropTypes.number.isRequired,
};

Counter.defaultProps = {
  color: 'normal',
};

export default function Counter({ txt, txtPlural, count, color }) {

  const plural = txtPlural || txt;

  return (
    <div className={classes.container}>
      <div className={classes.counter} data-color={color}>
        {count}
      </div>
      <div>
        <Trans key={txt} count={count}>
          {count > 1 ? plural : txt}
        </Trans>
      </div>
    </div>
  );
}


