import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { CategoryApi } from './../../../swagger_client';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { ParseUseFormErrors } from '../../../Utils/utils';
import FullscreenModal from '../../FullscreenModal/FullscreenModal';
import FormInputTxt from '../../Form/FormInputTxt/FormInputTxt';
import FormSummaryErrors from '../../Form/FormSummaryErrors/FormSummaryErrors';
import CancelButton from '../../CancelButton/CancelButton';
import classes from './TagForm.module.css';
import { useTranslation } from 'react-i18next';
import useGetTags from '../../Hooks/data/getTags/getTags';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import FormInputRadio from '../../Form/FormInputRadio/FormInputRadio';
import FormInputSelect from '../../Form/FormInputSelect/FormInputSelect';
import { toast } from 'react-toastify';
import useGetBrands from '../../Hooks/data/getBrands';
import { StoreContext } from '../../../stores';
import SubmitButton from '../../SubmitButton/SubmitButton';
import tagDefinition from '../../../proptypes/tag.definition';

TagForm.propTypes = {
  fields: PropTypes.shape(tagDefinition),
  setClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

function TagForm({ fields, setClose, update }) {
  const
    { authStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { tagLoading, tags } = useGetTags(),
    { brandsLoading, brands } = useGetBrands(),
    [isLoading, setIsLoading] = useState(),
    { register, handleSubmit, control, errors } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: fields
        ? fields
        : {
          scope: 'tag',
          parent: '',
          brandOwnerId: '',
          visibility: 'owner',
        },
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

  async function handleSubmitForm(formValues) {
    setIsLoading(true);
    const tag = {
        label: formValues.label,
        scope: formValues.scope,
        visibility: formValues.visibility,
      },
      categoryApi = new CategoryApi();

    if (tag.value) {
      delete tag.value;
    }

    if (formValues.brandOwnerId) {
      tag.brandOwnerId = formValues.brandOwnerId.value;
    } else {
      tag.brandOwnerId = _.first(brands).id;
      formValues.brandOwnerId = {
        label: _.first(brands).name,
        value: _.first(brands).id,
      };
    }

    //explicit pass "null" for delete parentId
    if(formValues.parent === 'delete'){
      tag.parentId = null;
    } else if(formValues.parent) {
      tag.parentId = formValues.parent.value;
    }

    if (fields && fields.id) {
      try {
        const newTag = await categoryApi.categoryPrototypePatchAttributes(
          fields.id, { body: tag }
        );
        update({ ...newTag, owner: formValues.brandOwnerId, parent: _.get(formValues, 'parent.originalTag') });
        toast(t('tag updated'));
      } catch (e) {
        toast.error(t(e));
      } 
    } else {
      try {
        const newTag = await categoryApi.categoryCreate({ body: tag });
        if (newTag.parentId) newTag.parent = _.find(tags, { id: newTag.parentId });
        update({ ...newTag, owner: formValues.brandOwnerId });
        toast(t('tag created'));
      } catch (e) {
        toast.error(t(e));
      } 
      setClose();
    }
  }

  return (
    <FullscreenModal onClose={setClose}>
      <form className={classes.form} onSubmit={handleSubmit(handleSubmitForm)}>
        <FormInputTxt
          register={register({
            required: { value: true, message: t('The name is required') },
          })}
          errors={errors}
          label={t('Name of tag')}
          name="label"
          placeholder={t('enter a name')}
        />
        <FormInputRadio
          register={register}
          label={t('Type of tag')}
          name="scope"
          scopeChoices={[
            { label: 'domain', value: 'tag' },
            { label: 'term', value: 'term' },
          ]}
        />
        {brandsLoading ? (
          <LoaderSmall />
        ) : (
          <FormInputSelect
            control={control}
            label="brands"
            name='brandOwnerId'
            defaultValue={brands && brands.length ? {
              label: _.first(brands).label,
              value: _.first(brands).id,
            } : null}
            placeholder={'Select a brand'}
            values={_.map(brands, (brand) => ({
              label: brand.label,
              value: brand.id,
            }))}
          />
        )}
        {!tagLoading ? (
          <FormInputSelect
            control={control}
            isClearable={true}
            classNamePrefix="place"
            label={t('Parent tag')}
            {...register('parent',
              {
                setValueAs: v => v ? v: 'delete',
              }
            )}
            name={'parent'}
            defaultValue={tags && tags.length ? {
              label: _.first(tags).label,
              value: _.first(tags).id,
            } : null}
            direction="top"
            placeholder="(optional) Select a parent tag"
            values={_.map(tags, (o) => {
              delete o.options;
              return o;
            })}
          />
        ) : (
          <LoaderSmall />
        )}

        {authStore.isAdmin && (
          <FormInputRadio
            register={register}
            label={t('visibility')}
            name="visibility"
            scopeChoices={[
              { label: 'all', value: 'all' },
              { label: 'my team', value: 'owner' },
            ]}
          />
        )}
        {ParseUseFormErrors(errors).length > 0 && (
          <FormSummaryErrors errors={ParseUseFormErrors(errors)} />
        )}
        <div className={classes.containerButtons}>
          <CancelButton onClose={setClose} txt="cancel" />
          <SubmitButton
            txt={t(fields && fields.id ? 'update the tag' : 'create the tag')}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </form>
    </FullscreenModal>
  );
}

export default observer(TagForm);