import { makeAutoObservable } from 'mobx';
import i18next from 'i18next';

export default class UiStore {

  appLoaded = false;
  device;
  title;
  sizes = {
    desktop: 2560,
    laptopL: 1440,
    laptop: 1024,
    laptopS: 980,
    tablet: 768,
    mobileL: 425,
    mobileM: 375,
    mobileS: 320,
  };

  constructor() {
    this.resize();
    makeAutoObservable(this);
    window.onresize = () => this.resize();
  }

  resize() {
    Object.keys(this.sizes).forEach((size) => {
      if (this.sizes[size] >= window.innerWidth) {
        this.device = size;
      }
    });

    if (!this.device) {
      if (window.innerWidth < 320) {
        this.device = 'mobile';
      }
      if (window.innerWidth > 2560) {
        this.device = 'desktop';
      }
    }
    
    return this.device;
  }

  setCurrentRoute(titleRoute) {
    this.currentRoute = titleRoute;
  }

  setAppLoaded(value) {
    this.appLoaded = value;
  }

  setTitle(title) {
    document.title = `${i18next.t('Monibrand')}${title ? ' - ' + title : ''}`;
  }
}
