import dayjs from 'dayjs';
import { DateState } from '../../../../types/dateState.interface';
import { dateSelect } from './date.select';
import classes from './DateFilterResume.module.css';
import Select from 'react-select';
import { useMemo } from 'react';
import { filterSelectStyle } from '../../../../Utils/reactSelectStyles';

interface props {
  readonly t: Function
  readonly handleSelect: Function
  readonly dateState: DateState
  readonly name: string
  readonly filters: any
}

export default function DateFilterResumeView({
  t,
  dateState,
  handleSelect,
  name,
  filters,
}: props) {
  const options = useMemo(() => {
    return dateSelect.map(choice => ({
      label: t(choice.period),
      value: {
        startDate: choice.start,
        endDate: choice.end,
        key: 'selection',
      }
    }));
  }, [ t ]);

  const selectedOption = useMemo(() => {
    if (filters && name && !filters[name]) {
      return null;
    }
    return dateState && dateState.length > 0 && dateState[0] && dateState[0].startDate && dateState[0].endDate ?
      options.find(ds =>
        dayjs(ds.value.startDate).isSame(dateState[0].startDate, 'day') &&
        dayjs(ds.value.endDate).isSame(dateState[0].endDate, 'day') /* &&
        ds.value.key === 'selection' */
      ) || null :
      null;
  }, [ options, dateState, filters, name ]);

  return (
    <div className={classes.container}>
      <div className={classes.choicesList}>
        <div className={classes.choicesListLabel}>{t('Period')}</div>
        <Select
          name="presence"
          placeholder={t('Select the period')}
          menuPosition="absolute"
          styles={filterSelectStyle}
          isClearable
          className={`basic-multi-select ${selectedOption ? classes.selectOptionSelected : ''}`}
          value={selectedOption}
          options={options}
          onChange={value => handleSelect(value?.value)}
        />
      </div>
    </div>
  );
}
