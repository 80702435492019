import PropTypes from 'prop-types';

export default function ChartBarSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 27 20">
      <path
        d="M 16.898438 13.332031 L 18.851562 13.332031 C 19.199219 13.308594 19.476562 13.023438 19.5 12.667969 L 19.5 5.667969 C 19.476562 5.308594 19.199219 5.023438 18.851562 5 L 16.898438 5 C 16.550781 5.023438 16.273438 5.308594 16.25 5.667969 L 16.25 12.667969 C 16.273438 13.023438 16.550781 13.308594 16.898438 13.332031 Z M 21.773438 13.332031 L 23.726562 13.332031 C 24.074219 13.308594 24.351562 13.023438 24.375 12.667969 L 24.375 0.667969 C 24.351562 0.308594 24.074219 0.0234375 23.726562 0 L 21.773438 0 C 21.425781 0.0234375 21.148438 0.308594 21.125 0.667969 L 21.125 12.667969 C 21.148438 13.023438 21.425781 13.308594 21.773438 13.332031 Z M 7.148438 13.332031 L 9.101562 13.332031 C 9.449219 13.308594 9.726562 13.023438 9.75 12.667969 L 9.75 9 C 9.726562 8.640625 9.449219 8.359375 9.101562 8.332031 L 7.148438 8.332031 C 6.800781 8.359375 6.523438 8.640625 6.5 9 L 6.5 12.667969 C 6.523438 13.023438 6.800781 13.308594 7.148438 13.332031 Z M 12.023438 13.332031 L 13.976562 13.332031 C 14.324219 13.308594 14.601562 13.023438 14.625 12.667969 L 14.625 2.332031 C 14.601562 1.976562 14.324219 1.691406 13.976562 1.667969 L 12.023438 1.667969 C 11.675781 1.691406 11.398438 1.976562 11.375 2.332031 L 11.375 12.667969 C 11.398438 13.023438 11.675781 13.308594 12.023438 13.332031 Z M 25.1875 16.667969 L 3.25 16.667969 L 3.25 0.832031 C 3.25 0.375 2.886719 0 2.4375 0 L 0.8125 0 C 0.363281 0 0 0.375 0 0.832031 L 0 18.332031 C 0 19.253906 0.726562 20 1.625 20 L 25.1875 20 C 25.636719 20 26 19.625 26 19.167969 L 26 17.5 C 26 17.039062 25.636719 16.667969 25.1875 16.667969 Z M 25.1875 16.667969"
        fill={color}
      />
    </svg>
  );
}

ChartBarSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
