import _ from 'lodash';
import { CompanyAccountApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';

export default function useCompanyAccountsFilter(name:string) {
  const companyAccountApi = new CompanyAccountApi(),
    { data } = useGet<{ count: number } | undefined>((f: Object) =>
      companyAccountApi.companyAccountCount(f)
    ),
    { t } = useTranslation();

  async function promiseOptions(inputValue?: string) {
    const filter: { limit: number; order: string; where?: Object } = {
      limit: 50,
      order: 'name asc',
    };

    if (inputValue) {
      filter.where = { name: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } };
    }

    const items = await companyAccountApi.companyAccountFind({
      filter: JSON.stringify(filter),
    });

    const options = _.map(items, (o) => {
      return {
        label: o.label || o.name,
        value: name === 'name' ? o.name : o.id,
      };
    });
    return options
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('select company account'),
      isMulti: true,
    },
    count: data?.count,
    altName: 'company account'
  };
}
