import { useState } from 'react';
import SubmitButton from '../../SubmitButton/SubmitButton';
import CompanyAccountForm from '../CompanyAccountForm';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

CompanyAccountCreateButton.propTypes = {
  update: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default function CompanyAccountCreateButton({ update, isLoading }) {
  const [isOpenForm, setIsOpenForm] = useState();
  const { t } = useTranslation();

  return (
    <>
      <SubmitButton
        isLoading={isLoading}
        onClick={() => setIsOpenForm(true)}
        txt={t('Create a Company Account')}
      />
      {isOpenForm && (
        <CompanyAccountForm
          update={update}
          setClose={() => setIsOpenForm(false)}
        />
      )}
    </>
  );
}
