export enum pictos {
    angleLeft = '\e900',
    archive = '\e901',
    bell = '\e902',
    bellSlash = '\e903',
    building = '\e904',
    bullseye = '\e905',
    checkCircle = '\e906',
    copyright = '\e908',
    dotCircle = '\e909',
    eye = '\e90a',
    eyeSlash = '\e90b',
    pen = '\e90c',
    tag = '\e90d',
    timesCircle = '\e90e',
    trash = '\e90f',
    calendarClock = '\e910',
    collecte = '\e911',
    dashboard = '\e912',
    notifications = '\e913',
    monitoring = '\e914',
    officiel = '\e915',
    parametres = '\e916',
    switchAccount = '\e917',
  }