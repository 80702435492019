import { useState, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './NavFilter.module.css';
import FiltersDescriptions from '../FiltersDescriptions/FiltersDescriptions';
import PanelFilter from '../PanelFilter/NewPanelFilter';
import Counters from './Counters';
import Filters from './NewFilters';

// This component displays the block for updatable filters

// This component displays the block for counters

// This component displays the whole block for filters
// Notice that the updatable filtes can be disabled with the
// displayFilters property

function NavFilter({
  counters,
  children,
  isLoading,
  pageStore,
  displayFilters = true,
  standaloneFilters,
}: {
  counters: any[];
  children: Array<ReactElement>;
  standaloneFilters: Array<ReactElement>;
  isLoading: boolean;
  pageStore: any;
  displayFilters: boolean;
}) {
  const [standAloneFilter, setStandAlone] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  function handleOpenPanel() {
    setIsOpen(true);
  }

  function childrenFilter(o: any) {
    //for advertiser dates group filter
    if (
      o.props.label === 'dates' &&
      standAloneFilter.match(
        /lastView|inprogressDate|updatedDate|acceptedDate|date|refusedDate/
      )
    ) {
      return true;
    } else {
      return o.props.label === standAloneFilter;
    }
  }

  return (
    <div className={classes.container}>
      {displayFilters && (
        <>
          <Filters
            pageStore={pageStore}
            standAloneFilter={standAloneFilter}
            setStandAlone={setStandAlone}
            childrenFilter={childrenFilter}
            handleOpenPanel={handleOpenPanel}
          >
            {standaloneFilters}
          </Filters>
          <div className={classes.counters}>
            <Counters isLoading={isLoading} counters={counters} />
          </div>
        </>
      )}

      {pageStore?.filtersAsArray?.length > 0 && (
        <>
          <div className={classes.description}>
            <FiltersDescriptions
              filters={pageStore.computedFiltersDescription}
              deleteFilter={(name) => pageStore.deleteFilter(name)}
              revertFilter={(filter) => pageStore.revertFilter(filter)}
              setCurrentHoverFilter={() => {}}
              clearAll={() => pageStore.reinitFilters()}
            />
          </div>
          {!displayFilters && (
            <div className={classes.counters}>
              <Counters isLoading={isLoading} counters={counters} />
            </div>
          )}
        </>
      )}
      {displayFilters && (
        <div className={classes.panel}>
          <PanelFilter
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            pageStore={pageStore}
            filtersElements={children}
          />
        </div>
      )}
    </div>
  );
}

export default observer(NavFilter);
