import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: 'submit',
  disabled: false
};

export default function Button({ text, onClick, type ,disabled}) {
  const { t } = useTranslation();

  return <button type={type} onClick={onClick} disabled={disabled}>{t(text)}</button>;
}