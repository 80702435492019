import { useEffect, useState } from 'react';
import classes from './MenuFooter.module.css';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useMenuExpanded } from '../MenuContext';
import { useTranslation } from 'react-i18next';
import AngleLeftSVG from '../../Icon/svg/AngleLeft.svg';
import AngleRightSVG from '../../Icon/svg/AngleRight.svg';

MenuFooter.propTypes = {
  toggleExpanded: PropTypes.func.isRequired,
  menuMinimized: PropTypes.bool
};

function MenuFooter({ toggleExpanded, menuMinimized }) {
  const expanded = useMenuExpanded(),
    { t } = useTranslation(),
    [ applyExpanded, setApplyExpanded ] = useState(expanded);

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setApplyExpanded(true);
      }, 200);
    } else {
      setApplyExpanded(false);
    }
  }, [ expanded ]);

  return !menuMinimized ? (
    <div className={`${classes.footer} ${!expanded ? classes.collapsed : ''}`} onClick={toggleExpanded}>
      { applyExpanded ? (
        <AngleLeftSVG color="var(--color-link-menu)" />
      ) : (
        <AngleRightSVG color="var(--color-link-menu)" />
      ) }
      { applyExpanded && (<span className={classes.footerText}>{t('Reduce menu')}</span>) }
    </div>
  ) : null;
}

export default observer(MenuFooter);
