import { useContext, createContext, useState, ReactNode } from 'react';

const generateCacheKey = () => {
  const date = new Date();

  return date.toISOString();
};

const ActionNoteContext = createContext({
  cacheKey: generateCacheKey(),
  updateCacheKey: () => {},
});

export const ActionNoteProvider = ({ children }: { children: ReactNode }) => {
  const [cacheKey, setCacheKey] = useState(generateCacheKey());
  const handleUpdate = () => {
    setCacheKey(generateCacheKey());
  };

  return (
    <ActionNoteContext.Provider
      value={{ cacheKey, updateCacheKey: handleUpdate }}
    >
      {children}
    </ActionNoteContext.Provider>
  );
};

export const useActionNote = () => {
  const { cacheKey, updateCacheKey } = useContext(ActionNoteContext);
  return { cacheKey, updateCacheKey };
};
