import { DateRange } from 'react-date-range-ts';
import { DateState } from '../../../../types/dateState.interface';
import CancelButton from '../../../CancelButton/CancelButton';
import SubmitButton from '../../../SubmitButton/SubmitButton';
import classes from './DateFilterPopin.module.css';

interface props {
  readonly handleConfirm: Function
  readonly setDateState: Function
  readonly setToggle: Function
  readonly t: Function
  readonly locale: any
  readonly dateState: DateState
}

export default function DateFilterPopinView({setDateState,t,dateState,locale,setToggle,handleConfirm}:props){

  return <div className={classes.datePickerContainer}>
    <div className={classes.datePicker}>
      <DateRange
        editableDateInputs={true}
        onChange={(item) => setDateState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        rangeColors={['#faba22']}
        ranges={dateState}
        direction="vertical"
        locale={locale}
        startDatePlaceholder={t('Early')}
        endDatePlaceholder={t('End date')}
      />
      <div className={classes.containerButtons}>
        <CancelButton
          txt={t('cancel')}
          onClose={() => setToggle(false)}
        />
        <SubmitButton
          txt={t('confirm')}
          isLoading={false}
          onClick={() => handleConfirm()}
        />
      </div>
    </div>
  </div>;
}