import PropTypes from 'prop-types';

export default function MonitoringSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
      <path
        d="M4.5,15a.5.5,0,1,1,0-1h7a.5.5,0,0,1,0,1ZM2,13a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2ZM1,2v9a1,1,0,0,0,1,1H13.292l-1.554-1.555a3,3,0,0,1-4-4.412L6.851,4.219,5.59,8a.5.5,0,0,1-.469.342H5.116a.5.5,0,0,1-.471-.332L4.1,6.5H2.565a.5.5,0,0,1,0-1H4.457a.5.5,0,0,1,.471.332l.172.479,1.19-3.569a.5.5,0,0,1,.923-.06l1.318,2.7a3,3,0,0,1,3.914,4.354l2.1,2.1A1,1,0,0,0,15,11V2a1,1,0,0,0-1-1H2A1,1,0,0,0,1,2ZM8,8a2,2,0,1,0,.971-1.714l.491,1.006.511-.735a.5.5,0,0,1,.822.571l-1,1.436a.5.5,0,0,1-.41.214.272.272,0,0,1-.039,0A.5.5,0,0,1,8.936,8.5l-.7-1.437A1.987,1.987,0,0,0,8,8Z"
        fill={color}
      />
    </svg>
  );
}

MonitoringSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
