import { observer } from 'mobx-react-lite';
import AdvertisersStore from '../../../pages/Advertisers/advertisers.store';
import AdvertiserActionsCellView from './AdvertiserActionsCellView';
import { Action } from '../../../types/action.newBackend';

interface Props {
  readonly item: Action;
  readonly store: AdvertisersStore;
}

function AdvertiserActionsCell(props: Props) {
  return <AdvertiserActionsCellView {...props} />;
}

export default observer(AdvertiserActionsCell);
