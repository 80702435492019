import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

function testMatchSize(expectedSize: number, maxMode = true) {
  if (typeof window === 'undefined') {
    return true;
  }
  if (maxMode) {
    return window.innerWidth <= expectedSize;
  } else {
    return window.innerWidth >= expectedSize;
  }
}

export default function useScreenSize(expectedSize: number, maxMode = true) {
  // Initialize the desktop size to an accurate value on initial state set
  const [isMatchSize, setMatchSize] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    function autoResize() {
      setMatchSize(testMatchSize(expectedSize, maxMode));
    }

    window.addEventListener('resize', throttle(autoResize, 200));

    // This is likely unnecessary, as the initial state should capture
    // the size, however if a resize occurs between initial state set by
    // React and before the event listener is attached, this
    // will just make sure it captures that.
    autoResize();

    // Return a function to disconnect the event listener
    return () => window.removeEventListener('resize', autoResize);
  }, [maxMode, expectedSize]);

  return isMatchSize;
}
