import Tippy from '@tippyjs/react';
import { ReactNode, forwardRef } from 'react';
import classes from './NewTooltip.module.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/shift-away.css';

interface Props {
  content: any;
  placement?: any;
  className?: string;
  children: ReactNode;
  displayMode?: string;
}

function NewTooltip({ content, children, placement, className = '', displayMode }: Props, ref: any) {
  return (
    <Tippy
      content={content}
      placement={placement}
      className={`${classes.box} ${className}`}
      animation={placement === 'bottom' ? 'shift-toward' : 'shift-away'}
    >
      <div className={`${classes.wrapper} ${displayMode === 'flex' ? classes.flex : ''}`}>{children}</div>
    </Tippy>
  );
}

export default forwardRef(NewTooltip);
