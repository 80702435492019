import PropTypes from 'prop-types';
import classes from './FormInputRadio.module.css';
import { useTranslation } from 'react-i18next';
import selectOptions from '../../../proptypes/selectOptions.definition';

FormInputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  scopeChoices: PropTypes.arrayOf(PropTypes.shape(selectOptions)).isRequired,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default function FormInputRadio({
  name,
  label,
  register,
  errors,
  scopeChoices,
  onChange
}) {
  const { t } = useTranslation();
  
  return (
    <div className={classes.container}>
      <label className={classes.label} htmlFor={name}>
        {t(label)}
      </label>
      <div className={classes.choicecontainer}>
        {scopeChoices.map((choice) => (
          <div key={choice.value} >
            <input
              className={classes.input}
              name={name}
              type="radio"
              onChange={onChange}
              data-testid={name}
              ref={register()}
              id={`scope-${choice.label}`}
              value={choice.value}
              data-error={!!errors}
            />
            <label htmlFor={`scope-${choice.label}`} className={classes.labelRadio}>
              {t(choice.label)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
