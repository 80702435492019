import PropTypes from 'prop-types';
import { getScale, getDimensions } from './IconSvgUtils';

export default function PaperPlaneSVG({ color, height }) {
  const scale = getScale(16.003, height);
  const dimensions = getDimensions(16, 16.003, null, height);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${height ? dimensions.height : dimensions.height}`}
    >
      <path
        d="M15.613.171a1,1,0,0,1,.422.975l-2,13a1,1,0,0,1-1.375.769L8.712,13.276,7.458,15.6a.751.751,0,0,1-1.413-.356v-3a.746.746,0,0,1,.159-.459l5.166-6.645a.251.251,0,0,0-.406-.263L5.371,9.941l-1,.9h0a1,1,0,0,1-1.056.181L.658,9.919A.993.993,0,0,1,.048,9.06a1.006,1.006,0,0,1,.5-.931l14-8a1,1,0,0,1,1.063.044Z"
        transform={`translate(-0.046 0.004) ${scale ? `scale(${scale})` : ''}`}
        fill={color}
      />
    </svg>
  );
}

PaperPlaneSVG.propTypes = {
  color: PropTypes.string.isRequired,
  height:PropTypes.number
};
