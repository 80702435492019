import _ from 'lodash';
import Switch from '../../Switch/Switch';
import PropTypes from 'prop-types';

SwitcherCell.propTypes = {
  item: PropTypes.object,
  store: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired
};

export default function SwitcherCell({ item, onClick, store,field }) {
  return <Switch isActived={_.get(item,field)} onClick={()=>onClick(item,store)} />;
}
