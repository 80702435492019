import i18next from 'i18next'
import {
  isDefaultOption, isExcludeOption, isContainOption
} from './option';

export default function createFilterDescription(options: any): string {
  const newOptions = options.filter((o: any) => isContainOption(o)),
    classics = options.filter((o: any) => isDefaultOption(o) || isExcludeOption(o)),
    classicOptions = classics.map((o: any) => o.label).join(', '),
    containsOptions = `${i18next.t('contains')} : ${newOptions
      .map((o: any) => o.label.replace(`${i18next.t('contains')} : `, ''))
      .join(', ')}`;
  return `${classicOptions}${
    newOptions.length > 0 && classics.length > 0 ? ',  ' : ''
  }${
    options.filter((o: any) => isContainOption(o)).length > 0 ? containsOptions : ''
  }`
}
