export const UpdateTermOptionsInitialState = {
  isOpen: false,
  saveOrganicResults: false,
  disableSaveAds: false,
  saveShoppingAds: false,
  isLoading: false,
};

export function UpdateTermOptionsReducer(state, action) {
  return UpdateTermOptionsActions[action.type]({ ...state }, action.payload);
}

export const UpdateTermOptionsActionsType = {
  setIsOpen: 'setIsOpen',
  setSaveOrganicResults: 'setSaveOrganicResults',
  setDisableSaveAds: 'setDisableSaveAds',
  setSaveShoppingAds: 'setSaveShoppingAds',
  setIsLoading: 'setIsLoading',
  reInitState: 'reInitState',
  setInitState: 'setInitState',
};

const UpdateTermOptionsActions = {
  setIsOpen: (state) => {
    state.isOpen = !state.isOpen;
    return state;
  },
  setInitState: (state,payload) => {
    if(payload.options) {
      state.disableSaveAds = payload.options?.disableSaveAds;
      state.saveOrganicResults = payload.options?.saveOrganicResults;
      state.saveShoppingAds = payload.options?.saveShoppingAds;
    } else {
      state.disableSaveAds = false;
      state.saveOrganicResults = false;
      state.saveShoppingAds = false;
    }

    return state;
  },
  setSaveOrganicResults: (state, payload) => {
    state.saveOrganicResults = payload;
    return state;
  },
  setDisableSaveAds: (state, payload) => {
    state.disableSaveAds = payload;
    return state;
  },
  setSaveShoppingAds: (state, payload) => {
    state.saveShoppingAds = payload;
    return state;
  },
  setIsLoading: (state, payload) => {
    state.isLoading = payload;
    return state;
  },
  reInitState: () => UpdateTermOptionsInitialState,
};
