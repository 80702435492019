import PropTypes from 'prop-types';

ShortMenuIcon.propTypes = {
  icon: PropTypes.any.isRequired,  
};

export default function ShortMenuIcon({ icon }) {
  return <span 
    className="moni-icon"
    dangerouslySetInnerHTML={{__html: `&#x${icon};`}} 
  />;
}
