import { useContext, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import ActionsStore from './actions.store';
import ActionPanel from './ActionPanel';
import classes from './Actions.module.css';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import useGetActionsTodo from '../../components/Hooks/data/getActionsTodo';
import CursorPagination from '../../components/Pagination/CursorPagination';
import NoResult from '../../assets/img/actionTodoNoResult.svg';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import { Action } from '../../types/action.newBackend';
import ErrorResult from '../../assets/img/actionError.svg';

function buildCriteria(filters: any) {
  return Object.keys(filters)
    .map((key) => ({ key, value: filters[key].valueNew }))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val.key]: val.value,
      }),
      {}
    );
}

const CACHE_KEY = 'todo_actions';

const ActionsContainer = () => {
  const { cacheStore, authStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const [ready, setReady] = useState(false);
  const [actionsStore] = useState(() => new ActionsStore(currentUser?.id));

  useEffect(() => {
    const init = async () => {
      await actionsStore.initPersist();
      setReady(true);
    };

    init();
    return () => {
      actionsStore.stopStore();
    };
  }, [actionsStore]);

  const [cachedData, setCachedData] = useState(() => {
    return cacheStore.get(CACHE_KEY);
  });

  const handleClearCache = () => {
    setCachedData(() => {
      cacheStore.clear(CACHE_KEY);
      return undefined;
    });
  };

  if (!ready) {
    return null;
  }

  return (
    <ActionsObserver
      actionsStore={actionsStore}
      savedState={cachedData}
      saveState={(data: SavedState) => cacheStore.save(CACHE_KEY, data)}
      clearState={handleClearCache}
    />
  );
};

const ActionsObserver = observer(Actions);

interface SavedState {
  selectedItemID: string;
  actionsData: any;
}

function Actions({
  actionsStore,
  saveState,
  savedState,
  clearState,
}: {
  actionsStore: ActionsStore;
  savedState: SavedState | undefined;
  saveState: (data: SavedState) => void;
  clearState: () => void;
}) {
  const { authStore, uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    history = useHistory();
  const currentItemsScrollbarRef = useRef();

  const didMount = useRef(false);
  const [criteria, setCriteria] = useState(() => {
    const defaultCriteria = { page: { size: 30 } };
    return {
      ...defaultCriteria,
      ...buildCriteria(actionsStore.filters),
    };
  });

  const actionsData = useGetActionsTodo(
    savedState?.actionsData ? savedState.actionsData : { page: 1 }
  );

  const { loading, actions, error, page, total, fetch, next, previous } =
    actionsData;

  useEffect(() => {
    uiStore.setTitle(t('To do'));
  }, [uiStore, t]);

  useEffect(() => {
    if (didMount.current === false && savedState !== undefined) {
      return;
    }
    clearState();
    fetch(authStore.token, criteria);
    // eslint-disable-next-line
  }, [authStore.token, criteria]);

  useEffect(() => {
    if (didMount.current === false) {
      didMount.current = true;
      return;
    }

    setCriteria((previousCriteria) => ({
      ...buildCriteria(actionsStore.filters),
      page: previousCriteria.page,
    }));
  }, [actionsStore.filters]);

  const handleChangePerPage = (perPage: number) => {
    setCriteria((prevCriteria) => ({
      ...prevCriteria,
      page: {
        size: perPage,
      },
    }));
  };

  const onSelectAction = (action: Action) => {
    saveState({
      actionsData,
      selectedItemID: action.ID,
    });

    history.push(`/action/${action.ID}`, { action, from: '/todo' });
  };

  const handleNext = () => {
    clearState();
    if (next) {
      next();
    }
  };

  const handlePrevious = () => {
    clearState();
    if (previous) {
      previous();
    }
  };

  return (
    <>
      <main className={classes.main}>
        <h2 className={classes.title}>
          <Breadcrumb excludeHomeSection />
          <div className={classes.count}>
            <span className={classes.countNumber}>{total}</span>{' '}
            {total > 1 ? t('actions') : t('action')}
          </div>
        </h2>
        {!!error && (
          <div className={classes.actions}>
            <div className={classes.noResultContainer}>
              <img alt='no result' src={ErrorResult} />
              <div className={classes.noResultTitle}>
                Une erreur est survenue
              </div>
              <div className={classes.noResultText}>
                Réessayez plus tard, si le problème persiste contactez-nous.
              </div>
            </div>
          </div>
        )}
        {loading === true && (
          <>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
            <div className={classes.panelLoading}></div>
          </>
        )}
        {!error && loading === false && (
          <>
            {actions.length === 0 && (
              <div className={classes.actions}>
                <div className={classes.noResultContainer}>
                  <img alt='no result' src={NoResult} />
                  <div className={classes.noResultTitle}>
                    Vous avez mérité une pause ;)
                  </div>
                  <div className={classes.noResultText}>
                    Vous n&apos;avez aucun e-mail non lu pour le moment.
                  </div>
                </div>
              </div>
            )}
            {actions.length > 0 && (
              <DynamicScrollbar
                ref={currentItemsScrollbarRef}
                className={classes.actions}
              >
                {actions.map((action) => (
                  <ActionPanel
                    key={action.ID}
                    hasFocus={action.ID === savedState?.selectedItemID}
                    action={action}
                    onSelect={onSelectAction}
                  />
                ))}
              </DynamicScrollbar>
            )}
          </>
        )}

        <CursorPagination
          total={total}
          perPage={criteria.page.size}
          currentPage={page}
          next={next ? handleNext : undefined}
          previous={previous ? handlePrevious : undefined}
          onChangePerPage={handleChangePerPage}
        />
      </main>
    </>
  );
}

export default observer(ActionsContainer);
