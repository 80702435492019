import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState,cloneElement, Children } from 'react';
import classes from './ShortMenuModale.module.css';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

ShortMenuModale.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * 
 * Vous pouvez intégrer directement vous éléments sans conteneur, ils seront mise en page automatiquement.
 */
export default function ShortMenuModale({ children }) {
  const [isOpen, setIsOpen] = useState(null),
    ref = useRef(null),
    triggerRef = useRef(null);

  useEffect(() => {
    function outSide(e){
      if (e.target === triggerRef.current || triggerRef.current.contains(e.target)) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(null);
      }
    }
  
    window.addEventListener('mousedown',outSide);
    return ()=> window.removeEventListener('mousedown',outSide);
  },
  // eslint-disable-next-line
    [ref]);

  useEffect(() => {
    if (isOpen && ref) {
      // sure to have enough place to displau modale
      const isHalfScreen = window.innerHeight / 1.6  < isOpen.y + ref.current.clientHeight;
      if(isHalfScreen){
        ref.current.style.bottom = `${window.innerHeight - isOpen.y + ref.current.clientHeight + 15}px`;
      }else {
        ref.current.style.top = `${isOpen.y + 15}px`;
        ref.current.style.bottom = 'auto';
      }
      ref.current.style.right = `${window.innerWidth  - isOpen.x - 15}px`;
      ref.current.style.display = 'block';
    }
    // eslint-disable-next-line
  }, [isOpen,ref]);
  


  return <>
    <div className={classes.container}>
      <div
        className={classes.menu}
        data-testid='toggle'
        ref={triggerRef}
        onClick={(evt) => {
          evt.stopPropagation();
          if (isOpen) {
            setIsOpen(null);
          } else {
            setIsOpen({
              x: evt.clientX,
              y: evt.clientY,
            });
          }
        }}
      >
        <FontAwesomeIcon
          className={isOpen ? classes.iconOpen : classes.icon}
          icon={faEllipsisV}
        />
      </div>
    </div>
    <CSSTransition
      in={isOpen}
      timeout={200}
      unmountOnExit
      mountOnEnter
      nodeRef={ref}
      classNames="shutter"
    >
      <div className={classes.modale} ref={ref}>
        {Children.map(children,child=>cloneElement(child, { setIsOpen, ...child.props }))}
      </div>
    </CSSTransition>
  </>;
}
