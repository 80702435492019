import classes from './TermTagsUpdate.module.css';
import { TermInterface } from '../../../types/term.interface';
import PopinTitle from '../../_ui/Popin/PopinTitle/PopinTitle';
import TagListForMulti from '../../Tag/TagListForMulti/TagListForMulti';
import { TagInterface } from '../../../types/tag.interface';

interface Props {
  readonly term: TermInterface
  readonly t: Function
  readonly onClose: Function
  readonly scope : 'term'|'tag'
  readonly tags: Array<TagInterface>
  readonly refetchTag: Function
}

export default function TermTagsUpdateView({scope,onClose,tags,t,refetchTag,term}:Props){

  return <div className={classes.container}>
    <PopinTitle text={t('Manage tags')} />
    <section className={classes.section} >
      <TagListForMulti
        scope={scope}
        options={tags}
        refetchTag={()=>refetchTag()}
        defaultTags={term.categories}
        refetch={()=>onClose()}
        selectedItems={[term.id]}
        onClose={()=>onClose()}
      />
    </section>
  </div>;
}