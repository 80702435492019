import { useState } from 'react';
import SettingTermsStore from '../../../pages/Settings/Terms/settingTerms.store';
import { TagInterface } from '../../../types/tag.interface';
import { TermInterface } from '../../../types/term.interface';

interface Props {
  readonly item: TermInterface
  readonly store: SettingTermsStore
  readonly tags: Array<TagInterface>
  readonly refetchTag: Function
}

export default function useSettingTermsActionsCell({ item, store,tags ,refetchTag}:Props) {
  const [Popin,setPopin] = useState<JSX.Element|null>(null);

  return {Popin,setPopin};
}