import { Children } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import classes from './NewFilters.module.css';
import StandAloneFilter from '../StandAloneFilter/NewStandaloneFilter';
import BrandSelector from '../../BrandSelector/BrandSelector';

function Filters({
  handleOpenPanel,
  standAloneFilter,
  setStandAlone,
  pageStore,
  children,
  childrenFilter,
}: {
  standAloneFilter: string;
  setStandAlone: (standAlone: string) => void;
  handleOpenPanel: (open: boolean) => void;
  pageStore: any;
  children: any[] | any;
  childrenFilter: (child: any) => boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.labelFilter}>{t('Filters')}</div>
      <div className={classes.filters}>
        {pageStore.filtersAsArray
          .filter((o: any) => {
            const panelFilters = Array.isArray(children)
              ? children
              : [children];
            return !!panelFilters.find((item) => item.props.label === o);
          })
          .map((currentFilter: any) => (
            <div className={classes.filter} key={currentFilter}>
              <div
                className={classes.filterName}
                onClick={() =>
                  setStandAlone(
                    standAloneFilter === currentFilter ? null : currentFilter
                  )
                }
                data-current={currentFilter === standAloneFilter}
              >
                {t(currentFilter)}
              </div>
              {currentFilter === standAloneFilter && (
                <div className={classes.child}>
                  {children.props ? (
                    <StandAloneFilter
                      child={children}
                      {...children.props}
                      pageStore={pageStore}
                      onClose={setStandAlone}
                    />
                  ) : children.filter(childrenFilter).length > 0 ? (
                    Children.map(children.filter(childrenFilter), (child) => (
                      <StandAloneFilter
                        child={child}
                        {...child.props}
                        pageStore={pageStore}
                        onClose={setStandAlone}
                      />
                    ))
                  ) : (
                    <BrandSelector />
                  )}
                </div>
              )}
            </div>
          ))}
        <div
          className={classes.add}
          onClick={() => {
            setStandAlone('');
            handleOpenPanel(true);
          }}
        >
          <div className={classes.addTxt}>{t('see all filters')}</div>
          <FontAwesomeIcon icon={faFilter} />
        </div>
      </div>
    </>
  );
}

export default observer(Filters);
