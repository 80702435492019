import SubmitButton from '../../../SubmitButton/SubmitButton';
import useDateFilterSelectDates from './useDateFilterSelectDates';

interface Props {
  readonly filters:any
  readonly name:string
  readonly setDateState:Function
  readonly setToggle: Function
}

export default function DateFilterSelectDates(props:Props) {
  const { handleSubmit } = useDateFilterSelectDates(props);
  return (
    <SubmitButton
      txt="select dates"
      onClick={() => handleSubmit()}
      isLoading={false}
    />
  );
}
