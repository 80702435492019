/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Files model module.
* @module model/Files
* @version 4.0.0
*/
export default class Files {
    /**
    * Constructs a new <code>Files</code>.
    * @alias module:model/Files
    * @class
    * @param mimeType {String} 
    */

    constructor(mimeType) {
        
        
        this['mimeType'] = mimeType;
        
    }

    /**
    * Constructs a <code>Files</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Files} obj Optional instance to populate.
    * @return {module:model/Files} The populated <code>Files</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Files();
                        
            
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('mimeType')) {
                obj['mimeType'] = ApiClient.convertToType(data['mimeType'], 'String');
            }
            if (data.hasOwnProperty('path')) {
                obj['path'] = ApiClient.convertToType(data['path'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
        }
        return obj;
    }

    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} mimeType
    * @default 'application/octet-stream'
    */
    mimeType = 'application/octet-stream';
    /**
    * @member {String} path
    */
    path = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;




}
