import i18next from 'i18next'

export function isDefaultOption(option: any) {
  return !isExcludeOption(option) && !isContainOption(option);
}

export function isExcludeOption(option: any) {
  return option.__isExclude__ || option.value?.startsWith('exclude:');
}

export function isContainOption(option: any) {
  return option.__isNew__ || option.value?.startsWith('contain:');
}

export function getOptionValue(option: any) {
  if (isContainOption(option)) {
    return option.value?.replace('contain:', '');
  }
  if (isExcludeOption(option)) {
    return option.value?.replace('exclude:', '');
  }
  return option.value;
}

export function formatContainOptionValue(value: string) {
  return !value.startsWith('contain:') ?
    `contain:${value}` : value;
}

export function formatContainOptionLabel(label: string) {
  return !label.startsWith(`${i18next.t('contains')} : `) ?
    `${i18next.t('contains')} : ${label}` : label;
}
