import { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';

function Logout() {
  const { authStore } = useContext(StoreContext);

  useEffect(() => {
    localStorage.clear();
    authStore.logout();
    // eslint-disable-next-line
  }, []);

  return !authStore.currentUser && <Redirect to="/login" />;
}

export default observer(Logout);
