import { observer } from 'mobx-react-lite';
import { Children } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import StandAloneFilter from '../StandAloneFilter/StandAloneFilter';
import BrandSelector from '../../BrandSelector/BrandSelector';
import PropTypes from 'prop-types';
import classes from './Filters.module.css';

Filters.propTypes = {
  pageStore: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  // currentHoverFilter: PropTypes.object,
  standAloneFilter: PropTypes.object,
  setStandAlone: PropTypes.func,
  childrenFilter: PropTypes.func,
  handleOpenPanel: PropTypes.func,
};

function Filters({
  pageStore,
  children,
  // currentHoverFilter,
  standAloneFilter,
  setStandAlone,
  childrenFilter,
  handleOpenPanel
}) {
  const { t } = useTranslation();
  // data-hover={currentHoverFilter === currentFilter}

  return (
    <>
      <div className={classes.labelFilter}>
        {t('Filter',{count:pageStore?.filtersAsArray?.length||0})}
      </div> 
      <div className={classes.filters}>
        {pageStore.filtersAsArray.filter(o=>{
          const panelFilters = Array.isArray(children) ? children : [children];
          return !!panelFilters.find(item=>item.props.label === o);
        }).map((currentFilter) => (
          <div
            className={classes.filter}
            key={currentFilter}
          >
            <div
              className={classes.filterName}
              onClick={() =>
                setStandAlone(
                  standAloneFilter === currentFilter ? null : currentFilter
                )
              }
              data-current={currentFilter === standAloneFilter}
            >
              {t(currentFilter)}
            </div>
            {currentFilter === standAloneFilter && (
              <div className={classes.child}>
                {children.props ? (
                  <StandAloneFilter
                    child={children}
                    {...children.props}
                    pageStore={pageStore}
                    onClose={setStandAlone}
                  />
                ) : children.filter(childrenFilter).length > 0 ? (
                  Children.map(children.filter(childrenFilter), (child) => (
                    <StandAloneFilter
                      child={child}
                      {...child.props}
                      pageStore={pageStore}
                      onClose={setStandAlone}
                    />
                  ))
                ) : (
                  <BrandSelector />
                )}
              </div>
            )}
          </div>
        ))}
 
        <div
          className={classes.add}
          onClick={() => {
            setStandAlone();
            handleOpenPanel(true);
          }}
        >
          <div className={classes.addTxt}>{t('see all filters')}</div>
          <FontAwesomeIcon icon={faFilter} />
        </div>
      </div>
    </>
  );
}

export default observer(Filters);
