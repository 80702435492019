import { useTranslation } from 'react-i18next';
import ActionPanel from './ActionPanel';
import classes from './ActionAdvertisementList.module.css';
import ActionAdvertisementRow from './ActionAdvertisementRow';
import Loader from '../../components/Loader/Loader';

interface ActionAdvertisementTableProps {
  advertisements: any[];
  shoppingType: boolean;
}

export function ActionAdvertisementTable({
  advertisements,
  shoppingType,
}: ActionAdvertisementTableProps) {
  const { t } = useTranslation();

  return advertisements?.length ? (
    <table className={classes.table}>
      <thead>
        <tr>
          {shoppingType && (
            <th className={classes.tableHeader}>
              {t('Shopping Advertisement')}
            </th>
          )}
          {!shoppingType && (
            <th className={classes.tableHeader}>{t('Advertisement')}</th>
          )}
          <th className={classes.tableHeader}>{t('Action')}</th>
          <th className={classes.tableHeader}>{t('Support')}</th>
          <th className={classes.tableHeader}>{t('Location')}</th>
          <th className={classes.tableHeader}>{t('Keywords')}</th>
          <th className={classes.tableHeader}>{t('Position')}</th>
          <th className={classes.tableHeader}>{t('Key dates')}</th>
        </tr>
      </thead>
      <tbody>
        {advertisements?.map((advertisement, index) => (
          <ActionAdvertisementRow key={index} advertisement={advertisement} />
        ))}
      </tbody>
    </table>
  ) : (
    <div className={classes.noAdvertisement}>{t('No advertisement')}</div>
  );
}

interface ActionAdvertisementListProps {
  advertisements: any[];
  loading: boolean;
  shoppingType: boolean;
}

export default function ActionAdvertisementList({
  advertisements,
  shoppingType,
  loading,
}: ActionAdvertisementListProps) {
  return (
    <ActionPanel
      managedScroll={false}
      noHeight
      centeredContent={loading || !advertisements?.length}
    >
      {loading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <ActionAdvertisementTable
          advertisements={advertisements}
          shoppingType={shoppingType}
        />
      )}
    </ActionPanel>
  );
}
