import PropTypes from 'prop-types';
import termDefinition from './term.definition';

const localizationDefinition = {
  id: PropTypes.string,
  locale: PropTypes.string,
  city: PropTypes.string,
  terms: PropTypes.arrayOf(termDefinition)
};

export default localizationDefinition;