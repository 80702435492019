import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Loader from './../../components/Loader/Loader';
import queryString from 'query-string';

export default function LegacyAdvertisers() {
  const history = useHistory();

  useEffect(() => {
    const params = queryString.parse(history.location.search),
      filters = {};

    if (params.start && params.end) {
      filters.date = JSON.stringify({ between: [params.start, params.end] });
    }

    if (params.brand) {
      filters.brand = JSON.stringify({
        inq: [params.brand],
      });
    }

    if (params.domain) {
      filters.domain = JSON.stringify({
        inq: [params.domain],
      });
    }

    if (params.type) {
      filters.type = JSON.stringify({
        inq: [params.type],
      });
    }

    history.replace(`/domains?${queryString.stringify(filters)}`);
    // eslint-disable-next-line
  }, []);

  return <Loader />;
}
