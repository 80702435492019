import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { BrandApi } from '../../swagger_client/src';
import classes from './BrandSelector.module.css';
import _ from 'lodash';
import Async from 'react-select/async';
import { useTranslation } from 'react-i18next';
import LoaderSmall from '../_ui/LoaderSmall/LoaderSmall';
import { createInputValueRegexContent } from '../../Utils/filter/createFilterInputValue';

function BrandSelector() {
  const { authStore } = useContext(StoreContext),
    brandApi = new BrandApi(),
    { t } = useTranslation(),
    [options, setOptions] = useState([]),
    [isLoading, setIsLoading] = useState(true);

  async function fetchExistFilters() {
    const items = await brandApi.brandFind({
      filter: JSON.stringify({
        order: 'name asc',
        limit: 10,
      }),
    });
    setOptions(
      _.map(items, (o) => ({
        label: o.label || o.name,
        value: o,
      }))
    );
    setIsLoading(false);
  }

  async function promiseOptions(inputValue) {
    const filter = {
      limit: 50,
      order: 'name asc',
    };
    if (inputValue) {
      filter.where = { name: { regexp: `/${createInputValueRegexContent(inputValue)}/i` } };
    }
    const items = await brandApi.brandFind({
      filter: JSON.stringify(filter),
    });
    const newOptions = _.map(items, (o) => {
      return {
        label: o.label || o.name,
        value: o,
      };
    });
    //protect to duplicate keys
    const response = _.uniqBy([...options, ...newOptions], 'value.id');
    setOptions(response);
    // alphabetic order.
    return response
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  async function handleChange(brands) {
    authStore.setCurrentBrands(brands);
    await promiseOptions();
  }

  useEffect(
    () => fetchExistFilters(),
    // eslint-disable-next-line
    []
  );

  return (
    <div className={classes.container}>
      {isLoading ? (
        <LoaderSmall />
      ) : (
        <Async
          value={authStore.currentBrands}
          styles={{
            control: (provided) => ({
              ...provided,
              border: 'none',
              background: 'none',
              outline: 'none',
              boxShadow: 'none',
              gap: '2px',
            }),
            valueContainer: (provided) => ({
              ...provided,
              textAlign: 'right',
              justifyContent: 'flex-end',
              padding: '2px',
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              cursor: 'pointer',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              cursor: 'pointer',
              color: 'black',
              padding: '0',
            }),
            clearIndicator: (provided) => ({
              ...provided,
              cursor: 'pointer',
              color: 'black',
              padding: '0',
            }),

            indicatorSeparator: (provided) => ({
              ...provided,
              backgroundColor: 'none',
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              color: 'white',
              backgroundColor: 'black',
              borderRadius: '5px 0 0 5px',
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: 'none',
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: 'white',
              backgroundColor: 'black',
              borderRadius: '0 5px 5px 0',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: 'black',
            }),
          }}
          isMulti
          defaultOptions
          cacheOptions
          placeholder={t('select brands')}
          loadOptions={promiseOptions}
          onChange={handleChange}
        />
      )}
    </div>
  );
}

export default observer(BrandSelector);
