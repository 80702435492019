import classes from './ActionForMultiSelectNoTrigger.module.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FullscreenModal from '../../FullscreenModal/FullscreenModal';
import CancelButton from '../../CancelButton/CancelButton';
import SubmitButton from '../../SubmitButton/SubmitButton';

ActionForMultiSelectNoTrigger.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fullHeight: PropTypes.bool,
  submitbuttonText: PropTypes.string,
  submitbuttonDisabled: PropTypes.bool,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default function ActionForMultiSelectNoTrigger({
  title,
  submitbuttonText,
  submitbuttonDisabled,
  onSubmit,
  children,
  isLoading,
  subTitle,
  fullHeight,
  onClose
}) {
  const { t } = useTranslation();

  async function handleSubmit(e) {
    e.preventDefault();
    await onSubmit();
  }

  return (
    <FullscreenModal onClose={onClose} fullHeight={fullHeight}> 
      <form className={classes.container} onSubmit={handleSubmit}>
        <header className={classes.header}>
          <div className={classes.title}>
            {typeof title === 'string' ? t(title) : title}
          </div>
          <div className={classes.subtitle}>
            {typeof subTitle === 'string' ? t(subTitle) : subTitle}
          </div>
        </header>
        <div className={classes.content}>
          <div className={classes.children}>{children}</div>
        </div>
        <div className={classes.buttons}>
          <CancelButton txt="cancel" onClose={onClose} />
          <SubmitButton
            txt={submitbuttonText || 'submit'}
            isLoading={isLoading}
            disabled={submitbuttonDisabled}
          />
        </div>
      </form>
    </FullscreenModal>
  );
}
