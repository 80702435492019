import { makeObservable, observable, action } from 'mobx';
import PageStore from '../../stores/page.store';
import { CategoryApi } from '../../swagger_client/src';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export default class TagsStore extends PageStore {
  categoryApi = new CategoryApi();
  tagToDelete = null;
  isAdmin = false;
  tagToUpdate = null;
  whereBrandIds = null;
  endpoint = '/categories';
  tableHeader = [
    { name: 'scope', title: 'type of tag', sortable: true, center: true },
    { name: 'label', title: 'tag', sortable: true, center: true },
    { name: 'name', title: 'slug', sortable: true, isAdmin: true, center: true },
    { name: 'parent.name', title: 'parent', center: true },
    { name: 'owner.label', title: 'brand', center: true },
    { name: 'actions', center: true },
  ];
  constructor(defaultFilters, isAdmin, currentUser) {
    super(currentUser.id);
    makeObservable(this, {
      tagToDelete: observable,
      tagToUpdate: observable,
      isAdmin: observable,
      whereBrandIds: observable,
      addTag: action,
      deleteTag: action,
      setTagToDelete: action,
      setTagToUpdate: action,
    });

    if (!this.filters) {
      this.filters = defaultFilters;
    }

    if (!isAdmin && currentUser) {
      const brands = _.get(currentUser, 'companyAccount.brands', []).map(
        (brand) => brand.id
      );
      if (brands) {
        this.whereBrandIds = { inq: brands };
      }
    }

    this.defaultFilters = defaultFilters;
    this.isAdmin = isAdmin;
    this.order = 'totalViews';
  }

  async handleCount() {
    this.setIsLoadingCount(true);
    if (this.whereBrandIds) {
      this.filters.brandOwnerId = this.whereBrandIds;
    }
    try {
      const { count } = await this.categoryApi.categoryCount({
        where: JSON.stringify(this.computedFilters),
      });
      this.setCount(count);
    } catch (e) {
      console.error(e);
    }

    delete this.filters.brandOwnerId;
    this.setIsLoadingCount(false);
  }

  addTag(tag) {
    if (tag.parentId) {
      tag.parent = _.find(this.items, { id: tag.parentId });
    }
    this.items.push(tag);
  }

  setTagToDelete(tag) {
    this.tagToDelete = tag || null;
  }

  setTagToUpdate(tag) {
    this.tagToUpdate = tag || null;
  }

  deleteTag() {
    _.remove(this.items, this.tagToDelete);
    this.setTagToDelete();
  }

  updateTag(payload) {
    if (payload.parentId) {
      payload.parent = _.find(this.items, { id: payload.parentId });
    }
    const index = _.findIndex(this.items, { id: this.tagToUpdate.id });
    this.items.splice(index, 1, payload);
    this.setTagToUpdate();
  }

  async handleFetch() {
    this.setIsLoading(true);

    if (this.whereBrandIds) {
      this.filters.brandOwnerId = this.whereBrandIds;
    }

    try {
      this.setItems(await this.categoryApi.categoryFind({
        filter: JSON.stringify({
          where: this.computedFilters,
          include: ['parent', 'children', 'owner'],
          order: `${this.order} ${this.direction.toUpperCase()}`,
          skip: (this.page - 1) * this.perPage,
          limit: this.perPage,
        }),
      }));
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }
    delete this.filters.brandOwnerId;
    this.initPage = true;
    this.setIsLoading(false);
  }

  get counters() {
    return [{ txt: 'tag', count: this.count }];
  }

  filterSearch(item) {
    const { scope, label, slug, parent, brand } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (brand) {
      brand.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (scope) {
      scope.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (label) {
      label.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (slug) {
      slug.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (parent) {
      parent.name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }
}
