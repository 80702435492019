import classes from './TagList.module.css';
import { useTranslation } from 'react-i18next';
import { DealerApi, TermApi } from '../../../swagger_client/src';
import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import TagForm from '../TagForm/TagForm';
import Creatable from 'react-select/creatable';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

export default function TagList({
  entity,
  options,
  refetchTag,
  updateTab,
  scope = 'tag',
  className = '',
  dynamicOptionsEnabled,
  confirmDeleteEnabled = true,
}: {
  entity: any;
  options: any[];
  refetchTag: () => void;
  updateTab: (options: any) => void;
  scope: 'tag' | 'term';
  className?: string;
  dynamicOptionsEnabled?: boolean;
  confirmDeleteEnabled?: boolean;
}) {
  const [defaultOptions, setDefaultOptions] = useState(
      _.map(_.get(entity, 'categories', []), (o) =>
        _.find(options, { value: o.id })
      )
    ),
    [isCreated, setIsCreated] = useState<
      { label: string; scope: 'tag' | 'term'; visibility: string } | undefined
    >(),
    [isRemoving, setIsRemoving] = useState<boolean | undefined>(),
    choicesToRemoveRef = useRef(null),
    dealerApi = new DealerApi(),
    termApi = new TermApi(),
    { t } = useTranslation();

  useEffect(() => {
    if (dynamicOptionsEnabled && entity && options) {
      setDefaultOptions(
        _.map(_.get(entity, 'categories', []), (o) =>
          _.find(options, { value: o.id })
        )
      );
    }
  }, [entity, options]); // eslint-disable-line

  async function handleAddChange(
    selectedOptions: any[],
    currentOptions: any[]
  ) {
    const optionToAdd = _.difference(selectedOptions, currentOptions)[0],
      newSelectedTags = [...selectedOptions];

    //if is new tag : create before
    if (optionToAdd.__isNew__) {
      setIsCreated({
        label: optionToAdd.label,
        scope,
        visibility: 'owner',
      });
      return;
    }
    try {
      if (scope === 'term') {
        await termApi.termPrototypeLinkCategories(
          entity.id,
          optionToAdd.originalTag.id,
          {}
        );
      }

      if (scope === 'tag') {
        await dealerApi.dealerPrototypeLinkCategories(
          entity.id,
          optionToAdd.originalTag.id,
          {}
        );
      }
    } catch (e: any) {
      return toast.error(t(e.message));
    }
    setDefaultOptions(newSelectedTags);
    if (updateTab) {
      updateTab(selectedOptions);
    }
    toast.success(t('tag added'));
  }

  async function handleRemoveChange(
    selectedOptions: any[],
    currentOptions: any[]
  ) {
    const optionToDeleteId = _.chain(currentOptions)
      .difference(selectedOptions)
      .first()
      // @ts-ignore
      .get('value')
      .value();
    try {
      if (scope === 'term') {
        await termApi.termPrototypeUnlinkCategories(
          entity.id,
          optionToDeleteId
        );
      }

      if (scope === 'tag') {
        await dealerApi.dealerPrototypeUnlinkCategories(
          entity.id,
          optionToDeleteId
        );
      }
    } catch (e: any) {
      return toast.error(t(e.message));
    }
    setDefaultOptions(selectedOptions);
    if (updateTab) {
      updateTab(selectedOptions);
    }
    toast.success(t('tag deleted'));
  }

  function getOptionToDelete({
    currentOptions,
    selectedOptions,
  }: {
    currentOptions: any[];
    selectedOptions: any[];
  }) {
    return (
      _.chain(currentOptions)
        .difference(selectedOptions)
        .first()
        // @ts-ignore
        .get('label')
        .value()
    );
  }

  async function handleChange(selectedOptions: any, currentOptions: any[]) {
    // Workarround for react-select undefined ref in array
    selectedOptions = _.compact(selectedOptions);
    currentOptions = _.compact(currentOptions);

    // No empty selection
    if (selectedOptions.length === 0 && currentOptions.length === 0) {
      return;
    }

    //if add or remove
    if (selectedOptions.length > currentOptions.length) {
      await handleAddChange(selectedOptions, currentOptions);
    } else {
      if (confirmDeleteEnabled) {
        setIsRemoving(true);
        // @ts-ignore
        choicesToRemoveRef.current = {
          selectedOptions,
          currentOptions,
          optionToDelete: getOptionToDelete({
            currentOptions,
            selectedOptions,
          }),
        };
      } else {
        await handleRemoveChange(selectedOptions, currentOptions);
      }
    }
  }

  async function confirmRemoveChange() {
    if (choicesToRemoveRef.current) {
      const { selectedOptions, currentOptions } = choicesToRemoveRef.current;
      await handleRemoveChange(selectedOptions, currentOptions);
      choicesToRemoveRef.current = null;
    }
    setIsRemoving(false);
  }

  async function abortRemoveChange() {
    choicesToRemoveRef.current = null;
    setIsRemoving(false);
  }

  async function addAfterCreate(tag: any) {
    tag.owner.id = tag.brandOwnerId;
    try {
      if (scope === 'term') {
        await termApi.termPrototypeLinkCategories(entity.id, tag.id, {});
      }

      if (scope === 'tag') {
        await dealerApi.dealerPrototypeLinkCategories(entity.id, tag.id, {});
      }
    } catch (e: any) {
      return toast.error(t(e.message));
    }
    setDefaultOptions([
      ...defaultOptions,
      { ...tag, originalTag: tag, value: tag.id },
    ]);
    toast.success(t('tag added'));
    refetchTag();
    setIsCreated(undefined);
  }

  return (
    <div className={`${classes.container} ${className} hoverable`}>
      {isCreated && (
        <TagForm
          setClose={() => setIsCreated(undefined)}
          fields={isCreated}
          update={(tag) => addAfterCreate(tag)}
        />
      )}
      {isRemoving && (
        <ConfirmModal
          title={t('Remove the tag')}
          // @ts-ignore
          message={
            // @ts-ignore
            choicesToRemoveRef.current?.optionToDelete ? (
              <span>
                {t('Do you want to remove the tag ')}
                {/* @ts-ignore */}
                <strong>{choicesToRemoveRef.current?.optionToDelete}</strong> ?
              </span>
            ) : (
              <span>{t('Do you want to remove the tag?')}</span>
            )
          }
          confirmMessage={t('Remove the tag')}
          setReset={abortRemoveChange}
          setSubmit={confirmRemoveChange}
        />
      )}
      <Creatable
        options={options}
        isMulti
        value={defaultOptions}
        formatCreateLabel={(input) => `${t('create')} "${input}"`}
        styles={taglistStyle}
        placeholder={t('select tags')}
        classNamePrefix='taglist'
        closeMenuOnScroll={(e: any) => {
          const targetClass = e.target.getAttribute('class');
          if (targetClass && !targetClass.includes('taglist')) {
            return true;
          }
          return false;
        }}
        menuPosition='fixed'
        onChange={(choices) => handleChange(choices, defaultOptions)}
      />
    </div>
  );
}
