import _ from 'lodash';

/**
 *
 *
 * @param {Array.{id: string, children:[],label: string, parentId?: string}} tags
 * @description add options key (inject children) and add value key (with id)
 * @returns {Array.{originalTag: Object,label: string,value: string, options:Array.Object}}
 */
export default function tagToOptions(tags) {
  function transformTagToOption (tag) {
    const newTag = {
      originalTag: tag,
      label: (tag.owner && tag.owner.label) ? `${tag.label} (${tag.owner?.label})` : tag.label,
      value: tag.id
    };
    if(tag?.children?.length > 0) newTag.options = _.map(tag.children, transformTagToOption);
    return newTag;
  }
  return _.map(tags, tag => transformTagToOption(tag));
}
