import _ from 'lodash';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import TagList from '../../Tag/TagList/OldTagList';
import PropTypes from 'prop-types';
import tagDefinition from '../../../proptypes/tag.definition';

DealerTagCell.propTypes = {
  item: PropTypes.object,
  store: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.shape(tagDefinition)).isRequired,
  tagFetch: PropTypes.func,
  isLoading: PropTypes.bool,
  field: PropTypes.string.isRequired
};

export default function DealerTagCell
({
  item,
  store,
  tags,
  tagFetch,
  isLoading,
}) {
  return !isLoading && _.get(item, 'dealer', undefined) ? (
    <TagList
      scope='tag'
      entity={item.dealer}
      options={tags}
      refetchTag={()=>tagFetch()}
      updateTab={(tags) => store.updateItem(item, tags)}
    />
  ) : (
    <LoaderSmall />
  );
}
