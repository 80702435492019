import PropTypes from 'prop-types';
import classes from './Checkbox.module.css';

Checkbox.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  checked: PropTypes.bool,
  handleClick: PropTypes.func,
  label: PropTypes.string,
};

export default function Checkbox({ value, checked, handleClick, label }) {

  function handleClickBox(){
    if (handleClick) {
      handleClick(value);
    }
  }
  
  return (
    <div
      data-testid='checkbox'
      className={classes.container}
      onClick={handleClickBox}
    >
      <div className={classes.checkbox} data-checked={checked} />
      <div className={classes.label}>{label}</div>
    </div>
  );
}