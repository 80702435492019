import PropTypes from 'prop-types';
import companyAccountDefinition from './companyAccount.definition';
import fileDefinition from './file.definition';
import roleDefinition from './role.definition';

const userDefinition = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.shape(roleDefinition)),
  avatar: PropTypes.shape(PropTypes.shape(fileDefinition)),
  avatarId: PropTypes.string,
  emailVerified: PropTypes.bool,
  companyAccountId: PropTypes.string,
  companyAccounts: PropTypes.arrayOf(PropTypes.shape(companyAccountDefinition)),
  

};

export default userDefinition;