import TermOptionsUpdateView from './TermOptionsUpdateView';
import useTermOptionsUpdate from './useTermOptionsUpdate';
import { TermInterface } from '../../../types/term.interface';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
}

export default function TermOptionsUpdate(props:Props){
  const useProps = useTermOptionsUpdate(props);

  return <TermOptionsUpdateView {...props} {...useProps}/>;
}