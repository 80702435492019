import { useState } from 'react';
import _ from 'lodash';
import classes from './ChipsInput.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

ChipsInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  setValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

ChipsInput.defaultProps = {
  values:[],
};

/**
 * 
 * Coller du texte depuis un csv, un fichier excel ou autre.
 * Vous pouvez aussi saisir votre texte. 
 * La validation fonction en pressent ENTRER. 
 * L'ajout de virgule créé automatiquement une séparation des éléments
 */
export default function ChipsInput({ values, setValues, placeholder }) {
  const [value, setValue] = useState('');

  function handleDelete(valueToDelete) {
    setValues(values.filter((it) => it !== valueToDelete));
  }

  function handleSubmit(pasteValue) {
    const newValue = pasteValue || value;
    if (newValue.length > 0) {
      const newValues = newValue.split(/[\t,;\n\r\b]/);
      setValues(
        _.uniq(
          _.concat(
            values,
            _.map(newValues, (o) => o.trim()).filter(
              (o) => o !== '' && !o.match(/[\t,;\n\r\b]/)
            )
          )
        )
      );
      setValue('');
    }
  }

  function handleOnKeyDown(e) {
    if (e.code === 'Backspace' && value === '') {
      setValues(values.slice(0, value.length - 1));
    }
    //dont use form
    if (e.code.match(/NumpadEnter|Enter/)) {
      handleSubmit();
    }
  }

  async function handleOnPaste(e) {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    handleSubmit(paste.split('|').join(','));
  }

  return (
    <div className={classes.container}>
      {values.map((item) => (
        <div className={classes.chip} key={item}>
          <div>{item}</div>
          <div className={classes.close} onClick={() => handleDelete(item)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      ))}
      <div className={classes.form}>
        <textarea
          className={classes.input}
          type="text"
          placeholder={`+ ${placeholder}`}
          value={value}
          onPaste={(e) => handleOnPaste(e)}
          onKeyDown={handleOnKeyDown}
          rows="1"
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e) => handleSubmit(e.target.value)}
        />
      </div>
    </div>
  );
}
