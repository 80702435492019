import _ from 'lodash';
import ClipBoard from '../../ClipBoard/ClipBoard';
import PropTypes from 'prop-types';

ClipboardCell.propTypes = {
  item: PropTypes.object,
  fieldTxt: PropTypes.string.isRequired
};

export default function ClipboardCell({item, fieldTxt}){

  return <ClipBoard txt={_.get(item,fieldTxt)} />;
}