import 'rc-slider/assets/index.css';
import classes from './NewRangeFilter.module.css';
import Slider from 'rc-slider';
import { Trans } from 'react-i18next';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

interface props {
  readonly name: string;
  readonly min: number;
  readonly max: number;
  readonly handleChange: Function;
  readonly resetRange: Function;
  readonly filters: any;
  readonly percent: number;
  readonly step: number;
  readonly marks: string;
  readonly rangeState: any;
  readonly resetFilters: Function;
  readonly label: string;
}

export default function RangeFilterView({
  name,
  min,
  max,
  percent,
  rangeState,
  handleChange,
  filters,
  step,
  marks,
  label,
}: props) {
  return (
    <div className={classes.container} data-active={!!filters?.[name]}>
      <div className={classes.title}>
        <Trans i18nKey={`filterTitle_${label}`}>
          <>{label}</>
        </Trans>
      </div>

      <div className={classes.containerRange} data-active={!!filters?.[name]}>
        <Range
          marks={marks}
          step={step}
          className={classes.range}
          min={min}
          max={max}
          tipFormatter={(value) => `${value}${percent ? '%' : ''}`}
          onChange={(values: any) => handleChange(values)}
          handleStyle={[
            {
              backgroundColor: 'var(--color-princ-monibrand)',
              borderColor: 'var(--color-princ-monibrand)',
            },
          ]}
          value={rangeState}
        />
      </div>
    </div>
  );
}
