import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './ActionStatus.module.css';

ActionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  showStatusWord: PropTypes.bool,
};

export default function ActionStatus({ status, showStatusWord }) {
  const {t} = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.round} data-color={status} />
      <div>
        {showStatusWord ? 'Status: ' + t(status) : t(status)}
      </div>
    </div>
  );
}
