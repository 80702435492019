import { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import classes from './FiltersPanel.module.css';
import { useTranslation } from 'react-i18next';
import usePanelFilter from '../../components/Filters/PanelFilter/usePanelFilter';
import { PanelFilterContentWithBlocks } from '../../components/Filters/PanelFilter/PanelFilterContentWithBlocks';
import AngleLeftSVG from '../../components/Icon/svg/AngleLeft.svg';

interface FiltersPanelProps {
  pageStore:any;
  children: ReactElement[];
  // filters
  onToggleFilters: () => void;
}

function FiltersPanel(props: FiltersPanelProps) {
  const { t } = useTranslation();
  const useProps = usePanelFilter({ isOpen: true, setIsOpen: () => {}, pageStore: props.pageStore });

  return (
    <nav className={classes.filtersContainer}>
      <h2 className={classes.filtersTitle}>
        <span>{t`Filters`}</span>
        <button className={classes.filtersToggle} onClick={props.onToggleFilters}>
          <AngleLeftSVG color="var(--color-link-menu)" />
        </button>
      </h2>
      <div className={classes.filtersList}>
        <PanelFilterContentWithBlocks {...useProps} blocks={props.children} />
      </div>
    </nav>
  );
}

export default observer(FiltersPanel);
