import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import classes from './AdvertiserGroupAction.module.css';
import { Status } from '../../../types/action.interface';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';

interface Props {
  selects: any[];
  setAdvertiserGroup: (status: string) => void;
}

interface GroupAction {
  name: string;
  status: string;
  available: (advertisers: any[]) => boolean;
  backendCall?: (token: string, advertiserIDs: string[]) => Promise<any>;
}

const actions: GroupAction[] = [
  {
    name: 'ignore',
    status: Status.ignored,
    available: (advertisers: any[]): boolean => {
      const found = advertisers.find(
        (advertiser) =>
          advertiser.status === Status.requested ||
          advertiser.status === Status.ignored
      );
      return !found;
    },
    backendCall: Backend.markAsIgnored,
  },
  {
    name: 'unignore',
    status: Status.new,
    available: (advertisers: any[]) => {
      const found = advertisers.find(
        (advertiser) => advertiser.status !== Status.ignored
      );
      return !found;
    },
    backendCall: Backend.cancelIgnore,
  },
  {
    name: 'request',
    status: Status.requested,
    available: (advertisers: any[]) => {
      const found = advertisers.find(
        (advertiser) => advertiser.status !== Status.new
      );
      return !found;
    },
    backendCall: Backend.markAsRequested,
  },
  {
    name: 'cancel request',
    status: Status.new,
    available: (advertisers: any[]) => {
      const found = advertisers.find(
        (advertiser) => advertiser.status !== Status.requested
      );
      return !found;
    },
    backendCall: Backend.cancelRequested,
  },
];

export default function AdvertiserGroupAction({
  selects,
  setAdvertiserGroup,
}: Props) {
  const { authStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const handleClick = (action: GroupAction) => {
    if (action.backendCall === undefined) {
      return;
    }
    const selectIDs = selects.map((s) => s.ID);
    const token = authStore.token;
    try {
      action.backendCall(token, selectIDs);
      setAdvertiserGroup(action.status);
      toast.success(t('Le statut des annonceurs est à jour.'));
    } catch (e) {
      toast.error(t('Une erreur est survenue'));
    }
  };

  if (selects.length <= 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <span className={classes.select}>
        <span className={classes.bold}>{selects.length} </span>
        {t(`selectionné${selects.length > 1 ? 's' : ''}`)}
      </span>

      <div className={classes.actions}>
        {actions.map((action) => {
          const available = action.available(selects);
          if (available === false) {
            return null;
          }

          return (
            <div
              onClick={() => handleClick(action)}
              className={classes.action}
              key={action.name}
            >
              {t(action.name)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
