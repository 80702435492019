import PropTypes from 'prop-types';

const selectOptions = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]).isRequired
};

export default selectOptions;