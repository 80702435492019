import Checkbox from '../../Checkbox/Checkbox';
import classes from './CheckboxWithLabel.module.css';

interface props {
  readonly value: boolean
  readonly setValue: Function
  readonly children: JSX.Element | Array<JSX.Element>
}
export default function CheckboxWithLabel({
  value,
  setValue,
  children,
}: props) {
  return (
    <div className={classes.container} onClick={()=>setValue(!value)}>
      <Checkbox value={value} checked={value} />
      <div>
        {children}
      </div>
    </div>
  );
}
