import Tippy from '@tippyjs/react';
import { ReactNode } from 'react';
import classes from './NewManagedTooltip.module.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/shift-away.css';

export function getIntentClassName(intent: string) {
  switch (intent) {
  case 'success':
    return classes.intentSuccess;
  default:
    return '';
  }
}

interface ManagedTooltipProps {
  children: ReactNode
  content: ReactNode | string;
  direction?: 'top' | 'right' | 'left' |'bottom';
  placement?: any;
  intent?: 'default' | 'success';
  arrow?: boolean;
  active: boolean;
  className?: string;
  displayMode?: 'default' | 'flex';
}

export default function ManagedTooltip({
  children,
  content,
  placement = 'bottom',
  intent = 'default',
  active,
  className,
  displayMode = 'default'
}: ManagedTooltipProps) {
  const intentClassName = getIntentClassName(intent);

  return (
    <Tippy
      content={content}
      placement={placement}
      className={`${classes.box} ${className} ${active ? intentClassName : ''}`}
      animation={placement === 'bottom' ? 'shift-toward' : 'shift-away'}
      visible={active}
      theme={intent}
    >
      <div className={`${classes.wrapper} ${displayMode === 'flex' ? classes.flex : ''}`}>{children}</div>
    </Tippy>
  );
}
