/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Category from './Category';
import Role from './Role';
import Files from './Files';
import ObjectID from './ObjectID';
import CompanyAccount from './CompanyAccount';

/**
* The UserAccount model module.
* @module model/UserAccount
* @version 4.0.0
*/
export default class UserAccount {
    /**
    * Constructs a new <code>UserAccount</code>.
    * @alias module:model/UserAccount
    * @class
    * @param email {String} 
    */

    constructor(email) {
        
        
        this['email'] = email;
        
    }

    /**
    * Constructs a <code>UserAccount</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserAccount} obj Optional instance to populate.
    * @return {module:model/UserAccount} The populated <code>UserAccount</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserAccount();
                        
            
            if (data.hasOwnProperty('emailAtCreation')) {
                obj['emailAtCreation'] = ApiClient.convertToType(data['emailAtCreation'], 'Boolean');
            }
            if (data.hasOwnProperty('queries')) {
                obj['queries'] = ApiClient.convertToType(data['queries'], [Object]);
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('realm')) {
                obj['realm'] = ApiClient.convertToType(data['realm'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('emailVerified')) {
                obj['emailVerified'] = ApiClient.convertToType(data['emailVerified'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('dashboardCategoryFilters')) {
                obj['dashboardCategoryFilters'] = ApiClient.convertToType(data['dashboardCategoryFilters'], [Category]);
            }
            if (data.hasOwnProperty('avatarId')) {
                obj['avatarId'] = ObjectID.constructFromObject(data['avatarId']);
            }
            if (data.hasOwnProperty('companyAccountId')) {
                obj['companyAccountId'] = ObjectID.constructFromObject(data['companyAccountId']);
            }
            if (data.hasOwnProperty('companyAccount')) {
                obj['companyAccount'] = ApiClient.convertToType(data['companyAccount'],CompanyAccount);
            }
            
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], [Role]);
            }

            if (data.hasOwnProperty('dashboards')) {
                obj['dashboards'] = ApiClient.convertToType(data['dashboards'], Object);
            }
            if (data.hasOwnProperty('dashboards')) {
                obj['avatar'] = ApiClient.convertToType(data['avatar'], Files);
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} emailAtCreation
    */
    emailAtCreation = undefined;
    /**
    * user's available brands
    * @member {Array.<Object>} queries
    */
    queries = undefined;
    /**
    * @member {String} firstName
    */
    firstName = undefined;
    /**
    * @member {String} lastName
    */
    lastName = undefined;
    /**
    * @member {String} realm
    */
    realm = undefined;
    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} email
    */
    email = undefined;
    /**
    * @member {Boolean} emailVerified
    */
    emailVerified = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {Array.<module:model/Category>} dashboardCategoryFilters
    */
    dashboardCategoryFilters = undefined;
    /**
    * @member {module:model/ObjectID} avatarId
    */
    avatarId = undefined;
    /**
    * @member {module:model/ObjectID} companyAccountId
    */
    companyAccountId = undefined;




}
