export function getScale(height, newHeight) {
  return newHeight / height;
}

export function getDimensions(defaultWidth, defaultHeight, width, height) {
  if (width && height) {
    return { width, height };
  }

  if (height) {
    const ratio = height / defaultHeight;
    return { height, width: defaultWidth * ratio};
  }

  if (width) {
    const ratio = width / defaultWidth;
    return { height: defaultHeight * ratio, width };
  }

  return { height: defaultHeight, width: defaultWidth };
}
