import { useContext, useEffect, useState } from 'react';
import classes from './LocalizationFilter.module.css';
import { FilterChild } from '../../../types/filter.interface';
import SelectFilter from '../SelectFilter/NewSelectFilter';
import { useRegionOptions } from '../../Hooks/useRegionOptions';
import { PanelFilterContext } from '../../Filters/PanelFilter/NewPanelFilter';

interface Props extends FilterChild {
  readonly type: string;
  readonly placeholder: string;
  readonly titleMode: boolean;
  readonly basedOn: string;
  readonly name: string;
  readonly label: string;
}

function RegionFilter(props: Props) {
  const regionOptions = useRegionOptions();
  const [locale, setLocale] = useState('');

  const { filters, setFilter } = useContext(PanelFilterContext);

  useEffect(() => {
    if (
      !filters[props.basedOn] ||
      filters[props.basedOn]?.valueNew?.length !== 1
    ) {
      setLocale('');
      setFilter(props.name, undefined);
      return;
    }

    setLocale(filters[props.basedOn].valueNew[0]);

    //eslint-disable-next-line
  }, [filters[props.basedOn]]);

  const options = regionOptions[locale];

  const passProps = {
    ...props,
    currentValue: () => {},
    reactSelectOptions: undefined,
    options: options ? options : [],
    filters,
    setFilter,
    concatMode: false,
  };
  return (
    <div className={classes.container}>
      <SelectFilter {...passProps} />
    </div>
  );
}

export default RegionFilter;
