import { useState } from 'react';
import classes from './ActionPanels.module.css';
import { useTranslation } from 'react-i18next';
import ActionPanel from './ActionPanel';
import History from './History';
import Conversations from './Conversations';
import Infos from './Infos';
import { Action } from '../../types/action.newBackend';

interface Props {
  action: Action;
  onModalOpened: (opened: boolean) => void;
}

export default function ActionPanels({ action, onModalOpened }: Props) {
  const { t } = useTranslation();

  const [conversationSearchTerm, setConversationSearchTerm] = useState('');

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <ActionPanel managedScroll={false} title={t('Activities')}>
          <History history={action.history} waiting={action.waiting ? true : false} />
        </ActionPanel>
        <ActionPanel
          title={t('Other conversations with this advertiser')}
          managedScroll={false}
          searchEnabled
          searchTerm={conversationSearchTerm}
          onChangeSearch={setConversationSearchTerm}
        >
          <Conversations
            onChangeSearch={setConversationSearchTerm}
            searchTerm={conversationSearchTerm}
            action={action}
            onModalOpened={onModalOpened}
          />
        </ActionPanel>
      </div>
      <div className={classes.notesContainer}>
        <ActionPanel
          className={classes.notes}
          title={t('Action infos')}
          managedScroll={false}
        >
          <Infos action={action} type='action' />
        </ActionPanel>
        <ActionPanel
          className={classes.notes}
          title={t('Advertiser infos')}
          managedScroll={false}
        >
          <Infos action={action} type='advertiser' />
        </ActionPanel>
        {action.links.brandAdvertiserGetNotes && (
          <ActionPanel
            className={classes.notes}
            title={t('Advertiser infos cross-countries')}
            managedScroll={false}
          >
            <Infos action={action} type='multiCountry' />
          </ActionPanel>
        )}

        {/* <ActionPanel title={t('Infos sur l'action')}>
      </ActionPanel>
      <ActionPanel title={t('Infos sur l'annonceur')}>
      </ActionPanel>
      <ActionPanel title={t('Infos sur l'annonceur multi-pays')}>
      </ActionPanel> */}
      </div>
    </div>
  );
}
