import _ from 'lodash';
import { UserAccountApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';

export default function useUsersFilter(name:string) {
  const userAccountApi = new UserAccountApi(),
    { t } = useTranslation();

  async function promiseOptions(inputValue?: string) {
    const filter: { limit: number; order: string; where?: Object } = {
      limit: 50,
      order: 'name asc',
    };

    if (inputValue) {
      filter.where = { [name]: { regexp: `/${inputValue}/i` } };
    }

    const items = await userAccountApi.userAccountFind({
      filter: JSON.stringify(filter),
    });

    const options = _.map(items, (o) => {
      return {
        label: o[name] || o.email,
        value:  o[name] || o.email
      };
    });

    return options.sort((a, b) => a.label.localeCompare(b.label));
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('select user'),
      isMulti: true,
    }
  };
}
