import { observer } from 'mobx-react-lite';
import Counter from '../../Counter/Counter';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import classes from './Counters.module.css';

Counters.propTypes = {
  isLoading: PropTypes.bool,
  counters: PropTypes.arrayOf(PropTypes.object).isRequired,
  noParenthesis: PropTypes.bool
};

function Counters({ isLoading, counters, noParenthesis }) {
  const { t } = useTranslation();
  noParenthesis = false;

  return isLoading ? (
    <LoaderSmall color="monibrand" />
  ) : (
    counters &&
    counters.length > 0 && (
      <div className={classes.containerCountChild}>
        {counters.map((counter) => (
          <div
            className={`${classes.counter} ${!noParenthesis ? classes.parenthesis : ''}`}
            key={counter.txt}
          >
            <Counter key={counter.txt} {...counter} />
          </div>
        ))}
        {counters.filter((it) => it.children === true).length > 0 && (
          <div className={classes.containerCountChild}>
            <div>({t('whose')}: </div>
            {counters
              .filter((it) => it.children === true)
              .map((counter) => (
                <div className={classes.counter} key={counter.txt}>
                  <Counter key={counter.txt} {...counter} />
                </div>
              ))}
          </div>
        )}
      </div>
    )
  );
}

export default observer(Counters);
