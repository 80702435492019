import { useTranslation } from 'react-i18next';

interface props {
  readonly filters: any;
  readonly setFilter: Function;
  readonly name: string;
  readonly options: any;
  readonly label: string;
  readonly placeholder: string;
  readonly currentValue: Function;
}

export default function useSelectFilter({
  filters,
  setFilter,
  name,
  label,
  placeholder,
}: props) {
  const { t } = useTranslation();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    if (newValue) {
      setFilter(name, {
        valueNew: newValue ? [newValue] : [],
        whereClause: { inq: [newValue] },
        description: newValue,
        value: newValue,
        label,
      });
    } else {
      setFilter(name);
    }
  }

  return {
    label,
    options: {
      placeholder: placeholder ? t(placeholder) : t('Enter...'),
      value: filters?.[name]?.value || '',
      onChange: handleChange,
    },
  };
}
