import AdvertiserAction from '../AdvertiserAction/AdvertiserAction';
import AdvertisersStore from '../../../pages/Advertisers/advertisers.store';
import { Action } from '../../../types/action.newBackend';

interface Props {
  readonly item: Action;
  readonly store: AdvertisersStore;
}

export default function AdvertiserActionsCellView({ item, store }: Props) {
  return (
    item.status.match(/ignored|new|requested/gm) && (
      <AdvertiserAction
        replaceAdvertiser={(advertiser: any) =>
          store.replaceAdvertiser(advertiser, true)
        }
        advertiser={item}
      />
    )
  );
}
