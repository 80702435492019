import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import BrandsStore from './brands.store';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import Pagination from '../../components/Pagination/Pagination';
import BrandsCreateButton from '../../components/Brands/BrandsCreateButton';
import BrandForm from '../../components/Brands/BrandForm/BrandForm';
import GroupActions from '../../components/GroupActions/GroupActions';
import AutoMatchModale from '../../components/AutoMatchModale/AutoMatchModale';
import SelectFilter from '../../components/Filters/SelectFilter/SelectFilter';
import MagicTable from '../../components/MagicTable/MagicTable';
import CheckboxCell from '../../components/MagicTable/CheckboxCell/CheckboxCell';
import SwitcherCell from '../../components/MagicTable/SwitcherCell/SwitcherCell';
import BrandsActionsCell from '../../components/Brands/BrandsActionsCell/BrandsActionsCell';
import BrandsFilter from '../../components/Filters/BrandsFilter/BrandsFilter';
import BrandsAutoMatchCell from '../../components/Brands/BrandsAutoMatchCell/BrandsAutoMatchCell';
import _ from 'lodash';
import FullscreenModal from '../../components/FullscreenModal/FullscreenModal';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import Button from '../../components/Button/Button';
import BrandTagOfficial from '../../components/Brands/BrandTagOfficial/BrandTagOfficial';

function Brands() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser, currentBrands, isAdmin } = authStore,
    { t } = useTranslation(),
    [brandsStore] = useState(() => new BrandsStore({}, isAdmin,currentUser.id));

  useEffect(() => {
    uiStore.setTitle(t('Brands'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    brandsStore.setBrandsFilter(currentBrands, 'id');
    // eslint-disable-next-line
  }, [currentBrands]);

  return (
    <div className="tableContainer">
      {brandsStore.openModaleAutoMatch && (
        <AutoMatchModale
          {...brandsStore.openModaleAutoMatch}
          onClose={() => brandsStore.setOpenModaleAutoMatch()}
        />
      )}
      {brandsStore.openModaleLinkActiveDomains && (

        <BrandTagOfficial 
          brand={brandsStore.openModaleLinkActiveDomains.brand} 
          onClose={() => brandsStore.setOpenModaleLinkActiveDomains()} 
        />
      )}
      {brandsStore?.brandToUpdate && (
        <BrandForm
          fields={{
            ...brandsStore.brandToUpdate,
            companyAccounts: _.chain(brandsStore).get('brandToUpdate.companyAccounts',[]).map(
              (comAccount) => ({
                label: comAccount.name,
                value: comAccount,
              })
            ).value(),
          }}
          setClose={() => brandsStore.updateOneBrand()}
          update={(tag) => brandsStore.updateBrand(tag)}
          getAutoMatchConfig={(autoMatch) => brandsStore.getAutoMatchConfig(autoMatch)}
        />
      )}
      {brandsStore.selectedItem && (
        <FullscreenModal onClose={() => brandsStore.setSelectedItem(null)}>
          <div className="tableTxtModale">
            {`${t('are you sure to')} ${t(brandsStore.selectedItem.type)} ${t('the brand')}${' '}
            ${brandsStore.selectedItem.term?.name} ?`}
          </div>
          <div className="tableButtonsModale">
            <Button onClick={() => brandsStore.setSelectedItem(null)} type="reset" text="cancel"/>
            <SubmitButton
              txt={t('submit')}
              isLoading={brandsStore.isLoadingAction}
              onClick={() => brandsStore.selectedItem.action()}
            />
          </div>
        </FullscreenModal>
      )}

      <div className="tableContainerFilter">
        <div className="tableContainerButtons">
          <BrandsCreateButton
            update={(tag) => brandsStore.addBrand(tag)}
            isLoading={brandsStore.isLoading}
          />
        </div>
        {currentUser && (
          <NavFilter
            isLoading={brandsStore.isLoadingCount}
            pageStore={brandsStore}
            counters={brandsStore.counters}
          >
            <BrandsFilter label="brand" name="id" altName="brand"/>
            <SelectFilter
              label="state of validate"
              name="validated"
              placeholder="state of validate"
              options={[
                { label: t('validated'), value: true },
                { label: t('unvalidated'), value: false },
              ]}
            />
          </NavFilter>
        )}
        <GroupActions
          selected={brandsStore.selectedItems}
          actions={brandsStore.actions}
          isLoading={brandsStore.isLoadingAction}
        />
      </div>
      <div className="tableContainer">
        <div className="table">
          <MagicTable store={brandsStore}>
            <SwitcherCell
              field="validated"
              onClick={(item, store) =>
                store.setSelectedItem({
                  brand: item,
                  action: () =>
                    store.updateBoolField('validated', [item], !item.validated),
                  type: item.validated ? 'unvalidated' : 'validated',
                })
              }
            />
            <SwitcherCell
              field="reminder.autoReminder"
              onClick={(item, store) =>
                store.setSelectedItem({
                  companyAccount: item,
                  action: () => store.updateAutoReminder(item),
                  type: item?.reminder?.autoReminder
                    ? 'desactivate'
                    : 'activate',
                })
              }
            />
            <SwitcherCell
              field="createNotification"
              onClick={(item, store) =>
                store.setSelectedItem({
                  brand: item,
                  action: () =>
                    store.updateBoolField(
                      'createNotification',
                      [item],
                      !item.createNotification
                    ),
                  type: item.createNotification
                    ? 'create notification'
                    : 'delete notification',
                })
              }
            />
            <SwitcherCell
              field="notificationAuto"
              onClick={(item, store) =>
                store.setSelectedItem({
                  companyAccount: item,
                  action: () =>
                    store.updateBoolField(
                      'notificationAuto',
                      [item],
                      !item.notificationAuto
                    ),
                  type: item?.notificationAuto
                    ? 'desactivate auto notification for'
                    : 'activate auto notification for',
                })
              }
            />
            <SwitcherCell
              field="createShoppingNotification"
              onClick={(item, store) =>
                store.setSelectedItem({
                  companyAccount: item,
                  action: () =>
                    store.updateBoolField(
                      'createShoppingNotification',
                      [item],
                      !item.createShoppingNotification
                    ),
                  type: item?.createShoppingNotification
                    ? 'desactivate shopping notification for'
                    : 'activate shopping notification for',
                })
              }
            />
            <CheckboxCell field="selectAll" />
            <BrandsAutoMatchCell field="autoMatchRegExp" />
            <BrandsActionsCell field="actions" />
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={brandsStore.count}
        perPage={brandsStore.perPage}
        page={brandsStore.page}
        handleSetPage={(pageNumber) => brandsStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(Brands);
