import { useEffect, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  UpdateTermOptionsReducer,
  UpdateTermOptionsInitialState,
  UpdateTermOptionsActionsType,
} from './UpdateTermOptions.reducer';
import { TermApi } from '../../../swagger_client/src';
import { toast } from 'react-toastify';
import Checkbox from '../../Checkbox/Checkbox';
import classes from './UpdateTermOptions.module.css';
import ActionForMultiSelect from '../../ActionForMultiSelect/ActionForMultiSelect';
import termDefinition from '../../../proptypes/term.definition';

UpdateTermOptions.propTypes = {
  selectedItems: PropTypes.any.isRequired,
  selectedItem: PropTypes.shape(termDefinition),
  refetch: PropTypes.func.isRequired,
  forShortMenuIcon: PropTypes.any,
  defaultOpen: PropTypes.bool
};

export default function UpdateTermOptions({
  selectedItems,
  selectedItem,
  refetch,
  defaultOpen,
  forShortMenuIcon
}) {
  const { t } = useTranslation(),
    [
      {
        isOpen,
        saveOrganicResults,
        disableSaveAds,
        saveShoppingAds,
        isLoading,
      },
      dispatch,
    ] = useReducer(UpdateTermOptionsReducer, UpdateTermOptionsInitialState);

  useEffect(() => {
    if (selectedItem) {
      dispatch({
        type: UpdateTermOptionsActionsType.setInitState,
        payload: selectedItem,
      });
    } else {
      dispatch({ type: UpdateTermOptionsActionsType.reInitState });
    }
  }, [selectedItem]);

  async function handleSubmit() {
    dispatch({
      type: UpdateTermOptionsActionsType.setIsLoading,
      payload: true,
    });
    // await
    try {
      const termApi = new TermApi();
      await termApi.termUpdateAll({
        body: {
          options: { saveOrganicResults, disableSaveAds, saveShoppingAds },
        },
        where: JSON.stringify({ id: { inq: selectedItems } }),
      });
      toast.success(
        `${selectedItems.length} ${t(
          selectedItems.length > 1 ? 'term updated' : 'terms updated'
        )}`
      );
    } catch (e) {
      toast.error(t('Update failed'));
    }
    dispatch({ type: UpdateTermOptionsActionsType.reInitState });
    await refetch();
  }

  return (
    <ActionForMultiSelect
      forShortMenuIcon={forShortMenuIcon}
      actionName="Modify options"
      title="Modify term options"
      isLoading={isLoading}
      isOpen={defaultOpen || isOpen}
      setIsOpen={() =>
        dispatch({ type: UpdateTermOptionsActionsType.setIsOpen })
      }
      onSubmit={handleSubmit}
      submitbuttonText="Modify options"
      subTitle={
        <Trans i18nKey="optionsTermsAssing" count={selectedItems.length}>
          Check the options you want to assign to the{' '}
          <strong>{{ count: selectedItems.length }}</strong> selected keywords.
        </Trans>
      }
    >
      <div className={classes.container}>
        <section className={classes.section} >
          <div className={classes.checkOptions}>
            <div className={classes.checkTitle}>{t('In the engine results page, you want to collect')}:</div>
            <div className={classes.checkbox}>
              <Checkbox
                label={t('The organic results')}
                checked={saveOrganicResults}
                handleClick={() =>
                  dispatch({
                    type: UpdateTermOptionsActionsType.setSaveOrganicResults,
                    payload: !saveOrganicResults,
                  })
                }
              />
            </div>
            <div className={classes.checkbox}>
              <Checkbox
                label={t('Paid results of the "Classic advertising" type')}
                checked={!disableSaveAds}
                handleClick={() =>
                  dispatch({
                    type: UpdateTermOptionsActionsType.setDisableSaveAds,
                    payload: !disableSaveAds,
                  })
                }
              />
            </div>
            <div className={classes.checkbox}>
              <Checkbox
                label={t('Paid results of the "Shopping" type')}
                checked={saveShoppingAds}
                handleClick={()=>
                  dispatch({
                    type: UpdateTermOptionsActionsType.setSaveShoppingAds,
                    payload: !saveShoppingAds,
                  })
                }
              />
            </div>
       
          </div>
        </section>
      </div>
    </ActionForMultiSelect>
  );
}
