import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function CancelButton({ txt, onClose }) {
  const { t } = useTranslation();
  return (
    <button 
      type="reset"
      className="reset"
      onClick={onClose}
      data-testid="reset-button"
    >
      {t(txt)}
    </button>
  );
}

CancelButton.propTypes = {
  txt: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};
