import ActionForMultiSelectNoTrigger from '../../ActionForMultiSelect/ActionForMultiSelectNoTrigger/ActionForMultiSelectNoTrigger';
import classes from './BrandTagOfficial.module.css';
import { BrandInterface } from '../../../types/brand.interface';
import ChipsInput from '../../ChipsInput/ChipsInput';

interface props {
  readonly t: Function
  readonly loading: boolean
  readonly handleLinkDomains: Function
  readonly setDomainWithControl: Function
  readonly onClose: Function
  readonly brand: BrandInterface
  readonly disabledSubmit: boolean
  readonly domains: Array<string>
}

export default function BrandTagOfficialView({
  t,
  loading,
  setDomainWithControl,
  handleLinkDomains,
  onClose,
  brand,
  domains,
  disabledSubmit
}: props) {
  return (
    <ActionForMultiSelectNoTrigger
      title={t('Link official domains to brand')}
      isLoading={loading}
      onSubmit={() => handleLinkDomains()}
      onClose={() => onClose()}
      submitbuttonText={t('link domains')}
      submitbuttonDisabled={
        loading || disabledSubmit
      }
      subTitle={
        <>
          <div
            className={classes.desc}
            dangerouslySetInnerHTML={{
              __html: t('linkOfficialDomainsToBrandDescription1', {
                brandLabel: brand.label,
              }),
            }}
          ></div>
          <div className={classes.desc}
            dangerouslySetInnerHTML={{
              __html: t('linkOfficialDomainsToBrandDescription2'),
            }}>
          </div>
        </>
      }
    >
      <div className={classes.container}>
        <div className={classes.label}>{t('Official domain names')}</div>
        <ChipsInput
          values={domains}
          setValues={(domains) => setDomainWithControl(domains)}
          placeholder={domains?.length === 0 ? t(
            'Fill the domain name and press ENTER'
          ) : ''}
        />
      </div>
    </ActionForMultiSelectNoTrigger>
  );
}
