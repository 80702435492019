import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ErrorsMsg from '../../components/ErrorsMsg/ErrorsMsg';
import { ParseUseFormErrors } from '../../Utils/utils';
import AuthInput from '../../components/AuthInput/AuthInput';
import PublicSubmitButton from '../../components/PublicSubmitButton/PublicSubmitButton';
import { StoreContext } from '../../stores';

function Forgot() {
  const { authStore, uiStore } = useContext(StoreContext),
    { recoverInProgress, recoverErrors } = authStore,
    { register, handleSubmit, errors } = useForm(),
    [ passwordRecoverSent, setPasswordRecoverSent ] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Dashboard access'));
    // eslint-disable-next-line
  }, []);

  const onHandleSubmit = (event) => {
    authStore.recoverPassword(event).then((success) => {
      if (success) {
        setPasswordRecoverSent(true);
      }
    });
  };

  return passwordRecoverSent ? (
    <div>
      {!recoverErrors && (
        <div>
          {t('An email allowing you to update your password has been sent to you!')}
        </div>
      )}
      {recoverErrors && <ErrorsMsg errors={recoverErrors} />}
      <Link to="/login">
        <div>{t('log in')}</div>
      </Link>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onHandleSubmit)} data-testid="form">
      <AuthInput
        label="Mail"
        name="email"
        register={register({
          required: {
            value: true,
            message: t('The email is required'),
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: t('The email is not invalid email address'),
          },
        })}
        errors={errors}
      />
      {Object.keys(errors).length > 0 && errors.constructor === Object && (
        <ErrorsMsg errors={ParseUseFormErrors(errors)} />
      )}
      <PublicSubmitButton disabled={recoverInProgress}>
        {t('Récupérer')}
      </PublicSubmitButton>
    </form>
  );
}

export default observer(Forgot);

Forgot.propTypes = {
  authStore: PropTypes.object.isRequired,
};
