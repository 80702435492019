import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoginImage from './../../assets/img/login-image.svg';
import classes from './Auth.module.css';
import { routes } from '../../Router/routes';

export default function Auth({children}) {
  const 
    {t} = useTranslation(),
    location = useLocation(), 
    routePortion = _.drop(location.pathname.split('/'), 1)[0];

  return( 
    <section className={classes.container}> 
      <article className={classes.containerGrid}>
        <div className={classes.grid}>
          <header className={classes.title}>
            {t(routes.find(it=>it.path.match(routePortion)).title)}
          </header>
          <div className={classes.containerImg}>
            <img className={classes.img} src={LoginImage} alt="monibrand" />  
          </div>
          <div className={classes.containerForm}>
            {children}
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

Auth.propTypes = {
  children: PropTypes.object
};