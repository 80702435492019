import { useState } from 'react';
import Hamburger from '../Hamburger/Hambuger';
import classes from './MenuMobile.module.css';
import ProfileCard from '../ProfileCard/ProfileCard';
import Menu from '../Menu/Menu';

export default function MenuMobile() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div data-testid="menu_mobile">
      <Hamburger setIsOpen={setIsOpen} isOpen={isOpen} />
      <div data-open={isOpen} className={classes.menu}>
        <ProfileCard/>
        <Menu setIsOpen={setIsOpen} />
      </div>
    </div>
  );
}