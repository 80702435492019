import { TermInterface } from '../../../types/term.interface';
import TermCompanyAccountEditView from './TermCompanyAccountEditView';
import useTermCompanyAccountEdit from './useTermCompanyAccountEdit';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
}

export default function TermCompanyAccountEdit(props:Props){
  const useProps = useTermCompanyAccountEdit(props);

  return <TermCompanyAccountEditView {...props} {...useProps}/>;
}