/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The AccessToken model module.
* @module model/AccessToken
* @version 4.0.0
*/
export default class AccessToken {
    /**
    * Constructs a new <code>AccessToken</code>.
    * @alias module:model/AccessToken
    * @class
    * @param id {String} 
    */

    constructor(id) {
        
        
        this['id'] = id;
        
    }

    /**
    * Constructs a <code>AccessToken</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AccessToken} obj Optional instance to populate.
    * @return {module:model/AccessToken} The populated <code>AccessToken</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccessToken();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('ttl')) {
                obj['ttl'] = ApiClient.convertToType(data['ttl'], 'Number');
            }
            if (data.hasOwnProperty('scopes')) {
                obj['scopes'] = ApiClient.convertToType(data['scopes'], ['String']);
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ObjectID.constructFromObject(data['userId']);
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    id = undefined;
    /**
    * time to live in seconds (2 weeks by default)
    * @member {Number} ttl
    * @default 1209600.0
    */
    ttl = 1209600.0;
    /**
    * Array of scopes granted to this access token.
    * @member {Array.<String>} scopes
    */
    scopes = undefined;
    /**
    * @member {Date} created
    */
    created = undefined;
    /**
    * @member {module:model/ObjectID} userId
    */
    userId = undefined;




}
