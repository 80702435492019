import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import usePanelFilter from '../PanelFilter/usePanelFilter';
import StandAloneFilterView from './StandAloneFilterView';

interface Props {
  readonly name:string
  readonly child: ReactElement
  readonly pageStore:any
  readonly onClose: Function
}

function StandAloneFilter({ name, child, pageStore, onClose }:Props) {
  const 
    useProps = usePanelFilter({pageStore,isOpen:true,setIsOpen:onClose}),
    passProps = {...useProps,onClose,child,name,pageStore};

  return <StandAloneFilterView {...passProps}/>;
}

export default observer(StandAloneFilter);
