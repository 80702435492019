import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router/Router';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import './Utils/i18n/i18n';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Store, { StoreContext } from './stores';
import { configure } from 'mobx';
import './assets/style/index.css';
import './assets/style/table.css';
import * as serviceWorker from './serviceWorker';
import './agent';
import cleanFormatFilters from './Utils/migration/cleanFormatFilters';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
const pack = require('./../package.json');

//For filter migration
cleanFormatFilters();

dayjs.extend(LocalizedFormat);
dayjs.extend(Duration);
dayjs.extend(RelativeTime);
dayjs.extend(updateLocale);

configure({
  enforceActions: 'never',
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_DASHBOARD_V2,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV_LABEL,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

console.log('%cVersion: ' + pack.version, 'color:green;font-size:20px');

const store = new Store(flagsmith);

ReactDOM.render(
  <FlagsmithProvider
    options={{
      environmentID: process.env.REACT_APP_FLAG_SMITH_ENVIRONMENT_ID as string,
      api:
        process.env.REACT_APP_FLAG_SMITH_API_URL ||
        'https://flagsmith.monibrand.com/api/v1/',
    }}
    flagsmith={flagsmith}
  >
    <StoreContext.Provider value={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </StoreContext.Provider>
  </FlagsmithProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
