import { observer } from 'mobx-react-lite';
import StopGoStore from './StopGo.store';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import classes from './News.module.css';

function StopGoNews() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go - News'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.mainDiv}>
      <h2 className={classes.title}>
        Ne gaspillez plus votre budget Google Ads inutilement !
      </h2>
      <p>
        Avec le <b>Stop & Go</b>, les enchères Google Ads sont automatiquement
        suspendues pour les termes de marque sur lesquels vous êtes déjà
        positionné en organique et sur lesquels aucun concurrent
        n&apos;enchérit.
      </p>
      <p>
        <b>Faîtes des économies en activant dès maintenant le Stop & Go !</b>
      </p>
    </div>
  );
}

export default observer(StopGoNews);
