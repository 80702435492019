import _ from 'lodash';
import classes from './TermsLinKCell.module.css';
import { Link } from 'react-router-dom';
import TermsStore from '../../../pages/Terms/terms.store';

export default function TermsLinKCell({
  item,
  field,
  store,
}: {
  item: any;
  field: any;
  store: TermsStore;
}) {
  const { content, locale, engine } = item;

  const locales = locale.split(/[-_]/);
  const country = locales[locales.length - 1];

  const to = {
    pathname: '/domains',
    state: {
      filters: _.mapValues(
        {
          ...store.filters,
          page: 1,
          keywordContent: {
            label: 'keywords',
            description: content,
            value: [{ label: content, value: content }],
            whereClause: { or: [{ term: { inq: [content] } }] },
            valueNew: [content],
          },
          country: {
            label: 'country',
            description: country,
            value: [{ label: country, value: country }],
            whereClause: { inq: [country] },
            valueNew: [country],
          },
          engine: {
            description: engine.toLowerCase(),
            label: 'engine',
            value: [{ label: engine, value: engine.toLowerCase() }],
            whereClause: { inq: [engine.toLowerCase()] },
            valueNew: [engine.toLowerCase()],
          },
        },
        JSON.stringify
      ),
    },
  };

  return (
    <div className={classes.container}>
      <Link className={classes.link} to={to}>
        <div>{_.get(item, field)}</div>
      </Link>
    </div>
  );
}
