import classes from './Switch.module.css';
import PropTypes from 'prop-types';

Switch.defaultProps = {
  testid: 'switch'
};

Switch.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isActived: PropTypes.bool,
  testid: PropTypes.string,
};

export default function Switch({isActived , onClick,disabled,testid}){

  return (
    <button
      disabled={disabled}
      className={classes.container}
      data-isactive={isActived}
      onClick={onClick}
    >
      <div
        className={classes.ball}
        data-isactive={isActived}
        data-testid={testid}
      />
    </button>
  );
}
