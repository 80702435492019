import { useState } from 'react';
import { toast } from 'react-toastify';
import { TermApi } from '../../swagger_client/src';
import LoaderSmall from '../_ui/LoaderSmall/LoaderSmall';
import classes from './AutoMatchModaleList.module.css';
import PropTypes from 'prop-types';
import DynamicScrollbar from '../DynamicScrollbar/DynamicScrollbar';

AutoMatchModaleList.propTypes = {
  where: PropTypes.object,
};

export default function AutoMatchModaleList({ where }) {
  const [terms, setTerms] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    termApi = new TermApi();

  useState(() => {
    handleFetch();
  }, []);

  async function handleFetch() {
    try {
      setTerms(await termApi.termFind({ filter: JSON.stringify({ where }) }));
    } catch (e) {
      toast.error(e.message);
    }
    setIsLoading(false);
  }

  return (
    <DynamicScrollbar className={classes.termsContainer}>
      {isLoading ? (
        <LoaderSmall />
      ) : (
        <div className={classes.terms}>
          {terms.map((term) => (
            <div className={classes.term} key={term.id}>
              {term.term}
            </div>
          ))}
        </div>
      )}
    </DynamicScrollbar>
  );
}
