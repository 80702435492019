/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.17.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { CompanyAccount } from '..';
import ApiClient from '../ApiClient';
import ObjectID from './ObjectID';

/**
* The Brand model module.
* @module model/Brand
* @version 4.17.0
*/
export default class Brand {
    /**
    * Constructs a new <code>Brand</code>.
    * @alias module:model/Brand
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Brand</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Brand} obj Optional instance to populate.
    * @return {module:model/Brand} The populated <code>Brand</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Brand();
                        
            
            if (data.hasOwnProperty('label')) {
                obj['label'] = ApiClient.convertToType(data['label'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('autoMatchRegExp')) {
                obj['autoMatchRegExp'] = ApiClient.convertToType(data['autoMatchRegExp'], 'String');
            }
            if (data.hasOwnProperty('autoMatchExcludeRegExp')) {
                obj['autoMatchExcludeRegExp'] = ApiClient.convertToType(data['autoMatchExcludeRegExp'], 'String');
            }
            if (data.hasOwnProperty('validated')) {
                obj['validated'] = ApiClient.convertToType(data['validated'], 'Boolean');
            }
            if (data.hasOwnProperty('createNotification')) {
                obj['createNotification'] = ApiClient.convertToType(data['createNotification'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }

            if (data.hasOwnProperty('notificationAuto')) {
                obj['notificationAuto'] = ApiClient.convertToType(data['notificationAuto'], 'Boolean');
            }
            if (data.hasOwnProperty('reminder')) {
                obj['reminder'] = ApiClient.convertToType(data['reminder'], 'Object');
            }
            
            if (data.hasOwnProperty('companyAccounts')) {
                obj['companyAccounts'] = ApiClient.convertToType(data['companyAccounts'], [CompanyAccount]);
            }

            if (data.hasOwnProperty('createShoppingNotification')) {
                obj['createShoppingNotification'] = ApiClient.convertToType(data['createShoppingNotification'], 'Boolean');
            }

            
        }

        return obj;
    }

    /**
    * @member {String} label
    */
    label = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {Boolean} validated
    */
    validated = undefined;
    /**
    * @member {Boolean} createNotification
    */
    createNotification = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;




}
