import { pictos } from '../../../types/pictos.enum';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

interface Props {
  readonly store: {
    setTagToUpdate: Function,
    setTagToDelete: Function,
  }
  readonly item: any
}

export default function TagsActionsCell({ item,store } : Props) {
  return (
    <ShortMenuModale >
      <ShortMenuList>
        <ShortMenuAction 
          icon={pictos.pen} 
          text="update tag" 
          onClick={() => store.setTagToUpdate(item)}
        />
        <ShortMenuAction 
          icon={pictos.trash} 
          text="delete tag" 
          onClick={() => store.setTagToDelete(item)}
        />
      </ShortMenuList>
    </ShortMenuModale>
  );
}
