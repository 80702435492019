import { useReducer, Reducer, useState } from 'react';
import Backend from '../../../stores/newBackend';
import { Tag } from '../../../types/tag';
import { ActionApi } from '../../../swagger_client/src';

type ActionResult = {
  type: 'results';
  tags: any[];
};

type ReducerAction =
  | { type: 'load' }
  | ActionResult
  | { type: 'error'; error: any };

interface State {
  loading: boolean;
  error: any;
  tags: any[];
}

const useGetActionTagsInitialeState = {
  loading: false,
  error: null,
  tags: [],
};

/* 
      const items = await this.actionApi.actionFind({
        actionFindOne / actionFindById
*/

const actionApi = new ActionApi();

export default function useGetActionTags() {
  const [{ loading, error, tags }, dispatch] = useReducer<
    Reducer<State, ReducerAction>
  >(useGetActionTagsReducer, useGetActionTagsInitialeState);

  const [fetch] = useState(() => {
    return async (token: string, link: string) => {
      dispatch({
        type: 'load',
      });

      try {
        // Using the old backend
        // const actions = await actionApi.actionFind({
        //   filter: JSON.stringify({
        //     where: { id: link.replace('/advertiserTag/action?actionID=', '') },
        //     include: [{ relation: 'dealer', scope: { include: 'categories' } }]
        //   }),
        // });
        const actionID = link.replace('/advertiserTag/action?actionID=', '');
        const advertiserTags = await Backend.getTagForAction(token, actionID);
        if (advertiserTags && advertiserTags.length > 0) {
          for (let i = 0; i < advertiserTags.length; i++) {
            advertiserTags[i].id = advertiserTags[i].ID;
            advertiserTags[i].actionId = advertiserTags[i].actionID;
          }
        }
        const dispatchData: ActionResult = {
          type: 'results',
          tags: advertiserTags,
        };

        dispatch(dispatchData);
        // Uncomment when the new backend will be available for tag management
        /* const results = await Backend.fetchLink(token, link);
        const dispatchData: ActionResult = {
          type: 'results',
          tags: results.map(parseTag),
        };

        dispatch(dispatchData); */
      } catch (e) {
        dispatch({ type: 'error', error: e });
      }
    };
  });

  return { loading, error, tags, fetch };
}

const parseTag = (httpTag: any): Tag => {
  const tag = {
    ...httpTag,
  };

  if (tag.createdDate) {
    tag.createdDate = new Date(tag.createdDate);
  }

  return tag;
};

function useGetActionTagsReducer(state: State, action: ReducerAction): State {
  const newState = { ...state };
  switch (action.type) {
    case 'load':
      newState.loading = true;
      break;
    case 'results':
      newState.loading = false;
      newState.tags = action.tags;
      break;
    case 'error':
      newState.loading = false;
      newState.error = action.error;
      break;
    default:
      throw new Error();
  }

  return newState;
}
