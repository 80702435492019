/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 5.18.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Brand from '../model/Brand';
import Category from '../model/Category';
import CategoryDealer from '../model/CategoryDealer';
import Dealer from '../model/Dealer';
import ObjectID from '../model/ObjectID';

/**
* Category service.
* @module api/CategoryApi
* @version 5.18.0
*/
export default class CategoryApi {

    /**
    * Constructs a new CategoryApi. 
    * @alias module:api/CategoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryCountWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryCount(opts) {
      return this.categoryCountWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryCreate(opts) {
      return this.categoryCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    categoryCreateChangeStreamGetCategoriesChangeStreamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'options': opts['options']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Categories/change-stream', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    categoryCreateChangeStreamGetCategoriesChangeStream(opts) {
      return this.categoryCreateChangeStreamGetCategoriesChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    categoryCreateChangeStreamPostCategoriesChangeStreamWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/Categories/change-stream', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    categoryCreateChangeStreamPostCategoriesChangeStream(opts) {
      return this.categoryCreateChangeStreamPostCategoriesChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryDeleteByIdWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryDeleteById(id) {
      return this.categoryDeleteByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryExistsGetCategoriesidExistsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/{id}/exists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryExistsGetCategoriesidExists(id) {
      return this.categoryExistsGetCategoriesidExistsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryExistsHeadCategoriesidWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/{id}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryExistsHeadCategoriesid(id) {
      return this.categoryExistsHeadCategoriesidWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Category>} and HTTP response
     */
    categoryFindWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Category];

      return this.apiClient.callApi(
        '/Categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Category>}
     */
    categoryFind(opts) {
      return this.categoryFindWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryFindByIdWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryFindById(id, opts) {
      return this.categoryFindByIdWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryFindOneWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/findOne', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryFindOne(opts) {
      return this.categoryFindOneWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPatchOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPatchOrCreate(opts) {
      return this.categoryPatchOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts children of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryPrototypeCountChildrenWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/{id}/children/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts children of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryPrototypeCountChildren(id, opts) {
      return this.categoryPrototypeCountChildrenWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts dealers of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryPrototypeCountDealersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts dealers of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryPrototypeCountDealers(id, opts) {
      return this.categoryPrototypeCountDealersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in children of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPrototypeCreateChildrenWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}/children', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in children of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPrototypeCreateChildren(id2, opts) {
      return this.categoryPrototypeCreateChildrenWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in dealers of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Dealer} and HTTP response
     */
    categoryPrototypeCreateDealersWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'domain': opts['domain'],
        'tests': opts['tests'],
        'date': opts['_date'],
        'updated': opts['updated'],
        'datas': opts['datas'],
        'processed': this.apiClient.buildCollectionParam(opts['processed'], 'multi'),
        'note': opts['note'],
        'metas': opts['metas'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Dealer;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in dealers of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Dealer}
     */
    categoryPrototypeCreateDealers(id2, opts) {
      return this.categoryPrototypeCreateDealersWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all children of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    categoryPrototypeDeleteChildrenWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Categories/{id}/children', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all children of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    categoryPrototypeDeleteChildren(id) {
      return this.categoryPrototypeDeleteChildrenWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all dealers of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    categoryPrototypeDeleteDealersWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all dealers of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    categoryPrototypeDeleteDealers(id) {
      return this.categoryPrototypeDeleteDealersWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    categoryPrototypeDestroyByIdChildrenWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Categories/{id}/children/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    categoryPrototypeDestroyByIdChildren(id, fk) {
      return this.categoryPrototypeDestroyByIdChildrenWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    categoryPrototypeDestroyByIdDealersWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    categoryPrototypeDestroyByIdDealers(id, fk) {
      return this.categoryPrototypeDestroyByIdDealersWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check the existence of dealers relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    categoryPrototypeExistsDealersWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/rel/{fk}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check the existence of dealers relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    categoryPrototypeExistsDealers(id, fk) {
      return this.categoryPrototypeExistsDealersWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPrototypeFindByIdChildrenWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}/children/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPrototypeFindByIdChildren(id, fk) {
      return this.categoryPrototypeFindByIdChildrenWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Dealer} and HTTP response
     */
    categoryPrototypeFindByIdDealersWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Dealer;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Dealer}
     */
    categoryPrototypeFindByIdDealers(id, fk) {
      return this.categoryPrototypeFindByIdDealersWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries children of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Category>} and HTTP response
     */
    categoryPrototypeGetChildrenWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Category];

      return this.apiClient.callApi(
        '/Categories/{id}/children', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries children of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Category>}
     */
    categoryPrototypeGetChildren(id, opts) {
      return this.categoryPrototypeGetChildrenWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries dealers of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Dealer>} and HTTP response
     */
    categoryPrototypeGetDealersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Dealer];

      return this.apiClient.callApi(
        '/Categories/{id}/dealers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries dealers of Category.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Dealer>}
     */
    categoryPrototypeGetDealers(id, opts) {
      return this.categoryPrototypeGetDealersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetches belongsTo relation owner.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Brand} and HTTP response
     */
    categoryPrototypeGetOwnerWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'refresh': opts['refresh']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Brand;

      return this.apiClient.callApi(
        '/Categories/{id}/owner', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetches belongsTo relation owner.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Brand}
     */
    categoryPrototypeGetOwner(id, opts) {
      return this.categoryPrototypeGetOwnerWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetches belongsTo relation parent.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPrototypeGetParentWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'refresh': opts['refresh']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}/parent', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetches belongsTo relation parent.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPrototypeGetParent(id, opts) {
      return this.categoryPrototypeGetParentWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CategoryDealer} and HTTP response
     */
    categoryPrototypeLinkDealersWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'id': opts['id'],
        'categoryId': opts['categoryId'],
        'dealerId': opts['dealerId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CategoryDealer;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/rel/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CategoryDealer}
     */
    categoryPrototypeLinkDealers(id2, fk, opts) {
      return this.categoryPrototypeLinkDealersWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPrototypePatchAttributesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPrototypePatchAttributes(id2, opts) {
      return this.categoryPrototypePatchAttributesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the dealers relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    categoryPrototypeUnlinkDealersWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/rel/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the dealers relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    categoryPrototypeUnlinkDealers(id, fk) {
      return this.categoryPrototypeUnlinkDealersWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryPrototypeUpdateByIdChildrenWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}/children/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for children.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryPrototypeUpdateByIdChildren(id2, fk, opts) {
      return this.categoryPrototypeUpdateByIdChildrenWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Dealer} and HTTP response
     */
    categoryPrototypeUpdateByIdDealersWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'domain': opts['domain'],
        'tests': opts['tests'],
        'date': opts['_date'],
        'updated': opts['updated'],
        'datas': opts['datas'],
        'processed': this.apiClient.buildCollectionParam(opts['processed'], 'multi'),
        'note': opts['note'],
        'metas': opts['metas'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Dealer;

      return this.apiClient.callApi(
        '/Categories/{id}/dealers/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for dealers.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Dealer}
     */
    categoryPrototypeUpdateByIdDealers(id2, fk, opts) {
      return this.categoryPrototypeUpdateByIdDealersWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryReplaceByIdPostCategoriesidReplaceWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryReplaceByIdPostCategoriesidReplace(id2, opts) {
      return this.categoryReplaceByIdPostCategoriesidReplaceWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryReplaceByIdPutCategoriesidWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryReplaceByIdPutCategoriesid(id2, opts) {
      return this.categoryReplaceByIdPutCategoriesidWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryReplaceOrCreatePostCategoriesReplaceOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/replaceOrCreate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryReplaceOrCreatePostCategoriesReplaceOrCreate(opts) {
      return this.categoryReplaceOrCreatePostCategoriesReplaceOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryReplaceOrCreatePutCategoriesWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryReplaceOrCreatePutCategories(opts) {
      return this.categoryReplaceOrCreatePutCategoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    categoryUpdateAllWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/Categories/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    categoryUpdateAll(opts) {
      return this.categoryUpdateAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    categoryUpsertWithWhereWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'label': opts['label'],
        'scope': opts['scope'],
        'brandOwnerId': opts['brandOwnerId'],
        'visibility': opts['visibility'],
        'id': opts['id'],
        'parentId': opts['parentId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/Categories/upsertWithWhere', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    categoryUpsertWithWhere(opts) {
      return this.categoryUpsertWithWhereWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
