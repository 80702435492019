
export const taglistStyle = {
  option: (provided, state) => (
    {
      ...provided,
      textAlign: 'left',
      fontSize: '14px',
      display: 'inherit',
      color: state?.data?.scope?.match(/global/)
        ? 'var(--color-green)'
        : state?.data?.scope?.match(/brand/)
          ? 'var(--color-orange)'
          : 'black',
      background: state.isFocused ? 'var(--color-bg-extra-light)' : 'white'
    }),
  menuList: (provided) => (
    {
      ...provided,
    }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: '1.1em',
    textAlign: 'left',
    paddingLeft: 0,

  }),
  group: (provided) => ({
    ...provided,
    paddingLeft: 15,
    margin: 3,
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,.05)',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    background: 'none',
    fontSize: '1rem',
    boxShadow: state.isFocused ? `-1.5px -1.5px 0 var(--color-princ-monibrand),
    1.5px -1.5px 0 var(--color-princ-monibrand), 
    -1.5px 1.5px 0 var(--color-princ-monibrand),  
    1.5px 1.5px 0 var(--color-princ-monibrand)`:
      `-1px -1px 0 var(--color-input-border),
    1px -1px 0 var(--color-input-border), 
    -1px 1px 0 var(--color-input-border),  
    1px 1px 0 var(--color-input-border)`,
    '&:hover': {
      boxShadow: `-1.5px -1.5px 0 var(--color-border),
      1.5px -1.5px 0 var(--color-border), 
      -1.5px 1.5px 0 var(--color-border),  
      1.5px 1.5px 0 var(--color-border)`
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px !important'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'rgb(0, 0, 0, 0.10)',
      color: 'rgb(255, 255, 255)',
    },
  }),
  multiValue: (provided,state) => {
    return {
      ...provided,
      padding: 5,
      borderRadius: 5,
      color: 'white',
      background: state.data.__isNew__ ?'var(--color-link-menu)': 'var(--color-azure)',
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const maxWidth= '300px';
    return { ...provided, opacity,maxWidth, transition };
  },
};

export const filterSelectStyle = {
  option: (provided, state) => (
    {
      ...provided,
      textAlign: 'left',
      display: 'inherit',
      fontSize: '14px',
      color: state?.data?.scope?.match(/global/)
        ? 'var(--color-green)'
        : state?.data?.scope?.match(/brand/)
          ? 'var(--color-orange)'
          : 'black',
      background: state.isFocused ? 'var(--color-bg-extra-light)' : 'white'
    }),
  control: (provided, state) => ({
    ...provided,
    border: 'solid 1px #dedede',
    background: 'none',
    boxShadow: state.isFocused ? `-1.5px -1.5px 0 var(--color-princ-monibrand),
    1.5px -1.5px 0 var(--color-princ-monibrand), 
    -1.5px 1.5px 0 var(--color-princ-monibrand),  
    1.5px 1.5px 0 var(--color-princ-monibrand)`:
      `-1px -1px 0 var(--color-bg-extra-light),
    1px -1px 0 var(--color-bg-extra-light), 
    -1px 1px 0 var(--color-bg-extra-light),  
    1px 1px 0 var(--color-bg-extra-light)`,
    '&:hover': {
      boxShadow: `-1.5px -1.5px 0 var(--color-border),
      1.5px -1.5px 0 var(--color-border), 
      -1.5px 1.5px 0 var(--color-border),  
      1.5px 1.5px 0 var(--color-border)`
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px !important'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    padding: 5,
    borderRadius: 5,
    color: 'white',
    background: 'var(--color-azure)',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  noOptionsMessage: (provided) => {
    const padding = 0;
    const margin = 0;
    return { ...provided, margin, padding };
  },
  menuList: (provided, state) => {
    const display = state.options.length === 0 ? 'none' : 'block';
    return { ...provided, display, fontSize: '14px' };
  }
};
