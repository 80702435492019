import _ from 'lodash';
import { Children, cloneElement, ReactElement } from 'react';
import Button from '../../Button/Button';
import DynamicScrollbar from '../../DynamicScrollbar/DynamicScrollbar';
import SubmitButton from '../../SubmitButton/SubmitButton';
import classes from './PanelFilterContent.module.css';
import Accordion from '../../Accordion/Accordion';

interface Props {
  readonly loading: boolean;
  readonly filters: any;
  readonly filtersElements: Array<ReactElement>;
  readonly reinitialization: Function;
  readonly setFilter: Function;
  readonly validate: Function;
  readonly t: Function;
}

export default function PanelFilterContent({
  loading,
  filters,
  filtersElements,
  setFilter,
  reinitialization,
  validate,
  t,
}: Props) {
  return (
    <>
      <div className={classes.main}>
        <DynamicScrollbar className={classes.containerFilters}>
          <div className={classes.filters}>
            {filters &&
              Children.map(filtersElements, (child: ReactElement) => (
                <Accordion
                  title={child.props.label}
                  initState={
                    !!_.get(filters?.[child.props.name], 'description')
                  }
                >
                  {cloneElement(child, {
                    filters,
                    setFilter,
                    name: child?.props.name,
                    label: child?.props.label,
                  })}
                </Accordion>
              ))}
          </div>
        </DynamicScrollbar>
      </div>
      <div className={classes.buttons}>
        <Button onClick={() => reinitialization()} type='reset' text='reset' />
        <SubmitButton
          txt={`${t('save & close')}`}
          onClick={() => validate()}
          isLoading={loading}
        />
      </div>
    </>
  );
}
