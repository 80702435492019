import PercentInputView from './PercentInputView';
import usePercentInput from './usePercentInput';

interface Props {
  readonly percent: number
  readonly setPercent: Function
  readonly min?:number;
  readonly max?:number
  readonly disabled?:boolean
}

export default function PercentInput(props:Props){
  const useProps = usePercentInput(props.setPercent);
  return <PercentInputView {...props} {...useProps}/>;
}