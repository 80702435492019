import PropTypes from 'prop-types';
import classes from './ErrorsMsg.module.css';

export default function ErrorsMsg({ errors, UnauthorizedMsg }) {
  return (
    <ol className={classes.errorsList}>
      {errors.map((error) => (
        <li className={classes.errors} key={error} data-testid="error">
          {error === 'Unauthorized'
            ? UnauthorizedMsg
              ? UnauthorizedMsg
              : error
            : error}
        </li>
      ))}
    </ol>
  );
}

ErrorsMsg.propTypes = {
  errors: PropTypes.array.isRequired,
  UnauthorizedMsg: PropTypes.string,
};
