import classes from './WorkspaceSelector.module.css';
import EnvelopeSVG from '../Icon/svg/Envelope.svg';
import ListCheckSVG from '../Icon/svg/ListCheck.svg';
import ChartBarSVG from '../Icon/svg/ChartBar.svg';
import { NavLink } from 'react-router-dom';
import NewTooltip from '../_ui/Tooltip/NewTooltip';
import { useTranslation } from 'react-i18next';

const WORKSPACES = [
  {
    key: 'default',
    path: '/',
    default: true,
    icon: ChartBarSVG,
    tooltip: 'Statistics'
  },
  {
    key: 'actions',
    path: '/actions',
    default: false,
    icon: EnvelopeSVG,
    tooltip: 'Actions'
  },
  {
    key: 'todo',
    path: '/todo',
    default: false,
    icon: ListCheckSVG,
    tooltip: 'To do'
  }
];

export default function WorkspaceSelector() {
  const { t } = useTranslation();

  return (
    <div className={classes.workspacesArea}>
      {WORKSPACES
        .map((workspace) => (
          <NavLink
            key={workspace.key}
            activeClassName={classes.workspaceActive}
            isActive={(match, location) => {
              let pathToCheck = location.pathname;
              if (/^\/action\/[a-bA-Z0-9]*/.test(location.pathname)) {
                pathToCheck = '/actions';
              }
              if (workspace.default) {
                const notDefaultWorkspaces = WORKSPACES.filter(workspace => !workspace.default);
                for (const notDefaultWorkspace of notDefaultWorkspaces) {
                  if (pathToCheck.startsWith(notDefaultWorkspace.path)) {
                    return false;
                  }
                }
                return true;
              } else {
                return pathToCheck.startsWith(workspace.path);
              }
            }}
            to={workspace.path}
          >
            <NewTooltip
              content={t(workspace.tooltip)}
              placement="bottom"
            >
              <button className={classes.workspaceButton}>
                <workspace.icon color="#fff" />
              </button>
            </NewTooltip>
          </NavLink>
        ))}
    </div>
  );
}
