import classes from './TermEditCompanyAccounts.module.css';
import CompanyAccountSelector from '../../CompanyAccountSelector/CompanyAccountSelector';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import ActionForMultiSelect from '../../ActionForMultiSelect/ActionForMultiSelect';
import { useState ,useEffect} from 'react';
import { toast } from 'react-toastify';
import { TermApi } from '../../../swagger_client/src';
import termDefinition from '../../../proptypes/term.definition';
import Promise from 'bluebird';

TermEditCompanyAccounts.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedItem: PropTypes.shape(termDefinition),
  refetch: PropTypes.func.isRequired,
  forShortMenuIcon: PropTypes.any
};

export default function TermEditCompanyAccounts({ selectedItems, selectedItem, refetch, forShortMenuIcon }) {
  const { t } = useTranslation(),
    [isOpen, setIsOpen] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [companyAccounts, setCompanyAccounts] = useState(),
    termApi = new TermApi();

  async function handleSubmit() {
    setIsLoading(true);
    if (!companyAccounts) {
      return toast.warning(t('You must select a company account'));
    }

    const terms = await termApi.termFind({
      filter: JSON.stringify({
        where: { id: { inq: selectedItems } },
        include: ['canBeAccessBy']
      })
    });

    await Promise.map(terms, async term => {

      try {
        await Promise.map(term.canBeAccessBy, (companyAccount) => {
          return termApi.termPrototypeUnlinkCanBeAccessBy(term.id, companyAccount.id, {});
        });
      } catch (e) {
        toast.error(t(e.message));
      }

      try {
        await Promise.map(companyAccounts, (companyAccount) => {
          return termApi.termPrototypeLinkCanBeAccessBy(term.id, companyAccount.value.id, {});
        });
      } catch (e) {
        toast.error(t(e.message));
      }
      return term;
    });

    toast.success(t('Company account access updated.'));

    setIsLoading(false);
    refetch();
    setIsOpen(false);
  }

  useEffect(()=>{
    if(selectedItem){
      setCompanyAccounts(selectedItem.canBeAccessBy.map(o => ({
        label: o.name,
        value: o
      })));
    }
  },[selectedItem]);

  return (
    <ActionForMultiSelect
      forShortMenuIcon={forShortMenuIcon}
      actionName="Modify customer access"
      title={selectedItem ? 'Modify customer access for keyword' : 'Modify customer access for keywords'}
      isLoading={isLoading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSubmit={handleSubmit}
      submitbuttonText="Modify customer access"
      subTitle={
        <Trans i18nKey="modifyCustomerAccess" count={selectedItems.length}>
          Select the company you link to the{' '}
          <strong>{{ count: selectedItems.length }}</strong> selected keywords.
        </Trans>
      }
    >
      {isOpen && <div className={classes.formContainer}>
        <CompanyAccountSelector
          onChange={setCompanyAccounts}
          menuPosition="fixed"
          menuPlacement="bottom"
          defaultValue={(selectedItem && selectedItem.canBeAccessBy)? selectedItem.canBeAccessBy.map(o=>({
            label: o.name,
            value: o
          })) : undefined}
        />
      </div> }
    </ActionForMultiSelect>
  );
}
