import classes from './TermFrequencyUpdate.module.css';
import { TermInterface } from '../../../types/term.interface';
import PopinTitle from '../../_ui/Popin/PopinTitle/PopinTitle';
import PopinSubtitle from '../../_ui/Popin/PopinSubtitle/PopinSubtitle';
import CancelButton from '../../CancelButton/CancelButton';
import SubmitButton from '../../SubmitButton/SubmitButton';
import ReactSelect from 'react-select';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import PercentInput from '../../_ui/PercentInput/PercentInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  readonly term: TermInterface
  readonly percent: number
  readonly t: Function
  readonly onClose: Function
  readonly loading: boolean
  readonly handleSubmit: Function
  readonly setFrequency: Function
  readonly setPercent: Function
  readonly frequency: { label: string; value: number }
  readonly frequencyOptions: Array<{ label: string; value: number }>
}

export default function TermFrequencyUpdateView({
  percent,
  setPercent,
  t,
  onClose,
  loading,
  handleSubmit,
  frequency,
  frequencyOptions,
  setFrequency,
}: Props) {
  return (
    <div className={classes.container}>
      <PopinTitle text={t('Modify keywords monitoring frequency')} />
      <PopinSubtitle text={t('chooseFrequency')} />
      <section className={classes.section}>
        <div className={classes.frequencyContainer}>
          <ReactSelect
            options={frequencyOptions}
            onChange={(choice) => setFrequency(choice)}
            value={frequency}
            menuPosition="fixed"
            styles={taglistStyle}
            placeholder={t('Select Frequency...')}
          />
        </div>
        <div
          className={classes.searchConvertRateContainer}
          data-disabled={frequency?.value !== 0}
        >
          <PercentInput percent={percent} setPercent={setPercent} />
          <div className={classes.infoText}>
            <FontAwesomeIcon
              className={classes.icon}
              icon={faExclamationTriangle}
            />
            <p dangerouslySetInnerHTML={{__html:t('impactFrequency', { percent })}}></p>
          </div>
        </div>
        <div className={classes.buttons}>
          <CancelButton txt="cancel" onClose={() => onClose()} />
          <SubmitButton
            onClick={() => handleSubmit()}
            txt="modify frequency"
            isLoading={loading}
            disabled={loading}
          />
        </div>
      </section>
    </div>
  );
}
