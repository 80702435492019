import { useContext, useRef } from 'react';
import { StoreContext } from '../../stores';
import { useState } from 'react';
import { useEffect } from 'react';

import classes from './ProfileCard.module.css';
import avatarDefault from '../../assets/img/default.png';
import { observer } from 'mobx-react';
import ProfileCardModale from './ProfileCardModale/ProfileCardModale';
import _ from 'lodash';
import SwitchUserPanel from '../SwitchUserPanel/SwitchUserPanel';

function ProfileCard() {
  const urlApi = process.env.REACT_APP_MONIBRAND_BACKEND_API_URL,
    ref = useRef(null),
    { authStore } = useContext(StoreContext),
    { currentUser, isAdmin } = authStore,
    [avatar, setAvatar] = useState(),
    [isModaleOpen, setIsModaleOpen] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      setAvatar(`${urlApi}/Files/${currentUser.avatarId}/get`);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return currentUser && currentUser.id ? (
    <>
      <div className={classes.container}>
        <div className={classes.profile}>
          <div className={classes.id}>
            <span className={classes.firstName}>{currentUser.firstName}</span>
            <span className={classes.lastName}>{currentUser.lastName}</span>
            <span className={classes.type}>({isAdmin ? 'admin': _.get(currentUser,'companyAccount.name')})</span>
          </div>
          <SwitchUserPanel/>
        </div>
        <div className={classes.avatar}  onClick={() => setIsModaleOpen(true)}>
          {avatar && (
            <img
              src={avatar}
              alt="profile"
              onError={() => setAvatar(avatarDefault)}
            />
          )}
        </div>
        {isModaleOpen && (
          <ProfileCardModale parentRef={ref} onClose={setIsModaleOpen} />
        )}
      </div>
    </>
  ) : (
    <></>
  );
}

export default observer(ProfileCard);
