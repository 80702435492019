import {  useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyAccountApi } from '../../swagger_client/src';
import FormInputTxt from '../Form/FormInputTxt/FormInputTxt';
import FullscreenModal from '../FullscreenModal/FullscreenModal';
import classes from './CompanyAccount.module.css';
import CancelButton from '../CancelButton/CancelButton';
import SubmitButton from '../SubmitButton/SubmitButton';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

function CompanyAccountForm({ fields, setClose, update }) {
  const { t } = useTranslation(),
    [isLoading, setIsLoading] = useState(),
    { register, handleSubmit, errors } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: fields ? fields : {},
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

  async function handleSubmitForm(formValues) {
    setIsLoading(true);
    const 
      companyAccountApi = new CompanyAccountApi(),
      body = {...formValues};

    try {
      if (fields) {
        update(await companyAccountApi.companyAccountPrototypePatchAttributes(fields.id,{body}));
        toast(t('Company account updated'));
      } else {
        update(await companyAccountApi.companyAccountCreate({ body }));
        toast(t('Company account created'));
      }
  
    } catch (e) {
      toast.error(t(e.response.statusText));
    }
    setClose();
  }

  return (
    <FullscreenModal onClose={setClose}>
      <form className={classes.form} onSubmit={handleSubmit(handleSubmitForm)}>

        <FormInputTxt
          register={register({
            required: { value: true, message: t('The name is required') },
          })}
          errors={errors}
          label={t('name')}
          name="name"
          placeholder={t('name')}
        />
        <div className={classes.containerButtons}>
          <CancelButton onClose={setClose} txt="cancel" />
          <SubmitButton
            testid="CompanyAccountForm-submit"
            isLoading={isLoading}
            txt={fields ? 'update the company account' : 'create the company account'}
          />
        </div>
      </form>
    </FullscreenModal>
  );
}

export default observer(CompanyAccountForm);

CompanyAccountForm.propTypes = {
  fields: PropTypes.object,
  update: PropTypes.func,
  setClose: PropTypes.func,
};
