import Select from 'react-select';
import classes from './SelectFilter.module.css';
import { Trans } from 'react-i18next';

interface AsyncCreateFilterViewHeaderProps {
  count?: number;
  label: string;
}

function AsyncCreateFilterViewTitle({ count, label }: AsyncCreateFilterViewHeaderProps) {
  return (
    <div className={classes.title}>
      <Trans i18nKey={`filterTitle_${label}`} count={count}>
        <>  
          {label} ({{count:count}})
        </>
      </Trans>
    </div>
  );
}

function AsyncCreateFilterViewCount({ count, label }: AsyncCreateFilterViewHeaderProps) {
  return count ? (
    <div className={classes.count}>
      <Trans i18nKey={`filterCount_${label}`} count={count}>
        <>  
          {{count:count}} {label}
        </>
      </Trans>
    </div>
  ) : null;
}

interface props {
  readonly label:string;
  readonly options:any;
  readonly titleMode?:boolean;
}

export default function SelectFilterView({label, options, titleMode}:props) {
  return (
    <div className={classes.container}>
      {titleMode ? (
        <AsyncCreateFilterViewTitle count={options?.options?.length} label={label} />
      ) : (
        <AsyncCreateFilterViewCount count={options?.options?.length} label={label} />
      )}
      <Select {...options} />
    </div>
  );
}
