import { useContext, useEffect } from 'react';
import classes from './ActionTags.module.css';
import useGetTags from '../../components/Hooks/data/getTags/getTags';
import useGetCurrentTags from '../../components/Hooks/data/getActionTag';
import { StoreContext } from '../../stores';
import TagList from '../../components/Tag/TagList/OldTagList';
import { Action } from '../../types/action.newBackend';

interface ActionTagsProps {
  action: Action;
}

export default function ActionTags({ action }: ActionTagsProps) {
  const { authStore } = useContext(StoreContext),
    { currentUser, isAdmin } = authStore,
    { tags } = useGetTags('tag', currentUser, isAdmin), // eslint-disable-line
    { tags: currentTags, fetch: currentTagFetch } = useGetCurrentTags(); // eslint-disable-line

  useEffect(() => {
    currentTagFetch(authStore.token, action.links.getTags);
  }, []); // eslint-disable-line

  const onRefectchTag = () => {
    // Nothing to do here
  };

  const onUpdateTags = (tags: any[]) => {
    // Nothing to do here
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Tags</div>
      <TagList
        className={`${classes.tagList} hoverable-trigger`}
        scope='tag'
        entity={{
          id: action.advertiserID,
          categories: currentTags,
          tags: currentTags,
        }}
        options={tags}
        dynamicOptionsEnabled
        refetchTag={onRefectchTag}
        updateTab={onUpdateTags}
      />
    </div>
  );
}
