import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import classes from './Conversations.module.css';
import MagicTable from '../../components/MagicTable/MagicTable';
import EngineCell from '../../components/MagicTable/EngineCell/EngineCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StatusCell from '../../components/MagicTable/StatusCell/StatusCell';
import LocalizationFlagCell from '../../components/MagicTable/LocalizationFlagCell/LocalizationFlagCell';
import ExchangesCell from './ConversationExchangeCell';
import BrandCell from './ConversationBrandCell';
import ConversationsStore from './conversations.store';
import FullConversationsModal from './FullConversationsModal';
import { Action } from '../../types/action.newBackend';

interface Props {
  action: Action;
  onModalOpened: (opened: boolean) => void;
  searchTerm?: string;
  onChangeSearch: (searchTerm: string) => void;
}

export default function Conversations({
  searchTerm,
  onChangeSearch,
  action,
  onModalOpened,
}: Props) {
  const { authStore } = useContext(StoreContext),
    { t } = useTranslation(),
    { currentUser, isAdmin } = authStore,
    [modalOpened, setModalOpened] = useState(false),
    [conversationsStore] = useState(() => {
      const store = new ConversationsStore(
        currentUser?.id,
        isAdmin,
        action.advertiserID,
        authStore.token
      );

      store.handleFetch();
      return store;
    });

  useEffect(() => {
    conversationsStore.setSearchTerm(searchTerm);
  }, [searchTerm, conversationsStore]);

  const onShowFullConversationsModal = () => {
    setModalOpened(true);
    onModalOpened(true);
  };

  const onCloseFullConversationsModal = () => {
    setModalOpened(false);
    onModalOpened(false);
  };

  const onOpenConversation = (row: any) => {
    const { origin } = window.location;
    const url = `${origin}/action/${row.ID}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {modalOpened && (
        <FullConversationsModal
          action={action}
          searchTerm={searchTerm as string}
          onClose={onCloseFullConversationsModal}
        />
      )}
      <div className={classes.container}>
        <div className={classes.body}>
          <MagicTable
            store={conversationsStore}
            checkedField='ID'
            showInputSearch={false}
            noFixedHeader
            simpleHeader
            clickOnRow
            onOpenRow={onOpenConversation}
            disableFirstFetch
          >
            <EngineCell field='engine' />
            <BrandCell field='domain' />
            <LocalizationFlagCell field='country' />
            <DateCell field='lastStatusDate' displayTime />
            <StatusCell field='status' />
            <ExchangesCell
              field='exchanges'
              messagesField='receivedCount'
              sentMessagesField='sendedCount'
            />
          </MagicTable>
        </div>
        <div className={classes.footer}>
          <button
            className={classes.allConversationsButton}
            onClick={onShowFullConversationsModal}
          >
            {t('See all conversations')}
          </button>
        </div>
      </div>
    </>
  );
}
