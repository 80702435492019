import { useState } from 'react';
import { TermInterface } from '../../../types/term.interface';
import { useTranslation } from 'react-i18next';
import { TermApi } from '../../../swagger_client/src';
import { toast } from 'react-toastify';
import { CompanyAccountInterface } from '../../../types/companyAccount.interface';
import Promise from 'bluebird';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
}

export default function useTermCompanyAccountEdit({ term, onClose }: Props) {
  const [companiesAccount, setCompaniesAccount] = useState<
      Array<{
        label: string
        value: CompanyAccountInterface
      }>
    >(
      term.canBeAccessBy.map((companyAccount: CompanyAccountInterface) => ({
        label: companyAccount.name,
        value: companyAccount,
      }))
    ),
    termApi = new TermApi(),
    [loading, setLoading] = useState<boolean>(false),
    { t } = useTranslation();

  async function handleSubmit() {
    setLoading(true);
    if (!companiesAccount) {
      const msg = t('You must select a company account');
      return toast.warning(msg);
    }

    try {
      await Promise.map(
        term.canBeAccessBy,
        (companyAccount: CompanyAccountInterface) => {
          return termApi.termPrototypeUnlinkCanBeAccessBy(
            term.id,
            companyAccount.id
          );
        }
      );

      await Promise.map(
        companiesAccount.map((companyAccount) => companyAccount.value),
        (companyAccount: CompanyAccountInterface) => {
          return termApi.termPrototypeLinkCanBeAccessBy(
            term.id,
            companyAccount.id,
            {}
          );
        }
      );
    } catch (e: any) {
      const msg = t(e?.message);
      toast.error(msg);
    }

    const msg = t('Company account access updated.');
    toast.success(msg);
    setLoading(false);
    onClose();
  }

  return { t, companiesAccount, setCompaniesAccount, loading, handleSubmit };
}
