export const statusOptions = [
  { value: 'ignored', label: 'Ignored' },
  { value: 'new', label: 'New' },
  { value: 'requested', label: 'Requested' },
  { value: 'inprogress', label: 'Inprogress' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'refused', label: 'Refused' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'reminded', label: 'Reminded' },
  { value: 'disappeared', label: 'Disappeared' },
  { value: 'reappearance', label: 'Reappearance' },
];

export const statusActionsOptions = [
  { value: 'ignored', label: 'Ignored' },
  { value: 'requested', label: 'Requested' },
  { value: 'inprogress', label: 'Inprogress' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'refused', label: 'Refused' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'reminded', label: 'Reminded' },
  { value: 'disappeared', label: 'Disappeared' },
  { value: 'reappearance', label: 'Reappearance' },
];

export const statusActionsSortOptions = (status: string): any => {
  const defaultSortOptions = [
    {
      value: '-lastView',
      label: 'Last Detection (recent to older)',
      revertLabel: 'Last Detection (older to recent)',
      revertValue: 'lastView',
    },
    {
      value: 'lastView',
      label: 'Last Detection (older to recent)',
      revertLabel: 'Last Detection (recent to older)',
      revertValue: '-lastView',
    },
    {
      value: '-createdDate',
      label: 'First detection (recent to older)',
      revertLabel: 'First detection (older to recent)',
      revertValue: 'createdDate',
    },
    {
      value: 'createdDate',
      label: 'First detection (older to recent)',
      revertLabel: 'First detection (recent to older)',
      revertValue: '-createdDate',
    },
    {
      value: '-requestedDate',
      label: 'Requested (recent to older)',
      revertLabel: 'Requested (older to recent)',
      revertValue: 'requestedDate',
    },
    {
      value: 'requestedDate',
      label: 'Requested (older to recent)',
      revertLabel: 'Requested (recent to older)',
      revertValue: '-requestedDate',
    },
    {
      value: '-updatedDate',
      label: 'Updated (recent to older)',
      revertLabel: 'Updated (older to recent)',
      revertValue: 'updatedDate',
    },
    {
      value: 'updatedDate',
      label: 'Updated (older to recent)',
      revertLabel: 'Updated (recent to older)',
      revertValue: '-updatedDate',
    },
  ];

  const optionalSortOption = {
    inprogress: [
      {
        value: '-inprogressDate',
        label: 'Notified (recent to older)',
        revertLabel: 'Notified (older to recent)',
        revertValue: 'inprogressDate',
      },
      {
        value: 'inprogressDate',
        label: 'Notified (older to recent)',
        revertLabel: 'Notified (recent to older)',
        revertValue: '-inprogressDate',
      },
    ],
    accepted: [
      {
        value: '-acceptedDate',
        label: 'Accepted (recent to older)',
        revertLabel: 'Accepted (older to recent)',
        revertValue: 'acceptedDate',
      },
      {
        value: 'acceptedDate',
        label: 'Accepted (older to recent)',
        revertLabel: 'Accepted (recent to older)',
        revertValue: '-acceptedDate',
      },
    ],
    refused: [
      {
        value: '-refusedDate',
        label: 'Refused (recent to older)',
        revertLabel: 'Refused (older to recent)',
        revertValue: 'refusedDate',
      },
      {
        value: 'refusedDate',
        label: 'Refused (older to recent)',
        revertLabel: 'Refused (recent to older)',
        revertValue: '-refusedDate',
      },
    ],
    reappearance: [
      {
        value: '-reappearanceDate',
        label: 'Reappearance (recent to older)',
        revertLabel: 'Reappearance (older to recent)',
        revertValue: 'reappearanceDate',
      },
      {
        value: 'reappearanceDate',
        label: 'Reappearance (older to recent)',
        revertLabel: 'Reappearance (recent to older)',
        revertValue: '-reappearanceDate',
      },
    ],
    disappeared: [
      {
        value: '-disappearedDate',
        label: 'Disappeared (recent to older)',
        revertLabel: 'Disappeared (older to recent)',
        revertValue: 'disappearedDate',
      },
      {
        value: 'disappearedDate',
        label: 'Disappeared (older to recent)',
        revertLabel: 'Disappeared (recent to older)',
        revertValue: '-disappearedDate',
      },
    ],
  };

  if (
    status &&
    status !== '' &&
    (status === 'inprogress' ||
      status === 'accepted' ||
      status === 'refused' ||
      status === 'reappearance' ||
      status === 'disappeared')
  ) {
    return defaultSortOptions.concat(optionalSortOption[status]);
  }

  return defaultSortOptions;
};
