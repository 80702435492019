import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullscreenModal from '../../FullscreenModal/FullscreenModal';
import classes from './TagListMultiTerms.module.css';
import PropTypes from 'prop-types';
import tagDefinition from '../../../proptypes/tag.definition';
import TagListForMulti from '../TagListForMulti/TagListForMulti';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';

TagListMultiTerms.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape(tagDefinition)).isRequired,
  commonTags: PropTypes.arrayOf(PropTypes.shape(tagDefinition)).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  scope: PropTypes.oneOf(['term','tag']).isRequired,
  refetch: PropTypes.func.isRequired,
  refetchTag: PropTypes.func.isRequired,
  forShortMenuIcon: PropTypes.any
};

export default function TagListMultiTerms({
  tags,
  refetch,
  commonTags,
  selectedItems,
  refetchTag,
  scope,
  forShortMenuIcon
}) {
  const [isOpenWithAction, setIsOpenWithAction] = useState(),
    { t } = useTranslation();
  return (
    <div>
      {forShortMenuIcon ? 
        <ShortMenuAction
          icon={forShortMenuIcon}
          text={'Manage tags'}
          onClick={() => setIsOpenWithAction('add')}
        />:
        <div
          className={classes.action}
          onClick={() => setIsOpenWithAction('add')}
        >
          {t('Manage tags')}
        </div> }
      {isOpenWithAction && (
        <FullscreenModal onClose={() => setIsOpenWithAction()}>
          <div className={classes.containerModale}>
            <div className={classes.titleModale}p>{t('tags management')}</div>
            <div>
              <TagListForMulti
                scope={scope}
                options={tags}
                refetchTag={refetchTag}
                defaultTags={commonTags}
                refetch={refetch}
                selectedItems={selectedItems}
                onClose={setIsOpenWithAction}
              />
            </div>
          </div>
        </FullscreenModal>
      )}
    </div>
  );
}