import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

const MenuContext = createContext({
  expanded: true
});

MenuProvider.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool.isRequired
};

export function MenuProvider({
  children, expanded
}) {
  const value = useMemo(
    () => ({
      expanded
    }),
    [
      expanded
    ]
  );

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
}

export function useMenuExpanded() {
  const { expanded } = useContext(MenuContext);
  return expanded;
}
