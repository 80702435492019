/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Category from '../model/Category';
import CompanyAccount from '../model/CompanyAccount';
import ObjectID from '../model/ObjectID';
import Term from '../model/Term';
import TermCompanyAccount from '../model/TermCompanyAccount';
import UserAccount from '../model/UserAccount';
import XAny from '../model/XAny';

/**
* CompanyAccount service.
* @module api/CompanyAccountApi
* @version 4.0.0
*/
export default class CompanyAccountApi {

    /**
    * Constructs a new CompanyAccountApi. 
    * @alias module:api/CompanyAccountApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountCountWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountCount(opts) {
      return this.companyAccountCountWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountCreate(opts) {
      return this.companyAccountCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    companyAccountCreateChangeStreamGetCompanyAccountsChangeStreamWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'options': opts['options']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/CompanyAccounts/change-stream', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    companyAccountCreateChangeStreamGetCompanyAccountsChangeStream(opts) {
      return this.companyAccountCreateChangeStreamGetCompanyAccountsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    companyAccountCreateChangeStreamPostCompanyAccountsChangeStreamWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'options': opts['options']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/CompanyAccounts/change-stream', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a change stream.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    companyAccountCreateChangeStreamPostCompanyAccountsChangeStream(opts) {
      return this.companyAccountCreateChangeStreamPostCompanyAccountsChangeStreamWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountDeleteByIdWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountDeleteById(id) {
      return this.companyAccountDeleteByIdWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountExistsGetCompanyAccountsidExistsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/exists', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountExistsGetCompanyAccountsidExists(id) {
      return this.companyAccountExistsGetCompanyAccountsidExistsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountExistsHeadCompanyAccountsidWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check whether a model instance exists in the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountExistsHeadCompanyAccountsid(id) {
      return this.companyAccountExistsHeadCompanyAccountsidWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CompanyAccount>} and HTTP response
     */
    companyAccountFindWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [CompanyAccount];

      return this.apiClient.callApi(
        '/CompanyAccounts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CompanyAccount>}
     */
    companyAccountFind(opts) {
      return this.companyAccountFindWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountFindByIdWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountFindById(id, opts) {
      return this.companyAccountFindByIdWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountFindOneWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/findOne', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountFindOne(opts) {
      return this.companyAccountFindOneWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountPatchOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountPatchOrCreate(opts) {
      return this.companyAccountPatchOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts canBeAccessTo of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountPrototypeCountCanBeAccessToWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts canBeAccessTo of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountPrototypeCountCanBeAccessTo(id, opts) {
      return this.companyAccountPrototypeCountCanBeAccessToWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Counts userAccounts of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountPrototypeCountUserAccountsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts/count', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Counts userAccounts of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountPrototypeCountUserAccounts(id, opts) {
      return this.companyAccountPrototypeCountUserAccountsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in canBeAccessTo of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    companyAccountPrototypeCreateCanBeAccessToWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'localizationId': opts['localizationId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in canBeAccessTo of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    companyAccountPrototypeCreateCanBeAccessTo(id2, opts) {
      return this.companyAccountPrototypeCreateCanBeAccessToWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Creates a new instance in userAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserAccount} and HTTP response
     */
    companyAccountPrototypeCreateUserAccountsWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'emailAtCreation': opts['emailAtCreation'],
        'queries': this.apiClient.buildCollectionParam(opts['queries'], 'multi'),
        'firstName': opts['firstName'],
        'lastName': opts['lastName'],
        'realm': opts['realm'],
        'username': opts['username'],
        'email': opts['email'],
        'emailVerified': opts['emailVerified'],
        'id': opts['id'],
        'dashboardCategoryFilters': this.apiClient.buildCollectionParam(opts['dashboardCategoryFilters'], 'multi'),
        'avatarId': opts['avatarId'],
        'companyAccountId': opts['companyAccountId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = UserAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Creates a new instance in userAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserAccount}
     */
    companyAccountPrototypeCreateUserAccounts(id2, opts) {
      return this.companyAccountPrototypeCreateUserAccountsWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all canBeAccessTo of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    companyAccountPrototypeDeleteCanBeAccessToWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all canBeAccessTo of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    companyAccountPrototypeDeleteCanBeAccessTo(id) {
      return this.companyAccountPrototypeDeleteCanBeAccessToWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deletes all userAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    companyAccountPrototypeDeleteUserAccountsWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Deletes all userAccounts of this model.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    companyAccountPrototypeDeleteUserAccounts(id) {
      return this.companyAccountPrototypeDeleteUserAccountsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    companyAccountPrototypeDestroyByIdCanBeAccessToWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    companyAccountPrototypeDestroyByIdCanBeAccessTo(id, fk) {
      return this.companyAccountPrototypeDestroyByIdCanBeAccessToWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    companyAccountPrototypeDestroyByIdUserAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    companyAccountPrototypeDestroyByIdUserAccounts(id, fk) {
      return this.companyAccountPrototypeDestroyByIdUserAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Check the existence of canBeAccessTo relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    companyAccountPrototypeExistsCanBeAccessToWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/rel/{fk}', 'HEAD',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Check the existence of canBeAccessTo relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    companyAccountPrototypeExistsCanBeAccessTo(id, fk) {
      return this.companyAccountPrototypeExistsCanBeAccessToWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    companyAccountPrototypeFindByIdCanBeAccessToWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    companyAccountPrototypeFindByIdCanBeAccessTo(id, fk) {
      return this.companyAccountPrototypeFindByIdCanBeAccessToWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserAccount} and HTTP response
     */
    companyAccountPrototypeFindByIdUserAccountsWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = UserAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts/{fk}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Find a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserAccount}
     */
    companyAccountPrototypeFindByIdUserAccounts(id, fk) {
      return this.companyAccountPrototypeFindByIdUserAccountsWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries canBeAccessTo of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Term>} and HTTP response
     */
    companyAccountPrototypeGetCanBeAccessToWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [Term];

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries canBeAccessTo of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Term>}
     */
    companyAccountPrototypeGetCanBeAccessTo(id, opts) {
      return this.companyAccountPrototypeGetCanBeAccessToWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Queries userAccounts of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/UserAccount>} and HTTP response
     */
    companyAccountPrototypeGetUserAccountsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'filter': opts['filter']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = [UserAccount];

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Queries userAccounts of CompanyAccount.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/UserAccount>}
     */
    companyAccountPrototypeGetUserAccounts(id, opts) {
      return this.companyAccountPrototypeGetUserAccountsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TermCompanyAccount} and HTTP response
     */
    companyAccountPrototypeLinkCanBeAccessToWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'id': opts['id'],
        'termId': opts['termId'],
        'companyAccountId': opts['companyAccountId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = TermCompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/rel/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TermCompanyAccount}
     */
    companyAccountPrototypeLinkCanBeAccessTo(id2, fk, opts) {
      return this.companyAccountPrototypeLinkCanBeAccessToWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountPrototypePatchAttributesWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountPrototypePatchAttributes(id2, opts) {
      return this.companyAccountPrototypePatchAttributesWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the canBeAccessTo relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    companyAccountPrototypeUnlinkCanBeAccessToWithHttpInfo(id, fk) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/rel/{fk}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the canBeAccessTo relation to an item by id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    companyAccountPrototypeUnlinkCanBeAccessTo(id, fk) {
      return this.companyAccountPrototypeUnlinkCanBeAccessToWithHttpInfo(id, fk)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Term} and HTTP response
     */
    companyAccountPrototypeUpdateByIdCanBeAccessToWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'term': opts['term'],
        'keywordType': opts['keywordType'],
        'devices': this.apiClient.buildCollectionParam(opts['devices'], 'multi'),
        'every': opts['every'],
        'engine': opts['engine'],
        'id': opts['id'],
        'brandId': opts['brandId'],
        'localizationId': opts['localizationId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Term;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/canBeAccessTo/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for canBeAccessTo.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Term}
     */
    companyAccountPrototypeUpdateByIdCanBeAccessTo(id2, fk, opts) {
      return this.companyAccountPrototypeUpdateByIdCanBeAccessToWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserAccount} and HTTP response
     */
    companyAccountPrototypeUpdateByIdUserAccountsWithHttpInfo(id2, fk, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2,
        'fk': fk
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'emailAtCreation': opts['emailAtCreation'],
        'queries': this.apiClient.buildCollectionParam(opts['queries'], 'multi'),
        'firstName': opts['firstName'],
        'lastName': opts['lastName'],
        'realm': opts['realm'],
        'username': opts['username'],
        'email': opts['email'],
        'emailVerified': opts['emailVerified'],
        'id': opts['id'],
        'dashboardCategoryFilters': this.apiClient.buildCollectionParam(opts['dashboardCategoryFilters'], 'multi'),
        'avatarId': opts['avatarId'],
        'companyAccountId': opts['companyAccountId']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = UserAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/userAccounts/{fk}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a related item by id for userAccounts.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserAccount}
     */
    companyAccountPrototypeUpdateByIdUserAccounts(id2, fk, opts) {
      return this.companyAccountPrototypeUpdateByIdUserAccountsWithHttpInfo(id2, fk, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountReplaceByIdPostCompanyAccountsidReplaceWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountReplaceByIdPostCompanyAccountsidReplace(id2, opts) {
      return this.companyAccountReplaceByIdPostCompanyAccountsidReplaceWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountReplaceByIdPutCompanyAccountsidWithHttpInfo(id2, opts) {
      let postBody = opts['body'];

      let pathParams = {
        'id': id2
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountReplaceByIdPutCompanyAccountsid(id2, opts) {
      return this.companyAccountReplaceByIdPutCompanyAccountsidWithHttpInfo(id2, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountReplaceOrCreatePostCompanyAccountsReplaceOrCreateWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/replaceOrCreate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountReplaceOrCreatePostCompanyAccountsReplaceOrCreate(opts) {
      return this.companyAccountReplaceOrCreatePostCompanyAccountsReplaceOrCreateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountReplaceOrCreatePutCompanyAccountsWithHttpInfo(opts) {
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountReplaceOrCreatePutCompanyAccounts(opts) {
      return this.companyAccountReplaceOrCreatePutCompanyAccountsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    companyAccountUpdateAllWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = Object;

      return this.apiClient.callApi(
        '/CompanyAccounts/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    companyAccountUpdateAll(opts) {
      return this.companyAccountUpdateAllWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompanyAccount} and HTTP response
     */
    companyAccountUpsertWithWhereWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'where': opts['where']
      };
      let headerParams = {
      };
      let formParams = {
        'name': opts['name'],
        'notificationAuto': opts['notificationAuto'],
        'reminder': opts['reminder'],
        'id': opts['id']
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'application/xml', 'text/xml'];
      let accepts = ['application/json', 'application/xml', 'text/xml', 'application/javascript', 'text/javascript'];
      let returnType = CompanyAccount;

      return this.apiClient.callApi(
        '/CompanyAccounts/upsertWithWhere', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @param {Object} opts Optional parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompanyAccount}
     */
    companyAccountUpsertWithWhere(opts) {
      return this.companyAccountUpsertWithWhereWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
