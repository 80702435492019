import useCreateSelectFilter from './useCreateSelectFilter';
import CreateSelectFilterView from './CreateSelectFilterView';
import { Filter } from '../../../types/filter.interface';
import { observer } from 'mobx-react-lite';

interface Props {
  readonly filters:Filter
  readonly setFilter:Function
  readonly name: string
  readonly options:any
  readonly label:string
  readonly reactSelectOptions:any
  readonly useRegexpMode:boolean
  readonly valueMapper?: (value: any) => any
}

function CreateSelectFilter(props:Props) {
  const useProps = useCreateSelectFilter(props),
    passProps = {...useProps};

  return <CreateSelectFilterView {...passProps} />;
}
export default observer(CreateSelectFilter);