function transformTagToOption (tag) {
  const options = [];

  const parentOption = {
    originalTag: tag,
    label: (tag.owner && tag.owner.label) ? `${tag.label} (${tag.owner?.label})` : tag.label,
    value: tag.id
  };

  if (tag?.children?.length > 0) {
    const groupedOptions = {
      originalTag: tag,
      value: `grouped-${tag.id}`,
      label: (tag.owner && tag.owner.label) ? `${tag.label} (${tag.owner?.label})` : tag.label,
    };

    groupedOptions.options = [];
    tag.children.forEach(tag => {
      groupedOptions.options.push(...transformTagToOption(tag));
    });

    options.push(groupedOptions);
  }

  options.push(parentOption);

  return options;
}

/**
 *
 * @param {Array.{id: string, children:[],label: string, parentId?: string}} tags
 * @description add options key (inject children) and add value key (with id)
 * @returns {Array.{originalTag: Object,label: string,value: string, options:Array.Object}}
 */
export default function tagToOptions(tags) {

  const children = {};
  const parents = [];

  tags.forEach(tag => {
    if (!tag.parentId) {
      parents.push(tag);
    }

    if (!children[tag.parentId]) {
      children[tag.parentId] = [];
    }
    children[tag.parentId].push(tag);
  });

  const options = [];
  tags.forEach(tag => {
    options.push(...transformTagToOption(tag));
  });


  return options;
}

