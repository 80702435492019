import classes from './FiltersToggleButton.module.css';
import { useTranslation } from 'react-i18next';

interface FiltersToggleButtonProps {
  pageStore: any;
  onToggleFilters: () => void;
}

export default function FiltersToggleButton(props: FiltersToggleButtonProps) {
  const filters = props.pageStore?.filtersAsArray;
  const { t } = useTranslation();

  return (
    <div className={classes.container} onClick={props.onToggleFilters}>
      {t('Filters')}
      <div className={classes.filtersHint}>{filters?.length}</div>
    </div>
  );
}
  