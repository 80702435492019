import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface props {
  readonly name: string;
  readonly min: number;
  readonly max: number;
  readonly label: string;
  readonly defaultValue: any;
  readonly setFilter: Function;
  readonly filters: any;
}

export default function useRangeFilter({
  name,
  min,
  max,
  label,
  defaultValue,
  setFilter,
  filters,
}: props) {
  const { t } = useTranslation(),
    [rangeState, setRangeState] = useState<Array<number>>();
  function handleChange(values: any) {
    setRangeState(values);
    setFilter(name, {
      label,
      whereClause: { between: values },
      description: `${t('between')} ${values[0]} ${t('and')} ${values[1]}`,
      valueNew: values,
    });
  }

  function resetFilters() {
    setRangeState(defaultValue);
    setFilter(name, undefined);
  }

  function resetRange() {
    setFilter(name, {
      label,
      whereClause: { between: defaultValue },
      description: `${t('between')} ${defaultValue[0]} ${t('and')} ${
        defaultValue[1]
      }`,
      valueNew: defaultValue,
    });
  }

  useEffect(() => {
    const whereClause = filters?.[name]?.whereClause || {
      between: defaultValue,
    };
    setRangeState(whereClause.between);
    setFilter(name, {
      label,
      whereClause,
      description: `${t('between')} ${min} ${t('and')} ${max}`,
      valueNew: defaultValue,
    });
    // eslint-disable-next-line
  }, []);

  return { handleChange, rangeState, setRangeState, resetRange, resetFilters };
}
