import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputSelect from '../../Form/FormInputSelect/FormInputSelect';
import classes from './BrandAndTypeModale.module.css';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SubmitButton from '../../SubmitButton/SubmitButton';
import { TermApi } from '../../../swagger_client/src';
import { toast } from 'react-toastify';
import termDefinition from '../../../proptypes/term.definition';
import BrandInputSelect from '../../Form/BrandInputSelect/BrandInputSelect';
import Button from '../../Button/Button';


BrandAndTypeModale.propTypes = {
  term: PropTypes.shape(termDefinition),
  updateTerm: PropTypes.func,
  onClose: PropTypes.func
};

export default function BrandAndTypeModale({ term, updateTerm, onClose }) {
  const [isLoading, setIsLoading] = useState(false),
    { t } = useTranslation(),
    termApi = new TermApi(),
    keywordtypes = [
      { value: 'GENERIC', label: t('generic') },
      { value: 'BRAND', label: t('brand') },
      { value: 'GENERIC_BRAND', label: t('generic brand') },
      { value: 'MULTIBRAND', label: t('multi brand') },
    ],
    { handleSubmit, control } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: {
        brand: term.brandId
          ? {
            label: term.brand.label || term.brand.name,
            value: term.brand,
          }
          : undefined,
        keywordType: _.find(keywordtypes, { value: term.keywordType }),
      },
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

  async function handleSubmitForm(formValues) {
    if(!formValues || !formValues.brand){
      onClose();
      return;
    }
    
    setIsLoading(true);

    const body = {
      keywordType: formValues.keywordType.value,
    };

    if (formValues.brand.value === 'delete') {
      body.brandId = null;
    } else {
      body.brandId = formValues.brand.value.id;
    }

    try {
      const newTerm = await termApi.termPrototypePatchAttributes(term.id, {
        body,
      });
      if (formValues.brand.value) {
        newTerm.brand = formValues.brand.value;
      }
      updateTerm({
        // Don't lose the localization and categories since they aren't
        // sent back by the patch request
        ...term,
        ...newTerm
      });
      toast.success(t('Term updated'));
      onClose();
    } catch (e) {
      toast.error(e.message);
      setIsLoading(false);
    }

  }

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className={classes.container}
    >
      <h2 className={classes.title}>
        {t('update the brand for the keyword')}:{' '}
        <span className="bold">{term.term}</span>
      </h2>
      <BrandInputSelect 
        control={control}
        label="brand"
        name="brand"
        menuPosition="fixed"
        placeholder={t('select brand')}
      />
      <FormInputSelect
        control={control}
        label="keyword type"
        name="keywordType"
        menuPosition="fixed"
        placeholder={t('select keyword type')}
        values={keywordtypes}
      />

      <div className={classes.buttons}>
        <Button type="reset" onClick={onClose} text="cancel" /> 
        <SubmitButton txt="submit" isLoading={isLoading} disabled={isLoading} />
      </div>
    </form>
  );
}
