import { useContext, useEffect } from 'react';

/**
 * @description Components
 */
import { withRouter } from 'react-router-dom';

/**
 * @description Styles and assets
 */
import classes from './Home.module.css';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function Home() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    url = _.get(currentUser,'dashboards.app-action-sem-summary'),
    { t } = useTranslation(),
    login = _.get(currentUser,'dashboards.clicdata.login'),
    password = _.get(currentUser,'dashboards.clicdata.password'),
    clicDataUrl =
      url + '?Authorization=Basic%20' + btoa(login + ':' + password);

  useEffect(() => {
    uiStore.setTitle(t('Dashboard access'));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <iframe title="Clicdata" className={classes.iframe} src={clicDataUrl} />
    </div>
  );
}

export default withRouter(Home);
