import PropTypes from 'prop-types';

export default function DashboardSVG({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
      <path
        d="M-20.467,16a1,1,0,0,1-1-1V9.534a1,1,0,0,1,1-1H-15a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1Zm0-1H-15V9.534h-5.467ZM-29,16a1,1,0,0,1-1-1V9.534a1,1,0,0,1,1-1h5.467a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1Zm0-1h5.467V9.534H-29Zm8.533-7.534a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1H-15a1,1,0,0,1,1,1V6.467a1,1,0,0,1-1,1Zm0-1H-15V1h-5.467Zm-8.533,1a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1h5.467a1,1,0,0,1,1,1V6.467a1,1,0,0,1-1,1Zm0-1h5.467V1H-29Z"
        transform="translate(30)"
        fill={color}
      />
    </svg>
  );
}

DashboardSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
