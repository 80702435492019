/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { Action } from '..';
import ApiClient from '../ApiClient';
import Category from './Category';
import ObjectID from './ObjectID';

/**
* The Dealer model module.
* @module model/Dealer
* @version 4.0.0
*/
export default class Dealer {
    /**
    * Constructs a new <code>Dealer</code>.
    * Website metadatas
    * @alias module:model/Dealer
    * @class
    * @param domain {String} 
    */

    constructor(domain) {
        
        
        this['domain'] = domain;
        
    }

    /**
    * Constructs a <code>Dealer</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Dealer} obj Optional instance to populate.
    * @return {module:model/Dealer} The populated <code>Dealer</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Dealer();
                        
            
            if (data.hasOwnProperty('domain')) {
                obj['domain'] = ApiClient.convertToType(data['domain'], 'String');
            }
            if (data.hasOwnProperty('tests')) {
                obj['tests'] = ApiClient.convertToType(data['tests'], Object);
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
            if (data.hasOwnProperty('updated')) {
                obj['updated'] = ApiClient.convertToType(data['updated'], 'Date');
            }
            if (data.hasOwnProperty('datas')) {
                obj['datas'] = ApiClient.convertToType(data['datas'], Object);
            }
            if (data.hasOwnProperty('processed')) {
                obj['processed'] = ApiClient.convertToType(data['processed'], ['String']);
            }
            if (data.hasOwnProperty('note')) {
                obj['note'] = ApiClient.convertToType(data['note'], 'String');
            }
            if (data.hasOwnProperty('metas')) {
                obj['metas'] = ApiClient.convertToType(data['metas'], Object);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('categories')) {
                obj['categories'] = ApiClient.convertToType(data['categories'], [Category]);
            }
            if (data.hasOwnProperty('actions')) {
                obj['actions'] = ApiClient.convertToType(data['actions'], [Action]);
            }
        }
        return obj;
    }

    /**
    * @member {String} domain
    */
    domain = undefined;
    /**
    * @member {Object} tests
    */
    tests = undefined;
    /**
    * @member {Date} date
    */
    date = undefined;
    /**
    * @member {Date} updated
    */
    updated = undefined;
    /**
    * @member {Object} datas
    */
    datas = undefined;
    /**
    * @member {Array.<String>} processed
    */
    processed = undefined;
    /**
    * @member {String} note
    */
    note = undefined;
    /**
    * @member {Object} metas
    */
    metas = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {Array.<module:model/Category>} tags
    */
    tags = undefined;




}
