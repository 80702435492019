import _ from 'lodash';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import TermsStore from '../../../pages/Terms/terms.store';
import TagList from '../../Tag/TagList/TagList';

export default function TermsTagsCell({
  item,
  store,
  tags,
  tagFetch,
  isLoading,
}: {
  item: any;
  store: TermsStore;
  tagFetch: () => void;
  isLoading: boolean;
  tags: any[];
}) {
  //wait "categorie" because item is proxy, and never undefined.
  if (isLoading || !_.get(item, 'categories', undefined)) {
    return <LoaderSmall />;
  }

  return (
    <TagList
      scope='term'
      entity={item}
      options={tags}
      refetchTag={tagFetch}
      updateTab={(tags) => store.updateItem(item, tags)}
    />
  );
}
