import dayjs from 'dayjs';

export default function translateEvery(time, t) {
  const hours = parseInt(dayjs.duration(time).format('H')),
    days = parseInt(dayjs.duration(time).format('D')),
    months = parseInt(dayjs.duration(time).format('M'));

  if (hours === 1) {
    return t('every hour');
  } else if (hours > 1 && days === 0) {
    return t('everyes') + ' ' + dayjs.duration(time).humanize();
  } else if (days === 1) {
    return t('every day');
  } else if (days > 1 && months === 0) {
    return t('everys') + ' ' + dayjs.duration(time).humanize();
  } else if (months === 1) {
    return t('every month');
  } else if (months > 1) {
    return t('everys') + ' ' + dayjs.duration(time).humanize();
  } else {
    return t('everys') + ' ' + dayjs.duration(time).humanize();
  }
}
