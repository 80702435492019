import PropTypes from 'prop-types';
import { getScale, getDimensions } from './IconSvgUtils';

export default function SmallEnvelopeSVG({ color, height }) {
  const scale = getScale(12, height);
  const dimensions = getDimensions(16, 12, null, height);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${height ? dimensions.height + 5 : dimensions.height}`}
    >
      <path
        d="M15.7,8.463a.188.188,0,0,1,.3.147V15a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,15V8.613a.187.187,0,0,1,.3-.147c.7.544,1.628,1.234,4.816,3.55C5.778,12.5,6.891,13.509,8,13.5c1.116.009,2.25-1.025,2.884-1.488C14.072,9.7,15,9.006,15.7,8.463ZM8,12.5c.725.013,1.769-.913,2.294-1.294,4.147-3.009,4.462-3.272,5.419-4.022A.748.748,0,0,0,16,6.594V6a1.5,1.5,0,0,0-1.5-1.5H1.5A1.5,1.5,0,0,0,0,6v.594a.752.752,0,0,0,.288.591c.956.747,1.272,1.012,5.419,4.022C6.231,11.587,7.275,12.512,8,12.5Z"
        transform={`translate(0 -4.5) ${scale ? `scale(${scale})` : ''}`}
        fill={color}
      />
    </svg>
  );
}

SmallEnvelopeSVG.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.number
};
