import { observer } from 'mobx-react';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';
import useUsersFilter from './useUsersFilter';

function UsersFilter(props:FilterChild) {
  const useProps = useUsersFilter(props.name),
    passProps = {...props,...useProps};

  return <AsyncCreateFilter {...passProps}/>;
}

export default observer(UsersFilter);
