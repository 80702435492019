import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import classes from './SwitchUserPanel.module.css';

function SwitchUserPanel() {
  const { t } = useTranslation(),
    { authStore } = useContext(StoreContext);

  return authStore.adminToken ? (
    <div
      className={classes.container}
      title={t(
        'When browsing as another person, the "users" page is disabled.'
      )}
    >
      <div
        className={classes.button}
        data-testid="switch_user_panel_button"
        onClick={() => authStore.recoverMyProfile()}
      >
        {t('Back to my profile')}
      </div>
    </div>
  ) : null;
}

export default observer(SwitchUserPanel);
