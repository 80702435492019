import { observer } from 'mobx-react-lite';
import CompanyAccountStore from './CompanyAccount.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import CompanyAccountCreateButton from '../../components/CompanyAccount/CompanyAccountCreateButton/CompanyAccountCreateButton';
import FullscreenModal from '../../components/FullscreenModal/FullscreenModal';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { useTranslation } from 'react-i18next';
import CompanyAccountForm from '../../components/CompanyAccount/CompanyAccountForm';
import MagicTable from '../../components/MagicTable/MagicTable';
import CompanyAccountActionsCell from '../../components/CompanyAccount/CompanyAccountActionsCell/CompanyAccountActionsCell';
import Button from '../../components/Button/Button';

function CompanyAccount() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [companyAccountStore] = useState(
      () => new CompanyAccountStore({},currentUser.id)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Companies accounts'));

    return () => {
      companyAccountStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tableContainer">
      {companyAccountStore.selectedItem && (
        <FullscreenModal
          onClose={() => companyAccountStore.setSelectedItem(null)}
        >
          <div className="tableTxtModale">
            {`${t('are you sure to')} ${t(companyAccountStore.selectedItem.type)} ${t('the company account')} ${' '}
            ${companyAccountStore.selectedItem?.term?.name} ?`}
          </div>
          
          <div className="tableButtonsModale">
            <Button onClick={() => companyAccountStore.setSelectedItem(null)} type="reset" text="cancel"/>
            <SubmitButton
              txt={t('submit')}
              isLoading={companyAccountStore.isLoadingAction}
              onClick={() => companyAccountStore.selectedItem.action()}
            />
          </div>
        </FullscreenModal>
      )}
      {companyAccountStore?.companyAccountToUpdate && (
        <CompanyAccountForm
          fields={companyAccountStore.companyAccountToUpdate}
          setClose={() => companyAccountStore.updateOneCompanyAccount()}
          update={(tag) => companyAccountStore.addCompanyAccount(tag)}
        />
      )}
      <div className="tableContainerFilter">
        <div className="tableContainerButtons">
          <CompanyAccountCreateButton
            update={(companyAccount) =>
              companyAccountStore.addCompanyAccount(companyAccount)
            }
            isLoading={companyAccountStore.isLoading}
          />
        </div>

        <NavFilter
          isLoading={companyAccountStore.isLoadingCount}
          pageStore={companyAccountStore}
          counters={companyAccountStore.counters}
        >
          <CompanyAccountsFilter
            label="company account"
            name="id"
          />
        </NavFilter>

      </div>
      <div className="tableBox">
        <div className="table">
          <MagicTable store={companyAccountStore}>
            <CompanyAccountActionsCell field="actions" />
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={companyAccountStore.count}
        perPage={companyAccountStore.perPage}
        page={companyAccountStore.page}
        handleSetPage={(pageNumber) => companyAccountStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(CompanyAccount);
