import { useContext } from 'react';
import { toast } from 'react-toastify';

import { pictos } from '../../../types/pictos.enum';
import { observer } from 'mobx-react';
import ShortMenuModale from '../../_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import ShortMenuList from '../../_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuAction from '../../_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';
import { Action } from '../../../types/action.newBackend';

interface Props {
  readonly advertiser: Action;
  readonly replaceAdvertiser: Function;
}

function AdvertiserAction({ replaceAdvertiser, advertiser }: Props) {
  const { authStore } = useContext(StoreContext);

  const handleDoAction = async (link: string) => {
    try {
      const action = await Backend.executeLink(authStore.token, link);
      replaceAdvertiser(action);
    } catch (e) {
      toast.error('Une erreur est suvenue.');
    }
  };

  return (
    <ShortMenuModale>
      <ShortMenuList>
        {advertiser.links?.markAsIgnored ? (
          <ShortMenuAction
            icon={pictos.eyeSlash}
            text='ignore'
            testid='ignore advertiser'
            onClick={() =>
              handleDoAction(advertiser.links.markAsIgnored as string)
            }
          />
        ) : (
          <></>
        )}
        {advertiser.links?.cancelIgnore ? (
          <ShortMenuAction
            icon={pictos.eyeSlash}
            text="don't ignore"
            onClick={() =>
              handleDoAction(advertiser.links.cancelIgnore as string)
            }
          />
        ) : (
          <></>
        )}
        {advertiser.links?.markAsRequested ? (
          <ShortMenuAction
            icon={pictos.bell}
            text='request'
            onClick={() =>
              handleDoAction(advertiser.links.markAsRequested as string)
            }
          />
        ) : (
          <></>
        )}
        {advertiser.links?.cancelRequested ? (
          <ShortMenuAction
            icon={pictos.bellSlash}
            text='cancel request'
            onClick={() =>
              handleDoAction(advertiser.links.cancelRequested as string)
            }
          />
        ) : (
          <></>
        )}
      </ShortMenuList>
    </ShortMenuModale>
  );
}
export default observer(AdvertiserAction);
