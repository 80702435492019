import { observer } from 'mobx-react-lite';
import classes from './FiltersDescriptions.module.css';
import PropTypes from 'prop-types';
import { ReactComponent as Cross } from '../../../assets/img/Close-popin.svg';
import { useTranslation } from 'react-i18next';
import NewTooltip from '../../_ui/Tooltip/NewTooltip';



FiltersDescriptions.propTypes = {
  filters: PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  revertFilter: PropTypes.func.isRequired,
  setCurrentHoverFilter: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};


function FiltersDescriptions({filters,deleteFilter,revertFilter,setCurrentHoverFilter,clearAll}){
  const {t} = useTranslation();

  return <div className={classes.container} onMouseLeave={()=>setCurrentHoverFilter()}>
    {filters.map((filter) => {
      return (
        <div
          key={filter.name}
          onMouseOver={()=>setCurrentHoverFilter(filter.name)}
        >
          <NewTooltip
            content={t(filter.name)}
            placement='bottom'
          >
            <div className={classes.filter}>
              <span className={filter.name === 'sortBy' ? classes.pointer : classes.normal} onClick={()=> { revertFilter(filter); }}>{filter.description}</span>
              <div className={classes.cross} onClick={()=> { deleteFilter(filter.name); }}>
                <Cross width={8} height={8}/>
              </div>
            </div>
          </NewTooltip>
        </div>
      );
    })}
    <div className={classes.clearAll} onClick={()=>clearAll()}>
      {t('Reset')}
    </div>
  </div>;
}

export default observer(FiltersDescriptions);
