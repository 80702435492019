import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import Menu from '../../components/Menu/Menu';
import PropTypes from 'prop-types';
import classes from './Dashboard.module.css';
import Header from '../../components/Header/Header';
import HeaderMobile from '../../components/HeaderMobile/HeaderMobile';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { ToastContainer } from 'react-toastify';
import MenuMobile from '../../components/MenuMobile/MenuMobile';
import { StoreContext } from '../../stores';

function DashboardLayout({ children }) {
  const { uiStore } = useContext(StoreContext),
    [menuExpanded, setMenuExpanded ] = useState(true),
    [menuHovered, setMenuHovered ] = useState(false),
    [menuMinimized, setMenuMinimized ] = useState(false);

  const onMenuToggled = (expanded) => {
    setMenuExpanded(expanded);
  };

  const onMenuHovered = (expanded) => {
    setMenuHovered(expanded);
  };

  const onMenuMinized = (minimized) => {
    setMenuMinimized(minimized);
  };

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        {uiStore.device.match(/desktop|laptop/) ? <Header menuExpanded={menuExpanded} /> : <HeaderMobile />}
      </header>
      <nav className={classes.nav}>
        {uiStore.device.match(/desktop|laptop/) ? (
          <Menu onMenuToggled={onMenuToggled} onMenuHovered={onMenuHovered} onMenuMinimized={onMenuMinized} />
        ) : (
          <MenuMobile />
        )}
      </nav>
      <main className={`${classes.main} ${menuHovered ? classes.menuHovered : ''} ${menuMinimized ? classes.menuMinimized : ''}`}>
        <Breadcrumb />
        {children}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </main>
    </div>
  );
}

export default observer(DashboardLayout);

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};
