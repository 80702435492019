import classes from './InputFilter.module.css';
import { Trans } from 'react-i18next';

interface AsyncCreateFilterViewHeaderProps {
  label: string;
}

function AsyncCreateFilterViewTitle({
  label,
}: AsyncCreateFilterViewHeaderProps) {
  return (
    <div className={classes.title}>
      <Trans i18nKey={`filterTitle_${label}`}>
        <>{label}</>
      </Trans>
    </div>
  );
}

interface props {
  readonly label: string;
  readonly options: any;
}

export default function SelectFilterView({ label, options }: props) {
  return (
    <div className={classes.container}>
      <AsyncCreateFilterViewTitle label={label} />
      <input {...options} type='text' className={classes.input} />
    </div>
  );
}
