import { diacritics } from './diacritics';

interface CreateInputValueRegexContentOptions {
  noSpaceConversion?: boolean;
}

export function createInputValueRegexContent(inputValue: string, options?: CreateInputValueRegexContentOptions) {
  let normalizedInputValue = inputValue
    .toLocaleLowerCase()
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  if (!options?.noSpaceConversion) {
    normalizedInputValue = normalizedInputValue.replace(/\ /g, '\-');
  }
    
  for (let baseCharacter of diacritics) {
    normalizedInputValue = normalizedInputValue.replace(
      new RegExp(baseCharacter.base, 'g'),
      `[${baseCharacter.base}${baseCharacter.letters}]`
    );
  }

  return normalizedInputValue;
}
