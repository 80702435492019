import _ from 'lodash';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import classes from './Pagination.module.css';
import { useTranslation } from 'react-i18next';

Pagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleSetPage: PropTypes.func.isRequired,
  handleSetNoLimit: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.number)

};

Pagination.defaultProps = {
  page: 1,
  perPage: 30,
  total: 0,
  choices: [30, 100, 200, 500]
};

function Pagination({
  perPage,
  total,
  page,
  handleSetPage,
  handleSetNoLimit,
  choices,
}) {
  const { t } = useTranslation(),
    [pages, setPages] = useState({ previous: [], next: [], totalPage: 0 });

  useEffect(
    () => {
      const newPages = { ...pages },
        allPages = [];

      if (perPage > 0) {
        newPages.totalPage = Math.ceil(total / perPage) || 0;
      }

      for (let i = 1; i < newPages.totalPage + 1; i++) {
        allPages.push(i);
      }
      newPages.previous = _.filter(
        allPages,
        (o) => _.lt(o, page) && _.gte(o, page - 5)
      );
      newPages.next = _.filter(
        allPages,
        (o) => _.gt(o, page) && _.lte(o, page + 5)
      );
      setPages(newPages);
    },
    // eslint-disable-next-line
    [page, total, perPage]
  );

  async function handleClick(type, value) {
    await handleSetPage({ type, value });
  }

  return (
    <div className={classes.container}>
      <div className={classes.perPageContainer}>
        <div className={classes.txtPerPage}>{t('Elements per page')} :</div>
        {choices.map((perPageCase) => (
          <div
            key={perPageCase}
            onClick={() => handleClick('perPage', perPageCase)}
            className={classes.page}
            data-current={perPage === perPageCase}
          >
            {perPageCase}
          </div>
        ))}
        {handleSetNoLimit && (
          <div
            onClick={() => {
              handleSetNoLimit();
            }}
            className={classes.page}
            data-current={!choices.find((o) => o === perPage)}
            title={t('Be careful, this may slow down the page')}
          >
            {t('Show All')}
          </div>
        )}
      </div>
      <div className={classes.paginationContainer}>
        {page !== 1 && (
          <div
            className={classes.button}
            onClick={() => handleSetPage({ type: 'page', value: 1 })}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </div>
        )}
        {page - 1 > 0 && (
          <div
            className={classes.button}
            onClick={() => handleSetPage({ type: 'page', value: page - 1 })}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        )}
        <div className={classes.pages}>
          {pages.previous[0] !== 1 && page !== 1 && (
            <div className={classes.page}>...</div>
          )}
          {pages.previous.map((number) => (
            <div
              key={number}
              className={classes.page}
              onClick={() => handleSetPage({ type: 'page', value: number })}
            >
              {number}
            </div>
          ))}
          <div className={classes.currentPage}>
            <div>{page}</div>
            <div className={classes.currenPageDetail}>/</div>
            <div className={classes.currenPageDetail}>{pages.totalPage}</div>
          </div>
          {pages.next.map((number) => (
            <div
              key={number}
              className={classes.page}
              onClick={() => handleSetPage({ type: 'page', value: number })}
            >
              {number}
            </div>
          ))}
          {pages.next[pages.next.length - 1] !== pages.totalPage &&
            pages.currentPage === pages.totalPage && (
            <div className={classes.page}>...</div>
          )}
        </div>
        {page + 1 <= pages.totalPage && (
          <div
            className={classes.button}
            onClick={() => handleSetPage({ type: 'page', value: page + 1 })}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        )}
        {page !== pages.totalPage && (
          <div
            className={classes.button}
            onClick={() =>
              handleSetPage({ type: 'page', value: pages.totalPage })
            }
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Pagination);
