import _ from 'lodash';
import ActionStatus from '../../ActionStatus/ActionStatus';
import PropTypes from 'prop-types';

StatusCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired
};

export default function StatusCell({ item, field }) {

  return <ActionStatus showStatusWord={false} status={_.get(item, field)} />;
}