import TermFrequencyUpdateView from './TermFrequencyUpdateView';
import useTermFrequencyUpdate from './useTermFrequencyUpdate';
import { TermInterface } from '../../../types/term.interface';

interface Props {
  readonly term: TermInterface
  readonly onClose: Function
}

export default function TermFrequencyUpdate(props:Props){
  const useProps = useTermFrequencyUpdate(props);

  return <TermFrequencyUpdateView {...props} {...useProps}/>;
}