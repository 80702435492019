import Select from 'react-select';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import createFilterDescription from '../../../Utils/filter/createFilterDescription';
import { useTranslation } from 'react-i18next';

interface props {
  readonly filters: any;
  readonly setFilter: Function;
  readonly name: string;
  readonly options: any;
  readonly label: string;
  readonly placeholder: string;
  readonly reactSelectOptions: any;
  readonly titleMode?: boolean;
  readonly optionsAutosort?: boolean;
  readonly concatMode?: boolean;
  readonly currentValue: Function;
}

export default function useSelectFilter({
  filters,
  setFilter,
  currentValue,
  name,
  label,
  placeholder,
  options,
  reactSelectOptions,
  titleMode,
  optionsAutosort = true,
  concatMode = true,
}: props) {
  const { t } = useTranslation();

  function handleChange(select: Select) {
    const choices = Array.isArray(select) ? select : [select];

    setFilter(
      name,
      choices.length === 0
        ? undefined
        : {
          valueNew: concatMode
            ? choices.map((o) => o.value).join(',')
            : choices.map((o) => o.value),
          whereClause: { inq: choices.map((o) => o.value) },
          description: createFilterDescription(choices),
          value: select,
          label,
        }
    );
    if (currentValue) {
      currentValue(select);
    }
  }

  return {
    label,
    options: {
      menuPosition: 'absolute',
      className: 'basic-multi-select',
      styles: taglistStyle,
      isMulti: true,
      ...reactSelectOptions,
      placeholder: placeholder || t('Select...'),
      options: optionsAutosort
        ? options.sort((a: any, b: any) => a.label.localeCompare(b.label))
        : options,
      value: filters?.[name]?.value || [],
      onChange: handleChange,
    },
    titleMode,
  };
}
