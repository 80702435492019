import { useState } from 'react';
import SubmitButton from '../../SubmitButton/SubmitButton';
import TagForm from '../TagForm/TagForm';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

CreateTag.propTypes = {
  update: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default function CreateTag({ update, isLoading }) {
  const [isOpenForm, setIsOpenForm] = useState();
  const { t } = useTranslation();

  return (
    <>
      <SubmitButton
        isLoading={isLoading}
        onClick={() => setIsOpenForm(true)}
        txt={t('create a tag')}
        testid="create-button"
      />
      {isOpenForm && (
        <TagForm
          update={update}
          setClose={() => setIsOpenForm(false)}
        />
      )}
    </>
  );
}
