import { makeObservable, observable, action } from 'mobx';
import PageStore from '../../stores/page.store';
import { BrandApi } from '../../swagger_client/src';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export default class BrandsStore extends PageStore {
  brandApi = new BrandApi();
  isAdmin = false;
  isLoadingAction = false;
  selectedItem = null;
  selectedItems = [];
  brandToUpdate = null;
  endpoint = '/brands';
  openModaleAutoMatch = null;
  openModaleLinkActiveDomains = null;
  updateMode = false;
  tableHeader = [
    { name: 'selectAll', value: 0, handleClick: () => this.toggleSelectAll(), center: true },
    { name: 'label', title: 'label', sortable: true, center: true },
    { name: 'name', title: 'name', sortable: true, center: true },
    { name: 'autoMatchRegExp', title: 'auto Match', sortable: true, center: true },
    { name: 'companyAccounts', title: 'company account', field:'name', center: true },
    { name: 'validated', title: 'validated', sortable: true, center: true },
    { name: 'createNotification', title: 'notification', center: true },
    { name: 'createShoppingNotification', title: 'Notification Shopping', sortable: true, center: true },
    { name: 'notificationAuto', title: 'notification auto', sortable: true, center: true },
    { name: 'reminder.autoReminder', title: 'autoReminder', sortable: true, center: true },
    { name: 'reminder.frequency', title: 'frequency', sortable: true, center: true },
    { name: 'reminder.quota', title: 'quota', sortable: true, center: true },
    { name: 'actions',title: 'Actions', center: true },
  ];
  constructor(defaultFilters, isAdmin, user) {
    super(user);
    makeObservable(this, {
      isAdmin: observable,
      isLoadingAction: observable,
      selectedItems: observable,
      selectedItem: observable,
      updateMode: observable,
      brandToUpdate: observable,
      openModaleAutoMatch: observable,
      openModaleLinkActiveDomains: observable,
      updateBrand: action,
      addBrand: action,
      updateBoolField: action,
      deleteBrand: action,
      updateOneBrand: action,
      toggleSelectAll: action,
      toggleSelectOne: action,
      updateAutoReminder: action,
      setOpenModaleAutoMatch: action,
      setSelectedItem: action,
      setOpenModaleLinkActiveDomains: action
    });
    if (!this.filters) {
      this.filters = defaultFilters;
    }
    this.defaultFilters = defaultFilters;
    this.isAdmin = isAdmin;
    this.order = 'totalViews';
    this.handleCount();
    this.handleFetch();
  }

  setSelectedItem(value) {
    this.selectedItem = value;
  }

  toggleSelectAll() {
    if (this.filteredItems.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.tableHeader[0].value = 0;
    } else {
      this.selectedItems = this.filteredItems.map((item) => item.id);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item) {
    this.selectedItems = _.xor([item.id], this.selectedItems);
  }

  updateOneBrand(brand) {
    this.brandToUpdate = brand;
  }

  setOpenModaleAutoMatch(brand, option) {
    this.openModaleAutoMatch = brand
      ? {
        brand,
        option,
      }
      : null;
  }

  setOpenModaleLinkActiveDomains(brand, option) {
    this.openModaleLinkActiveDomains = brand
      ? {
        brand,
        option,
      }
      : null;
  }

  async handleCount() {
    this.setIsLoadingCount(true);
    try {
      const { count } = await this.brandApi.brandCount({
        where: JSON.stringify(this.computedFilters),
      });
      this.setCount(count);
    } catch (e) {
      console.error(e);
    }
    this.setIsLoadingCount(false);
  }

  async handleFetch() {
    this.setIsLoading(true);
    try {
      this.setItems(await this.brandApi.brandFind({
        filter: JSON.stringify({
          where: this.computedFilters,
          order: `${this.order} ${this.direction.toUpperCase()}`,
          skip: (this.page - 1) * this.perPage,
          include: ['companyAccounts'],
          limit: this.perPage,
        }),
      }));
    } catch (e) {
      toast.error(i18next.t(e.message.toString()));
    }

    this.initPage = true;
    this.setIsLoading(false);
  }

  get counters() {
    return [{ txt: 'brand', count: this.count }];
  }

  async deleteBrand(idToDelete) {
    this.isLoadingAction = true;
    try {
      await this.brandApi.brandDeleteById(idToDelete);
      this.items = _.filter(this.items, (o) => o.id !== idToDelete);
      this.selectedItem = null;
      toast.success(i18next.t('brand deleted'));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.isLoadingAction = false;
  }



  updateBrand() {
    this.handleFetch();
  }

  get actions() {
    return [
      {
        type: 'delete create notification',
        action: () =>
          this.updateBoolField(
            'createNotification',
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            false
          ),
      },
      {
        type: 'create notification',
        action: () =>
          this.updateBoolField(
            'createNotification',
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            true
          ),
      },
      {
        type: 'unvalidateBrand',
        action: () =>
          this.updateBoolField(
            'validated',
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            false
          ),
      },
      {
        type: 'validatedBrand',
        action: () =>
          this.updateBoolField(
            'validated',
            _.filter(
              this.items,
              (o) => !!this.selectedItems.find((it) => it === o.id)
            ),
            true
          ),
      },
    ];
  }

  addBrand(brand) {
    this.items.push(brand);
  }

  async updateBoolField(fieldName, brands, value) {
    this.isLoadingAction = true;
    this.isLoading = true;
    try {
      await this.brandApi.brandUpdateAll({
        body: { [fieldName]: value },
        where: JSON.stringify({ id: { inq: _.map(brands, (o) => o.id) } }),
      });
      this.isLoadingAction = false;

      if (brands.length === 1) {
        brands[0][fieldName] = value;
        const index = _.findIndex(this.items, { id: brands[0].id });
        this.items.splice(index, 1, brands[0]);
      } else {
        this.isLoadingAction = false;
        this.filters.id = { inq: _.map(brands, (o) => o.id) };
        await this.handleFetch();
        await this.handleCount();
        this.filters.id = undefined;
      }
      this.selectedItem = null;
      toast.success(i18next.t(fieldName + `${value ? '-updated':'-disabled'}`));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.selectedItem = null;
    this.isLoading = false;
  }

  async updateAutoReminder(brand) {
    this.isLoadingAction = true;
    this.isLoading = true;
    const body = { ...brand };

    if (!body.reminder) {
      body.reminder = {};
    }

    body.reminder.autoReminder = brand.reminder
      ? !brand.reminder.autoReminder
      : true;
    try {
      await this.brandApi.brandUpdateAll({
        body,
        where: JSON.stringify({ id: body.id }),
      });
      this.isLoadingAction = false;
      const index = _.findIndex(this.items, { id: body.id });
      this.items.splice(index, 1, body);
      this.selectedItem = null;
      toast.success(i18next.t('autoReminder' + body.reminder.autoReminder ? '-updated':'-disabled'));
    } catch (e) {
      toast.error(i18next.t(e));
    }
    this.selectedItem = null;
    this.isLoading = false;
  }

  filterSearch(item) {
    const { name,label, autoMatchRegExp, companyAccounts } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (label) {
      label.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    
    if (autoMatchRegExp) {
      autoMatchRegExp.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    if (companyAccounts) {
      _.map(
        companyAccounts,
        (companyAccount) =>
          companyAccount.name.toLowerCase().includes(this.inputFilterValue) && count++
      );
    }

    return count > 0 ? true : false;
  }

  getAutoMatchConfig(autoMatch) {
    const autoMatchTestReg = /^(?:\\b)?(.+[^\][^b])(?:\\b)?$/;
    const autoMatchResult = autoMatchTestReg.exec(autoMatch) || [null, ''];
    return {
      fullAutoMatch: autoMatchResult[0],
      rawAutoMatch: autoMatchResult[1],
    };
  }
}
