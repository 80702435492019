import { observer } from 'mobx-react-lite';
import FilterPanelView from './PanelFilterView';
import usePanelFilter from './usePanelFilter';
import { ReactElement } from 'react';

interface Props {
  readonly isOpen:boolean
  readonly setIsOpen: Function,
  readonly pageStore:any
  readonly filtersElements: Array<ReactElement>
}

function PanelFilter(props:Props) {
  const useProps = usePanelFilter(props),
    passProps = { ...props, ...useProps };
  return <FilterPanelView {...passProps} />;
}

export default observer(PanelFilter);