/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 3.39.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';
 import ObjectID from './ObjectID';
 
 /**
 * The Sem model module.
 * @module model/Sem
 * @version 3.39.1
 */
 export default class Sem {
     /**
     * Constructs a new <code>Sem</code>.
     * @alias module:model/Sem
     * @class
     * @param term {String} 
     * @param _date {Date} 
     * @param brand {String} 
     * @param description {String} 
     * @param targetUrl {String} 
     * @param position {Number} 
     * @param country {String} 
     * @param language {String} 
     * @param engine {String} 
     * @param type {String} 
     * @param device {String} 
     */
 
     constructor(term, _date, brand, description, targetUrl, position, country, language, engine, type, device) {
         
         
         this['term'] = term;
         this['date'] = _date;
         this['brand'] = brand;
         this['description'] = description;
         this['targetUrl'] = targetUrl;
         this['position'] = position;
         this['country'] = country;
         this['language'] = language;
         this['engine'] = engine;
         this['type'] = type;
         this['device'] = device;
         
     }
 
     /**
     * Constructs a <code>Sem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Sem} obj Optional instance to populate.
     * @return {module:model/Sem} The populated <code>Sem</code> instance.
     */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new Sem();
                         
             
             if (data.hasOwnProperty('term')) {
                 obj['term'] = ApiClient.convertToType(data['term'], 'String');
             }
             if (data.hasOwnProperty('date')) {
                 obj['date'] = ApiClient.convertToType(data['date'], 'Date');
             }
             if (data.hasOwnProperty('brand')) {
                 obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
             }
             if (data.hasOwnProperty('title')) {
                 obj['title'] = ApiClient.convertToType(data['title'], 'String');
             }
             if (data.hasOwnProperty('description')) {
                 obj['description'] = ApiClient.convertToType(data['description'], 'String');
             }
             if (data.hasOwnProperty('displayUrl')) {
                 obj['displayUrl'] = ApiClient.convertToType(data['displayUrl'], 'String');
             }
             if (data.hasOwnProperty('targetUrl')) {
                 obj['targetUrl'] = ApiClient.convertToType(data['targetUrl'], 'String');
             }
             if (data.hasOwnProperty('position')) {
                 obj['position'] = ApiClient.convertToType(data['position'], 'Number');
             }
             if (data.hasOwnProperty('realPosition')) {
                 obj['realPosition'] = ApiClient.convertToType(data['realPosition'], 'Number');
             }
             if (data.hasOwnProperty('domain')) {
                 obj['domain'] = ApiClient.convertToType(data['domain'], 'String');
             }
             if (data.hasOwnProperty('country')) {
                 obj['country'] = ApiClient.convertToType(data['country'], 'String');
             }
             if (data.hasOwnProperty('language')) {
                 obj['language'] = ApiClient.convertToType(data['language'], 'String');
             }
             if (data.hasOwnProperty('region')) {
                 obj['region'] = ApiClient.convertToType(data['region'], 'String');
             }
             if (data.hasOwnProperty('engine')) {
                 obj['engine'] = ApiClient.convertToType(data['engine'], 'String');
             }
             if (data.hasOwnProperty('type')) {
                 obj['type'] = ApiClient.convertToType(data['type'], 'String');
             }
             if (data.hasOwnProperty('device')) {
                 obj['device'] = ApiClient.convertToType(data['device'], 'String');
             }
             if (data.hasOwnProperty('id')) {
                 obj['id'] = ObjectID.constructFromObject(data['id']);
             }
             if (data.hasOwnProperty('termId')) {
                 obj['termId'] = ObjectID.constructFromObject(data['termId']);
             }
             if (data.hasOwnProperty('dealer')) {
                 obj['dealer'] = ObjectID.constructFromObject(data['dealer']);
             }
 
         }
         return obj;
     }
 
     /**
     * @member {String} term
     */
     term = undefined;
     /**
     * @member {Date} date
     */
     date = undefined;
     /**
     * @member {String} brand
     */
     brand = undefined;
     /**
     * @member {String} title
     */
     title = undefined;
     /**
     * @member {String} description
     */
     description = undefined;
     /**
     * @member {String} displayUrl
     */
     displayUrl = undefined;
     /**
     * @member {String} targetUrl
     */
     targetUrl = undefined;
     /**
     * @member {Number} position
     */
     position = undefined;
     /**
     * @member {Number} realPosition
     */
     realPosition = undefined;
     /**
     * @member {String} domain
     */
     domain = undefined;
     /**
     * @member {String} country
     */
     country = undefined;
     /**
     * @member {String} language
     */
     language = undefined;
     /**
     * @member {String} region
     */
     region = undefined;
     /**
     * @member {String} engine
     */
     engine = undefined;
     /**
     * @member {String} type
     * @default 'ads'
     */
     type = 'ads';
     /**
     * @member {String} device
     * @default 'desktop'
     */
     device = 'desktop';
     /**
     * @member {module:model/ObjectID} id
     */
     id = undefined;
     /**
     * @member {module:model/ObjectID} termId
     */
     termId = undefined;
 
 
 
 
 } 