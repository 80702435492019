import { observer } from 'mobx-react-lite';
import classes from './ShortNavFilter.module.css';
import PropTypes from 'prop-types';
import FiltersDescriptions from '../FiltersDescriptions/FiltersDescriptions';
import Counters from './Counters';

// This component displays the block for updatable filters

// This component displays the whole block for filters

ShortNavFilter.propTypes = {
  pageStore: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  counters: PropTypes.arrayOf(PropTypes.object).isRequired,
  countersNoParenthesis: PropTypes.bool
};

function ShortNavFilter({ counters, isLoading, pageStore, countersNoParenthesis }) {
  return (
    <div className={classes.container}>
      <div className={classes.description}>
        {pageStore?.filtersAsArray?.length > 0 && (
          <FiltersDescriptions
            filters={pageStore.computedFiltersDescription}
            deleteFilter={(name)=>pageStore.deleteFilter(name)}
            revertFilter={(filter)=>pageStore.revertFilter(filter)}
            setCurrentHoverFilter={() => {}}
            clearAll={()=> pageStore.reinitFilters()}
          />
        )}
      </div>
      <div className={classes.counters}>
        <Counters
          isLoading={isLoading}
          counters={counters}
          noParenthesis={countersNoParenthesis}
        />
      </div>
    </div>
  );
}

export default observer(ShortNavFilter);
