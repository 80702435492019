import { DateState } from '../../../../types/dateState.interface';
import DateFilterResumeView from './DateFilterResumeView';
import useDateFilterResume from './useDateFilterResume';

interface Props {
  readonly name: string
  readonly filters: any
  readonly dateMin: Date
  readonly label:string
  readonly handleConfirm: Function
  readonly setDateState: Function
  readonly setFilter:Function
  readonly setToggle: Function
  readonly locale: any
  readonly toggle: boolean
  readonly dateState: DateState
}

export default function DateFilterResume(props:Props){
  const 
    useProps = useDateFilterResume(props),
    passProps = {...props,...useProps};

  return <DateFilterResumeView {...passProps}/>;
}