/**
 * backend-api
 * LoopBack Application
 *
 * OpenAPI spec version: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Contact from './Contact';
import ObjectID from './ObjectID';

/**
* The ActionEmail model module.
* @module model/ActionEmail
* @version 4.0.0
*/
export default class ActionEmail {
    /**
    * Constructs a new <code>ActionEmail</code>.
    * @alias module:model/ActionEmail
    * @class
    * @param status {String} 
    * @param createdDate {Date} 
    * @param open {Boolean} 
    */

    constructor(status, createdDate, open) {
        
        
        this['status'] = status;
        this['createdDate'] = createdDate;
        this['open'] = open;
        
    }

    /**
    * Constructs a <code>ActionEmail</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ActionEmail} obj Optional instance to populate.
    * @return {module:model/ActionEmail} The populated <code>ActionEmail</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ActionEmail();
                        
            
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], Object);
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
            if (data.hasOwnProperty('subject')) {
                obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('createdDate')) {
                obj['createdDate'] = ApiClient.convertToType(data['createdDate'], 'Date');
            }
            if (data.hasOwnProperty('receivedDate')) {
                obj['receivedDate'] = ApiClient.convertToType(data['receivedDate'], 'Date');
            }
            if (data.hasOwnProperty('sendedDate')) {
                obj['sendedDate'] = ApiClient.convertToType(data['sendedDate'], 'Date');
            }
            if (data.hasOwnProperty('open')) {
                obj['open'] = ApiClient.convertToType(data['open'], 'Boolean');
            }
            if (data.hasOwnProperty('events')) {
                obj['events'] = ApiClient.convertToType(data['events'], [Object]);
            }
            if (data.hasOwnProperty('templateType')) {
                obj['templateType'] = ApiClient.convertToType(data['templateType'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ObjectID.constructFromObject(data['id']);
            }
            if (data.hasOwnProperty('recipients')) {
                obj['recipients'] = ApiClient.convertToType(data['recipients'], [Contact]);
            }
            if (data.hasOwnProperty('actionId')) {
                obj['actionId'] = ObjectID.constructFromObject(data['actionId']);
            }
            if (data.hasOwnProperty('attachments')) {
                obj['attachments'] = ApiClient.convertToType(data['attachments'], [ObjectID]);
            }
        }
        return obj;
    }

    /**
    * @member {Object} email
    */
    email = undefined;
    /**
    * @member {String} body
    */
    body = undefined;
    /**
    * @member {String} subject
    */
    subject = undefined;
    /**
    * @member {String} status
    * @default 'draft'
    */
    status = 'draft';
    /**
    * @member {Date} createdDate
    */
    createdDate = undefined;
    /**
    * @member {Date} receivedDate
    */
    receivedDate = undefined;
    /**
    * @member {Date} sendedDate
    */
    sendedDate = undefined;
    /**
    * @member {Boolean} open
    * @default false
    */
    open = false;
    /**
    * @member {Array.<Object>} events
    */
    events = undefined;
    /**
    * @member {String} templateType
    */
    templateType = undefined;
    /**
    * @member {module:model/ObjectID} id
    */
    id = undefined;
    /**
    * @member {Array.<module:model/Contact>} recipients
    */
    recipients = undefined;
    /**
    * @member {module:model/ObjectID} actionId
    */
    actionId = undefined;
    /**
    * @member {Array.<module:model/ObjectID>} attachments
    */
    attachments = undefined;




}
