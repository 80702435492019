import PropTypes from 'prop-types';
import classes from './FormInputTxt.module.css';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

FormInputTxt.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text','number','email','password']),
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};

FormInputTxt.defaultProps = {
  type:'text',
  rules:{}
};

export default function FormInputTxt({
  name,
  label,
  placeholder,
  register,
  errors,
  onChange,
  type,
  disabled
}) {

  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <label className={classes.label} htmlFor={name}>
        {t(label)}
      </label>
      <input
        disabled={disabled}
        className={classes.input}
        data-error={!!_.get(errors,name)}
        name={name}
        type={type}
        onChange={onChange}
        data-testid={name}
        id={name}
        placeholder={t(placeholder)}
        ref={register}
      />
      {errors && _.get(errors,name) && (
        <div className={classes.errorMsg}>{t(errors[name].message)}</div>
      )}
    </div>
  );
}
