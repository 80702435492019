import { observer } from 'mobx-react';
import useTermsFilter from './useTermsFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/NewAsyncCreateFilter';

interface Props extends FilterChild {
  readonly filterKey: string;
}

function TermsFilter(props: Props) {
  const useProps = useTermsFilter(props.filterKey, props?.options),
    passProps = { ...props, ...useProps };

  return <AsyncCreateFilter {...passProps} />;
}

export default observer(TermsFilter);
