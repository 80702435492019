import _ from 'lodash';
import classes from './ConversationExchangeCell.module.css';
// import SmallEnvelopeSVG from '../../components/Icon/svg/SmallEnvelope.svg';
import MessagesIcon from './MessagesIcon';
import PaperPlaneSVG from '../../components/Icon/svg/PaperPlane.svg';

interface LabelWithIconProps {
  text: string;
  icon: any;
  textClassName?: string;
}

function LabelWithIcon({ text, icon, textClassName }: LabelWithIconProps) {
  return (
    <div className={classes.labelWithIcon}>
      <div className={classes.icon}>{icon}</div>
      <div className={textClassName}>{text}</div>
    </div>
  );
}

interface ExchangesCellProps {
  item?: any;
  field: string;
  messagesField: string;
  sentMessagesField: string;
}

export default function ExchangesCell({ item, messagesField, sentMessagesField }: ExchangesCellProps) {
  return (
    <div className={classes.container}>
      <LabelWithIcon
        text={_.get(item, messagesField)?.toString() || '0'}
        textClassName={classes.counterText}
        icon={<MessagesIcon count={0} />}
      />
      <LabelWithIcon
        text={_.get(item, sentMessagesField)?.toString() || '0'}
        textClassName={classes.counterText}
        icon={<PaperPlaneSVG height={12} color="var(--color-link-menu)" />}
      />
    </div>
  );
}
