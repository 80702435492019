import PropTypes from 'prop-types';

export default function OpenNewTabSVG({ color, onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      <path
        d="M-3804.079-1444.276a.941.941,0,0,1,0-1.331l12.511-12.511h-7.14a.941.941,0,0,1-.94-.941.941.941,0,0,1,.94-.942h9.412a.939.939,0,0,1,.4.087h0l.017.008.006,0,.013.007.01,0,.01.005.012.006.007,0,.014.008.005,0,.015.009,0,0,.016.01,0,0,.016.011,0,0,.016.011,0,0,.015.012,0,0,.014.011,0,0,.013.011.007.006.01.009.01.009.008.007.017.016.016.017.007.008.008.009.01.011.005.006.01.013,0,0,.011.014,0,0,.011.015,0,0,.011.016,0,0,.011.016,0,0,.01.016,0,0,.009.015,0,.005.008.014,0,.007.007.012.005.01,0,.009.007.013,0,.006.008.017v0a.937.937,0,0,1,.087.4v9.411a.941.941,0,0,1-.941.941.942.942,0,0,1-.941-.941v-7.139l-12.51,12.511a.938.938,0,0,1-.666.276A.934.934,0,0,1-3804.079-1444.276Z"
        transform="translate(3804.355 1460)"
        fill={color || '#fff'}
      />
    </svg>
  );
}

OpenNewTabSVG.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func
};
