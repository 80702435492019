import PropTypes from 'prop-types';

export default function ReportingSVG({ color }) {
  return (
    <g id="_136_e-commerce" data-name="136 – e-commerce">
      <rect id="body" width="25" height="25" fill="none" />
      <g id="icon" transform="translate(1.667 1.667)">
        <path
          id="Path_166"
          data-name="Path 166"
          d="M19.307,21.667H2.5a2.5,2.5,0,0,1-2.5-2.5H0a2.5,2.5,0,0,1,2.5-2.5H14.167a2.5,2.5,0,0,1,2.5,2.5h0a2.5,2.5,0,0,0,2.5,2.5h0a2.5,2.5,0,0,0,2.5-2.5V3a3,3,0,0,0-3-3H6.333a3,3,0,0,0-3,3V16.667"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_167"
          data-name="Path 167"
          d="M0,.417H8.333"
          transform="translate(8.333 6.25)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
        <path
          id="Path_167_Copy"
          data-name="Path 167 Copy"
          d="M0,.417H5"
          transform="translate(8.333 9.583)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
    </g>
  );
}

ReportingSVG.propTypes = {
  color: PropTypes.string.isRequired,
};
