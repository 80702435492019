import { useReducer, Reducer, useState } from 'react';
import { Action } from '../../../types/action.newBackend';
import Backend from '../../../stores/newBackend';

type ActionResult = {
  type: 'results';
  advertisements: any[];
  total: number;
};

type ActionClear = {
  type: 'clearResults';
};

type ActionCountResult = {
  type: 'countResults';
  countAdvertisement: {
    countAdvertisement: number;
    countShopping: number;
  };
  total: number;
};

type ReducerAction =
  | { type: 'load' }
  | { type: 'loadCount' }
  | ActionResult
  | ActionCountResult
  | ActionClear
  | { type: 'error'; error: any };

interface State {
  loading: boolean;
  loadingCount: boolean;
  error: any;
  advertisements: any[];
  countAdvertisement: {
    countAdvertisement: number;
    countShopping: number;
  };
  total: number;
}

const useGetActionsInitialeState = {
  loading: true,
  loadingCount: true,
  error: null,
  advertisements: [],
  countAdvertisement: {
    countAdvertisement: -1,
    countShopping: -1,
  },
  total: 0,
};

export default function useGetAdvertisements(initState?: Partial<State>) {
  const [
    { loading, loadingCount, error, advertisements, countAdvertisement, total },
    dispatch,
  ] = useReducer<Reducer<State, ReducerAction>>(
    useGetActionsReducer,
    initState
      ? {
          ...useGetActionsInitialeState,
          ...initState,
        }
      : useGetActionsInitialeState
  );

  const [fetchBackend] = useState(() => {
    return async (token: string, actionID: string, criteria?: any) => {
      dispatch({
        type: 'load',
      });

      try {
        const results = await Backend.searchAdvertisementsByActionID(token, actionID, criteria);
        const dispatchData: ActionResult = {
          type: 'results',
          advertisements: results,
          total: 0,
        };

        dispatch(dispatchData);
      } catch (e) {
        dispatch({ type: 'error', error: e });
      }
    };
  });

  const fetchCountBackend = async (token: string, actionID: string) => {
    dispatch({
      type: 'loadCount',
    });

    try {
      const count = await Backend.countAdvertisementByActionID(token, actionID);
      const dispatchData: ActionCountResult = {
        type: 'countResults',
        countAdvertisement: count,
        total: 0,
      };

      dispatch(dispatchData);
    } catch (e) {
      dispatch({ type: 'error', error: e });
    }
  };

  const clear = () => {
    const dispatchData: ActionClear = {
      type: 'clearResults',
    };
    dispatch(dispatchData);
  };

  return {
    loading,
    loadingCount,
    error,
    advertisements,
    countAdvertisement,
    fetch: fetchBackend,
    fetchCount: fetchCountBackend,
    clear,
    total,
  };
}

function useGetActionsReducer(state: State, action: ReducerAction): State {
  const newState = { ...state };
  switch (action.type) {
    case 'load':
      newState.loading = true;
      newState.advertisements = [];
      newState.error = undefined;
      break;
    case 'loadCount':
      newState.loadingCount = true;
      newState.countAdvertisement = {
        countAdvertisement: -1,
        countShopping: -1,
      };
      newState.error = undefined;
      break;
    case 'results':
      newState.loading = false;
      newState.advertisements = action.advertisements;
      newState.total = action.total;
      break;
    case 'countResults':
      newState.loadingCount = false;
      newState.countAdvertisement = action.countAdvertisement;
      newState.total = action.total;
      break;
    case 'clearResults':
      newState.loading = false;
      newState.advertisements = [];
      newState.total = 0;
      break;
    case 'error':
      newState.loading = false;
      newState.error = action.error;
      break;
    default:
      throw new Error();
  }

  return newState;
}
