import { useTranslation } from 'react-i18next';
import translateEvery from '../../../Utils/translateEvery';
import PropTypes from 'prop-types';
import termDefinition from '../../../proptypes/term.definition';

SettingTermsFrequencyCell.propTypes = {
  item: PropTypes.shape(termDefinition),
};

export default function SettingTermsFrequencyCell({item}){
  const {t} = useTranslation();
  return translateEvery(item.every, t); 
}