import domainDefinition from '../../../proptypes/domain.definition';
import classes from './DomainsRealPositonAvgCell.module.css';
import PropTypes from 'prop-types';

DomainsRealPositonAvgCell.propTypes = {
  item: PropTypes.shape(domainDefinition),
  field: PropTypes.string,
};

export default function DomainsRealPositonAvgCell({ item, field }) {
  const num = item[field].toFixed(4).split('.');
  return (
    <div>
      <span className={classes.int}>{num[0]}</span>.
      <span className={classes.float}>{num[1] || '0000'}</span>
    </div>
  );
}
